import React, { useEffect, useState, memo } from "react";
import clsx from "clsx";
import { makeStyles, Box, Theme, Typography, FormControl, useMediaQuery } from "@material-ui/core";
import TextInput from "../input/TextInputV2";
import Form, { FormInput } from "../input/Form";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { setUserVanity, setUserData } from "../../reducers/user/UserSlice";
import { updateProfileAction, validateUserNickname } from "../../actions/userAction";
import { removeUserFirstTimeLogin } from "../../utils/localStorage";
import useKeyPress from "../../hooks/useKeyPressed";
import useDebounce from "../../hooks/useDebounce";
import { textColor } from "../../style";
import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import TextInputV4 from "../input/TextInputV4";

const useStyle = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  heading: {
    marginTop: 9,
    marginBottom: 25,
    textAlign: "center",
    maxWidth: 278,
    fontWeight: 700,
    lineHeight: "40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 18
    }
  },
  subtitle: {
    ...theme.typography.subtitle1,
    marginBottom: "15px",
    fontSize: 14,
    color: textColor.LIGHT_GRAY,
    fontWeight: "normal"
  },
  textField: {
    fontSize: 16,
    color: textColor.GRAY
  },
  button: {
    width: "100%",
    height: "46px",
    borderRadius: "px",
    padding: 0,
    background: "linear-gradient(135deg, #eb3693, #f0017c)",
    marginTop: 45,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 25
    }
  },
  buttonDisabled: {
    background: "#868686",
    backgroundColor: "#868686 !important",
    color: "#fff !important"
  },

  buttonAvailability: {
    width: "100%",
    height: "46px",
    borderRadius: "23px",
    padding: 0,
    marginTop: "20px",
    marginBottom: "20px",
    color: "#f0017c"
  },
  validateSuccess: {
    fontSize: "14px",
    fontStyle: "italic",
    color: "#B5B5B5",
    marginTop: 5,
    marginBottom: 5
  },
  iconValidator: {
    position: "absolute",
    top: "14px",
    right: "-6px"
  }
}));

interface ContentNicknameProps {
  nextStep: () => void;
}

const HelperTextMessage = memo(() => {
  const classes = useStyle();
  return <span className={classes.validateSuccess}>*This field is required.</span>;
});

HelperTextMessage.displayName = "HelperTextMessage";

const ContentNickname = memo(({ nextStep }: ContentNicknameProps) => {
  const classes = useStyle();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const data = useAppSelector((state) => state.user.data);
  const dispatch = useAppDispatch();

  const initialFormInputs = {
    nickname: FormInput()
  };

  const validateForm = (inputs = formInputs) => {
    let hasError: boolean;
    for (const inputName in inputs) {
      const formInput = inputs[inputName];
      if ("nickname" === inputName) {
        // formInput.error = formInput.value === "" ? "This is required" : "";
        formInput.error = formInput.value === "";
        setDisabled(true);
      }
      hasError = hasError ? hasError : formInput.error !== "";
    }
    return !hasError;
  };

  const checkAvailability = async () => {
    const nickname = formInputs.nickname.value.trim();
    if (nickname === "") {
      formInputs.nickname.error = "This is required.";
    } else {
      try {
        const response = await validateUserNickname(nickname);
        if (!response.isAvailable) {
          formInputs.nickname.error = response.message;
          setIsValid(false);
        } else {
          formInputs.nickname.error = "";
          setIsValid(true);
        }
        setFormInputs({ ...formInputs });
      } catch (error) {
        formInputs.nickname.error = "Error validating handle.";
        setIsValid(false);
      }
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    if (!isValid) return false;
    try {
      const payload = {
        nickname: formInputs.nickname.value,
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        gender: data.gender
      };
      const response = await updateProfileAction(payload);
      if (typeof response.data.vanity !== "undefined" && response.data.vanity.length) {
        dispatch(
          setUserData({
            ...data,
            nickname: formInputs.nickname.value
          })
        );
        dispatch(setUserVanity(response.data.vanity));
      }
      // Remove userFirstTimeLogin localstorage
      removeUserFirstTimeLogin();
      nextStep();
    } catch (error) {
      formInputs.nickname.error = error.response.data;
      setIsValid(false);
    } finally {
      setIsLoading(false);
    }
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const handleUserInput = useDebounce(formInputs.nickname.value, 400);
  useEffect(() => {
    if (handleUserInput) {
      checkAvailability();
    }
  }, [handleUserInput]);

  const hasPressedEnter: boolean = useKeyPress("Enter");
  useEffect(() => {
    if (hasPressedEnter && isValid) {
      submitForm();
    }
  }, [hasPressedEnter, isValid]);

  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.heading}>
        {"Let's Start With Your Handle"}
      </Typography>
      <FormControl fullWidth>
        {!isValid && <HelperTextMessage />}
        <TextInputV4
          variant="outlined"
          id="nickname"
          name="nickname"
          label="Your Handle"
          marginTop="10px"
          width="100%"
          placeholder="Your Handle"
          InputLabelProps={{
            style: {
              fontSize: 16,
              lineHeight: 0.5
            }
          }}
          InputProps={{
            classes: {
              input: classes.textField
            },
            endAdornment: {
              classes: {
                borderRadius: 30
              }
            },
            disabled: isLoading
          }}
          autoFocus={true}
          onChange={(e) => {
            handleInputChange(e);
            setIsValid(false);
          }}
          value={formInputs.nickname.value}
          error={formInputs.nickname.error}
          helperText={
            disabled && (formInputs.nickname.error ? formInputs.nickname.error : handleUserInput === "" && "")
          }
          onFocus={handleOnFocus}
          showSuccessIcon={isValid}
          showErrorIcon={formInputs.nickname.error}
        />
      </FormControl>
      <PrimaryButtonV3
        className={clsx(classes.button, !isValid && classes.buttonDisabled)}
        handleClick={submitForm}
        disabled={isLoading || !isValid}
      >
        {isLoading ? "Saving Handle..." : "Save Handle"}
      </PrimaryButtonV3>
      <Typography variant="subtitle1" className={classes.subtitle}>
        This handle goes in the Wish List URL that you'll share with friends, and helps others to find you. You can 
        change your handle anytime.
      </Typography>
    </Box>
  );
});

ContentNickname.displayName = "ContentNickname";
export default ContentNickname;
