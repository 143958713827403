import React from "react";
import { Box } from "@material-ui/core";

import SectionTitle from "../../common/sectionTitle/SectionTitle";

export default function PersonalHeader() {
  return (
    <Box paddingX="16px" paddingTop="32px" paddingBottom="16px" width={{ lg: "45%" }} marginX="auto">
      <SectionTitle>Profile info</SectionTitle>
    </Box>
  );
}
