import React from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import TwitterLogo from "./twitter_logo_white.png";
import { makeStyles } from "@material-ui/core/styles";
import { AuthButtonsProps } from "../types";

const useButtonStyle = makeStyles(() => ({
  button: {
    backgroundColor: "#38A1F3",
    height: "46px",
    borderRadius: "23px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#38A1F3"
    }
  }
}));

export default function TwitterLoginButton({ className, label, ...rest }: AuthButtonsProps): ReturnType<React.FC> {
  const classes = useButtonStyle();

  return (
    <Button className={clsx(classes.button, className)} {...rest}>
      <img src={TwitterLogo} alt={`${label} with Twitter`} height="15px" width="15px" style={{ marginRight: "10px" }} />
      <div style={{ color: "#FFFFFF" }}>{`${label} with Twitter`}</div>
    </Button>
  );
}
