import { makeStyles } from "@material-ui/core/styles";
import { DESKTOP_CONTENT_WIDTH } from "../common/Constants";
import {
  TOOLBAR_DEFAULT_HEIGHT,
  TOOLBAR_SM_HEIGHT,
  TOOLBAR_XS_HEIGHT
} from "../common/DesktopAppHeader/DesktopAppHeaderStyles";

const CHECKOUT_ROOT_MARGIN_TOP = 40;

export const CheckoutStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      width: DESKTOP_CONTENT_WIDTH,
      marginTop: CHECKOUT_ROOT_MARGIN_TOP + "px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 16
    }
  },
  leftCard: {
    borderRadius: "8px",
    boxShadow: "inset 0 -1px 0 0 #E3E3E3, 0 1px 7px 0 rgba(0,0,0,0.15)",
    [theme.breakpoints.up("lg")]: {
      flexBasis: "57%"
    }
  },
  rightCard: {
    position: "sticky",
    flexBasis: "39%",
    borderRadius: "8px",
    boxShadow: "inset 0 -1px 0 0 #E3E3E3, 0 1px 7px 0 rgba(0,0,0,0.15)",
    height: "fit-content",
    top: checkoutOrderDetailCardStickyTop(TOOLBAR_DEFAULT_HEIGHT),
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      top: checkoutOrderDetailCardStickyTop(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      top: checkoutOrderDetailCardStickyTop(TOOLBAR_SM_HEIGHT)
    }
  },
  cardContent: {
    padding: "24px"
  },
  supportedCardsImg: {
    width: "193px",
    height: "34px"
  },
  paymentInfoContainer: {
    width: "100%",
    marginTop: "44px",
    [theme.breakpoints.up("lg")]: {
      width: "70%"
    }
  },
  headerGuestCheckoutContainer: {
    width: "100%"
  },
  billingInfoContainer: {
    marginTop: "44px"
  },
  orderButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  },
  orderCircularProgress: {
    color: "#fff",
    width: "20px !important",
    height: "20px !important",
    marginRight: "20px"
  },
  tableCell: {
    borderBottom: "none",
    padding: "7px 16px"
  },
  beforeLastTableCell: {
    borderBottom: "none",
    padding: "7px 16px 14px 16px"
  },
  highlightedTableCell: {
    backgroundColor: "#F7F7F7",
    borderBottom: "none",
    padding: "14px 16px"
  }
}));

function checkoutOrderDetailCardStickyTop(toolbarHeight) {
  return CHECKOUT_ROOT_MARGIN_TOP + toolbarHeight + `px`;
}
