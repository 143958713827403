import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const IconInstagram = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.996" viewBox="0 0 20 19.996"> */}
      <path
        id="Icon_awesome-instagram"
        data-name="Icon awesome-instagram"
        d="M10,7.109a5.127,5.127,0,1,0,5.127,5.127A5.118,5.118,0,0,0,10,7.109Zm0,8.46a3.333,3.333,0,1,1,3.333-3.333A3.339,3.339,0,0,1,10,15.568ZM16.529,6.9a1.2,1.2,0,1,1-1.2-1.2A1.193,1.193,0,0,1,16.529,6.9Zm3.4,1.214a5.918,5.918,0,0,0-1.615-4.19,5.956,5.956,0,0,0-4.19-1.615c-1.651-.094-6.6-.094-8.25,0A5.948,5.948,0,0,0,1.68,3.919,5.937,5.937,0,0,0,.065,8.108c-.094,1.651-.094,6.6,0,8.25a5.918,5.918,0,0,0,1.615,4.19,5.964,5.964,0,0,0,4.19,1.615c1.651.094,6.6.094,8.25,0a5.918,5.918,0,0,0,4.19-1.615,5.956,5.956,0,0,0,1.615-4.19c.094-1.651.094-6.595,0-8.245ZM17.792,18.129a3.374,3.374,0,0,1-1.9,1.9c-1.316.522-4.439.4-5.894.4s-4.582.116-5.894-.4a3.374,3.374,0,0,1-1.9-1.9c-.522-1.316-.4-4.439-.4-5.894s-.116-4.582.4-5.894a3.374,3.374,0,0,1,1.9-1.9c1.316-.522,4.439-.4,5.894-.4s4.582-.116,5.894.4a3.374,3.374,0,0,1,1.9,1.9c.522,1.316.4,4.439.4,5.894S18.314,16.818,17.792,18.129Z"
        transform="translate(0.005 -2.238)"
        fill="#ef1581"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default IconInstagram;
