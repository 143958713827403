import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import axios from "axios";
import RollbarTracker from "../../utils/RollbarTracker";

/**
 * This component is used for resolving gift receivers userId and then redirecting them to the receivers wishlist.
 * @param props
 * @constructor
 */
export default function GifterRedirect(props: any) {
  const wishlistUserId = props.match.params.userId;

  const getWishlistUsersEncryptedUserId = () => {
    axios
      .get(`/api/getEncryptedUserId?user_id=${wishlistUserId}`)
      .then((response) => {
        const encryptedUserId = response.data.encryptedUserId;
        if (encryptedUserId.length > 0) {
          props.history.push("/gift/wishlist/" + encryptedUserId);
        }
      })
      .catch((error) => {
        RollbarTracker.logError("Error getting Encrypted userId", error);
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    if (wishlistUserId) {
      getWishlistUsersEncryptedUserId();
    }
  }, []);

  return (
    <Box>
      <Box height={150} display="flex" justifyContent="center">
        <CircularProgress style={{ marginTop: "50px" }} />
      </Box>
    </Box>
  );
}
