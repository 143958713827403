import React from "react";
import Hero from "./hero/Hero";
import HowItWorksSection from "./sections/HowItWorksSection";
import ManifestGiftsSection from "./sections/ManifestGiftsSection";
import PrivacyEverythingSection from "./sections/PrivacyEverythingSection";
import CategoriesSection from "./sections/CategoriesSection";
import MostWishedSection from "./sections/MostWishedSection";
import StartYourWishlistSection from "./sections/StartYourWishlistSection";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "./theme";

const Home = (): ReturnType<React.FC> => {
  return (
    <AppLayout theme={theme} style={{ marginTop: -64 }}>
      <Hero />
      <HowItWorksSection />
      <ManifestGiftsSection />
      <PrivacyEverythingSection />
      <CategoriesSection />
      <MostWishedSection />
      <StartYourWishlistSection />
    </AppLayout>
  );
};

export default Home;
