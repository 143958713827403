import React, { useState, memo, useRef, useEffect } from "react";
import { makeStyles, Box, Theme, FormControl, TextField, withStyles } from "@material-ui/core";
import { useAppSelector } from "../../hooks";
import { vysionShare } from "../../sdk";
import Toast from "light-toast";
import CopyIcon from "../customIcons/CopyIcon";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: 24,
    marginTop: 34
  },
  flex: {
    display: "flex",
    alignSelf: "flex-start"
  },
  heading: {
    ...theme.typography.h4,
    marginTop: "10px",
    marginBottom: "42px",
    textAlign: "center",
    maxWidth: "325px",
    fontSize: 26,
    fontWeight: "bold"
  },
  textField: {
    fontSize: 16,
    padding: "14px",
    marginRight: "15px",
    width: "430px"
  },

  iconValidator: {
    position: "absolute",
    top: "-2px",
    right: "-3px",
    [theme.breakpoints.down("sm")]: {
      right: "-19px"
    }
  },
  fakeField: {
    opacity: 0,
    position: "absolute",
    top: 0,
    width: "100%"
  }
}));

interface PersonalizeVanityUrlViewProps {
  nextStep?: () => void;
}

const PersonalizeVanityUrlView = memo(({ nextStep }: PersonalizeVanityUrlViewProps) => {
  const SITE_URL = window.location.origin;
  const { vanity: defaultVanity, encryptedUserId } = useAppSelector((state) => state.user.data);
  const [unValidatedVanity, setUnValidatedVanity] = useState(defaultVanity);
  const [error, setError] = useState(null);
  const [wishlistUrl, setWishlistUrl] = useState<string>("");
  const textAreaRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    textAreaRef.current.blur();
    Toast.info("Copied to clipboard.", 750);
    console.log(wishlistUrl);
    vysionShare("wishlist_share_url", wishlistUrl, encryptedUserId);
  };

  useEffect(() => {
    if (defaultVanity) {
      setWishlistUrl(`${SITE_URL}/${defaultVanity}`);
      setUnValidatedVanity(defaultVanity);
    } else {
      setWishlistUrl(`${SITE_URL}/gift/wishlist/${encryptedUserId}`);
    }
  }, [defaultVanity]);

  const TextFieldWithStyles = withStyles({
    root: {
      borderRadius: 30,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
      },
      "& div": {
        borderRadius: 30
      }
    }
  })(TextField);

  return (
    <Box className={classes.root}>
      <FormControl fullWidth>
        <TextField inputRef={textAreaRef} variant="outlined" className={classes.fakeField} value={wishlistUrl} />
        <TextFieldWithStyles
          id="vanity-url"
          variant="outlined"
          name="vanityUrl"
          label="Your personal wishlist URL"
          defaultValue={`${SITE_URL}/${unValidatedVanity}`}
          value={`${SITE_URL}/${unValidatedVanity}`}
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.textField
            }
          }}
          style={{
            cursor: "grab"
          }}
          error={error}
        />
        <Box
          style={{
            position: "absolute",
            right: "18.5px",
            top: "17px"
          }}
          display="flex"
          flexDirection="row"
        >
          {document.queryCommandSupported("copy") && (
            <CopyIcon
              className={classes.iconValidator}
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard()}
            />
          )}
        </Box>
      </FormControl>
    </Box>
  );
});

PersonalizeVanityUrlView.displayName = "PersonalizeVanityUrlView";
export default PersonalizeVanityUrlView;
