import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import SectionSubtitle from "../common/sectionTitle/SectionSubtitle";
import PrimaryButton from "../buttons/PrimaryButton";
import { DESKTOP_CONTENT_WIDTH } from "../common/Constants";
import useNav, { clearHistoryState } from "../../hooks/useNav";
import { STOREHOME_PATH } from "../../constants/paths";
import { useQuery } from "../../hooks/useQuery";
import ErrorPageGif from "./error-page-gif.gif";

const ErrorContent = (): ReturnType<React.FC> => {
  const location = useLocation();
  const query = useQuery();
  const { goTo } = useNav();
  const [errorMessage, setErrorMessage] = useState<string>(
    query.get("errorMessage")
      ? `${query.get("errorMessage")} <br/><br/>  Find gifts by browsing our stores.`
      : "There was an error processing this request. Please try again later <br/><br/> Find gifts by browsing our stores."
  );

  useEffect(() => {
    const errMsg = location.state?.errorMessage;
    if (errMsg) {
      setErrorMessage(errMsg);
    }
    return () => clearHistoryState();
  }, [location.state?.errorMessage]);

  return (
    <Box
      style={{ paddingTop: "24px", paddingBottom: "56px" }}
      width={{ lg: DESKTOP_CONTENT_WIDTH }}
      marginX={{ lg: "auto" }}
    >
      <Box display="flex" justifyContent="center" style={{ marginTop: "52px" }}>
        <img src={ErrorPageGif} />
      </Box>
      <Box display="flex" justifyContent="center" marginTop="32px" paddingLeft="16px" paddingRight="16px">
        <SectionSubtitle style={{ textAlign: "center" }}>
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </SectionSubtitle>
      </Box>
      <Box marginX="auto" textAlign="center" marginTop="16px" width={{ lg: "30%" }}>
        <PrimaryButton handleClick={() => goTo(STOREHOME_PATH)}>Browse stores</PrimaryButton>
      </Box>
    </Box>
  );
};

export default ErrorContent;
