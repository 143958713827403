import React, { useMemo, cloneElement } from "react";
import clsx from "clsx";
import { Box, makeStyles, Theme, BoxProps } from "@material-ui/core";
import IconFacebook from "../../customIcons/socialMedia/IconFacebook";
import IconTiktok from "../../customIcons/socialMedia/IconTiktok";
import IconInstagram from "../../customIcons/socialMedia/IconInstagram";
import IconTwitter from "../../customIcons/socialMedia/IconTwitter";
import IconPinterest from "../../customIcons/socialMedia/IconPinterest";

const useStyles = makeStyles((theme: Theme) => ({
  socialListContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      marginTop: "unset"
    }
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 30,
    width: 20
  }
}));

interface SocialMediaProps {
  name: string;
  url: string;
  iconComponent: React.ReactElement<any>;
}

const SocialMedia = ({ name, url, iconComponent }: SocialMediaProps): ReturnType<React.FC> => {
  const classes = useStyles();
  const handleClick = () => window.open(url, "_blank").focus();
  return cloneElement(iconComponent, { name, className: classes.socialIcon, onClick: handleClick });
};

const SocialMediaLinks = ({ className, ...rest }: BoxProps): ReturnType<React.FC> => {
  const classes = useStyles();

  const SOCIAL_LIST = useMemo(
    () => [
      {
        name: "instagram",
        url: "https://www.instagram.com/giftmewishlist/",
        iconComponent: <IconInstagram />
      },
      {
        name: "facebook",
        url: "https://www.facebook.com/shareyourwishlist",
        iconComponent: <IconFacebook />
      },
      { name: "twitter", url: "https://twitter.com/GiftMeWishlist", iconComponent: <IconTwitter /> },
      { name: "tiktok", url: "https://www.tiktok.com/@giftmewishlist", iconComponent: <IconTiktok /> },
      { name: "pinterest", url: "https://www.pinterest.com/giftmewishlist", iconComponent: <IconPinterest /> }
    ],
    []
  );

  return (
    <Box className={clsx(classes.socialListContainer, className)} {...rest}>
      {SOCIAL_LIST.map((item, i) => (
        <SocialMedia {...item} key={i} />
      ))}
    </Box>
  );
};

export default SocialMediaLinks;
