import React from "react";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";

import { GiftSearchAvailableFiltersOptionObject } from "./giftSearchAvailableFilters";
import { withStyles } from "@material-ui/core/styles";

interface GiftFiltersItemOptionProps {
  option: GiftSearchAvailableFiltersOptionObject;
}

const componentStyles = makeStyles((theme) => ({
  formControlLabel: {
    width: "100%",
    marginLeft: 0
  }
}));

const FormControlLabelWithStyles = withStyles({
  root: {
    "& .MuiFormControlLabel-label": {
      fontSize: 16
    },
    "& .MuiSvgIcon-root": {
      height: 20,
      width: 20
    }
  }
})(FormControlLabel);

const GiftFiltersItemOption: React.FC<GiftFiltersItemOptionProps> = (props) => {
  const styles = componentStyles();
  const { option } = props;
  return (
    <FormControlLabelWithStyles
      className={styles.formControlLabel}
      control={<Checkbox name={option.label} value={option.value} />}
      label={option.label}
    />
  );
};

export default GiftFiltersItemOption;
