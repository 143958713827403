import React from "react";
import { Box, Typography } from "@material-ui/core";
import GifterSpecialNote from "./GifterSpecialNote";
import ReceivedGiftImage from "./ReceivedGiftImage";
import RedeemGiftDialogButtons from "./RedeemGiftDialogButtons";
import {
  RedeemDialogButtonStyle,
  RedeemDialogContentStyle,
  RedeemGiftCaptionStyle,
  RedeemGiftHeaderStyle,
  RedeemGiftNameStyle
} from "./RedeemGiftFlowStyles";
import { roundToDecimalPlaces } from "../../utils/stringUtils";
import DeclineAGiftButton from "./DeclineAGiftButton";
import { OrderInterface } from "../../models/order";

interface ReceivedAGiftDialogContentProps {
  order: OrderInterface;
  buyerNickname: string;
  onAcceptGiftClicked: () => void;
  onGiftDeclined: () => void;
  onExchangeForCreditClicked: () => void;
}

const ReceivedAGiftDialogContent: React.FC<ReceivedAGiftDialogContentProps> = (
  props: ReceivedAGiftDialogContentProps
) => {
  const { order, buyerNickname, onAcceptGiftClicked, onGiftDeclined, onExchangeForCreditClicked } = props;
  const contentStyles = RedeemDialogContentStyle();
  const buttonDialogStyle = RedeemDialogButtonStyle();
  const redeemGiftNameStyle = RedeemGiftNameStyle();
  const redeemGiftHeaderStyle = RedeemGiftHeaderStyle();
  const redeemGiftCaptionStyle = RedeemGiftCaptionStyle();

  const order_offer_total =
    order && order.offer_data.exchange.credits ? order.offer_data.exchange.credits.offer_total : undefined;
  const order_offer_bonus_amount =
    order && order.offer_data.exchange.credits ? order.offer_data.exchange.credits.bonus_amount : undefined;

  return (
    <Box className={contentStyles.root}>
      <Typography variant="body1">Order number: {order.order_id}</Typography>

      <ReceivedGiftImage
        alt="Gift Image"
        src={order.gift.image_url}
        style={{ alignSelf: "center", marginTop: "16px" }}
      />

      <Typography variant="h6" className={redeemGiftNameStyle.textStyle} style={{ marginTop: "8px" }}>
        {order.gift.name}
      </Typography>

      <GifterSpecialNote specialNote={order.message} gifterName={buyerNickname} style={{ marginTop: "16px" }} />

      <RedeemGiftDialogButtons
        buttonClassName={buttonDialogStyle.button}
        positiveButtonText={`Accept Gift\n ${
          order_offer_bonus_amount && order_offer_bonus_amount > 0
            ? `(get ${"$" + roundToDecimalPlaces(order_offer_bonus_amount, 2)} less value)`
            : ""
        }`}
        onPositiveButtonClick={onAcceptGiftClicked}
        onNegativeButtonClick={onExchangeForCreditClicked}
        negativeButtonText={`Exchange\n(for ${
          "$" +
          roundToDecimalPlaces(
            order_offer_total && order_offer_total > 0 ? order_offer_total : order.pricing.total_price_paid_converted,
            2
          )
        } Store Credit)`}
        useSecondaryButton={true}
      />

      {order_offer_bonus_amount && order_offer_bonus_amount > 0 ? (
        <Typography variant="body2" className={redeemGiftHeaderStyle.textStyle}>
          *BONUS: This one-time offer includes an extra{" "}
          <b>${roundToDecimalPlaces(order_offer_bonus_amount, 2)} Store Credit!</b>
        </Typography>
      ) : null}

      <DeclineAGiftButton order={order} onGiftDeclined={onGiftDeclined} />

      <Typography variant="caption" className={redeemGiftCaptionStyle.textStyle} style={{ marginTop: "16px" }}>
        Store Credit Terms: Store credit is good toward your choice of thousands of items in our store, including
        categories like fragrances, jewelry, cosmetics, flowers, shoes, handbags and more. Store credit cannot be used
        to buy other egifts or items added to your wishlist from third-parties outside of our store. This is a one-time
        offer to exchange your gift for Store Credit and your choice of whether or not to participate in this offer is
        final.
      </Typography>
    </Box>
  );
};

export default ReceivedAGiftDialogContent;
