import React from "react";
import { Box, Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import SectionTitle from "../../common/sectionTitle/SectionTitle";

const usesStyles = makeStyles((theme) => ({
  root: {
    height: "70px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      "flex-basis": "100%"
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0
    }
  }
}));

export default function HowItWorksHeader({ title }) {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const classes = usesStyles();

  return (
    <Box className={classes.root}>
      <SectionTitle
        style={{
          fontSize: "29px",
          fontWeight: "bold",
          lineHeight: "37px",
          marginTop: isDesktop ? "150px" : "100px"
        }}
      >
        {title}
      </SectionTitle>
    </Box>
  );
}
