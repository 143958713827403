import React from "react";
import { Typography } from "@material-ui/core/";

interface FormattedProductDetailsProps {
  description: string;
}

export function FormattedProductDetails(props: FormattedProductDetailsProps) {
  const index = props.description.indexOf("<table");
  const details = index >= 0 ? props.description.slice(0, index) : props.description;

  return <Typography style={{ fontSize: 14 }}>{details}</Typography>;
}
