import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Typography } from "@material-ui/core";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import SupportedCards from "./supported_cards.png";
import { CARD_CVV_LABEL, CARD_CVV_SIZE } from "../../input/card/CardUtils";
import Form from "../../input/Form";
import { initialFormInputs } from "./PaymentMethodFormInputs";
import PrimaryButton from "../../buttons/PrimaryButton";
import Toast from "light-toast";
import { createPaymentMethod } from "../../../actions/paymentAction";
import PaymentInformationForm from "./PaymentInformationForm";
import PaymentMethodsBillingInformationForm from "./PaymentMethodsBillingInformationForm";
import { useAppSelector } from "../../../hooks";

const PaymentMethodsAddStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  supportedCardsImg: {
    width: "193px",
    height: "34px"
  },
  paymentInfoCaption: {
    ...theme.typography.body2,
    fontStyle: "italic",
    color: theme.palette.grey[500]
  },
  addButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  }
}));

export default function PaymentMethodsAdd({ onPaymentMethodAdded }: { onPaymentMethodAdded: () => void }) {
  const styles = PaymentMethodsAddStyles();
  const [isSavingNewCard, setIsSavingNewCard] = useState<boolean>(false);
  const { email, firstname, lastname } = useAppSelector((state) => state.user.data);

  const validateForm = (inputs = formInputs) => {
    let hasError = false;

    for (let inputName in inputs) {
      const formInput = inputs[inputName];

      if ("billing_name" === inputName) {
        formInput.error = formInput.value === "" ? "Full Name is required" : "";
      } else if ("card" === inputName) {
        const validatedCardInput = formInputs.card.onValidate();
        formInputs.card.cardNumber = validatedCardInput.cardNumber;
        formInputs.card.validation = validatedCardInput.validation;
        formInputs.card.error = validatedCardInput.error;

        // TODO DO SOMETHING WITH EXPIRATION
      } else if ("exp_month" === inputName) {
        if (!formInput.value || formInput.value === "") {
          formInput.error = "Expiration month is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter a 2 digit expiry month";
        } else {
          formInput.error = "";
        }
      } else if ("exp_year" === inputName) {
        if (!formInput.value || formInput.value === "") {
          formInput.error = "Expiration year is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter the last 2 digits of expiry year!";
        } else {
          formInput.error = "";
        }
      } else if ("csv" === inputName) {
        validateCSV(formInput);
      } else if ("billing_address" === inputName) {
        formInput.error = formInput.value === "" ? "Billing Address is required" : "";
      } else if ("billing_country" === inputName) {
        formInput.error = formInput.value === "" ? "Country is required" : "";
      } else if ("billing_city" === inputName) {
        formInput.error = formInput.value === "" ? "City is required" : "";
      } else if ("billing_state" === inputName && formInputs["billing_country"].value === "US") {
        formInput.error = formInput.value === "" ? "State is required" : "";
      } else if ("billing_province" === inputName && formInputs["billing_country"].value !== "US") {
        formInput.error = formInput.value === "" ? "State/Province is required" : "";
      } else if ("billing_zip" === inputName) {
        formInput.error = formInput.value === "" ? "Zip Code is required" : "";
      } else if ("billing_email" === inputName) {
        formInput.error = formInput.value === "" ? "Email is required" : "";
      }
      if (!hasError && formInput.error !== "") {
        hasError = true;
      }
    }

    return !hasError;
  };

  const validateCSV = (csvInput) => {
    if (csvInput.value === "") {
      csvInput.error = "Card " + csvInput.label + " is required";
    } else if (csvInput.value.length !== csvInput.maxLength) {
      csvInput.error = csvInput.maxLength + " digit " + csvInput.label + " required";
    } else {
      csvInput.error = "";
    }
  };

  const onBindCardValidate = (onValidateHook) => {
    formInputs.card.onValidate = onValidateHook;
  };

  const onCardInputDataUpdated = (cardNumber, validation, error) => {
    formInputs.card.cardNumber = cardNumber;
    formInputs.card.validation = validation;
    formInputs.card.error = error;

    let csvLabel;
    let csvMaxLength;
    let csvInput = formInputs.csv;
    let card = validation && validation.card ? validation.card : undefined;

    if (card) {
      csvLabel = card.code.name;
      csvMaxLength = card.code.size;
    } else {
      csvLabel = CARD_CVV_LABEL;
      csvMaxLength = CARD_CVV_SIZE;
    }

    if (csvLabel !== csvInput.label || csvMaxLength !== csvInput.maxLength) {
      csvInput.label = csvLabel;
      csvInput.maxLength = csvMaxLength;

      if (csvInput.value !== "" || csvInput.error !== "") {
        validateCSV(csvInput);
      }

      setFormInputs({
        ...formInputs
      });
    }
  };

  const addPaymentMethod = async () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });
      return;
    }

    setIsSavingNewCard(true);

    try {
      await createPaymentMethod({
        account_number: formInputs.card.cardNumber.trim(),
        csv: formInputs.csv.value.trim(),
        exp_month: formInputs.exp_month.value.trim(),
        exp_year: `20${formInputs.exp_year.value}`.trim(),
        billing_address: formInputs.billing_address.value.trim(),
        billing_city: formInputs.billing_city.value.trim(),
        billing_country: formInputs.billing_country.value.trim(),
        billing_email: formInputs.billing_email.value.trim(),
        billing_name: formInputs.billing_name.value.trim(),
        billing_state:
          formInputs.billing_country.value.trim() === "US"
            ? formInputs.billing_state.value.trim()
            : formInputs.billing_province.value.trim(),
        billing_zip: formInputs.billing_zip.value.trim()
      });

      onPaymentMethodAdded();
      setIsSavingNewCard(false);
      resetForm();
    } catch (error) {
      Toast.fail("Error adding new card. Please try again. Please make sure your card information is correct");
      setIsSavingNewCard(false);
    }
  };

  const resetForm = () => {
    formInputs.billing_email.value = "";
    formInputs.billing_name.value = "";

    formInputs.card.value = "";
    formInputs.exp_month.value = "";
    formInputs.exp_year.value = "";
    formInputs.csv.value = "";
    formInputs.billing_address.value = "";
    formInputs.billing_city.value = "";
    formInputs.billing_state.value = "";
    formInputs.billing_province.value = "";
    formInputs.billing_zip.value = "";
    formInputs.billing_country.value = "";
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  useEffect(() => {
    formInputs.billing_email.value = email;
    formInputs.billing_name.value = `${firstname} ${lastname}`;
  }, []);

  return (
    <>
      <Box>
        <SectionTitle>Add a new payment method</SectionTitle>
        <img
          className={styles.supportedCardsImg}
          style={{ marginTop: "8px" }}
          src={SupportedCards}
          alt="supported cards"
        />

        <Typography className={styles.paymentInfoCaption} style={{ marginTop: "16px" }}>
          We accept all major credit cards and pre-paid debit cards.
        </Typography>

        <PaymentInformationForm
          billing_name={formInputs.billing_name ? formInputs.billing_name : ""}
          exp_month={formInputs.exp_month ? formInputs.exp_month : ""}
          exp_year={formInputs.exp_year ? formInputs.exp_year : ""}
          csv={formInputs.csv ? formInputs.csv : ""}
          onBindCardValidate={onBindCardValidate}
          onCardInputDataUpdated={onCardInputDataUpdated}
          handleInputChange={handleInputChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
          style={{ marginTop: "16px" }}
        />
      </Box>
      <Divider style={{ margin: "2rem auto" }} />
      <Box>
        <SectionTitle>Billing info</SectionTitle>
        <PaymentMethodsBillingInformationForm
          billing_address={formInputs.billing_address ? formInputs.billing_address : ""}
          billing_zip={formInputs.billing_zip ? formInputs.billing_zip : ""}
          billing_city={formInputs.billing_city ? formInputs.billing_city : ""}
          billing_state={formInputs.billing_state ? formInputs.billing_state : ""}
          billing_province={formInputs.billing_province ? formInputs.billing_province : ""}
          billing_country={formInputs.billing_country ? formInputs.billing_country : ""}
          billing_email={formInputs.billing_email ? formInputs.billing_email : ""}
          handleInputChange={handleInputChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
        />
        <PrimaryButton className={styles.addButton} handleClick={() => addPaymentMethod()} disabled={isSavingNewCard}>
          {isSavingNewCard ? "Adding new payment method..." : "Add new payment method"}
        </PrimaryButton>
      </Box>
    </>
  );
}
