import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const IconTwitter = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"> */}
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_20" data-name="Rectangle 20" width="20" height="20" fill="#ef1581" />
        </clipPath>
      </defs>
      <g id="twitter" clipPath="url(#clip-path)">
        <path
          id="Path_104"
          data-name="Path 104"
          d="M19.962,4.182a8.334,8.334,0,0,1-2.354.646,4.132,4.132,0,0,0,1.8-2.269,8.469,8.469,0,0,1-2.606.987,4.1,4.1,0,0,0-7.092,2.8,4.2,4.2,0,0,0,.106.937A11.612,11.612,0,0,1,1.367,3.008,4.018,4.018,0,0,0,.812,5.071,4.1,4.1,0,0,0,2.635,8.484,4.087,4.087,0,0,1,.778,7.971v.051a4.1,4.1,0,0,0,3.288,4.023,4.138,4.138,0,0,1-1.843.071A4.114,4.114,0,0,0,6.06,14.963,8.223,8.223,0,0,1,.975,16.717,8.7,8.7,0,0,1,0,16.661,11.663,11.663,0,0,0,6.3,18.5,11.581,11.581,0,0,0,17.963,6.847c0-.174,0-.35-.013-.525A8.28,8.28,0,0,0,20,4.2Z"
          transform="translate(0 -0.146)"
          fill="#ef1581"
        />
      </g>
      {/* </svg> */}
    </SvgIcon>
  );
};

export default IconTwitter;
