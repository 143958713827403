import React from "react";
import {
  Typography,
  TableBody,
  Table,
  TableRow,
  TableCell, makeStyles
} from "@material-ui/core";
import { CURRENCY } from "../../common/Constants";
import { GiftDetailsPricingType } from "./GiftDetailsTypes";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  th: {
    borderBottom: "none",
    padding: "5px 80px 5px 0",
  }
}));

function TableRowComponent(props) {
  const styles = useStyles();
  const { title, value, thStyles, valueStyles } = props;

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        className={styles.th}
        style={thStyles}
      >
        <Typography component="span" variant="body1" style={valueStyles}>
          {title}
        </Typography>
      </TableCell>
      <TableCell align="left" className={styles.th} style={{
        ...thStyles,
        textAlign: "right"
      }}>
        <Typography component="span" variant="body1" style={valueStyles}>
          {value}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

const GiftDetailsTotal = ({ pricing }: { pricing: GiftDetailsPricingType }) => {
  const {
    price_paid_dollars,
    shipping_price_paid_dollars,
    tax_price_paid_dollars,
    total_price_paid_dollars,
  } = pricing;

  return (
    <Table aria-label="customized table">
      <TableBody>
        <TableRowComponent title="Item" value={`${CURRENCY}${price_paid_dollars.toFixed(2)}`} />
        <TableRowComponent title="Delivery" value={`${CURRENCY}${shipping_price_paid_dollars.toFixed(2)}`} />
        <TableRowComponent title="Tax/Fees" value={`${CURRENCY}${tax_price_paid_dollars.toFixed(2)}`} />
        <TableRowComponent
          title="Total"
          value={`${CURRENCY}${total_price_paid_dollars.toFixed(2)}`}
          valueStyles={{
            fontWeight: "bold",
            fontSize: "22px",
          }}
        />
      </TableBody>
    </Table>
  );
};

TableRowComponent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  thStyles: PropTypes.object,
  valueStyles: PropTypes.object,
};

export default GiftDetailsTotal;
