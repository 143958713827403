export const mapGiftsByMasterGiftId = (gifts: any) => {
  let mappedGifts = [];

  Object.values(gifts).map((gift: any) => {
    // is this gift part of a group with a single item as the master item?
    if (gift.group_data.consolidated_group) {
      // is the gift the master item? (i.e - should it be shown in the gift listing?)
      if (gift.group_master_gift_id === gift.gift_id) {
        // it's the master item in the consolidated group, let's add it
        mappedGifts.push(gift);
        //   console.log("master item, ", gift);
      } else {
        // not the master item, we don't add it to the UI listing
        //console.log("not the master item, hiding gift, ", gift);
      }
    } else {
      mappedGifts.push(gift);
    }
    return gift;
  });

  return mappedGifts;
};

export const getRandomItems = (arr: any[], items: number) => {
  var ret = [];
  var indexes = [];
  var arr_length = arr.length;

  // If we don't have enough items to return - return the original array
  if (arr_length < items) {
    return arr;
  }

  while (ret.length < items) {
    var i = Math.floor(Math.random() * arr_length);
    if (indexes.indexOf(i) === -1) {
      indexes[indexes.length] = i;
      ret[ret.length] = arr[i];
    }
  }
  return ret;
};
