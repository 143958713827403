import React, { FC, MouseEvent, useCallback } from "react";
import { Box, Typography, makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import { GiftInterface } from "../../../models/gift";
import { getImageUrl } from "../../../actions/giftAction";
import WishlistButton from "../../buttons/WishlistButton";
import { CURRENCY } from "../../common/Constants";
import { COLORS, textColor } from "../../../style/index";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { decrementUserWishlistCount, incrementUserWishlistCount } from "../../../reducers/user/UserSlice";
import { addWishlist, removeWishlist } from "../../../actions/wishlistAction";
import RollbarTracker from "../../../utils/RollbarTracker";
import { SetPromptLoginInterface } from "../../../interface/AppInterface";
import useNav from "../../../hooks/useNav";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";
import { updateRecipientsToLocalStorage } from "../../../utils/localStorage";

const withoutPaddingStores = ["501039"];

const componentStyles = makeStyles((theme) => ({
  title: {
    margin: "10px 0",
    fontWeight: "normal",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    [theme.breakpoints.down("md")]: {
      margin: "5px 0",
      fontSize: "16px"
    }
  },
  super: {
    verticalAlign: "super",
    fontSize: "14px"
  },
  gridListItem: {
    cursor: "pointer"
  },
  gridListItemImage: {
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    position: "relative",
    padding: 20,
    "&:hover": {
      "& $hoverOverlay": {
        visibility: "visible"
      }
    }
  },
  hoverOverlay: {
    transition: "visibility 0 ease-out 0.3s",
    backgroundColor: "#484848",
    width: "100%",
    height: "100%",
    opacity: "0.4",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "15px",
    visibility: "hidden",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  typography: {
    color: COLORS.LIGHT_BLACK
  }
}));

interface GiftItemProps {
  gift: GiftInterface;
  setCurrentGift: React.Dispatch<React.SetStateAction<GiftInterface | null>>;
  setProductModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  storeId: string;
  storeName: string;
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
  scrollPosition: any;
}

const GiftItem = (props: GiftItemProps): ReturnType<FC> => {
  const styles = componentStyles();
  const { goTo } = useNav();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const dispatch = useAppDispatch();
  const { encryptedUserId, nickname, wishlistCount } = useAppSelector((state) => state.user.data);
  const { gift, setCurrentGift, setProductModalIsOpen, storeId, storeName, setPromptLogin, scrollPosition } = props;

  const showProductModal = useCallback(() => {
    setCurrentGift(gift);
    setProductModalIsOpen(true);
    window.history.pushState(gift, "", `/store/${storeId}/${storeName}/gift/${gift.gift_id}`);
  }, [gift]);

  const addGiftToWishlist = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (!encryptedUserId) {
        // todo - returnTo should go back to this page
        setPromptLogin({
          promptLogin: true,
          returnTo: undefined,
          registration: false
        });
        return;
      }

      if (gift.is_in_wishlist) return;

      updateGiftWishlistState(true);
      dispatch(incrementUserWishlistCount());

      try {
        await addWishlist(gift);
        updateRecipientsToLocalStorage({
          nickname,
          userId: encryptedUserId,
          giftCount: wishlistCount
        });
      } catch (error) {
        dispatch(decrementUserWishlistCount());
        updateGiftWishlistState(false);
        RollbarTracker.logError("Error adding gift to wishlist", error);
        if (error.response.status === 500) {
          goTo("/error");
        }
      }
    },
    [gift]
  );

  const removeGiftFromWishlist = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (!gift.is_in_wishlist) return;

      updateGiftWishlistState(false);
      dispatch(decrementUserWishlistCount());
      try {
        await removeWishlist(gift);
        updateRecipientsToLocalStorage({
          nickname,
          userId: encryptedUserId,
          giftCount: wishlistCount
        });
      } catch (error) {
        dispatch(incrementUserWishlistCount());
        updateGiftWishlistState(true);
        RollbarTracker.logError("Error removing gift from wishlist", error);
        if (error.response.status === 500) {
          goTo("/error");
        }
      }
    },
    [gift]
  );

  const updateGiftWishlistState = (addedToWishlist: boolean) => {
    gift.is_in_wishlist = addedToWishlist;
  };

  return (
    <Box className={styles.gridListItem}>
      <Box
        display={{ lg: "flex" }}
        flexWrap="wrap"
        className={styles.gridListItemImage}
        style={{
          padding: withoutPaddingStores.includes(storeId) ? 0 : 20
        }}
      >
        <Box flexBasis="100%" textAlign="center" onClick={showProductModal}>
          <LazyLoadImage
            placeholderSrc={getImageUrl(gift, "image_url")}
            src={getImageUrl(gift)}
            alt={gift.name}
            scrollPosition={scrollPosition}
            style={{
              maxWidth: "100%",
              borderRadius: "15px"
            }}
          />
        </Box>
        {isDesktop && <Box className={styles.hoverOverlay} onClick={showProductModal} />}
        <Box flexBasis="40%" display="flex" alignItems="center" marginX="auto">
          <WishlistButton
            addedToWishlist={gift.is_in_wishlist}
            handleClick={(e) => {
              gift.is_in_wishlist ? removeGiftFromWishlist(e) : addGiftToWishlist(e);
            }}
            style={{
              top: 10,
              right: 10,
              height: "48px",
              width: "48px"
            }}
          />
        </Box>
      </Box>
      <Box flexBasis="100%" onClick={showProductModal}>
        <Box
          style={{
            margin: "15px 0",
            color: textColor.DARK1
          }}
        >
          <Typography variant="h6" className={styles.typography}>
            {gift.brand && gift.brand !== "" ? gift.brand.replace("&apos;", "'") : ""}
          </Typography>
          <Typography variant="h6" className={clsx(styles.title, styles.typography)}>
            {gift.name.replace("&apos;", "'")}
          </Typography>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="h6" className={clsx(styles.super, styles.typography)}>
              {CURRENCY}
            </Typography>
            <Typography variant="h6" className={styles.typography}>
              {roundToDecimalPlaces(gift.pricing.true_price_converted, 2).split(".")[0]}.
            </Typography>
            <Typography variant="h6" className={clsx(styles.super, styles.typography)}>
              {roundToDecimalPlaces(gift.pricing.true_price_converted, 2).split(".").pop()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GiftItem;
