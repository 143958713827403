import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import SelectRecipientPopper from "../common/selectRecipient/SelectRecipientPopper";
import { getAuthenticatedUserId } from "../../utils/localStorage";
import { COLORS } from "../../style";
import { useApp } from "../../context/app-context";
import { filterRecipientList } from "../common/selectRecipient/utils";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";

const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.typography.h6,
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    marginRight: "5px",
    color: COLORS.LIGHT_BLACK,
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "32px"
  },
  wrapper: {
    display: "flex",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      maxWidth: "unset"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      maxWidth: 270
    }
  },
  recipient: {
    ...theme.typography.h4,
    fontSize: 24,
    fontWeight: 800,
    color: "#E6007E",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: 24
    }
  }
}));

interface SelectWishListForProps {
  username?: string;
  isViewingOtherWishlist?: boolean;
}

const SelectWishListFor = ({ username }: SelectWishListForProps) => {
  const { loggedInUserFullname } = useApp();
  const { isDesktop, isMobileXs, isTabletMobileOnly } = useMediaBreakpoints();
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState({
    userId: "",
    nickname: ""
  });
  const [recipients] = useState(JSON.parse(localStorage.getItem("recipients")));
  const authenticatedUserId = getAuthenticatedUserId();
  const filteredRecipients = filterRecipientList(recipients);

  const toggleSelectRecipientForm = (event) => {
    if (filteredRecipients.length > 0) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "select-recipient-popper" : undefined;

  const displayHandle = useMemo(() => (!username ? `${loggedInUserFullname}'s` : `${username}'s`), [username]);

  useEffect(() => {
    setSelectedRecipient(JSON.parse(localStorage.getItem("selectedRecipient")));
  }, [username]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      aria-describedby={id}
      onClick={toggleSelectRecipientForm}
      className={styles.wrapper}
      style={{
        marginTop: isDesktop && authenticatedUserId ? 12 : "unset"
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        style={{
          borderBottom: filteredRecipients.length > 0 ? "1px solid #E92983" : "none"
        }}
      >
        <Typography
          className={styles.recipient}
          style={{
            color: filteredRecipients.length > 0 ? COLORS.PINK : COLORS.LIGHT_BLACK
          }}
        >
          {displayHandle}

          {displayHandle.length > 20 && isMobileXs && !isTabletMobileOnly && (
            <span className={styles.label} style={{ fontSize: 24 }}>
              {" Wishlist"}
            </span>
          )}
        </Typography>
        {filteredRecipients.length > 0 && (
          <ExpandMoreSharpIcon
            color="secondary"
            style={{
              marginTop: "3px",
              cursor: "pointer"
            }}
          />
        )}
      </Box>

      {(displayHandle.length <= 20 || isDesktop || isTabletMobileOnly) && (
        <Typography className={styles.label} style={{ fontSize: 24, marginLeft: isDesktop ? 5 : 0 }}>
          Wishlist
        </Typography>
      )}
      <SelectRecipientPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        recipients={recipients}
        selectedRecipient={selectedRecipient}
        toggleSelectRecipientForm={toggleSelectRecipientForm}
      ></SelectRecipientPopper>
    </Box>
  );
};

export default SelectWishListFor;
