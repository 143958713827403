import React from "react";
import { Link, Breadcrumbs, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

interface SearchBreadcrumbProps {
  style?: React.CSSProperties;
}

const SearchBreadcrumb: React.FC<SearchBreadcrumbProps> = (props) => {
  const history = useHistory();
  const onClickHandler = (page) => {
    history.push("/store/storehome");
  };

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{
        marginBottom: 25
      }}
    >
      <Link
        color="inherit"
        variant="body2"
        onClick={onClickHandler}
        style={{
          cursor: "pointer",
          fontSize: 16
        }}
      >
        Home
      </Link>
      <Typography
        color="textPrimary"
        variant="body2"
        style={{
          fontWeight: "bold",
          fontSize: 16
        }}
      >
        Search
      </Typography>
    </Breadcrumbs>
  );
};

export default SearchBreadcrumb;
