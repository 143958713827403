import React, { useRef, FC } from "react";
import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import Toast from "light-toast";
import { vysionShare } from "../../../actions/vysionAction";
import useNav from "../../../hooks/useNav";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInputV2";

const ShareContentStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  content: {
    width: "95%",
    marginBottom: theme.typography.pxToRem(16)
  },
  link: {
    cursor: "pointer",
    fontSize: "16px",
    marginBottom: "26px"
  },
  textField: {
    fontSize: "15px"
  }
}));

interface ShareDialogContentProps {
  shareUrl: string;
  shareContentDescription: string;
  encryptedUserId: string;
  closeDialog: () => void;
  hidePersonalizedLink?: boolean;
}

export default function ShareDialogContent({
  shareUrl,
  shareContentDescription,
  encryptedUserId,
  closeDialog,
  hidePersonalizedLink
}: ShareDialogContentProps): ReturnType<FC> {
  const { goTo } = useNav();
  const contentStyles = ShareContentStyles();
  const textAreaRef = useRef<HTMLInputElement>(null);
  const copyToClipboard = () => {
    textAreaRef.current.focus();
    textAreaRef.current.select();
    document.execCommand("copy");
    Toast.info("Copied to clipboard.", 750);
    vysionShare("wishlist_share_url", shareUrl, encryptedUserId);
  };

  const redirectToProfile = () => {
    goTo("/profile/account", {
      focusVanityUrlInput: true
    });
    closeDialog();
  };

  return (
    <Box className={contentStyles.root}>
      <Typography variant="subtitle1" style={{ marginBottom: "16px" }}>
        {shareContentDescription}
      </Typography>
      <TextInput
        inputRef={textAreaRef}
        id="shareContent"
        label=""
        defaultValue={shareUrl}
        inputProps={{
          readOnly: true,
          className: contentStyles.textField
        }}
        className={contentStyles.content}
        onClick={copyToClipboard}
      />

      {!hidePersonalizedLink && (
        <Link className={contentStyles.link} onClick={redirectToProfile} underline="always">
          Personalize this url
        </Link>
      )}
      {
        // Logical shortcut for only displaying the button if the copy command exists
        document.queryCommandSupported("copy") && (
          <PrimaryButton className={contentStyles.content} handleClick={() => copyToClipboard()}>
            Copy URL to clipboard
          </PrimaryButton>
        )
      }
    </Box>
  );
}
