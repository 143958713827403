import React from "react";
import { Box, makeStyles, Typography, Link, useMediaQuery, Theme } from "@material-ui/core";
import Section from "./common/Section";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  storeImage: {
    width: "100%",
    height: "690px",
    objectFit: "cover",
    borderRadius: "10px",
    backgroundColor: "transparent",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "50%",
      borderRadius: 0
    }
  },
  storeImageByTwo: {
    [theme.breakpoints.down("md")]: {
      height: "224px"
    }
  },
  storeImageByThree: {
    height: "560px",
    [theme.breakpoints.down("md")]: {
      height: "224px"
    }
  },
  storeImageByThreeWithBg: {
    height: "560px",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      height: "224px"
    }
  },
  storeImageByFour: {
    height: "412px",
    [theme.breakpoints.down("md")]: {
      height: "224px"
    }
  },
  storeImageByFourInner: {
    height: "203px",
    [theme.breakpoints.down("md")]: {
      width: "190px"
    }
  },
  firstStoreImage: {
    paddingLeft: 0
  },
  lastStoreImage: {
    paddingRight: 0
  },
  title: {
    display: "inline-block",
    paddingTop: "8px",
    fontWeight: 400,
    color: "#2d2d2d",
    fontSize: "18px",
    [theme.breakpoints.down("md")]: {
      fontWeight: 700,
      fontSize: "20px"
    }
  },
  viewAll: {
    color: "#2c2c2c",
    fontSize: "16px",
    "&:hover, &:focus": {
      textDecoration: "none"
    }
  },
  storeBox: {
    padding: "0 10px"
  },
  arrowLeft: {
    fontSize: "12px",
    marginLeft: "6px",
    verticalAlign: "middle",
    marginBottom: "3px"
  }
}));

const StoreContent = (props) => {
  const classes = useStyles();

  return (
    <>
      <img
        src={props.imgSrc}
        className={`${classes.storeImage} ${
          props.position === "left" ? classes.firstStoreImage : classes.lastStoreImage
        } ${classes[props.imgStyle]}`}
      />
      <Typography variant={"h5"} className={classes.title} style={props.titleStyle}>
        {props.desc}
      </Typography>
    </>
  );
};

const RenderStoreBox = (props: any) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <Box className={classes.storeBox} style={props.style}>
      <Link href={props.src} style={{ color: "unset" }}>
        <StoreContent
          imgSrc={props.imgSrc}
          imgStyle={props.imgStyle}
          desc={props.description}
          titleStyle={isDesktop ? props.titleStyle : null}
        />
      </Link>

      {isDesktop && (
        <Typography variant={"h6"} style={{ float: "right" }}>
          <Link href={props.src} className={`${classes.title} ${classes.viewAll}`}>
            View All
            <ArrowForwardIosIcon className={classes.arrowLeft} />
          </Link>
        </Typography>
      )}
    </Box>
  );
};

const RenderSectionShoes = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {/*Shoes*/}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: isDesktop ? "180px" : "150px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "0px !important",
          paddingRight: isDesktop ? "unset" : "0px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <Typography
            variant={"h3"}
            style={{
              margin: isDesktop ? "104px 10px 0" : "80px 16px 0",
              position: "absolute",
              top: 0,
              left: 0,
              fontWeight: 800,
              color: "#2D2D2D"
            }}
          >
            {"Shoes"}
          </Typography>
          <Box
            style={{
              backgroundColor: "#F2F0F5",
              height: "440px",
              display: "flex",
              overflow: isDesktop ? "unset" : "scroll",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <RenderStoreBox
              style={{
                width: isDesktop ? "25%" : "100%",
                padding: isDesktop ? "86px 52px" : "86px 4.5px",
                marginLeft: isDesktop ? "unset" : "92px",
                textAlign: "center"
              }}
              imgStyle={"storeImageByFourInner"}
              position="left"
              description="Boots"
              imgSrc="/assets/store/boots.png"
              src="/store/store/5376250/Boots"
            />
            <RenderStoreBox
              style={{
                width: isDesktop ? "25%" : "100%",
                padding: isDesktop ? "86px 52px" : "86px 4.5px",
                textAlign: "center"
              }}
              imgStyle={"storeImageByFourInner"}
              description="Sneakers"
              imgSrc="/assets/store/sneakers.png"
              src="/store/store/5376246/Sneakers"
            />
            <RenderStoreBox
              style={{
                width: isDesktop ? "25%" : "100%",
                padding: isDesktop ? "86px 52px" : "86px 4.5px",
                textAlign: "center"
              }}
              imgStyle={"storeImageByFourInner"}
              description="Heels & Sandals"
              imgSrc="/assets/store/heels.png"
              src="/store/store/5376249/Heels"
            />
          </Box>
        </>
      </Section>
    </>
  );
};

const RenderSectionJewelry = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {/*Jewelry*/}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: isDesktop ? "180px" : "150px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <Typography
            variant={"h3"}
            style={{
              margin: isDesktop ? "104px 10px 0" : "80px 16px 0",
              position: "absolute",
              top: 0,
              left: 0,
              fontWeight: 800,
              color: "#2D2D2D"
            }}
          >
            {"Jewelry"}
          </Typography>
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="left"
            description="Diamonds"
            imgSrc="/assets/store/diamonds.png"
            src="/store/store/114293/Diamonds"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Earrings"
            imgSrc="/assets/store/earrings.png"
            src="/store/store/114672/Earrings"
          />
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "33.33%" }}
              imgStyle={"storeImageByThree"}
              position="right"
              description="Necklaces"
              imgSrc="/assets/store/necklaces.png"
              src="/store/store/252022/Necklaces"
            />
          )}
        </>
      </Section>
      {!isDesktop && (
        <Section
          backgroundImage={"transparent"}
          contentProps={{
            paddingTop: "24px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingLeft: isDesktop ? "unset" : "6px !important",
            paddingRight: isDesktop ? "unset" : "6px !important",
            alignItems: isDesktop ? "center" : "start"
          }}
        >
          <>
            <RenderStoreBox
              style={{ width: "50%" }}
              imgStyle={"storeImageByThree"}
              position="right"
              description="Necklaces"
              imgSrc="/assets/store/necklaces.png"
              src="/store/store/252022/Necklaces"
            />
            <RenderStoreBox
              style={{ width: "50%" }}
              imgStyle={"storeImageByThree"}
              position="left"
              description="Bracelets"
              imgSrc="/assets/store/bracelets.png"
              src="/store/store/121406/Bracelets"
            />
          </>
        </Section>
      )}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: "40px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "33.33%" }}
              imgStyle={"storeImageByThree"}
              position="left"
              description="Bracelets"
              imgSrc="/assets/store/bracelets.png"
              src="/store/store/121406/Bracelets"
            />
          )}
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Anklets"
            imgSrc="/assets/store/anklets.png"
            src="/store/store/121795/Anklets"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="right"
            description="Toe Rings"
            imgSrc="/assets/store/toe_rings.png"
            src="/store/store/121805/Toe%20Rings"
          />
        </>
      </Section>
    </>
  );
};

const RenderSectionWear = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {/*Wear*/}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: isDesktop ? "104px" : "80px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <RenderStoreBox
            style={{ width: isDesktop ? "25%" : "50%" }}
            titleStyle={{ fontSize: "18px", paddingTop: "5px" }}
            imgStyle={"storeImageByFour"}
            position="left"
            description="Swimwear"
            imgSrc="/assets/store/swimwear.png"
            src="/store/store/1756525/Swimwear"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "25%" : "50%" }}
            titleStyle={{ fontSize: "18px", paddingTop: "5px" }}
            imgStyle={"storeImageByFour"}
            description="Lingerie"
            imgSrc="/assets/store/lingerie.png"
            src="/store/store/501039/Lingerie"
          />
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "25%" }}
              titleStyle={{ fontSize: "18px", paddingTop: "5px" }}
              imgStyle={"storeImageByFour"}
              description="Sleepwear"
              imgSrc="/assets/store/sleepwear.png"
              src="/store/store/1756528/Sleepwear"
            />
          )}
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "25%" }}
              titleStyle={{ fontSize: "18px", paddingTop: "5px" }}
              imgStyle={"storeImageByFour"}
              position="right"
              description="Partywear"
              imgSrc="/assets/store/partywear.png"
              src="/store/store/5524061/Partywear"
            />
          )}
        </>
      </Section>
      {!isDesktop && (
        <Section
          backgroundImage={"transparent"}
          contentProps={{
            paddingTop: "24px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingLeft: isDesktop ? "unset" : "6px !important",
            paddingRight: isDesktop ? "unset" : "6px !important",
            alignItems: isDesktop ? "center" : "start"
          }}
        >
          <>
            <RenderStoreBox
              style={{ width: "50%" }}
              imgStyle={"storeImageByFour"}
              position="left"
              description="Sleepwear"
              imgSrc="/assets/store/sleepwear.png"
              src="/store/store/1756528/Sleepwear"
            />
            <RenderStoreBox
              style={{ width: "50%" }}
              imgStyle={"storeImageByFour"}
              position="right"
              description="Partywear"
              imgSrc="/assets/store/partywear.png"
              src="/store/store/5524061/Partywear"
            />
          </>
        </Section>
      )}
    </>
  );
};

const RenderSectionHomeDecor = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {/*Home Decor*/}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: isDesktop ? "180px" : "150px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <Typography
            variant={"h3"}
            style={{
              margin: isDesktop ? "104px 10px 0" : "80px 16px 0",
              position: "absolute",
              top: 0,
              left: 0,
              fontWeight: 800,
              color: "#2D2D2D"
            }}
          >
            {"Home Decor"}
          </Typography>
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="left"
            description="Home Furniture"
            imgSrc="/assets/store/home_furniture_male.png"
            src="/store/store/3900907/Home%20Furniture"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Decor & Lighting"
            imgSrc="/assets/store/decor_and_lighting_male.png"
            src="/store/store/3900918/Decor%20&%20Lighting"
          />
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "33.33%" }}
              imgStyle={"storeImageByThree"}
              position="right"
              description="Bed & Bath"
              imgSrc="/assets/store/bed_and_bath_male.png"
              src="/store/store/3900932/Bed%20&%20Bath"
            />
          )}
        </>
      </Section>
      {!isDesktop && (
        <Section
          backgroundImage={"transparent"}
          contentProps={{
            paddingTop: "24px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingLeft: isDesktop ? "unset" : "6px !important",
            paddingRight: isDesktop ? "unset" : "6px !important",
            alignItems: isDesktop ? "center" : "start"
          }}
        >
          <>
            <RenderStoreBox
              style={{ width: "50%" }}
              imgStyle={"storeImageByThree"}
              position="right"
              description="Bed & Bath"
              imgSrc="/assets/store/bed_and_bath_male.png"
              src="/store/store/3900932/Bed%20&%20Bath"
            />
            <RenderStoreBox
              style={{ width: "50%" }}
              imgStyle={"storeImageByThree"}
              position="left"
              description="Kitchen & Dining"
              imgSrc="/assets/store/kitchen_and_dining_male.png"
              src="/store/store/3900934/Kitchen%20&%20Dining"
            />
          </>
        </Section>
      )}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: "40px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "33.33%" }}
              imgStyle={"storeImageByThree"}
              position="left"
              description="Kitchen & Dining"
              imgSrc="/assets/store/kitchen_and_dining_male.png"
              src="/store/store/3900934/Kitchen%20&%20Dining"
            />
          )}
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Outdoor Living"
            imgSrc="/assets/store/outdoor_furniture_male.png"
            src="/store/store/3900934/Outdoor%20Living"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="right"
            description="Home Office"
            imgSrc="/assets/store/office_furniture_male.png"
            src="/store/store/3900939/Home%20Office"
          />
        </>
      </Section>
    </>
  );
};

const RenderSectionFood = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {/*Foods*/}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: isDesktop ? "180px" : "150px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important",
          paddingBottom: isDesktop ? "104px" : "unset"
        }}
      >
        <>
          <Typography
            variant={"h3"}
            style={{
              margin: isDesktop ? "104px 10px 0" : "80px 16px 0",
              position: "absolute",
              top: 0,
              left: 0,
              fontWeight: 800,
              color: "#2D2D2D"
            }}
          >
            {"Foods"}
          </Typography>
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="left"
            description="Cookies"
            imgSrc="/assets/store/cookies.png"
            src="/store/store/1823646/Cookies"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Chocolates"
            imgSrc="/assets/store/chocolates.png"
            src="/store/store/5427903/Chocolates"
          />
        </>
      </Section>
    </>
  );
};

const RenderSectionForFemale = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: "104px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <RenderStoreBox
            style={{ width: "50%" }}
            position="left"
            description="Sunglasses"
            imgSrc="/assets/store/sunglasses.png"
            src="/store/store/5376248/Sunglasses"
          />
          <RenderStoreBox
            style={{ width: "50%" }}
            position="right"
            description="Bags and Wallets"
            imgSrc="/assets/store/bags_and_wallets.png"
            src="/store/store/5376240/Bags%20&%20Wallets"
          />
        </>
      </Section>

      {/*Others*/}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: isDesktop ? "104px" : "80px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="left"
            description="Fragrances"
            imgSrc="/assets/store/fragrances.png"
            src="/store/store/129730/Fragrances"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Watches"
            imgSrc="/assets/store/watches.png"
            src="/store/store/5376251/Timepieces"
          />
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "33.33%" }}
              imgStyle={"storeImageByThree"}
              position="right"
              description="Flowers"
              imgSrc="/assets/store/flowers.png"
              src="/store/store/1742068/Floral"
            />
          )}
        </>
      </Section>
      {!isDesktop && (
        <Section
          backgroundImage={"transparent"}
          contentProps={{
            paddingTop: "24px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingLeft: isDesktop ? "unset" : "6px !important",
            paddingRight: isDesktop ? "unset" : "6px !important",
            alignItems: isDesktop ? "center" : "start"
          }}
        >
          <RenderStoreBox
            style={{ width: "100%" }}
            imgStyle={"storeImageByThree"}
            position="right"
            description="Flowers"
            imgSrc="/assets/store/flowers.png"
            src="/store/store/1742068/Floral"
          />
        </Section>
      )}

      <RenderSectionShoes />
      <RenderSectionJewelry />
      <RenderSectionWear />
      <RenderSectionHomeDecor />
      <RenderSectionFood />
    </>
  );
};

const RenderSectionForMale = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {/*Others*/}
      {!isDesktop && (
        <Section
          backgroundImage={"transparent"}
          contentProps={{
            paddingTop: "24px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingLeft: isDesktop ? "unset" : "6px !important",
            paddingRight: isDesktop ? "unset" : "6px !important",
            alignItems: isDesktop ? "center" : "start"
          }}
        >
          <RenderStoreBox
            style={{ width: "100%" }}
            imgStyle={"storeImageByThree"}
            position="right"
            description="Fragrances"
            imgSrc="/assets/store/fragrances_male.png"
            src="/store/store/129730/Fragrances"
          />
        </Section>
      )}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: "24px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "33.33%" }}
              imgStyle={"storeImageByThree"}
              position="left"
              description="Fragrances"
              imgSrc="/assets/store/fragrances_male.png"
              src="/store/store/129730/Fragrances"
            />
          )}
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Watches"
            imgSrc="/assets/store/watches_male.png"
            src="/store/store/5376251/Timepieces"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="right"
            description="Sunglasses"
            imgSrc="/assets/store/sunglasses_male.png"
            src="/store/store/5376248/Sunglasses"
          />
        </>
      </Section>

      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: "104px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <RenderStoreBox
            style={{ width: "50%" }}
            position="left"
            description="Flowers"
            imgSrc="/assets/store/flowers_male.png"
            src="/store/store/1742068/Floral"
          />
          <RenderStoreBox
            style={{ width: "50%" }}
            position="right"
            description="Bags and Wallets"
            imgSrc="/assets/store/bags_and_wallets_male.png"
            src="/store/store/5376240/Bags%20&%20Wallets"
          />
        </>
      </Section>

      <RenderSectionHomeDecor />
      <RenderSectionFood />
    </>
  );
};

const RenderSectionForOthers = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: isDesktop ? "104px" : "80px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <RenderStoreBox
            style={{ width: "50%" }}
            position="left"
            description="Sunglasses"
            imgSrc="/assets/store/sunglasses_others.png"
            src="/store/store/5376248/Sunglasses"
          />
          <RenderStoreBox
            style={{ width: "50%" }}
            position="right"
            description="Bags and Wallets"
            imgSrc="/assets/store/bags_and_wallets_others.png"
            src="/store/store/5376240/Bags%20&%20Wallets"
          />
        </>
      </Section>

      {/*Others*/}
      <Section
        backgroundImage={"transparent"}
        contentProps={{
          paddingTop: "104px",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingLeft: isDesktop ? "unset" : "6px !important",
          paddingRight: isDesktop ? "unset" : "6px !important",
          alignItems: isDesktop ? "center" : "start",
          maxWidth: "1920px !important"
        }}
      >
        <>
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            position="left"
            description="Fragrances"
            imgSrc="/assets/store/fragrances_others.png"
            src="/store/store/129730/Fragrances"
          />
          <RenderStoreBox
            style={{ width: isDesktop ? "33.33%" : "50%" }}
            imgStyle={"storeImageByThree"}
            description="Watches"
            imgSrc="/assets/store/watches_others.png"
            src="/store/store/5376251/Timepieces"
          />
          {isDesktop && (
            <RenderStoreBox
              style={{ width: "33.33%" }}
              imgStyle={"storeImageByThree"}
              position="right"
              description="Flowers"
              imgSrc="/assets/store/flowers.png"
              src="/store/store/1742068/Floral"
            />
          )}
        </>
      </Section>
      {!isDesktop && (
        <Section
          backgroundImage={"transparent"}
          contentProps={{
            paddingTop: "24px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingLeft: isDesktop ? "unset" : "6px !important",
            paddingRight: isDesktop ? "unset" : "6px !important",
            alignItems: isDesktop ? "center" : "start"
          }}
        >
          <RenderStoreBox
            style={{ width: "100%" }}
            imgStyle={"storeImageByThree"}
            position="right"
            description="Flowers"
            imgSrc="/assets/store/flowers.png"
            src="/store/store/1742068/Floral"
          />
        </Section>
      )}

      <RenderSectionShoes />
      <RenderSectionJewelry />
      <RenderSectionWear />
      <RenderSectionHomeDecor />
      <RenderSectionFood />
    </>
  );
};

export default function StoreHomeSections(props) {
  if (props.gender === "m") {
    return <RenderSectionForMale />;
  } else if (props.gender === "f") {
    return <RenderSectionForFemale />;
  } else {
    return <RenderSectionForOthers />;
  }
}
