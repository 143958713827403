import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DESKTOP_DIALOG_MAX_WIDTH } from "../../common/Constants";
import AppDialog from "../../common/dialog/AppDialog";
import EnterAPaymentMethodContent from "./EnterAPaymentMethodContent";
import EditAPaymentMethodContent from "./EditAPaymentMethodContent";
import SavedPaymentMethodsContent from "./SavedPaymentMethodsContent";
import { getCurrentUser, formatUserPaymentData } from "../../../actions/userAction";

const PAGE_SAVED_PAYMENT_METHODS = "PAGE_SAVED_PAYMENT_METHODS";
const PAGE_ENTER_A_PAYMENT_METHOD = "PAGE_ENTER_A_PAYMENT_METHOD";
const PAGE_EDIT_A_PAYMENT_METHOD = "PAGE_EDIT_A_PAYMENT_METHOD";

const PaymentMethodDialogStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    paddingBottom: "24px",
    margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_DIALOG_MAX_WIDTH
    }
  },
  dialogContentClassName: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

export default function PaymentMethodDialog(props) {
  // TODO @bikrant: Assuming paymentMethods is an array of card details that can be added into a flat list
  const {
    open,
    onClose,
    billingName,
    onPaymentMethodSelected,
    onPaymentMethodAdded,
    paymentInfo,
    paymentInfos: initialPaymentInfos
  } = props;

  const [page, setPage] = useState(PAGE_SAVED_PAYMENT_METHODS);
  const dialogStyles = PaymentMethodDialogStyles();
  const [paymentInfos, setPaymentInfos] = useState(initialPaymentInfos);

  const onBackPressed = () => {
    setPage(PAGE_SAVED_PAYMENT_METHODS);
  };

  // Back Icon is only available from Payment Method Form
  const onBackPressedCallback =
    page === PAGE_ENTER_A_PAYMENT_METHOD || page === PAGE_EDIT_A_PAYMENT_METHOD ? onBackPressed : undefined;

  // Close Icon is only available from Saved Payment Methods List
  const onCloseCallback = page === PAGE_SAVED_PAYMENT_METHODS ? onClose : undefined;

  return (
    <AppDialog
      open={open}
      onClose={onCloseCallback}
      onBackPressed={onBackPressedCallback}
      fullWidth={false}
      paperClassName={dialogStyles.paper}
      dialogContentClassName={dialogStyles.dialogContentClassName}
    >
      {DialogContent(
        page,
        setPage,
        paymentInfos,
        billingName,
        onPaymentMethodSelected,
        onPaymentMethodAdded,
        paymentInfo,
        setPaymentInfos
      )}
    </AppDialog>
  );
}

function DialogContent(
  page,
  setPage,
  paymentInfos,
  billingName,
  onPaymentMethodSelected,
  onPaymentMethodAdded,
  paymentInfo,
  setPaymentInfos
) {
  const [selectedPaymentInfoToEdit, setSelectedPaymentInfoToEdit] = useState(null);
  const onNavigateToAddNewPaymentPage = () => {
    setPage(PAGE_ENTER_A_PAYMENT_METHOD);
  };

  const onNavigateToEditCardPage = (paymentMethod) => {
    setSelectedPaymentInfoToEdit(paymentMethod);
    setPage(PAGE_EDIT_A_PAYMENT_METHOD);
  };

  const handleOnPaymentMethodSelected = (paymentMethod) => {
    onPaymentMethodSelected(paymentMethod);
  };

  const handleOnPaymentMethodAdded = (paymentProfileId) => {
    onPaymentMethodAdded(paymentProfileId);
    refetchUserPaymentInfos();
    setPage(PAGE_SAVED_PAYMENT_METHODS);
  };

  const refetchUserPaymentInfos = async () => {
    try {
      const response = await getCurrentUser();
      const formattedUserPaymentData = formatUserPaymentData(response.data.payment_profile_data);
      console.log(formattedUserPaymentData.paymentInfos);
      setPaymentInfos(formattedUserPaymentData.paymentInfos);
    } catch (error) {
      console.log("refetchUserPaymentInfos", error);
    }
  };

  const handleOnPaymentMethodEdited = (paymentProfileId, updatedPaymentInfo) => {
    refetchUserPaymentInfos();
    setPage(PAGE_SAVED_PAYMENT_METHODS);
  };

  if (page === PAGE_ENTER_A_PAYMENT_METHOD) {
    return (
      <EnterAPaymentMethodContent
        handleOnPaymentMethodAdded={handleOnPaymentMethodAdded}
        billingName={billingName}
        paymentInfo={paymentInfo}
      />
    );
  }

  if (page === PAGE_EDIT_A_PAYMENT_METHOD) {
    return (
      <EditAPaymentMethodContent
        handleOnPaymentMethodEdited={handleOnPaymentMethodEdited}
        billingName={billingName}
        paymentInfo={paymentInfo}
        selectedPaymentInfoToEdit={selectedPaymentInfoToEdit}
      />
    );
  }

  return (
    <SavedPaymentMethodsContent
      handleOnPaymentMethodSelected={handleOnPaymentMethodSelected}
      handleAddNewPayment={onNavigateToAddNewPaymentPage}
      handleEditPayment={onNavigateToEditCardPage}
      paymentInfos={paymentInfos}
      paymentInfo={paymentInfo}
    />
  );
}
