import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DESKTOP_DIALOG_MAX_WIDTH } from "../../common/Constants";
import AppDialog from "../../common/dialog/AppDialog";
import { PaymentInfoType } from "./PaymentMethodType";
import EditPaymentMethod from "../../common/paymentMethods/PaymentMethodsEditContent";
import PaymentMethodsAdd from "../../common/paymentMethods/PaymentMethodsAdd";

const PaymentMethodDialogStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    paddingBottom: "24px",
    margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_DIALOG_MAX_WIDTH
    }
  },
  dialogContentClassName: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

interface PropsInterface {
  open: boolean;
  page: string;
  onClose: () => void;
  onPaymentMethodAddedOrUpdated: () => void;
  paymentMethod: PaymentInfoType;
}

function PaymentMethodDialog({ open, page, onClose, onPaymentMethodAddedOrUpdated, paymentMethod }: PropsInterface) {
  const dialogStyles = PaymentMethodDialogStyles();
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      fullWidth={false}
      paperClassName={dialogStyles.paper}
      dialogContentClassName={dialogStyles.dialogContentClassName}
    >
      {page === "edit" ? (
        <EditPaymentMethod
          selectedPaymentInfoToEdit={paymentMethod}
          onPaymentMethodEdited={onPaymentMethodAddedOrUpdated}
        />
      ) : (
        <PaymentMethodsAdd onPaymentMethodAdded={onPaymentMethodAddedOrUpdated} />
      )}
    </AppDialog>
  );
}

export default PaymentMethodDialog;
