import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CheckIcon = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="18.086" height="18.086" viewBox="0 0 18.086 18.086"> */}
      <path
        id="check"
        d="M10.028,2.54a8.2,8.2,0,0,0,3.3-.672A8.749,8.749,0,0,0,16.058.018,8.7,8.7,0,0,0,17.9-2.7,8.215,8.215,0,0,0,18.571-6,8.215,8.215,0,0,0,17.9-9.3a8.717,8.717,0,0,0-1.85-2.725,8.7,8.7,0,0,0-2.729-1.85,8.246,8.246,0,0,0-3.3-.668,8.2,8.2,0,0,0-3.287.668A8.7,8.7,0,0,0,4-12.028,8.717,8.717,0,0,0,2.153-9.3,8.215,8.215,0,0,0,1.485-6a8.169,8.169,0,0,0,.672,3.3A8.763,8.763,0,0,0,4.008.018a8.749,8.749,0,0,0,2.725,1.85A8.178,8.178,0,0,0,10.028,2.54ZM9.1-1.916a.871.871,0,0,1-.417-.1,1.124,1.124,0,0,1-.356-.321L6.267-4.852A.885.885,0,0,1,6.1-5.128a.856.856,0,0,1-.053-.294.709.709,0,0,1,.211-.514.687.687,0,0,1,.51-.215.793.793,0,0,1,.343.075.856.856,0,0,1,.308.277L9.062-3.7l3.489-5.59a.746.746,0,0,1,.642-.4.821.821,0,0,1,.523.185.6.6,0,0,1,.233.492.705.705,0,0,1-.066.294,1.962,1.962,0,0,1-.154.277L9.835-2.338a.98.98,0,0,1-.325.316A.818.818,0,0,1,9.1-1.916Z"
        transform="translate(-0.985 15.046)"
        fill="#3bd915"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default CheckIcon;
