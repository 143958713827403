import React, { useState, useEffect } from "react";
import AppContentContainer from "../common/AppContentContainer";
import { GiftInterface } from "../../models/gift";
import SearchBreadcrumb from "./SearchBreadcrumb";
import SearchResultCount from "./SearchResultCount";
import { Box } from "@material-ui/core/";
import GiftModal from "../common/gift/GiftModal";
import { getGiftsAction } from "../../actions/giftAction";
import GiftResults from "../common/gift/GiftResults";
import { SetPromptLoginInterface } from "../../interface/AppInterface";
import { useQuery } from "../../hooks/useQuery";

interface SearchProps {
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
}
const Search: React.FC<SearchProps> = (props: SearchProps) => {
  const [gifts, setGifts] = useState<Array<GiftInterface>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentGift, setCurrentGift] = useState<GiftInterface | null>(null);
  const [productModalIsOpen, setProductModalIsOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const { setPromptLogin } = props;
  const query = useQuery();

  const onSetPage = (page: number): void => {
    setPage(page);
  };

  const onSetSortBy = (sortBy: string): void => {
    setSortBy(sortBy);
  };

  useEffect(() => {
    const fetchGifts = async () => {
      setLoading(true);
      try {
        const response = await getGiftsAction({
          page: page,
          pageSize: 20,
          storeId: "129730", // hack to make the api worked. should be deleted when api is fixed
          product_filters: {
            search: "shoes"
          }
        });
        setGifts(Object.values(response.data.gifts));
        console.log(Object.values(response.data.gifts));
        setLoading(false);
      } catch (error) {
        console.log("error here", error);
      }
    };

    fetchGifts();
  }, []);
  return (
    <AppContentContainer
      style={{
        marginTop: 35
      }}
    >
      <Box>
        <SearchBreadcrumb />
        <SearchResultCount
          count={100}
          searchString={query.get("searchString")}
          style={{
            marginBottom: 43
          }}
        />
        <GiftResults
          isLoading={loading}
          isPaginated={true}
          page={page}
          totalPages={5}
          setPage={onSetPage}
          gifts={gifts}
          sortBy={sortBy}
          setSortBy={onSetSortBy}
          setCurrentGift={setCurrentGift}
          setProductModalIsOpen={setProductModalIsOpen}
          setPromptLogin={setPromptLogin}
          style={{
            width: "45%"
          }}
        />
      </Box>
      <GiftModal
        open={productModalIsOpen}
        onClose={() => {
          window.history.back();
          setProductModalIsOpen(false);
        }}
        gift={currentGift}
        setPromptLogin={setPromptLogin}
      />
    </AppContentContainer>
  );
};

export default Search;
