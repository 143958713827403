import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const IconTiktok = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20"> */}
      <path
        id="Icon_simple-tiktok"
        data-name="Icon simple-tiktok"
        d="M11.56.017C12.659,0,13.75.008,14.841,0A5.172,5.172,0,0,0,16.31,3.475a5.94,5.94,0,0,0,3.559,1.492V8.325a9.037,9.037,0,0,1-3.525-.808,10.389,10.389,0,0,1-1.36-.775c-.008,2.433.008,4.867-.017,7.292a6.335,6.335,0,0,1-1.133,3.283,6.265,6.265,0,0,1-4.96,2.675,6.154,6.154,0,0,1-3.424-.858,6.28,6.28,0,0,1-3.063-4.758c-.017-.417-.025-.833-.008-1.242A6.308,6.308,0,0,1,9.7,7.567c.017,1.233-.034,2.467-.034,3.7a2.956,2.956,0,0,0-2.535.308A2.9,2.9,0,0,0,6,13.033a3.283,3.283,0,0,0-.118,1.342,2.871,2.871,0,0,0,5.262,1.05,1.915,1.915,0,0,0,.344-.883c.084-1.492.05-2.975.059-4.467.008-3.358-.008-6.708.017-10.058Z"
        transform="translate(-2.369)"
        fill="#ef1581"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default IconTiktok;
