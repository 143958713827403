import { makeStyles } from "@material-ui/core/styles";
import { DESKTOP_CONTENT_WIDTH } from "../../common/Constants";

export const CheckoutResultStyles = makeStyles((theme) => ({
  contentStyle: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "16px",
    paddingBottom: "32px",
    paddingLeft: "8px",
    paddingRight: "8px",
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_WIDTH,
      margin: "0 auto",
      paddingLeft: "0px",
      paddingRight: "0px"
    }
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_WIDTH,
      justifyContent: "space-between",
      flexDirection: "row"
    }
  },
  leftCard: {
    marginTop: "8px",
    borderRadius: "8px",
    boxShadow: "inset 0 -1px 0 0 #E3E3E3, 0 1px 7px 0 rgba(0,0,0,0.15)",
    [theme.breakpoints.up("lg")]: {
      flexBasis: "57%"
    }
  },
  rightCard: {
    marginTop: "24px",
    borderRadius: "8px",
    boxShadow: "inset 0 -1px 0 0 #E3E3E3, 0 1px 7px 0 rgba(0,0,0,0.15)",
    height: "fit-content",
    [theme.breakpoints.up("lg")]: {
      marginTop: "8px",
      flexBasis: "39%"
    }
  },
  cardContent: {
    padding: "24px"
  },
  orderConfirmationContainer: {
    padding: "24px 0px",
    borderBottom: "1px solid #CCCCCC",
    [theme.breakpoints.up("lg")]: {
      padding: "24px 16px"
    }
  },
  tableCell: {
    borderBottom: "none",
    padding: "7px 16px"
  },
  beforeLastTableCell: {
    borderBottom: "none",
    padding: "7px 16px 14px 16px"
  },
  highlightedTableCell: {
    backgroundColor: "#F7F7F7",
    borderBottom: "none",
    padding: "14px 16px"
  }
}));
