import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import Form, { FormInput } from "../../input/Form";
import PersonalHeader from "./PersonalHeader";
import { FormControl, Box, makeStyles } from "@material-ui/core";
import TextInput from "../../input/TextInput";
import GenderSelector from "../../input/GenderSelector";
import PrimaryButton from "../../buttons/PrimaryButton";
import TermsAndConditions from "../../termsAndConditions/TermsAndConditions";

import RollbarTracker from "../../../utils/RollbarTracker";

import { UserContext } from "../../../context";
import { DESKTOP_CONTENT_WIDTH } from "../../common/Constants";
import UpdateEmailDialog from "../accountDetails/UpdateEmailDialog";

const componentStyles = makeStyles(() => ({
  orderButton: {
    height: "50px",
    borderRadius: "25px",
    width: "200px",
    marginTop: "16px"
  }
}));

const initialFormInputs = {
  nickname: FormInput(),
  email: FormInput(),
  gender: FormInput()
};

export default function Personal(props: any) {
  const context = useContext(UserContext);
  const classes = componentStyles();

  const [updateEmailIsOpen, setUpdateEmailIsOpen] = useState<boolean>(false);

  const onUpdateEmailDialogClosed = () => {
    setUpdateEmailIsOpen(false);
  };

  const onEmailUpdated = (newEmail: string) => {
    handleInputChange({ target: { name: "email", value: newEmail } });
    setUpdateEmailIsOpen(false);
  };

  const onUpdateEmailClicked = () => {
    setUpdateEmailIsOpen(true);
  };

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (let inputName in inputs) {
      const formInput = inputs[inputName];

      if ("email" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("nickname" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("gender" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const prefillFormInputValue = (formInput, value) => {
    if (value && value !== "") {
      formInput.value = value;
    }
  };

  const fetchUser = () => {
    axios
      .get("/api/user/")
      .then((response) => {
        console.log(response);

        prefillFormInputValue(formInputs.email, response.data.email);
        prefillFormInputValue(formInputs.nickname, response.data.nickname);
        prefillFormInputValue(formInputs.gender, response.data.gender);

        setFormInputs({ ...formInputs });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // Handle 401
          console.log("no user, pushing to stores");
          props.history.push("/store/storehome");
          return;
        }

        RollbarTracker.logError("Error getting user", error);
        console.log("error: " + error);
        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  const setUser = () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });
      return;
    }

    axios
      .post("/api/user", {
        nickname: formInputs.nickname.value,
        email: formInputs.email.value,
        gender: formInputs.gender.value
      })
      .then((response) => {
        // todo - handle redirect to buy flow
        context.user.set({
          email: formInputs.email.value,
          gender: formInputs.gender.value,
          nickname: formInputs.nickname.value
        });
        props.history.push("/store/storehome");
      })
      .catch((error) => {
        RollbarTracker.logError("Error setting user", error);
        console.log("error: " + error);
        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Box>
      <Box width={{ lg: DESKTOP_CONTENT_WIDTH }} marginX={{ lg: "auto" }} marginBottom="16px">
        <PersonalHeader />
        <Box display="flex" width={{ lg: "45%" }} marginX="auto">
          <FormControl
            fullWidth
            style={{
              marginLeft: "16px",
              marginRight: "16px",
              marginTop: "20px",
              marginBottom: "16px"
            }}
          >
            <TextInput
              id="nickname"
              name="nickname"
              label="Handle"
              value={formInputs.nickname.value}
              error={formInputs.nickname.error}
              helperText={formInputs.nickname.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
            <Box display={"inline-flex"} alignItems={"center"}>
              <TextInput
                id="email"
                name="email"
                disabled={true}
                label="Email address"
                marginTop="16px"
                value={formInputs.email.value}
                error={formInputs.email.error}
                helperText={formInputs.email.error}
                onChange={handleInputChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
              />
              <Box style={{ minWidth: "8px" }}></Box>
              <PrimaryButton className={classes.orderButton} handleClick={onUpdateEmailClicked}>
                Update Email
              </PrimaryButton>
            </Box>
            <GenderSelector
              id="gender"
              name="gender"
              label="Gender"
              style={{ marginTop: "16px", marginLeft: "10px" }}
              value={formInputs.gender.value}
              error={formInputs.gender.error}
              helperText={formInputs.gender.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </FormControl>
        </Box>
        <Box marginTop="16px" marginX="auto" width={{ lg: "35%" }} textAlign="center">
          <PrimaryButton handleClick={setUser}>Save</PrimaryButton>
        </Box>
      </Box>

      <UpdateEmailDialog
        open={updateEmailIsOpen}
        onClose={onUpdateEmailDialogClosed}
        onEmailUpdated={onEmailUpdated}
        initialEmail={formInputs.email.value}
        nickname={formInputs.nickname.value}
      />
      <TermsAndConditions />
    </Box>
  );
}
