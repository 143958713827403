import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { buttonColor } from "style";

const usesStyles = makeStyles(() => ({
  buttonStyle: {
    borderRadius: 24,
    color: buttonColor.PINK,
    border: `${buttonColor.PINK} 1px solid`,
    fontWeight: "normal",
    textTransform: "none",
    flexDirection: "row"
  },
  buttonStyleDisabled: {
    borderRadius: 24,
    color: buttonColor.GREY,
    border: `${buttonColor.GREY} 1px solid`,
    fontWeight: "normal",
    textTransform: "none"
  },
  textOneStyle: {
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "22px",
    textTransform: "none"
  },
  textOneStyleBold: {
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "22px",
    fontWeight: "bold",
    textTransform: "none"
  },
  textTwoStyle: {
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "22px",
    fontWeight: "normal",
    textTransform: "none"
  }
}));

interface TransferCreditButtonProps {
  style?: React.CSSProperties;
  text: string;
  secondaryText?: string;
  handleClick?: () => void;
  disabled?: boolean;
}
const TransferCreditButton: React.FC<TransferCreditButtonProps> = (props: TransferCreditButtonProps) => {
  const styles = usesStyles();
  const { style, text, secondaryText, handleClick, disabled } = props;

  const onClick = () => {
    if (!disabled) {
      handleClick();
    }
  };

  return (
    <Button
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
      className={styles.buttonStyle}
      style={{ ...style }}
    >
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Typography className={disabled ? styles.textOneStyle : styles.textOneStyleBold}>{text}</Typography>
        {secondaryText ? <Typography className={styles.textTwoStyle}>{secondaryText}</Typography> : null}
      </Box>
    </Button>
  );
};

export default TransferCreditButton;
