import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, CardMediaProps, useMediaQuery, Theme } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import MuiCardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useNav from "../../../hooks/useNav";
import { COLORS, FONT_FAMILIES } from "../../../style";

const useStyles = ({ height, maxWidth, titleStyle, borderRadius, bgSize }) => {
  return makeStyles((theme) => ({
    root: {
      maxWidth: maxWidth ?? "100%",
      borderRadius: borderRadius ?? 8,
      backgroundColor: "transparent",
      boxShadow: "none"
    },
    media: {
      height,
      borderRadius: borderRadius ?? 8,
      backgroundSize: bgSize ?? "cover"
    },
    content: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 8,
      paddingBottom: 8,
      "&:last-child": {
        paddingBottom: 20
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "28px",
      fontFamily: FONT_FAMILIES.POPPINS,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "21px"
      },
      ...titleStyle
    },
    viewAll: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 0,
      paddingRight: 0,
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    }
  }));
};

interface CardMediaComponentProps extends CardMediaProps {
  title?: string | "";
  subTitle?: string | "";
  height: string | number;
  titleStyle?: any;
  maxWidth?: string | number;
  color?: "textPrimary" | "textSecondary";
  src: string;
  redirectTo?: string;
  showViewAll?: boolean;
  borderRadius?: string | number;
  bgSize?: "contain" | "cover" | "auto" | "unset";
}

export default function CardMedia({
  title,
  subTitle,
  src,
  height,
  maxWidth,
  color,
  redirectTo,
  showViewAll = false,
  titleStyle,
  borderRadius,
  bgSize,
  ...rest
}: CardMediaComponentProps) {
  const { goTo } = useNav();
  const classes = useStyles({ height, maxWidth, titleStyle, borderRadius, bgSize })();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const handleRedirectTo = () => goTo(redirectTo ?? "#");

  const [visible, setVisible] = useState<boolean>(false);
  const placeholderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!visible && placeholderRef.current) {
      const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
        if (intersectionRatio > 0) {
          setVisible(true);
        }
      });
      observer.observe(placeholderRef.current);
      return () => observer.disconnect();
    }
  }, [visible, placeholderRef]);

  return (
    <Card className={classes.root} {...rest}>
      <CardActionArea onClick={handleRedirectTo}>
        {visible ? (
          <MuiCardMedia className={classes.media} image={src} title={title} />
        ) : (
          <div
            style={{
              height,
              backgroundColor: COLORS.WHITE,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: "0.8"
            }}
            aria-label={title}
            ref={placeholderRef}
          >
            <CircularProgress />
          </div>
        )}
      </CardActionArea>
      {title && (
        <CardContent className={classes.content}>
          <Box>
            <Typography
              className={classes.title}
              variant="h5"
              color={color}
              style={{ cursor: "pointer" }}
              onClick={handleRedirectTo}
            >
              {title}
            </Typography>
            {subTitle && (
              <Typography
                variant="body2"
                color={color}
                style={{
                  fontSize: isDesktop ? 18 : 16,
                  fontWeight: 700,
                  lineHeight: isDesktop ? "27px" : "24px"
                }}
                onClick={handleRedirectTo}
              >
                {subTitle}
              </Typography>
            )}
          </Box>
          {showViewAll && (
            <Box className={classes.viewAll}>
              <Typography
                variant="body2"
                color={color}
                style={{ fontSize: 16, lineHeight: "26px" }}
                onClick={handleRedirectTo}
              >
                View All
              </Typography>
              <img src={"/assets/arrow.svg"} style={{ marginLeft: 3 }} />
            </Box>
          )}
        </CardContent>
      )}
    </Card>
  );
}
