export const textColor = {
  DARK: "#AFB0B1",
  DARK1: "#484848",
  NORMAL: "#E92983",
  LIGHT: "rgba(0,0,0,.3)",
  GRAY: "#333333",
  LIGHT_GRAY: "#A3A3A3",
  LIGHT_GRAY1: "#ECECF3",
  LIGHT_GRAY2: "#A4A4A4",
  LIGHT_GRAY3: "#888888",
  WHITE: "#ffffff"
};

export const backgroundTheme = {
  iconFill: "#0d5c93",
  grayFill: "#F7F7F7",
  wishlistButtonFill: "#ECECF3"
};

export const slidebarTheme = {
  listHover: "#f3f3f3",
  headerBG: "#f2f3f5",
  headerText: "rgba(0,0,0,.8)"
};

export const iconTheme = {
  DARK: "#484848"
};

export const buttonColor = {
  PINK: "#E92983",
  PINK1: "#ED5CA0",
  GREY: textColor.LIGHT_GRAY
};

export const socialColors = {
  FACEBOOK: "#3479ea",
  twitter: "#1d9bf0"
};

export const FONT_FAMILIES = {
  SOFIA_PRO: "Sofia Pro",
  POPPINS: "Poppins",
  OPEN_SANS: "Open Sans"
};

export const COLORS = {
  WHITE: "#FFFFFF",
  LIGHT_BLACK: "#2D2D2D",
  BLACK: "#000000",
  MEDIUM_BLACK: "#090909",
  PINK: "#E6007E",
  MEDIUM_PINK: "#EF1581",
  LIGHT_GRAY: "#707070",
  FLESH: "#FFDEE5",
  DARK_GRAY: "#323232"
};

export const HEADER = {
  headerBg: "#323232",
  header1: "#02060B",
  header2: "#323232",
  syncHeader: "#ACACE6"
};

export const BACKGROUND = {
  bgColor: "#F7F7F7",
  WHITE: "#ffffff",
  LIGHT_GRAY: "#A3A3A3"
};
