import React from "react";
import { Box, Theme, useMediaQuery } from "@material-ui/core";
import Section from "../storeV2/common/Section";
import SectionLabel from "../storeV2/common/SectionLabel";

const StoreHomePhotoHeader = (props) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const isLargeDesktopBetweenDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.between("lg", "md"));
  const isLargeDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("xl"));

  let banner = "/assets/store/banner_category_others.png";
  let bannerMobile = "/assets/store/banner_category_mobile_others.png";
  if (props.gender === "m") {
    banner = "/assets/store/banner_category_male.png";
    bannerMobile = "/assets/store/banner_category_mobile_male.png";
  } else if (props.gender === "f") {
    banner = "/assets/store/banner_category.png";
    bannerMobile = "/assets/store/banner_category_mobile.png";
  }

  return (
    <>
      <Section
        backgroundImage={isDesktop ? banner : bannerMobile}
        height="440px"
        style={{
          backgroundPosition: isLargeDesktop ? "top center" : isLargeDesktopBetweenDesktop ? "unset" : "90% 26%",
          backgroundSize: "cover",
          backgroundRepeat: isLargeDesktop ? "no-repeat" : "unset",
          display: "block"
        }}
        contentProps={{
          justifyContent: "center",
          alignItems: "center",
          display: isDesktop ? "flex" : "block !important"
        }}
      >
        <Box style={{ width: "100%" }}>
          <SectionLabel
            title="Get the gifts"
            marginLeft={isDesktop ? "90px" : "unset"}
            marginBottom="unset"
            style={{
              color: "#FFFFFF"
            }}
            titleStyle={{
              paddingTop: "60px",
              fontSize: isDesktop ? "48px" : "32px"
            }}
          />
          <SectionLabel
            title="that you love!"
            marginLeft={isDesktop ? "90px" : "unset"}
            marginBottom="unset"
            style={{
              color: "#FFFFFF"
            }}
            titleStyle={{
              fontSize: isDesktop ? "48px" : "32px"
            }}
          />
        </Box>
      </Section>
    </>
  );
};

export default StoreHomePhotoHeader;
