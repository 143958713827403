import React, { useMemo, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CustomSelectV2 from "./CustomSelectV2";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";
import { FONT_FAMILIES, textColor } from "../../style/index";
import { Typography, makeStyles } from "@material-ui/core";

interface BirthdayOptionValueObject {
  birthYear: number;
  birthMonth: string;
  birthDay: string;
}
const months = [
  {
    full: "January",
    abbreviated: "Jan"
  },
  {
    full: "February",
    abbreviated: "Feb"
  },
  {
    full: "March",
    abbreviated: "Mar"
  },
  {
    full: "April",
    abbreviated: "Apr"
  },
  {
    full: "May",
    abbreviated: "May"
  },
  {
    full: "June",
    abbreviated: "June"
  },
  {
    full: "July",
    abbreviated: "July"
  },
  {
    full: "August",
    abbreviated: "Aug"
  },
  {
    full: "September",
    abbreviated: "Sept"
  },
  {
    full: "October",
    abbreviated: "Oct"
  },
  {
    full: "November",
    abbreviated: "Nov"
  },
  {
    full: "December",
    abbreviated: "Dec"
  }
];

interface BirthdaySelectorProps {
  values: BirthdayOptionValueObject;
  onChange: (params: EventListener) => void;
  onBlur: (params: EventListener) => void;
}

const componentStyles = makeStyles(() => ({
  label: {
    position: "relative",
    color: textColor.DARK1,
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 9,
    transform: "translate(0, 0) scale(1)",
    fontFamily: FONT_FAMILIES.SOFIA_PRO
  }
}));

const BirthdaySelector: React.FC<BirthdaySelectorProps> = ({ values, onChange, onBlur }: BirthdaySelectorProps) => {
  const [monthDays, setMonthDays] = useState([]);
  const { isMobile } = useMediaBreakpoints();
  const styles = componentStyles();

  const generateNumOfDays = (totalDays: number) => {
    const days = [];
    for (let i = 1; i <= totalDays; i++) {
      days.push({
        label: i.toString(),
        value: i.toString()
      });
    }

    return days;
  };

  useEffect(() => {
    if (values.birthMonth || values.birthYear) {
      getMonthDays(values.birthMonth, values.birthYear);
    }
  }, [values]);

  const listOfMonths = useMemo(
    () =>
      months.map((month) => {
        return {
          label: month.abbreviated,
          value: month.full
        };
      }),
    []
  );

  const listOfYear = useMemo(
    () => (startYear) => {
      const currentYear = new Date().getFullYear(),
        years = [];
      startYear = startYear || 1900;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }

      return years.map((item) => {
        return {
          label: item,
          value: item
        };
      });
    },
    []
  );

  const getMonthDays = (month: string, year: number) => {
    if (month === "" || year === null) {
      return setMonthDays([]);
    }
    const dayIndex = listOfMonths.map((e) => e.value).indexOf(month) + 1;
    let totalDays = new Date(year, dayIndex, 0).getDate();
    if (dayIndex === 2) {
      totalDays = totalDays + 1; // To add 28 & 29
    }
    const setDays = generateNumOfDays(totalDays);
    setMonthDays(setDays);
  };

  const handleMonthAndYearOnChange = (event: any) => {
    const value = event.target.value;

    if (values.birthYear) {
      getMonthDays(value, values.birthYear);
    }

    if (value !== "") {
      onChange(event);
      onChange({
        ...event,
        target: {
          name: "birthDay",
          value: ""
        }
      });
    } else {
      onChange(event);
      onChange({
        ...event,
        target: {
          name: "birthDay",
          value: ""
        }
      });
    }
  };

  return (
    <FormControl component="fieldset" style={{ marginTop: 20 }}>
      <div>
        <FormLabel component="legend" className={styles.label} style={{ marginBottom: 6 }}>
          Birthday
        </FormLabel>
        <Typography variant="body1">
          We’ll let your wishlist subscribers know when your birthday is coming up so they can buy you something nice!
          (We’ll never let them know your actual birthday.)
        </Typography>
      </div>
      <Box flex marginTop={3}>
        <CustomSelectV2
          name="birthYear"
          options={listOfYear(1920)}
          id="birthYear"
          label="Year"
          addLabelAsValue={true}
          value={values.birthYear}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="1"
          error={null}
          style={{
            marginRight: 15,
            width: isMobile ? 100 : 130
          }}
        />
        <CustomSelectV2
          name="birthMonth"
          options={listOfMonths}
          id="birthMonth"
          label="Month"
          addLabelAsValue={true}
          value={values.birthMonth}
          onChange={handleMonthAndYearOnChange}
          onBlur={onBlur}
          autoComplete="January"
          error={null}
          style={{ marginRight: 15, width: isMobile ? 100 : 130 }}
        />
        <CustomSelectV2
          name="birthDay"
          options={monthDays}
          id="birthDay"
          label="Day"
          addLabelAsValue={true}
          value={values.birthDay}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="1"
          error={null}
          style={{ width: isMobile ? 100 : 130 }}
        />
      </Box>
    </FormControl>
  );
};

export default BirthdaySelector;
