import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import ReceivedGiftImage from "./ReceivedGiftImage";
import RedeemGiftDialogButtons from "./RedeemGiftDialogButtons";
import {
  RedeemDialogButtonStyle,
  RedeemGiftCaptionStyle,
  RedeemGiftDividerStyle,
  RedeemGiftHeaderStyle,
  RedeemGiftNameStyle
} from "./RedeemGiftFlowStyles";
import { roundToDecimalPlaces } from "../../utils/stringUtils";

export default function ExchangeForCreditDialogContent(props: any) {
  const exchangeForCreditDialogStyle = RedeemDialogButtonStyle();
  const redeemGiftNameStyle = RedeemGiftNameStyle();
  const redeemGiftHeaderStyle = RedeemGiftHeaderStyle();
  const redeemGiftCaptionStyle = RedeemGiftCaptionStyle();
  const redeemGiftDividerStyle = RedeemGiftDividerStyle();

  let order_offer_total =
    props.order && props.order.offer_data.exchange.credits
      ? props.order.offer_data.exchange.credits.offer_total
      : undefined;
  let order_offer_bonus_amount =
    props.order && props.order.offer_data.exchange.credits
      ? props.order.offer_data.exchange.credits.bonus_amount
      : undefined;

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" style={{ paddingBottom: "16px" }}>
      <Typography variant="body1">Order number: {props.order.order_id}</Typography>

      <ReceivedGiftImage
        alt="Gift Image"
        src={props.order.gift.image_url}
        style={{ alignSelf: "center", marginTop: "16px" }}
      />

      <Typography variant="h6" className={redeemGiftNameStyle.textStyle} style={{ marginTop: "8px" }}>
        {props.order.gift.name}
      </Typography>

      <Divider className={redeemGiftDividerStyle.style} style={{ marginTop: "16px" }} />

      <Typography variant="subtitle1" className={redeemGiftHeaderStyle.textStyle} style={{ marginTop: "16px" }}>
        Would you like to exchange this gift for{" "}
        <b>
          {order_offer_total && order_offer_total > 0
            ? "$" + roundToDecimalPlaces(order_offer_total, 2) + "*"
            : "$" + roundToDecimalPlaces(props.order.pricing.total_price_paid_converted, 2)}
        </b>{" "}
        in our store?
      </Typography>

      {order_offer_bonus_amount && order_offer_bonus_amount > 0 ? (
        <Typography variant="body2" className={redeemGiftHeaderStyle.textStyle} style={{ marginTop: "8px" }}>
          *BONUS: This one-time offer includes an extra{" "}
          <b>${roundToDecimalPlaces(order_offer_bonus_amount, 2)} Store Credit!</b>
        </Typography>
      ) : null}

      <RedeemGiftDialogButtons
        buttonClassName={exchangeForCreditDialogStyle.button}
        positiveButtonText={`Yes, exchange\n(for ${
          "$" +
          roundToDecimalPlaces(
            order_offer_total && order_offer_total > 0
              ? order_offer_total
              : props.order.pricing.total_price_paid_converted,
            2
          )
        } Store Credit)`}
        onPositiveButtonClick={props.onExchangeForCreditClicked}
        onNegativeButtonClick={props.onKeepGiftClicked}
        negativeButtonText={`No, keep this gift\n ${
          order_offer_bonus_amount && order_offer_bonus_amount > 0
            ? `(get ${"$" + roundToDecimalPlaces(order_offer_bonus_amount, 2)} less value)`
            : ""
        }`}
        useSecondaryButton={true}
      />

      <Typography variant="caption" className={redeemGiftCaptionStyle.textStyle}>
        Store Credit Terms: Store credit is good toward your choice of thousands of items in our store, including
        categories like fragrances, jewelry, cosmetics, flowers, shoes, handbags and more. Store credit cannot be used
        to buy other egifts or items added to your wishlist from third-parties outside of our store. This is a one-time
        offer to exchange your gift for Store Credit and your choice of whether or not to participate in this offer is
        final.
      </Typography>
    </Box>
  );
}
