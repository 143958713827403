import React, { ReactElement, useMemo, useEffect, useState, useLayoutEffect, useRef } from "react";
import Section from "../common/appLayout/Section";
import { Box, Typography, makeStyles, Avatar, CircularProgress, Grid } from "@material-ui/core";
import { BACKGROUND } from "../../style";
import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import SecondaryButtonV3 from "../buttons/SecondaryButtonV3";
import { useApp } from "../../context/app-context";
import SelectWishListFor from "../gifter/SelectWishListFor";
import ReadMoreReadLess from "../common/readMoreReadLess/ReadMoreReadLess";
import { WISHLIST_EDIT_PROFILE_PATH } from "../../constants/paths";
import useNav from "../../hooks/useNav";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import clsx from "clsx";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";
const DEFAULT_PROFILE_URL = "/assets/wishlist/profile-empty-male.jpg";

gsap.registerPlugin(ScrollTrigger);

const useStyles = ({ profileUrl, isWithinScrollTrigger, isViewingOtherWishlistWithNoPhoto, isLoading }) => {
  return makeStyles((theme) => ({
    section: {
      alignItems: "center",
      backgroundRepeat: "no-repeat"
    },
    sectionMobile: {
      height: isViewingOtherWishlistWithNoPhoto || isLoading ? 0 : 375,
      alignItems: "center",
      display: "block",
      backgroundPosition: "center",
      backgroundImage: `url(${profileUrl ?? DEFAULT_PROFILE_URL})`,
      marginTop: isViewingOtherWishlistWithNoPhoto || isLoading ? 0 : 130,
      zIndex: 2,
      visibility: isViewingOtherWishlistWithNoPhoto || isLoading ? "hidden" : "visible",
      opacity: isWithinScrollTrigger ? 0 : 1,
      [theme.breakpoints.only("sm")]: {
        height: isViewingOtherWishlistWithNoPhoto || isLoading ? 0 : 425
      }
    },
    content: {},
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: BACKGROUND.bgColor,
      minHeight: "unset",
      alignItems: "flex-start",
      paddingTop: 20,
      paddingBottom: 0
    },
    avatarSm: {
      width: 68,
      height: 68,
      marginRight: 8
    },
    description: {
      textAlign: "left",
      color: "#484848",
      fontSize: 16,
      marginTop: 10
    },
    vanityWishlist: {
      marginTop: 5,
      display: "block",
      "& > .vanity": {
        color: "#E6007E",
        fontSize: 14
      }
    },
    fixedProfileHeader: {
      position: "fixed",
      top: 74,
      backgroundColor: BACKGROUND.bgColor,
      zIndex: 4,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 5,
      paddingBottom: 5,
      minHeight: 78,
      left: 0
    }
  }));
};

export interface ReceiverDetailsObject {
  nickname: string;
  encryptedUserId: string;
  vanity: string;
  about: string;
  profileUrl?: string;
}

interface WishlistProfileMobileProps {
  receiverDetails: ReceiverDetailsObject;
  isLoading?: boolean;
  giftCount: number;
  pageCount: number;
}

const WishlistProfileMobile = ({
  receiverDetails,
  isLoading,
  giftCount,
  pageCount
}: WishlistProfileMobileProps): ReactElement => {
  const { setOpenShareDialog, loggedInUserFullname, encryptedUserId } = useApp();
  const { goTo } = useNav();
  const [isWithinScrollTrigger, setIsWithinScrollTrigger] = useState(false);
  const [initScrollTrigger, setInitScrollTrigger] = useState(null);
  const userDetailsRef = useRef(null);
  const { isTabletMobileOnly, isMobileXs } = useMediaBreakpoints();

  const vanityUrl = useMemo(() => {
    let wishlistUrl = `${window.location.hostname}/gift/wishlist/${encryptedUserId}`;
    if (receiverDetails.vanity && receiverDetails.vanity.length) {
      wishlistUrl = `${window.location.hostname}/${receiverDetails.vanity}`;
    }
    return wishlistUrl;
  }, [receiverDetails.vanity]);

  const isViewingOtherWishlist = useMemo(
    () => receiverDetails.encryptedUserId !== encryptedUserId ?? true,
    [receiverDetails.encryptedUserId, encryptedUserId]
  );

  const isViewingOtherWishlistWithNoPhoto = useMemo(
    () => (isViewingOtherWishlist && !receiverDetails.profileUrl ? true : false),
    [isViewingOtherWishlist, receiverDetails.profileUrl]
  );

  const classes = useStyles({
    profileUrl: receiverDetails.profileUrl,
    isWithinScrollTrigger,
    isViewingOtherWishlistWithNoPhoto,
    isLoading
  })();

  // force scroll to top before loading the page
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);

  useLayoutEffect(() => {
    let snapper = null;
    if (!isLoading && pageCount === 1 && !isViewingOtherWishlistWithNoPhoto) {
      setInitScrollTrigger(
        ScrollTrigger.create({
          trigger: ".profile",
          start: "top+=10 top+=80",
          end: "bottom center-=398",
          markers: false,
          scrub: 0.5,
          pin: true,
          toggleActions: "play none none reverse",
          pinSpacing: false,
          onEnterBack: () => {
            setIsWithinScrollTrigger(false);
          },
          onLeave: () => {
            setIsWithinScrollTrigger(true);
          }
        })
      );

      snapper = ScrollTrigger.create({
        trigger: ".profile",
        start: "top+=10 top+=80",
        end: "bottom center-=398",
        markers: false,
        snap: {
          snapTo: 4,
          duration: 0.5
        }
      });
    } else if (!isLoading && pageCount === 1 && userDetailsRef.current && isViewingOtherWishlistWithNoPhoto) {
      setInitScrollTrigger(
        ScrollTrigger.create({
          trigger: userDetailsRef.current,
          start: "top top+=80",
          end: "center+=50 top+=195",
          markers: false,
          scrub: 0.5,
          toggleActions: "play none none reverse",
          pinSpacing: false,
          onEnterBack: () => {
            setIsWithinScrollTrigger(false);
          },
          onLeave: () => {
            setIsWithinScrollTrigger(true);
          }
        })
      );
    }

    window.scrollTo(0, 0);

    return () => {
      if (snapper) {
        snapper.kill();
      }
    };
  }, [isLoading, pageCount, isViewingOtherWishlistWithNoPhoto]);

  useLayoutEffect(() => {
    let gsp = [];
    if (
      initScrollTrigger &&
      !isViewingOtherWishlistWithNoPhoto &&
      userDetailsRef.current &&
      receiverDetails.encryptedUserId
    ) {
      gsp.push(
        gsap.to(".profile", {
          scrollTrigger: {
            ...initScrollTrigger.vars
          },
          width: 68,
          height: 68,
          left: 16,
          top: 80,
          zIndex: 0,
          borderRadius: "50%"
        })
      );
      gsp.push(
        gsap.to(userDetailsRef.current, {
          scrollTrigger: {
            ...initScrollTrigger.vars
          },
          opacity: 0
        })
      );
      gsp.push(
        gsap.to(".wishlistMobileProfileContainer", {
          scrollTrigger: {
            ...initScrollTrigger.vars
          },
          marginTop: -250,
          zIndex: 3
        })
      );
    } else if (initScrollTrigger && isViewingOtherWishlistWithNoPhoto && userDetailsRef.current) {
      gsp.push(
        gsap.to(userDetailsRef.current, {
          scrollTrigger: {
            ...initScrollTrigger.vars
          },
          opacity: 0
        })
      );
    }

    return () => {
      if (initScrollTrigger && gsp.length > 0) {
        gsp.map((item) => item.kill());
        initScrollTrigger.kill();
        setInitScrollTrigger(null);
      }
    };
  }, [userDetailsRef, initScrollTrigger, isViewingOtherWishlistWithNoPhoto, receiverDetails.encryptedUserId]);

  // useLayoutEffect(() => {
  //   let tr = null;
  //   if (isWithinScrollTrigger) {
  //     tr = gsap.to(".customAvatar", {
  //       width: 68,
  //       height: 68,
  //       duration: 0.5
  //     });
  //   }

  //   return () => {
  //     if (tr) {
  //       tr.kill();
  //     }
  //   };
  // }, [isWithinScrollTrigger]);

  return (
    <Box className="wishlistMobileProfileContainer" style={{ marginTop: isTabletMobileOnly ? -15 : "unset" }}>
      <Section className={clsx(classes.sectionMobile, "profile")} />

      <Section className={classes.section} contentProps={{ className: classes.content }}>
        <Box className={classes.wrapper} style={{ marginTop: isViewingOtherWishlistWithNoPhoto ? 74 : "unset" }}>
          {isLoading && pageCount <= 1 ? (
            <CircularProgress style={{ marginTop: 100, alignSelf: "center" }} />
          ) : (
            <>
              {isWithinScrollTrigger && (
                <Box className={classes.fixedProfileHeader}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    {!isViewingOtherWishlist ? (
                      <Avatar
                        alt={loggedInUserFullname}
                        src={receiverDetails.profileUrl ?? DEFAULT_PROFILE_URL}
                        className={clsx(classes.avatarSm, "customAvatar")}
                      />
                    ) : (
                      receiverDetails.profileUrl && (
                        <Avatar
                          alt={receiverDetails.nickname}
                          src={receiverDetails.profileUrl}
                          className={clsx(classes.avatarSm, "customAvatar")}
                        />
                      )
                    )}

                    <Typography
                      variant="body1"
                      style={{ fontSize: 16, fontWeight: 700, maxWidth: isMobileXs ? 200 : "unset" }}
                    >
                      {`${receiverDetails.nickname}'s`}
                      <br />
                      Wishlist
                    </Typography>
                  </Box>
                  <Typography variant="body1" style={{ alignSelf: "flex-end", fontWeight: 16, lineHeight: "26px" }}>
                    {`${giftCount} ${giftCount > 1 || giftCount === 0 ? "items" : "item"}`}
                  </Typography>
                </Box>
              )}

              <div ref={userDetailsRef} style={{ width: "100%" }}>
                {isViewingOtherWishlist && (
                  <Typography variant="body1" style={{ fontSize: 14, marginBottom: 5, alignSelf: "flex-start" }}>
                    Viewing
                  </Typography>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <SelectWishListFor
                    username={receiverDetails.nickname ?? loggedInUserFullname}
                    isViewingOtherWishlist={isViewingOtherWishlist}
                  />
                  <Typography variant="body1" style={{ alignSelf: "flex-end", fontWeight: 16, lineHeight: "26px" }}>
                    {`${giftCount} ${giftCount > 1 || giftCount === 0 ? "items" : "item"}`}
                  </Typography>
                </Box>

                {!isViewingOtherWishlist && (
                  <Grid container spacing={4} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Grid item sm={6} xs={6}>
                      <PrimaryButtonV3 onClick={() => setOpenShareDialog(true)} style={{ width: "100%", height: 40 }}>
                        Share
                      </PrimaryButtonV3>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <SecondaryButtonV3
                        text="Edit Profile"
                        style={{ background: "#fff", color: "#E6007E", width: "100%", height: 40 }}
                        onClick={() => goTo(WISHLIST_EDIT_PROFILE_PATH)}
                      />
                    </Grid>
                  </Grid>
                )}

                {isViewingOtherWishlist && (
                  <Box className={classes.vanityWishlist}>
                    <Typography variant="body1" className="vanity">
                      {vanityUrl}
                    </Typography>
                  </Box>
                )}
              </div>

              <ReadMoreReadLess
                className={classes.description}
                charLimit={70}
                readLessText="read less"
                readMoreText="read more"
                longtext={receiverDetails.about}
              />
            </>
          )}
        </Box>
      </Section>
    </Box>
  );
};

export default WishlistProfileMobile;
