import React from "react";
import { Typography, Grid, useMediaQuery, Theme, makeStyles } from "@material-ui/core";
import Section from "../common/Section";
import SectionLabel from "../common/SectionLabel";
import PrimaryButtonV2 from "../common/PrimaryButtonV2";
import { COLORS } from "../../../style";
import { useApp } from "../../../context/app-context";

const useStyles = makeStyles((theme) => ({
  section: {
    height: 717,
    paddingTop: 109,
    paddingBottom: 80,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 60,
      height: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      paddingTop: 80
    }
  }
}));

const HowItWorksSection = () => {
  const classes = useStyles();
  const { setPromptLogin } = useApp();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <Section className={classes.section} bgcolor={COLORS.WHITE}>
      <Grid container spacing={4}>
        <Grid item lg={12} style={{ margin: "auto", textAlign: "center" }}>
          <Typography
            variant="h2"
            style={{
              marginBottom: 48,
              color: COLORS.LIGHT_BLACK,
              fontSize: isDesktop ? 48 : 24,
              fontWeight: isDesktop ? 700 : 800,
              lineHeight: isDesktop ? "58px" : "32px"
            }}
          >
            How It works
          </Typography>
        </Grid>

        <Grid item lg={4} xs={11}>
          <SectionLabel
            iconSrc="create-wishlist.svg"
            title="Create Wishlist"
            description="Choose from thousands of trending and designer products in our hand-picked shops."
            lists={["Browse trending and designer items", "Save your favorites to your Wishlist"]}
            marginBottom={5}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <SectionLabel
            iconSrc="share-wishlist.png"
            title="Share Wishlist"
            description="Let fans and friends know what you want, or share your link with that special someone."
            lists={[
              "Copy your wishlist link to your social media accounts",
              "Share your wishlist link with your fans and friends"
            ]}
            marginBottom={5}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <SectionLabel
            iconSrc="receive-gifts.svg"
            title="Receive Gifts"
            description="Gifts arrive at your door smoothly and safely, with your privacy top of mind."
            lists={["Accept or exchange gifts instantly", "Get exactly what you want"]}
            marginBottom={5}
          />
        </Grid>
        <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
          <PrimaryButtonV2
            label="Create My Wishlist"
            style={{ width: isDesktop ? 202 : 212, height: 48, marginTop: 12 }}
            onClick={() =>
              setPromptLogin({
                promptLogin: true,
                returnTo: undefined,
                registration: true,
                forceRegistration: true
              })
            }
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default HowItWorksSection;
