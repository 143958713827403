import React from "react";
import { Box, Typography } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { makeStyles } from "@material-ui/core";

interface GiftPaginationProps {
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
}

const componentStyles = makeStyles(() => ({
  root: {
    marginTop: 32,
    float: "right"
  },
  label: {
    fontWeight: "bold"
  },
  iconContainer: {
    marginTop: -2,
    marginLeft: 20
  },
  icon: {
    cursor: "pointer"
  }
}));
const GiftPagination: React.FC<GiftPaginationProps> = (props) => {
  const styles = componentStyles();
  const { page, totalPages, setPage } = props;

  const onNextClickHandler = () => {
    if (page === totalPages) return;

    setPage(page + 1);
  };

  const onPrevClickHandler = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  return (
    <Box display="flex" className={styles.root}>
      <Typography variant="caption" className={styles.label}>
        Page {page} of {totalPages}
      </Typography>
      <Box className={styles.iconContainer}>
        <KeyboardArrowLeftIcon fontSize="small" className={styles.icon} onClick={onPrevClickHandler} />
        <KeyboardArrowRightIcon fontSize="small" className={styles.icon} onClick={onNextClickHandler} />
      </Box>
    </Box>
  );
};

export default GiftPagination;
