import { EmptyViewDataObject } from "./types";

export const emptyViewData: EmptyViewDataObject = {
  orderHistory: {
    alt: "Order history",
    title: "No Orders Added Yet",
    description: "Tap the button below to start shopping!",
    image: "/assets/emptyView/order-history.png",
    buttonText: "Browse Products"
  },
  myGifts: {
    alt: "My Gifts",
    title: "No Gifts Received",
    description: "Tap the button below and start adding your favorite products to your wishlist!",
    image: "/assets/emptyView/my-gifts.png",
    buttonText: "Browse Products"
  }
};
