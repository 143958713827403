import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import Toast from "light-toast";
import PaymentMethodsHeader from "./PaymentMethodsHeader";
import PaymentMethodsList from "./PaymentMethodsList";
import PaymentMethodsAddRow from "./PaymentMethodsAddRow";
import ProfileContentContainer, { ProfileContentContainerStyles } from "../ProfileContentContainer";
import { getCurrentUser, formatUserPaymentData } from "../../../actions/userAction";
import { useHistory } from "react-router-dom";
import { PaymentInfoType } from "./PaymentMethodType";
import PaymentMethodDialog from "./PaymentMethodDialog";

interface StateInterface {
  loading: boolean;
  paymentMethod: PaymentInfoType;
  paymentMethods: Array<PaymentInfoType>;
}

export default function PaymentMethods(props) {
  const profileContentContainerStyles = ProfileContentContainerStyles();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState<PaymentInfoType>(null);
  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentInfoType>>([]);
  const [page, setPage] = useState<string>("");
  const [shouldOpenDial, setShouldOpenDialog] = useState<boolean>(false);

  const onEditPaymentMethodClick = (paymentMethod) => {
    setActivePaymentMethod(paymentMethod);
    setPage("edit");
    setShouldOpenDialog(true);
  };

  const onAddPaymentMethodClick = () => {
    setPage("add");
    setShouldOpenDialog(true);
  };

  const onPaymentMethodAddedOrUpdated = () => {
    setShouldOpenDialog(false);
    getPaymentMethods();
  };

  const onDialogClose = () => {
    setShouldOpenDialog(false);
  };

  const getPaymentMethods = async () => {
    try {
      const response = await getCurrentUser();
      const { paymentInfos } = formatUserPaymentData(response.data.payment_profile_data);
      setPaymentMethods([...paymentInfos]);
    } catch (error) {
      if (error.response.status === 401) {
        history.push("/store/storehome");
        return;
      }
      Toast.fail("Error pulling user payment methods.", 500);
    }
  };

  const preparePaymentMethods = async () => {
    setLoading(true);
    await getPaymentMethods();
    setLoading(false);
  };

  useEffect(() => {
    let done = false;
    if (!done) {
      preparePaymentMethods();
    }

    return () => {
      done = true;
    };
  }, []);

  return (
    <>
      <ProfileContentContainer page="payment_methods" isLoading={loading}>
        <>
          <PaymentMethodsHeader />
          <Divider className={profileContentContainerStyles.divider} />
          <PaymentMethodsList paymentMethods={paymentMethods} onEditPaymentMethodClick={onEditPaymentMethodClick} />
          <PaymentMethodsAddRow onAddPaymentMethodClick={onAddPaymentMethodClick} />
        </>
      </ProfileContentContainer>
      <PaymentMethodDialog
        open={shouldOpenDial}
        onClose={onDialogClose}
        page={page}
        onPaymentMethodAddedOrUpdated={onPaymentMethodAddedOrUpdated}
        paymentMethod={activePaymentMethod}
      />
    </>
  );
}
