import React from "react";
import { Typography, ListItem, Radio, ListItemText, Box } from "@material-ui/core";
import { PaymentInfoType } from "./PaymentMethodType";
import PaymentMethodsEditButton from "./PaymentMethodsEditButton";
import { makeStyles } from "@material-ui/core/styles";

const componentStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "1rem",
    paddingBottom: "1rem"
  }
}));

interface PropsInterface {
  paymentMethod: PaymentInfoType;
  onEditPaymentMethodClick: (paymentMethod) => void;
}

const PaymentMethodsItem = ({ paymentMethod, onEditPaymentMethodClick }: PropsInterface) => {
  const styles = componentStyles();

  return (
    <ListItem alignItems="flex-start" button={true} disableGutters={true} divider={true} className={styles.root}>
      {/* <Radio
        value=""
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      /> */}
      <ListItemText
        primary={
          <React.Fragment>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  {paymentMethod.account_type} {paymentMethod.account_number}
                </Typography>
                <Typography>{paymentMethod.billing_name}</Typography>
                <Box display="flex" justifyContent="row">
                  <Typography
                    style={{
                      marginRight: 15
                    }}
                  >
                    Expires {paymentMethod.exp_month}/{paymentMethod.exp_year}
                  </Typography>
                  <PaymentMethodsEditButton
                    onEditPaymentMethodClick={() => {
                      onEditPaymentMethodClick(paymentMethod);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default PaymentMethodsItem;
