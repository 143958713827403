import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { buttonColor } from "../../style/index";

const usesStyles = makeStyles(() => ({
  buttonStyle: {
    color: buttonColor.PINK,
    border: `${buttonColor.PINK} 1px solid`,
    fontWeight: "bold",
    width: "100%",
    height: 48,
    borderRadius: 8,
    padding: 0,
    textTransform: "capitalize"
  }
}));

interface SecondaryButtonV3Props extends ButtonProps {
  style?: React.CSSProperties;
  text: string;
  handleClick?: () => void;
}
const SecondaryButtonV3: React.FC<SecondaryButtonV3Props> = (props: SecondaryButtonV3Props) => {
  const styles = usesStyles();
  const { style, text, handleClick, ...rest } = props;

  return (
    <Button variant="outlined" onClick={handleClick} className={styles.buttonStyle} style={{ ...style }} {...rest}>
      {text}
    </Button>
  );
};

export default SecondaryButtonV3;
