import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { CURRENCY } from "../Constants";
import { useAppSelector } from "../../../hooks";
import { COLORS } from "../../../style";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";
import { textColor } from "../../../style/index";

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.typography.h6,
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    textTransform: "uppercase",
    color: textColor.DARK1,
    fontWeight: 700
  }
}));

interface CreditsProps {
  style?: React.CSSProperties;
}
const Credits: React.FC<CreditsProps> = (props: CreditsProps) => {
  const styles = useStyles();
  const { style } = props;
  const currency = CURRENCY;
  const { userId, credits } = useAppSelector((state) => state.user.data);

  if (!userId || credits <= 0) {
    return null;
  }

  return (
    <Typography className={styles.title} style={{ ...style }}>
      Credit {currency}
      {roundToDecimalPlaces(credits.toString(), 2)}
    </Typography>
  );
};

export default Credits;
