import React from "react";
import { Box, Typography, BoxProps } from "@material-ui/core";
import PrimaryLink from "../buttons/PrimaryLink";
import { withStyles } from "@material-ui/core/styles";
import { FONT_FAMILIES, COLORS } from "../../style";
interface TermsAndConditionsProps extends BoxProps {
  textAlign?: "center" | "left" | "right";
  mainFontSize?: string;
  linkFontSize?: string;
}

export default function TermsAndConditions({
  mainFontSize,
  linkFontSize,
  textAlign,
  ...rest
}: TermsAndConditionsProps) {
  const WhiteTextTypography = withStyles({
    root: {
      color: COLORS.LIGHT_GRAY,
      fontSize: "16px",
      lineHeight: "22px"
    }
  })(Typography);

  return (
    <Box
      style={{
        width: "100%"
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...rest}
    >
      <WhiteTextTypography
        align={textAlign ?? "center"}
        style={{
          marginTop: "16px",
          fontSize: mainFontSize ?? "unset",
          fontFamily: FONT_FAMILIES.POPPINS,
          lineHeight: "26px"
        }}
      >
        &copy; {new Date().getFullYear()} RealGifts, LLC. All rights reserved.&nbsp;
      </WhiteTextTypography>
      <Box display="flex" justifyContent="center">
        <PrimaryLink
          text="Terms"
          align={textAlign ?? "center"}
          marginBottom="16px"
          fontSize={linkFontSize ?? "12px"}
          textColor="#777777"
          href="https://www.getrealgifts.com/home?page=terms"
          fontFamily={FONT_FAMILIES.POPPINS}
        />
        <WhiteTextTypography style={{ fontSize: linkFontSize ?? "12px", fontFamily: FONT_FAMILIES.POPPINS }}>
          &nbsp;&amp;&nbsp;
        </WhiteTextTypography>
        <PrimaryLink
          text="Privacy"
          align={textAlign ?? "center"}
          marginBottom="16px"
          fontSize={linkFontSize ?? "12px"}
          textColor="#777777"
          href="https://www.getrealgifts.com/home?page=privacy"
          fontFamily={FONT_FAMILIES.POPPINS}
        />
      </Box>
    </Box>
  );
}
