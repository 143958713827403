import React from "react";
import { Typography } from "@material-ui/core/";
import { GiftDetailsStyles } from "./GiftDetails";
import { GiftDetailsType } from "./GiftDetailsTypes";
import moment from "moment";

const GiftDetailsOrderInformation = ({ orderData }: { orderData: GiftDetailsType }) => {
  const giftDetailsStyles = GiftDetailsStyles();
  const { date_ordered, order_id } = orderData;
  const formattedDateOrdered = () => {
    return moment(date_ordered).format("MM/DD/YYYY");
  };

  return (
    <div className={giftDetailsStyles.subColumn}>
      <Typography
        variant="body1"
        className={giftDetailsStyles.subTitle}
      >
        Order Information
      </Typography>
      <Typography
        variant="body1"
        className={giftDetailsStyles.label}
      >
        Order Date:
        <Typography component="span"> {formattedDateOrdered()}</Typography>
      </Typography>
      <Typography
        variant="body1"
        className={giftDetailsStyles.label}
      >
        Order Number:
        <Typography component="span"> {order_id}</Typography>
      </Typography>
    </div>
  );
};

export default GiftDetailsOrderInformation;
