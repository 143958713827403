import React, { FC } from "react";
import cx from "classnames";
import { Box, Typography, Link } from "@material-ui/core";
import { PROFILE_ACCOUNT_SYNC_PATH } from "constants/paths";
import { SyncHeaderStyles } from "./SyncHeaderStyles";
import { useApp } from "context/app-context";

interface SyncHeaderProps {}

const SyncHeader: FC<SyncHeaderProps> = ({}: SyncHeaderProps) => {
  const classes = SyncHeaderStyles();
  const { setPromptLogin, encryptedUserId } = useApp();

  const onSyncClick = () => {
    if (!encryptedUserId) {
      setPromptLogin({ promptLogin: true, returnTo: PROFILE_ACCOUNT_SYNC_PATH });
    }
  };

  return (
    <Box className={cx(classes.syncHeaderStyle)}>
      <Typography className={classes.syncHeaderTextStyle}>
        Found us elsewhere? You may now access your Wish List and Store Credit directly on Gift.Me!{" "}
        <Link
          href={encryptedUserId ? PROFILE_ACCOUNT_SYNC_PATH : "#"}
          underline="always"
          className={classes.syncHeaderTextStyle}
          onClick={onSyncClick}
        >
          Sync
        </Link>
      </Typography>
    </Box>
  );
};

export default SyncHeader;
