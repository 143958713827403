import React from "react";
import { Typography, Box, useMediaQuery, Theme, makeStyles } from "@material-ui/core";
import Section from "../common/Section";
import PrimaryButtonV2 from "../common/PrimaryButtonV2";
import { useApp } from "../../../context/app-context";

const useStyles = makeStyles((theme) => ({
  section: {
    height: 491,
    alignItems: "center",
    backgroundColor: "#3F3F3F",
    justifyContent: "center",
    backgroundImage: `url(${"/assets/home/startwishlist-2x.jpg"})`,
    [theme.breakpoints.down("md")]: {
      backgroundPositionX: "center",
      height: 351
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${"/assets/home/startwishlist-mobile-2x.jpg"})`,
      backgroundPositionX: "center",
      height: 351
    }
  },
  sectionContent: {},
  wrapper: {
    width: 575,
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  }
}));

const StartYourWishlistSection = () => {
  const { setPromptLogin } = useApp();
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <Section className={classes.section} contentProps={{ className: classes.sectionContent }}>
      <Box className={classes.wrapper}>
        <Typography
          variant="h2"
          style={{
            marginBottom: isDesktop ? 26 : "unset",
            fontSize: isDesktop ? 48 : 24,
            lineHeight: isDesktop ? "58px" : "32px",
            fontWeight: 800
          }}
        >
          Start your wishlist and get the gift that you love
        </Typography>
        <Typography
          variant="h4"
          style={{
            marginBottom: 30,
            fontSize: isDesktop ? 24 : 16,
            lineHeight: isDesktop ? "32px" : "26px",
            fontWeight: isDesktop ? 700 : 400
          }}
        >
          Let friends and fans know what you want, or share the link with that special someone.
        </Typography>
        <PrimaryButtonV2
          label="Create My Wishlist"
          style={{ width: 202, height: isDesktop ? 45 : 40 }}
          onClick={() =>
            setPromptLogin({
              promptLogin: true,
              returnTo: undefined,
              registration: true,
              forceRegistration: true
            })
          }
        />
      </Box>
    </Section>
  );
};

export default StartYourWishlistSection;
