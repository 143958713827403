import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = {};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state = null, action: PayloadAction = null) => {}
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
