import React from "react";
import { Box, Typography, makeStyles, List, Divider } from "@material-ui/core";
import { PaymentInfoType } from "./PaymentMethodType";
import PaymentMethodsItem from "./PaymentMethodsItem";

const ComponentStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem"
  },
  header: {
    ...theme.typography.body2,
    marginTop: "2rem",
    fontSize: 18,
    fontWeight: 600
  }
}));
const PaymentMethodsList = ({
  paymentMethods,
  onEditPaymentMethodClick
}: {
  paymentMethods: Array<PaymentInfoType>;
  onEditPaymentMethodClick: (paymentMethod) => void;
}) => {
  const styles = ComponentStyles();

  return (
    <Box className={styles.root}>
      <Typography className={styles.header}>Saved cards:</Typography>
      <List>
        {paymentMethods.map((paymentMethod) => (
          <PaymentMethodsItem
            paymentMethod={paymentMethod}
            onEditPaymentMethodClick={() => {
              onEditPaymentMethodClick(paymentMethod);
            }}
          />
        ))}
      </List>
    </Box>
  );
};

export default PaymentMethodsList;
