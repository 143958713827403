import React from "react";
import { Theme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import RecipientList from "./RecipientList";

export default function SelectRecipientPopper(props: any) {
  const { anchorEl, recipients, selectedRecipient, toggleSelectRecipientForm, id, open } = props;
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
      style={{
        minWidth: isMobile ? "100%" : "300px",
        zIndex: 3,
        paddingLeft: isMobile ? 16 : "unset",
        paddingRight: isMobile ? 16 : "unset"
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={150}>
          <RecipientList
            selectedRecipient={selectedRecipient}
            recipients={recipients}
            toggleSelectRecipientForm={toggleSelectRecipientForm}
          />
        </Fade>
      )}
    </Popper>
  );
}
