import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DESKTOP_DIALOG_MAX_WIDTH } from "../../common/Constants";
import AppDialog from "../../common/dialog/AppDialog";
import AddNewBillingContent from "./AddNewBillingContent";
import SavedBillingInfoListContent from "./SavedBillingInfoListContent";

const PAGE_SAVED_BILLING_ADDRESSES = "PAGE_SAVED_BILLING_ADDRESSES";
const PAGE_ADD_NEW_BILLING_ADDRESS = "PAGE_ADD_NEW_BILLING_ADDRESS";

const ChangeBillingInfoDialogStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    paddingBottom: "24px",
    margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_DIALOG_MAX_WIDTH
    }
  },
  dialogContentClassName: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

export default function ChangeBillingInfoDialog(props) {
  // TODO @bikrant: Assuming saved billing addresses is an array of data that can be added into a flat list
  const { open, onClose } = props;
  const [page, setPage] = useState(PAGE_SAVED_BILLING_ADDRESSES);
  const dialogStyles = ChangeBillingInfoDialogStyles();

  const onBackPressed = () => {
    setPage(PAGE_SAVED_BILLING_ADDRESSES);
  };

  // Back Icon is only available from Payment Method Form
  const onBackPressedCallback = page === PAGE_ADD_NEW_BILLING_ADDRESS ? onBackPressed : undefined;

  // Close Icon is only available from Saved Payment Methods List
  const onCloseCallback = page === PAGE_SAVED_BILLING_ADDRESSES ? onClose : undefined;

  return (
    <AppDialog
      open={open}
      onClose={onCloseCallback}
      onBackPressed={onBackPressedCallback}
      fullWidth={false}
      paperClassName={dialogStyles.paper}
      dialogContentClassName={dialogStyles.dialogContentClassName}
    >
      {DialogContent(page, setPage, props)}
    </AppDialog>
  );
}

function DialogContent(page, setPage, props) {
  const onNavigateToAddNewBillingAddressPage = () => {
    setPage(PAGE_ADD_NEW_BILLING_ADDRESS);
  };

  if (page === PAGE_ADD_NEW_BILLING_ADDRESS) {
    return <AddNewBillingContent />;
  }

  return (
    <SavedBillingInfoListContent
      email={props.email}
      handleAddNewBillingAddress={onNavigateToAddNewBillingAddressPage}
      paymentInfos={props.paymentInfos}
    />
  );
}
