import axios, { AxiosResponse } from "axios";

const cleanGift = (gift) => {
  /**
   * remove `description_plus` field
   * this caused the request being blocked by cloudflare
   * when it contains an html table element
   */
  const { description_plus, ...cleanGift } = gift;

  return cleanGift;
};

export async function addWishlist(gift): Promise<AxiosResponse<any>> {
  return axios.post("/api/wishlist", { gift: cleanGift(gift) });
}

export async function removeWishlist(gift): Promise<AxiosResponse<any>> {
  return axios.post("/api/wishlist/delete", { gift: cleanGift(gift) });
}

export async function getGiftsByVanity(params: {vanity: string, page: number, pageSize: number}): Promise<AxiosResponse<any>> {
  const { vanity, page } = params;
  const pageSize = params.pageSize || 10;
  return axios.get(`/api/wishlist/${vanity}?page=${page}&pageSize=${pageSize}`);
}