import React, { useState, useEffect } from "react";
import { Box, GridList, GridListTile, CircularProgress } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { replaceUnicodeChars } from "../../utils/stringUtils";
import { DESKTOP_CONTENT_WIDTH } from "../common/Constants";
import AppFooter from "../common/appFooter/AppFooter";
import { useAppSelector } from "../../hooks";
import { StoreInterface } from "../../models/store";
import { SetPromptLoginInterface } from "../../interface/AppInterface";
import StoreHomeHeader from "../store/StoreHomeHeader";
import { getGift } from "../../actions/giftAction";
import useNav from "../../hooks/useNav";
import { STOREHOME_PATH } from "../../constants/paths";
import GiftShareModal from "../common/gift/GiftShareModal";
import GiftModal from "../common/gift/GiftModal";
import { getGiftsByVanity } from "../../actions/wishlistAction";
import { addItemToRecipients, setSelectedRecipient } from "../../utils/localStorage";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_WIDTH,
      margin: "0 auto"
    }
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)"
  }
}));

const getStoreImageUrl = (store: any) => {
  if (store.store_image_url && store.store_image_url.length > 0) {
    return store.store_image_url;
  }
  return store.featured_gift_image_url;
};

const ITEM_CELL_HEIGHT = 225;

interface ShopForSomeoneProps {
  loadedStores: StoreInterface[];
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
  match: any;
}

const ShopForSomeone: React.FC<ShopForSomeoneProps> = ({
  loadedStores,
  match,
  setPromptLogin
}: ShopForSomeoneProps) => {
  const styles = useStyles();
  const { goTo } = useNav();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const { encryptedUserId, email, nickname, gender } = useAppSelector((state) => state.user.data);
  const [gift, setGift] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openProductShareModal, setOpenProductShareModal] = useState<boolean>(false);

  const isProfileComplete = encryptedUserId
    ? email && email.length !== 0 && nickname && nickname.length !== 0 && gender && gender.length !== 0
    : false;

  const onModalClose = () => {
    goTo(STOREHOME_PATH);
  };

  const handleOnProductShareClicked = () => {
    setOpenProductShareModal(true);
  };

  const onGiftShareModalClose = () => {
    setOpenProductShareModal(false);
  };

  const prepareGift = async () => {
    const response = await getGift(match.params.giftId);
    const gift = Object.values(response.data.gifts)[0];
    setGift(gift);
  };

  const prepareWishlist = (data) => {
    const newGifts = [];
    Object.values(data.gifts).map((gift: any) => {
      // is this gift part of a group with a single item as the master item?
      if (gift.group_data.consolidated_group) {
        // is the gift the master item? (i.e - should it be shown in the gift listing?)
        if (gift.group_master_gift_id === gift.gift_id) {
          // it's the master item in the consolidated group, let's add it
          newGifts.push(gift);
          console.log("master item, ", gift);
        } else {
          // not the master item, we don't add it to the UI listing
          console.log("not the master item, hiding gift, ", gift);
        }
      } else {
        newGifts.push(gift);
      }
    });

    addItemToRecipients({
      nickname: data.username,
      userId: data.userId,
      giftCount: newGifts.length
    });
    setSelectedRecipient({
      nickname: data.username,
      userId: data.userId,
      giftCount: newGifts.length
    });
  };

  const prepareReceiver = async () => {
    const response = await getGiftsByVanity({
      vanity: match.params.vanity,
      page: 1,
      pageSize: 100
    });
    prepareWishlist(response.data);
  };

  useEffect(() => {
    async function prepareData() {
      setLoading(true);
      try {
        await prepareGift();
        await prepareReceiver();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    if (!gift) {
      prepareData();
    }

    return () => {
      setGift(null);
    };
  }, [match.params.giftId, match.params.vanity]);

  if (loading) {
    return (
      <Box>
        <Box height={150} display="flex" justifyContent="center">
          <CircularProgress style={{ marginTop: "50px" }} />
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Box className={styles.gridContainer}>
        <GridList cols={isDesktop ? 2 : 1} cellHeight={ITEM_CELL_HEIGHT} style={{ margin: isDesktop ? "unset" : 0 }}>
          {isProfileComplete && (
            <GridListTile
              style={{
                padding: "0px"
              }}
            >
              <StoreHomeHeader nickname={nickname} encryptedUserId={encryptedUserId} />
            </GridListTile>
          )}
          {isDesktop
            ? loadedStores.slice(0, isProfileComplete ? 1 : 2).map((store) => (
                <GridListTile
                  style={{
                    padding: "0px"
                  }}
                  key={store.store_id}
                >
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "url('" + getStoreImageUrl(store) + "') no-repeat center",
                      backgroundSize: "cover",
                      cursor: "pointer"
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      style={{
                        background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(128, 128, 128, 0))",
                        height: "100px",
                        width: "100%",
                        position: "absolute",
                        bottom: "0"
                      }}
                    >
                      <Box
                        marginLeft={2}
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bold",
                          color: "#ffffff",
                          marginBottom: "-40px"
                        }}
                      >
                        {replaceUnicodeChars(store.name)}
                      </Box>
                    </Box>
                  </Box>
                </GridListTile>
              ))
            : null}
        </GridList>
        <GridList cols={isDesktop ? 3 : 2} cellHeight={ITEM_CELL_HEIGHT} style={{ margin: isDesktop ? "unset" : 0 }}>
          {loadedStores
            .slice(isProfileComplete ? (isDesktop ? 1 : 0) : isDesktop ? 2 : 0, loadedStores.length)
            .map((store) => (
              <GridListTile
                style={{
                  padding: "0px"
                }}
                key={store.store_id}
              >
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                    background: "url('" + getStoreImageUrl(store) + "') no-repeat center",
                    backgroundSize: "cover",
                    cursor: "pointer"
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    style={{
                      background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(128, 128, 128, 0))",
                      height: "100px",
                      width: "100%",
                      position: "absolute",
                      bottom: "0"
                    }}
                  >
                    <Box
                      marginLeft={2}
                      style={{
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        color: "#ffffff",
                        marginBottom: "-40px"
                      }}
                    >
                      {replaceUnicodeChars(store.name)}
                    </Box>
                  </Box>
                </Box>
              </GridListTile>
            ))}
        </GridList>
        <AppFooter />
      </Box>
      <GiftModal
        open={true}
        onClose={onModalClose}
        gift={gift}
        setPromptLogin={setPromptLogin}
        onShareProductClicked={handleOnProductShareClicked}
      />
      <GiftShareModal open={openProductShareModal} onClose={onGiftShareModalClose} gift={gift} />
    </Box>
  );
};

export default ShopForSomeone;
