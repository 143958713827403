import React from "react";
import { Box } from "@material-ui/core";
import PageNotFoundImage from "./page_not_found_image.svg";
import SectionTitle from "../common/sectionTitle/SectionTitle";
import SectionSubtitle from "../common/sectionTitle/SectionSubtitle";
import PrimaryButton from "../buttons/PrimaryButton";
import { DESKTOP_CONTENT_WIDTH } from "../common/Constants";

export default function PageNotFoundContent(props: any) {
  return (
    <Box
      style={{ paddingTop: "24px", paddingBottom: "56px" }}
      width={{ lg: DESKTOP_CONTENT_WIDTH }}
      marginX={{ lg: "auto" }}
    >
      <SectionTitle style={{ paddingLeft: "16px", paddingRight: "16px" }}>Page not found</SectionTitle>
      <Box display="flex" justifyContent="center" style={{ marginTop: "52px" }}>
        <img src={PageNotFoundImage} alt="Page not found" />
      </Box>
      <Box display="flex" justifyContent="center" marginTop="32px" paddingLeft="16px" paddingRight="16px">
        <SectionSubtitle style={{ textAlign: "center" }}>
          The page you are trying to access does not exist or has been moved.
          <br />
          <br />
          Find gifts by browsing our stores.
        </SectionSubtitle>
      </Box>
      <Box marginX="auto" textAlign="center" marginTop="16px" width={{ lg: "30%" }}>
        <PrimaryButton handleClick={props.handleClick}>Browse stores</PrimaryButton>
      </Box>
    </Box>
  );
}
