import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const usesStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-end",
      flexDirection: "column",
      position: "relative",
      width: "100%"
    }
  }
}));

interface HowItWorksItemHeaderImageProps {
  text?: string;
  src: string;
  alt?: string;
  style?: any;
}

export default function HowItWorksItemHeaderImage({ text, src, alt, style }: HowItWorksItemHeaderImageProps) {
  const classes = usesStyles();
  return (
    <Box className={classes.root}>
      <img src={src} alt={alt} style={style} />
      {text && (
        <Typography
          variant="body1"
          style={{
            color: "#ffffff",
            position: "absolute",
            bottom: "8px",
            marginLeft: "28px"
          }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
}
