import React from "react";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import TextInput from "../input/TextInput";
import SectionTitle from "../common/sectionTitle/SectionTitle";
import CheckoutGiftDetail from "./CheckoutGiftDetail";

export default function CheckoutSpecialMessage(props: any) {
  const {
    includeSpecialMessage,
    handleIncludeSpecialMessageChange,
    gift,
    itemCost,
    handleInputChange,
    handleOnBlur,
    handleOnFocus,
    isDesktop,
    sender_name,
    special_message
  } = props;

  return (
    <Box>
      <SectionTitle style={{ marginBottom: "24px" }}>Order details</SectionTitle>

      {!isDesktop && (
        <CheckoutGiftDetail
          imageUrl={gift.image_url}
          giftName={gift.name.replace("&apos;", "'")}
          receiverNickname={props.receiverNickname}
          itemCost={itemCost}
        />
      )}
      <TextInput
        id="senderName"
        name="sender_name"
        label="From:"
        placeholder={"Sender's Nickname"}
        autoComplete="sender_name username"
        value={sender_name.value}
        error={sender_name.error}
        helperText={sender_name.error}
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        fullWidth={true}
        disabled={props.disabled}
      />

      <FormControlLabel
        style={{ marginTop: "16px" }}
        label="Include a message"
        control={
          <Checkbox
            checked={includeSpecialMessage}
            name="includeSpecialMessage"
            onChange={handleIncludeSpecialMessageChange}
            color="primary"
            disabled={props.disabled}
          />
        }
      />

      {includeSpecialMessage && (
        <TextInput
          id="special_message"
          name="special_message"
          label="Special Message"
          autoComplete="username"
          multiline={true}
          disabled={!includeSpecialMessage || props.disabled}
          rows={4}
          style={{ marginTop: "16px" }}
          value={special_message.value}
          error={special_message.error}
          helperText={special_message.error}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          fullWidth={true}
          maxLength={200}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
      )}
    </Box>
  );
}
