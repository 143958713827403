import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CustomSelect from "../input/CustomSelect";

export interface GiftOptionObject {
  name: string;
  label: string;
  value: string;
  options: any[];
  error: string | null;
}

interface RedeemGiftOptionsSelectorProps {
  styles: any;
  selectedGiftOptions: GiftOptionObject[];
  setSelectedGiftOptions: (params: any) => void;
}

export const parseGiftOptionsList = (options: any) => {
  const data = JSON.parse(options);
  return data.map((item: any) => {
    return {
      label: item.option_value_name,
      value: item.option_value,
      ...item
    };
  });
};

const RedeemGiftOptionsSelector = ({
  styles,
  selectedGiftOptions,
  setSelectedGiftOptions
}: RedeemGiftOptionsSelectorProps) => {
  const handlOptionOnchange = (event) => {
    const { value, name } = event.target;
    setSelectedGiftOptions((prev) => {
      return prev.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            error: "",
            value
          };
        } else {
          return item;
        }
      });
    });
  };

  return (
    selectedGiftOptions.length > 0 && (
      <FormControl component="fieldset" style={{ marginTop: 20 }}>
        <Typography
          className={styles.redeemGiftCaptionStyle?.textStyle}
          style={{ marginTop: "24px", color: "#7E7E7E" }}
        >
          Please choose from available options:
        </Typography>
        {selectedGiftOptions.map((item, idx) => {
          return (
            <Box marginTop={2} key={idx}>
              <CustomSelect
                name={item.name}
                options={item.options}
                id={item.name}
                label={item.label}
                value={item.value}
                onChange={handlOptionOnchange}
                error={item.error}
                helperText={item.error}
                autoComplete=""
                style={{ width: "100%" }}
              />
            </Box>
          );
        })}
      </FormControl>
    )
  );
};

export default RedeemGiftOptionsSelector;
