import React, { useState, useMemo, cloneElement } from "react";
import { useLocation } from "react-router-dom";
import { Box, AppBar, IconButton, Toolbar, useScrollTrigger, styled } from "@material-ui/core";
import IconShareWhite from "./share_white.svg";
import { HeaderStyle } from "./AppHeaderStyles";
import ArrowLeft from "../../../atoms/icons/ArrowLeft";
import DesktopAppHeaderV2 from "../DesktopAppHeader/DesktopAppHeaderV2";
import { useAppSelector } from "../../../hooks";
import { COLORS, HEADER } from "../../../style/index";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";
import { useApp } from "../../../context/app-context";
import useNav from "../../../hooks/useNav";
import { STOREHOME_PATH, HOME_PATH, PROFILE_PERSONAL_PATH } from "../../../constants/paths";
import SyncHeader from "../syncHeader/SyncHeader";

interface AppHeaderV2Props {
  variant?: "header1" | "header2";
}

const ElevationScroll = (props: any) => {
  const { children, window, headerVariant } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return cloneElement(children, {
    style: {
      boxShadow: trigger ? "0px 8px 8px -8px rgba(0,0,0,0.1)" : "none",
      backgroundColor: HEADER[headerVariant] ?? "#02060B"
    }
  });
};

const BackPreviousContainer = styled("div")({
  padding: 16
});

const AppHeaderV2: React.FC<AppHeaderV2Props> = ({ variant = "header1" }: AppHeaderV2Props) => {
  const classes = HeaderStyle();
  const location = useLocation();
  const { goTo, goBack } = useNav();
  const { setPromptLogin, encryptedUserId, sidebarToggle, setOpenShareDialog } = useApp();

  const { isDesktop } = useMediaBreakpoints();
  const [expanded] = useState<boolean>(false);

  const { email, nickname, gender } = useAppSelector((state) => state.user.data);
  const isProfileComplete = encryptedUserId
    ? email && email.length !== 0 && nickname && nickname.length !== 0 && gender && gender.length !== 0
    : false;

  const indexOfEncryptedId = window.location.pathname.lastIndexOf("/");
  const addressBarEncryptedId = window.location.pathname.substring(indexOfEncryptedId + 1);
  const hideShare = addressBarEncryptedId !== encryptedUserId;

  const shouldShowSyncHeader = () => {
    return !location.pathname.includes("/profile");
  };

  const navigateToHome = () => {
    if (encryptedUserId) {
      goTo(STOREHOME_PATH);
    } else {
      goTo(HOME_PATH);
    }
  };

  const onOpenShare = () => {
    if (encryptedUserId) {
      if (isProfileComplete) {
        setOpenShareDialog(true);
      } else {
        goTo(PROFILE_PERSONAL_PATH);
      }
    } else {
      setPromptLogin({
        promptLogin: true,
        returnTo: undefined,
        registration: false,
        forceRegistration: false
      });
    }
  };

  const hideBackBtnHomePageInLoggedOutState = useMemo(() => {
    return location.pathname === HOME_PATH && !encryptedUserId;
  }, [location, encryptedUserId]);

  const viewBack = !sidebarToggle && window.history.state !== null && window.history.state.key !== null;
  const hideShareIcon = expanded || hideShare;

  return isDesktop ? (
    <DesktopAppHeaderV2 variant={variant} />
  ) : (
    <>
      <ElevationScroll headerVariant={variant}>
        <AppBar position="fixed" elevation={0} className={classes.appBarStyle}>
          <Toolbar className={classes.toolbarStyle2}>
            {!hideBackBtnHomePageInLoggedOutState && viewBack && (
              <BackPreviousContainer onClick={() => goBack()}>
                <ArrowLeft cssStyle={{ color: COLORS.WHITE }} />
              </BackPreviousContainer>
            )}
            <IconButton
              aria-label="Share Page"
              className={
                hideShareIcon
                  ? `${classes.headerIconStyle} ${classes.shareIconFadeOutTransition}`
                  : `${classes.headerIconStyle} ${classes.shareIconFadeInTransition}`
              }
              onClick={onOpenShare}
            >
              <img src={IconShareWhite} alt="secure" height="24" width="24" />
            </IconButton>
            <img
              src={"/assets/header_logo_v4.svg"}
              alt="GiftMe Logo"
              style={{ width: 94, left: "50%", transform: "translate(-50% ,0)" }}
              onClick={navigateToHome}
              className={expanded ? `${classes.logoStyle} ${classes.logoExpandedTransition}` : classes.logoStyle}
            />
          </Toolbar>
          {shouldShowSyncHeader() ? <SyncHeader /> : null}
        </AppBar>
      </ElevationScroll>
      <Box className={classes.appBarSpaceStyle} />
    </>
  );
};

export default AppHeaderV2;
