import React from "react";
import clsx from "clsx";
import { Box, makeStyles, Theme, BoxProps } from "@material-ui/core";
import TermsAndConditions from "../../termsAndConditions/TermsAndConditions";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";

const useStyles = makeStyles((theme: Theme) => ({
  footerContainer: {
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 35,
    paddingBottom: 25,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: "-68px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "unset"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      paddingLeft: 28,
      paddingRight: 28,
      marginBottom: "unset"
    }
  },
  socialLinks: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      marginBottom: 15
    }
  }
}));
interface AppFooterProps extends BoxProps {
  termsCustomStyle?: any;
  linkFontSize?: string;
}

const AppFooter = ({ className, termsCustomStyle, linkFontSize, ...rest }: AppFooterProps): ReturnType<React.FC> => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.footerContainer, className)} {...rest}>
      <TermsAndConditions
        textAlign="left"
        justifyContent="flex-start"
        alignItems="baseline"
        style={termsCustomStyle}
        linkFontSize={linkFontSize}
      />
      <SocialMediaLinks className={classes.socialLinks} />
    </Box>
  );
};

export default AppFooter;
