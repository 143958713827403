import React from "react";
import { Box, Link, Theme } from "@material-ui/core";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { makeStyles } from "@material-ui/core/styles";

const RedeemGiftDialogButtonsStyle = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "24px",
    paddingBottom: "16px"
  },
  negativeButtonStyle: {
    marginTop: "24px",
    alignSelf: "center"
  },
  buttonStyle: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("lg")]: {
      width: "80%"
    }
  }
}));

export default function RedeemGiftDialogButtons(props: any) {
  const classes = RedeemGiftDialogButtonsStyle();

  const buttonClassName = props.buttonClassName
    ? `${classes.buttonStyle} ${props.buttonClassName}`
    : classes.buttonStyle;

  return (
    <Box className={classes.root}>
      <PrimaryButton className={buttonClassName} handleClick={props.onPositiveButtonClick}>
        {props.positiveButtonText}
      </PrimaryButton>

      {/*Negative button is optional. If Parent doesn't set negativeButtonText, it will not be rendered.*/}
      {props.negativeButtonText ? (
        props.useSecondaryButton ? (
          <SecondaryButton
            className={buttonClassName}
            text={props.negativeButtonText}
            handleClick={props.onNegativeButtonClick}
            style={{ marginTop: "10px" }}
          />
        ) : (
          <Link
            onClick={props.onNegativeButtonClick}
            color="inherit"
            variant="body2"
            component="button"
            underline="always"
            className={classes.negativeButtonStyle}
          >
            {props.negativeButtonText}
          </Link>
        )
      ) : null}
    </Box>
  );
}
