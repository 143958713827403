import React, { ReactElement } from "react";
import clsx from "clsx";
import { Box, BoxProps, makeStyles } from "@material-ui/core";
import GridWrapper from "./GridWrapper";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    backgroundSize: "cover",
    position: "relative"
  },
  content: {
    display: "flex"
  }
}));

interface SectionProps extends BoxProps {
  backgroundImage?: string;
  children?: ReactElement;
  className?: BoxProps["className"];
  contentProps?: BoxProps;
}

const Section = ({
  backgroundImage,
  children,
  style,
  contentProps,
  className,
  ...rest
}: SectionProps): ReactElement => {
  const classes = useStyles();
  const bgImage = backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {};
  return (
    <Box className={clsx(classes.root, className)} style={{ ...bgImage, ...style }} {...rest}>
      <GridWrapper className={classes.content} {...contentProps}>
        {children}
      </GridWrapper>
    </Box>
  );
};

export default Section;
