import Rollbar from "rollbar";

export const RollbarTracking = (() => {
  const rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_GIFT_ME_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.REACT_APP_ENVIRONMENT,
    version: process.env.REACT_APP_VERSION
  });
  const logDebug = (...args) => {
    rollbar.debug(...args);
  };
  const logWarning = (...args) => {
    rollbar.warning(...args);
  };
  const logCritical = (...args) => {
    rollbar.critical(...args);
  };
  const logInfo = (...args) => {
    rollbar.info(...args);
  };
  const logError = (...args) => {
    rollbar.error(...args);
  };
  return {
    logDebug,
    logWarning,
    logCritical,
    logInfo,
    logError,
    rollbar
  };
})();
export default RollbarTracking;
