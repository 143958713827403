import React, { useState, useEffect } from "react";
import { InputLabel, Select, FormControl, FormHelperText, styled } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FONT_FAMILIES, textColor } from "../../style/index";

export interface CustomSelectPropsInterface {
  value: string | number | null;
  helperText?: string;
  id: string;
  name: string;
  label: string;
  error: string | null;
  autoComplete: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  onChange: (params) => void;
  onBlur?: (params) => void;
}

interface CustomSelectV2Props extends CustomSelectPropsInterface {
  options: Array<{ value: string; label: string }>;
  addLabelAsValue?: boolean;
}

const StylesFormControl = styled(FormControl)(() => ({
  "& div": {
    borderRadius: 30
  },
  "& select:focus": {
    background: "none"
  }
}));

const CustomInputLabel = withStyles({
  root: {
    position: "relative",
    color: textColor.DARK1,
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 9,
    transform: "translate(0, 0) scale(1)",
    lineHeight: "24.39px",
    fontFamily: FONT_FAMILIES.SOFIA_PRO
  }
})(InputLabel);

const CustomSelectV2: React.FC<CustomSelectV2Props> = (props: CustomSelectV2Props) => {
  const [value, setValue] = useState<string | number | null>(null);
  const {
    value: propValue,
    helperText,
    name,
    id,
    label,
    error,
    autoComplete,
    disabled,
    style,
    className,
    options,
    onChange,
    onBlur,
    addLabelAsValue = false
  } = props;

  const handleChange = (event) => {
    event.preventDefault();
    setValue(event.target.value);
    onChange({
      ...event,
      target: {
        value: event.target.value,
        name: name
      }
    });
  };

  const handleOnBlur = (event) => {
    onBlur({
      ...event,
      target: {
        ...event.target,
        name
      }
    });
  };

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return (
    <StylesFormControl style={style} className={className}>
      {!addLabelAsValue && <CustomInputLabel htmlFor={id}>{label}</CustomInputLabel>}
      <Select
        native
        disabled={disabled}
        value={value || ""}
        id={id}
        name={name}
        autoComplete={autoComplete}
        onChange={handleChange}
        onBlur={handleOnBlur}
        variant="outlined"
        inputProps={{
          name: `${name}-input`,
          id: `${id}-input`
        }}
        error={error ? true : false}
      >
        {addLabelAsValue ? <option value="">{label}</option> : <option aria-label="None" value="" />}
        {React.Children.toArray(
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        )}
      </Select>
      <FormHelperText
        style={{
          color: error ? "#f44336" : "inherit"
        }}
      >
        {helperText}
      </FormHelperText>
    </StylesFormControl>
  );
};

export default CustomSelectV2;
