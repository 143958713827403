import * as sdk from "../sdk";
import axios, { AxiosResponse } from "axios";

export const EVENT_PAYMENT_ATTEMPT = "payment_attempt";
export const EVENT_PAYMENT_SUCCESSFUL = "payment_successful";
export const EVENT_PAYMENT_FAILURE = "payment_failure";

interface IVysionPaymentEventPayload {
  eventName: string,
  giftId: string,
  senderId?: string | number,
  receiverId?: string | number,
  success?: boolean,
  reason?:string 
}

export function vysionShare(
  eventName: string,
  wishlistUrl?: string,
  wishlistOwnerId?: string | number
):Promise<AxiosResponse<any>> {
  return sdk.vysionShare(eventName, wishlistUrl, wishlistOwnerId);
}

export function vysionPaymentEvent(payload: IVysionPaymentEventPayload): Promise<AxiosResponse<any>> {
  return axios.post("/api/vysion/payment-events", payload);
}
