import React, { useEffect, useState } from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import Toast from "light-toast";
import AccountSettingsSocialLoginOption from "./AccountSettingsSocialLoginOption";
import Caption from "../../common/caption/Caption";
import { UserInterface } from "../../../models/user";
import Confirmation from "../../common/Confirmation";
import { getCurrentUser, unLinkSocialLogin } from "../../../actions/userAction";
import { useAppDispatch } from "../../../hooks";
import { setUserLocalDbData } from "../../../reducers/user/UserSlice";
import IconFacebookRoundedBlue from "../../customIcons/socialMedia/IconFacebookRoundedBlue";
import IconTwitterBlue from "../../customIcons/socialMedia/IconTwitterBlue";
import IconGoogle from "../../customIcons/socialMedia/IconGoogle";
import { useQuery } from "../../../hooks/useQuery";
import { SectionHeaderTitle } from "../ProfileComponents";

interface AccountSettingsLoginOptionsProps {
  user: UserInterface;
}

const componentStyles = makeStyles(() => ({
  root: {
    marginTop: 40
  },
  caption: {
    marginTop: 8,
    marginBottom: 23,
    color: "inherit",
    fontStyle: "normal"
  },
  socialIcon: {
    transform: "scale(2.66666667)"
  },
  loginOptionsContainer: {
    marginTop: 60
  }
}));

const AccountSettingsLoginOptions: React.FC<AccountSettingsLoginOptionsProps> = (
  props: AccountSettingsLoginOptionsProps
) => {
  const { user } = props;
  const query = useQuery();
  const styles = componentStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [active, setActive] = useState(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onChangeHandler = async (social: string, checked: boolean) => {
    if (checked) {
      window.location.href = `/auth/${social}?returnTo=/profile/account-settings`;
      return;
    }

    setActive(getSocialLogin(social));
    setOpen(true);
  };

  const isLinked = (social: string) => {
    const socialLogin = user.socialLogins.find((socialLogin) => socialLogin.social === social);
    return socialLogin || user.mainLogin === social ? true : false;
  };

  const getSocialLogin = (social: string) => {
    const found = user.socialLogins.find((socialLogin) => socialLogin.social === social);
    return found ? found : { social, id: null };
  };

  const handleCancelHandler = () => {
    setOpen(false);
  };

  const handleOkHandler = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);
    try {
      await unLinkSocialLogin({ id: active.id, social: active.social });
      refreshUserData();
      setOpen(false);
      setIsProcessing(false);
      Toast.info("Successfully unlink social account.", 2000);
    } catch (error) {
      setIsProcessing(false);
      setOpen(false);
      Toast.fail(error.response.data, 2000);
    }
  };

  const refreshUserData = async () => {
    const response = await getCurrentUser();
    dispatch(setUserLocalDbData(response.data.localDbData));
  };

  useEffect(() => {
    const promptLogin = query.get("prompt-login");
    if (typeof promptLogin !== "undefined" && parseInt(promptLogin) === 1) {
      Toast.fail(query.get("error-message"), 5000, () => {
        Toast.hide();
      });
    }
  }, [query.get("prompt-login")]);

  return (
    <>
      <Box className={styles.root}>
        <SectionHeaderTitle text="Login Options" />
        <Caption className={styles.caption}>Use your Facebook, Twitter or Google account to log in to Gift.me</Caption>
        <Box display="flex" justifyContent="space-around" className={styles.loginOptionsContainer}>
          <AccountSettingsSocialLoginOption
            isLinked={isLinked("facebook")}
            social="facebook"
            label="Facebook"
            socialColor="#0076FB"
            onChangeHandler={onChangeHandler}
            iconComponent={<IconFacebookRoundedBlue />}
          />
          <AccountSettingsSocialLoginOption
            isLinked={isLinked("twitter")}
            social="twitter"
            label="Twitter"
            socialColor="#2CAEF4"
            onChangeHandler={onChangeHandler}
            iconComponent={<IconTwitterBlue />}
          />
          <AccountSettingsSocialLoginOption
            isLinked={isLinked("google")}
            social="google"
            label="Google"
            socialColor="#B5B5B5"
            onChangeHandler={onChangeHandler}
            iconComponent={<IconGoogle />}
          />
        </Box>
      </Box>
      <Confirmation
        processing={isProcessing}
        open={open}
        handleCancel={handleCancelHandler}
        handleOk={handleOkHandler}
        content={active ? `Are you sure you want to unlink <b>${active.social}</b> account?` : "Are you sure?"}
      />
    </>
  );
};

export default AccountSettingsLoginOptions;
