import React from "react";
import { Box } from "@material-ui/core";

export default function StoreHeader(props) {
  return (
    <>
      {props.nickname && (
        <Box
          style={{
            height: "100%",
            width: "100%",
            background: "url('/Home Screen/1024x1024_HomeScreen_Receiver.jpg') no-repeat center",
            backgroundSize: "cover",
            cursor: "pointer"
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{
              background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(128, 128, 128, 0))",
              height: "100px",
              width: "100%",
              position: "absolute",
              bottom: "0"
            }}
          >
            <Box
              marginLeft={2}
              style={{
                fontSize: "1.5em",
                fontWeight: "bold",
                color: "#ffffff",
                marginBottom: "-40px"
              }}
            >
              {props.nickname ? "♡ " + props.nickname + "'s Wishlist ♡" : ""}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
