import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f0017c",
      contrastText: "#fff"
    },
    background: {
      default: "#F7F7F7"
    },
    text: {
      primary: "#333333",
      secondary: "#4B4B4B",
      hint: "#424242",
      disabled: "#A3A3A3"
    },
    grey: {
      300: "#CCCCCC",
      500: "#A3A3A3",
      600: "#7E7E7E",
      700: "#565656",
      800: "#424242",
      900: "#212121",
      A400: "#333333",
      A700: "#565656"
    }
  },
  typography: {
    h3: {
      fontFamily: "Open Sans",
      fontSize: "36px",
      fontWeight: "normal",
      letterSpacing: "0",
      lineHeight: "49px"
    },
    h4: {
      fontFamily: "Open Sans",
      fontSize: "28px",
      fontWeight: "normal",
      letterSpacing: "0",
      lineHeight: "38px"
    },
    h5: {
      fontFamily: "Open Sans",
      fontSize: "26px",
      letterSpacing: "0",
      lineHeight: "36px"
    },
    h6: {
      fontFamily: "Open Sans",
      fontSize: "22px",
      letterSpacing: "0",
      lineHeight: "24px",
      fontWeight: "bold",
      [breakpoints.down("sm")]: {
        fontSize: "18px"
      }
    },
    subtitle1: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "20px",
      fontWeight: "bold"
    },
    body1: {
      fontFamily: "Open Sans",
      fontSize: "18px",
      letterSpacing: "0",
      lineHeight: "22px"
    },
    body2: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "19px"
    },
    caption: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      letterSpacing: "0",
      lineHeight: "17px"
    },
    button: {
      textTransform: "none",
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "22px"
    }
  },
  overrides: {
    MuiRadio: {
      root: {
        "& svg:first-child": {
          transform: "scale(1)"
        }
      }
    }
  }
});

theme.props = {
  MuiButton: {
    disableElevation: true
  },
  MuiFormHelperText: {
    variant: "standard"
  }
};

export default theme;
