import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Theme } from "@material-ui/core/";
import HeaderLogo from "../appHeader/header_logo.png";
import { ArrowBack } from "@material-ui/icons";
import { getBrowserDetails } from "../../../utils/helpers";
import clsx from "clsx";

const DefaultDialogStyle = makeStyles((theme: Theme) => ({
  paperStyle: {
    borderRadius: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.8)"
  },
  paperStyleNoTransparent: {
    borderRadius: "8px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    position: "absolute",
    bottom: -30
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden"
  },
  dialogContentStyle: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  backButton: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    ...theme.typography.h6,
    fontSize: 19
  },
  headerIcon: {
    marginTop: "16px"
  }
}));

function DialogTitle(props: any) {
  const { classes, onClose, onBackPressed, noHeaderLogo } = props;
  return (
    <Box className={classes.headerContainer}>
      {onBackPressed && (
        <IconButton aria-label="goBack" className={classes.backButton} onClick={onBackPressed}>
          <ArrowBack />
        </IconButton>
      )}

      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{
            zIndex: 10000
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      {!noHeaderLogo && (
        <img src={HeaderLogo} alt="GiftMe Logo" width="50" height="50" className={classes.headerIcon} />
      )}
    </Box>
  );
}

export default function AppDialog(props: any) {
  const classes = DefaultDialogStyle();
  const { open, onClose, onBackPressed, title, children, footer } = props;
  const { isIos } = getBrowserDetails();

  const paperClassName = props.isNotTransparent
    ? clsx(classes.paperStyleNoTransparent, props.paperClassName)
    : clsx(classes.paperStyle, props.paperClassName);

  const dialogContentClassName = props.dialogContentClassName
    ? `${classes.dialogContentStyle} ${props.dialogContentClassName}`
    : classes.dialogContentStyle;

  useEffect(() => {
    // fixed scroll on body when modal is open
    // only for ios devices
    if (open && isIos) {
      document.body.style.position = "fixed";
    }
    return () => {
      document.body.style.position = "unset";
    };
  }, [open, isIos]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: paperClassName }}
      BackdropProps={{ className: props.BackdropProps }}
      aria-labelledby="customized-dialog-title"
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
      maxWidth={"lg"}
      style={{ zIndex: props.zIndexOverride ? props.zIndexOverride : 1300 }}
    >
      {props.titleComponent ? (
        <props.titleComponent />
      ) : (
        <DialogTitle
          id="customized-dialog-title"
          classes={classes}
          onClose={onClose}
          onBackPressed={onBackPressed}
          titleRootStyle={props.titleRootStyle}
          noHeaderLogo={props.noHeaderLogo}
        >
          {title}
        </DialogTitle>
      )}

      <MuiDialogContent className={dialogContentClassName}>{children}</MuiDialogContent>

      {/* Footer is optional.*/}
      {footer ? footer : null}
    </Dialog>
  );
}
