import axios from "axios";

export const UserGetVanity = async () => {
  try {
    const response = await axios.get("/api/user/vanity");
    return response.data.vanity;
  } catch (error) {
    console.log("Error getting user vanity", error);
  }
};
