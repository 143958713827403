import React from "react";
import { Box } from "@material-ui/core";

export default function HowItWorksNextIndicator() {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box
        alignSelf="center"
        style={{
          marginTop: "16px",
          backgroundColor: "#fdf8ee",
          borderRadius: "100%",
          height: "5px",
          width: "5px"
        }}
      />
      <Box
        alignSelf="center"
        style={{
          marginTop: "2px",
          backgroundColor: "#fcf2de",
          borderRadius: "100%",
          height: "5px",
          width: "5px"
        }}
      />
      <Box
        alignSelf="center"
        style={{
          marginTop: "2px",
          backgroundColor: "#f8e4bf",
          borderRadius: "100%",
          height: "5px",
          width: "5px"
        }}
      />
      <Box
        alignSelf="center"
        style={{
          marginTop: "2px",
          backgroundColor: "#edbd65",
          borderRadius: "100%",
          height: "5px",
          width: "5px"
        }}
      />
    </Box>
  );
}
