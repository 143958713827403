import React from "react";
import { Typography } from "@material-ui/core";
import { GiftDetailsStyles } from "./GiftDetails";
import { GiftDetailsType } from "./GiftDetailsTypes";

const GiftDetailsShippingInformation = ({
  orderData,
  isOrder
}: {
  orderData: GiftDetailsType;
  isOrder: boolean;
}) => {
  const giftDetailsStyles = GiftDetailsStyles();
  const {
    receiver_firstname,
    receiver_lastname,
    receiver_address,
    receiver_city,
    receiver_state,
    receiver_zip,
    receiver_phone,
    buyer_address,
    buyer_city,
    buyer_state,
    buyer_zip,
    buyer_phone
  } = orderData;
  return (
    <div className={giftDetailsStyles.nickname}>
      <Typography variant="body1" className={giftDetailsStyles.subTitle}>
        {/* {isOrder ? "Billing Info" : "Shipping Info"} */}
        {!isOrder && "Shipping Info"}
      </Typography>
      {!isOrder && (
        <Typography variant="body1" className={giftDetailsStyles.name}>
          {receiver_firstname} {receiver_lastname}
        </Typography>
      )}
      {/* {isOrder ? (
        <Typography variant="body1" className={giftDetailsStyles.address}>
          {buyer_address}
          <br />
          {buyer_city}
          {buyer_state && `, ${buyer_state}`}
          <br />
          {buyer_zip}
          <br />
          {buyer_phone}
        </Typography>
      ) : (
        <Typography variant="body1" className={giftDetailsStyles.address}>
          {receiver_address}
          <br />
          {receiver_city}
          {receiver_state && `, ${receiver_state}`}
          <br />
          {receiver_zip}
          <br />
          {receiver_phone}
        </Typography>
      )} */}
      {!isOrder && (
        <Typography variant="body1" className={giftDetailsStyles.address}>
          {receiver_address}
          <br />
          {receiver_city}
          {receiver_state && `, ${receiver_state}`}
          <br />
          {receiver_zip}
          <br />
          {receiver_phone}
        </Typography>
      )}
    </div>
  );
};

export default GiftDetailsShippingInformation;
