import React, { ReactElement, useMemo } from "react";
import Section from "../common/appLayout/Section";
import { Box, Typography, makeStyles, Avatar, CircularProgress } from "@material-ui/core";
import { COLORS } from "../../style";
import { useApp } from "../../context/app-context";
import SelectWishListFor from "../gifter/SelectWishListFor";
import ReadMoreReadLess from "../common/readMoreReadLess/ReadMoreReadLess";
import { WISHLIST_EDIT_PROFILE_PATH } from "../../constants/paths";
import useNav from "../../hooks/useNav";

import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import SecondaryButtonV3 from "../buttons/SecondaryButtonV3";

const DEFAULT_PROFILE_URL = "/assets/wishlist/profile-empty-male.jpg";

const useStyles = ({ isLoading }) => {
  return makeStyles(() => ({
    section: {
      alignItems: "center",
      backgroundRepeat: "no-repeat"
    },
    content: {},
    wrapper: {
      display: "flex",
      backgroundColor: isLoading ? "unset" : COLORS.WHITE,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 40,
      paddingBottom: 40
    },
    avatarLg: {
      width: 164,
      height: 164
    },
    title: {
      color: COLORS.LIGHT_BLACK
    },
    subtitle: {
      color: COLORS.LIGHT_BLACK,
      fontSize: 14
    },
    description: {
      textAlign: "center",
      maxWidth: 510,
      color: "#484848",
      fontSize: 16,
      marginTop: 10
    },
    profileAction: {
      marginTop: 30,
      width: 370,
      display: "flex",
      justifyContent: "space-between",
      "& > button": {
        fontSize: 14,
        width: 175,
        height: 48,
        lineHeight: 0,
        border: "solid 1px #E6007E",
        boxShadow: "none"
      }
    },
    vanityWishlist: {
      display: "flex",
      marginTop: 5,
      "& > .vanity": {
        color: "#E6007E",
        fontSize: 14
      }
    }
  }));
};

export interface ReceiverDetailsObject {
  nickname: string;
  encryptedUserId: string;
  vanity: string;
  about: string;
  profileUrl?: string;
}

interface WishlistProfileDesktopProps {
  receiverDetails: ReceiverDetailsObject;
  isLoading?: boolean;
  giftCount: number;
}

const WishlistProfileDesktop = ({
  receiverDetails,
  isLoading,
  giftCount
}: WishlistProfileDesktopProps): ReactElement => {
  const { setOpenShareDialog, loggedInUserFullname, encryptedUserId } = useApp();
  const { goTo } = useNav();

  const vanityUrl = useMemo(() => {
    let wishlistUrl = `${window.location.hostname}/gift/wishlist/${encryptedUserId}`;
    if (receiverDetails.vanity && receiverDetails.vanity.length) {
      wishlistUrl = `${window.location.hostname}/${receiverDetails.vanity}`;
    }
    return wishlistUrl;
  }, [receiverDetails.vanity]);

  const isViewingOtherWishlist = useMemo(
    () => receiverDetails.encryptedUserId !== encryptedUserId ?? true,
    [receiverDetails.encryptedUserId, encryptedUserId]
  );
  const classes = useStyles({ isLoading })();

  return (
    <Section className={classes.section} contentProps={{ className: classes.content }}>
      <Box className={classes.wrapper}>
        {isLoading ? (
          <CircularProgress style={{ marginTop: 200 }} />
        ) : (
          <>
            {!isViewingOtherWishlist ? (
              <Avatar
                alt={loggedInUserFullname}
                src={receiverDetails.profileUrl ?? DEFAULT_PROFILE_URL}
                className={classes.avatarLg}
              />
            ) : (
              receiverDetails.profileUrl && (
                <Avatar alt={receiverDetails.nickname} src={receiverDetails.profileUrl} className={classes.avatarLg} />
              )
            )}

            {isViewingOtherWishlist && (
              <Typography variant="body1" style={{ fontSize: 16, marginBottom: 5, marginTop: 10 }}>
                Viewing
              </Typography>
            )}

            <SelectWishListFor
              username={receiverDetails.nickname ?? loggedInUserFullname}
              isViewingOtherWishlist={isViewingOtherWishlist}
            />

            <Box className={classes.vanityWishlist}>
              <Typography variant="body1" className="vanity">
                {vanityUrl}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                &nbsp; • &nbsp; Wishlist ({`${giftCount} ${giftCount > 1 || giftCount === 0 ? "items" : "item"}`})
              </Typography>
            </Box>

            <ReadMoreReadLess
              className={classes.description}
              charLimit={250}
              readLessText="read less"
              readMoreText="read more"
              longtext={receiverDetails.about}
            />

            {!isViewingOtherWishlist && (
              <Box className={classes.profileAction}>
                <PrimaryButtonV3 handleClick={() => setOpenShareDialog(true)}>Share</PrimaryButtonV3>
                <SecondaryButtonV3 text="Edit Profile" handleClick={() => goTo(WISHLIST_EDIT_PROFILE_PATH)} />
              </Box>
            )}
          </>
        )}
      </Box>
    </Section>
  );
};

export default WishlistProfileDesktop;
