// Detects screen size for desktop and mobile devices
const getBrowserDetails = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  // Detects if device is Tablet
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

  // Detects device name
  const deviceName = () => {
    let device = "Unknown";
    const ua = {
      Linux: /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      ChromeOS: /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i
    };
    Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
  };

  // Detects if device is on iOS
  const isIosIphone = () => {
    return /iphone|ipod/.test(userAgent);
  };

  const isIosIpad = () => {
    return /ipad/.test(userAgent);
  };

  const isSafari = () => {
    const isWebkit = !!userAgent.match(/WebKit/i);
    return isWebkit && !userAgent.match(/(CriOS|FxiOS|OPiOS|mercury|Chrome)/i);
  };

  const isChrome = () => {
    const isWebkit = !!userAgent.match(/WebKit/i);
    return isWebkit && !!userAgent.match(/(CriOS|FxiOS|OPiOS|mercury|Chrome)/i);
  };

  let windowNavigator: any;
  windowNavigator = window.navigator;
  // Detects if device is in standalone mode
  const isInWebAppiOS = "standalone" in windowNavigator && windowNavigator.standalone;
  const isInWebAppChrome = window.matchMedia ? window.matchMedia("(display-mode: standalone)").matches : false;
  const isInStandaloneMode = () => isInWebAppiOS || isInWebAppChrome;

  // Checks if not iOS and permission
  const notificationAllowed = typeof Notification === "function" && Notification.permission === "granted";

  // Checks if mobile browser
  const scWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const isMobile =
    (typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1) && scWidth < 1024;

  // Returns true not Standalone mode
  const isIos = isIosIphone() || isIosIpad();
  const showInstall = isIos && isSafari() && !isInStandaloneMode();
  const showAndroidInstall = !isIos && isMobile && isChrome() && !isInStandaloneMode();

  const browserName = () => {
    var browser,
      sUsrAg = window.navigator.userAgent;

    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf("Firefox") > -1) {
      browser = "Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      browser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      browser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      browser = "MS IE";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      browser = "MS Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      browser = "Google Chrome";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      browser = "Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      browser = "unknown";
    }

    return browser;
  };

  const wImgCalibration = isMobile && !isTablet ? window.innerWidth - 40 : 370;
  const hImgCalibration = isMobile && !isTablet ? wImgCalibration * 1.325 : 490;

  return {
    showInstall,
    showAndroidInstall,
    isIpad: isIosIpad(),
    isStandaloneMode: isInStandaloneMode(),
    notificationAllowed,
    isSafari: isSafari(),
    isMobile,
    isTablet,
    deviceName,
    browserName,
    isIos,
    wImgCalibration,
    hImgCalibration
  };
};

const isEmpty = (value) => {
  if (typeof value === "undefined" || value.length <= 0 || value === "" || value === null || value !== value) {
    return true;
  }

  return Object.keys(value).length === 0 && value.constructor === Object;
};

export { getBrowserDetails, isEmpty };
