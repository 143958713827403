import React, { useState } from "react";
import TextInput from "../TextInput";
import { getCardImage, getValidationError, resolveCardDetail, CARD_NUMBER_SEPARATOR } from "./CardUtils";
import { InputAdornment } from "@material-ui/core";

const initialCardState = {
  cardNumber: "",
  formattedCardNumber: "",
  cardImage: getCardImage(),
  cardValidation: undefined,
  error: "",
  hadError: false,
  edited: false,
  regexp: /^[0-9]*$/
};

export default function CardInput(props: any) {
  const [cardState, setCardState] = useState(initialCardState);

  /**
   * Callback used for triggering manual card validation
   * @returns {{error: string, cardNumber: string, validation: CardNumberVerification}}
   */
  const onValidate = () => {
    const { unFormattedNumber, formattedCardNumber, validation } = resolveCardDetail(cardState.cardNumber);

    const error = getValidationError(unFormattedNumber, validation, true);

    setCardState({
      ...cardState,
      cardNumber: unFormattedNumber,
      formattedCardNumber: formattedCardNumber,
      cardValidation: validation,
      error: error
    });

    return {
      cardNumber: unFormattedNumber,
      validation: validation,
      error: error
    };
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (!cardState.regexp.test(value.replaceAll(CARD_NUMBER_SEPARATOR, ""))) {
      return;
    }

    const { unFormattedNumber, formattedCardNumber, validation } = resolveCardDetail(value);

    // If handleOnBlur had an error on focus, perform input validation on every edit
    let error = "";
    if (cardState.hadError) {
      error = getValidationError(unFormattedNumber, validation);
    }

    setCardState({
      ...cardState,
      cardNumber: unFormattedNumber,
      formattedCardNumber: formattedCardNumber,
      cardImage: getCardImage(validation.card),
      cardValidation: validation,
      edited: true,
      error: error
    });

    if (props.onCardInputDataUpdated) {
      // Returning card input data with strict validation error
      const cardNumber = unFormattedNumber;
      props.onCardInputDataUpdated(cardNumber, validation, getValidationError(cardNumber, validation, true));
    }
  };

  const handleOnFocus = (e) => {
    cardState.hadError = cardState.hadError ? cardState.hadError : cardState.error.length > 0;
  };

  const handleOnBlur = (e) => {
    // If CardInput was edited, perform input validation
    if (cardState.edited) {
      const error = getValidationError(cardState.cardNumber, cardState.cardValidation, true);

      setCardState({
        ...cardState,
        error: error
      });
    }
  };

  // Binding validate callback upstream for allowing manual validation
  if (props.onBindCardValidate) {
    props.onBindCardValidate(onValidate);
  }

  return (
    <TextInput
      id={props.id}
      name={props.name}
      label={props.label}
      type="tel"
      autoComplete="cc-number"
      value={cardState.formattedCardNumber}
      error={cardState.error ? true : false}
      helperText={cardState.error}
      style={props.style}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={cardState.cardImage} style={{ alignSelf: "auto", height: "1.6em" }} />
          </InputAdornment>
        )
      }}
      onChange={handleInputChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    />
  );
}
