import React from "react";
import { Box, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HowItWorksItem from "./howItWorks/HowItWorksItem";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";

const usesStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      "flex-wrap": "wrap",
      "justify-content": "space-evenly"
    }
  },
  text: {
    fontSize: "25px",
    lineHeight: "32px",
    color: "#2D2D2D",
    [theme.breakpoints.up("md")]: {
      fontSize: "32px"
    }
  },
  textBold: {
    fontWeight: "bold"
  },
  subText: {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#2D2D2D",
    paddingTop: "30px"
  }
}));

export default function GetGifts() {
  const { isDesktop } = useMediaBreakpoints();
  const classes = usesStyles();

  const curatedSelectionText = (
    <div>
      <Typography className={classes.text}>
        Your Gift.me wishlist is a curated selection of all your favorite treats
      </Typography>
      <Typography className={classes.subText}>
        From designer sandals to decadent chocolate, you can choose from thousands of items available from our
        hand-picked boutiques. Then share it on social and let the goodies come right to your door!
      </Typography>
    </div>
  );

  const loveSurprisesText = (
    <div>
      <Typography className={classes.text}>Love surprises? Who doesn’t!</Typography>
      <Typography className={classes.subText}>
        Who doesn’t! You can also let your followers browse through our entire collection and send the thoughtful
        present that makes them think of you!
      </Typography>
    </div>
  );

  return (
    <Box className={classes.root}>
      <Box
        textAlign="center"
        style={{
          margin: isDesktop ? "108px 100px" : "50px 25px"
        }}
        m="auto"
      >
        <Typography className={classes.text}>
          Gift.me is the best way to <span className={classes.textBold}>get gifts</span> from fans, friends and family!
          How? Easy! Just <span className={classes.textBold}>create</span> a wishlist,{" "}
          <span className={classes.textBold}>share</span> it, and <span className={classes.textBold}>watch</span> the
          gifts pour in.
        </Typography>
      </Box>
      <HowItWorksItem
        alt="Receive"
        src="/assets/howItWorks/curated_selection.png"
        style={{
          display: isDesktop ? "flex" : "block",
          margin: 0
        }}
        imgStyle={{
          width: "100%",
          objectFit: "cover",
          paddingRight: isDesktop ? 30 : 0,
          paddingLeft: 0,
          marginBottom: isDesktop ? "unset" : 30
        }}
      >
        <Box
          style={{
            width: isDesktop ? "50%" : "100%",
            padding: isDesktop ? 50 : 0,
            display: "flex"
          }}
        >
          {curatedSelectionText}
        </Box>
      </HowItWorksItem>
      <HowItWorksItem
        alt="Receive"
        src="/assets/howItWorks/love_surprises.png"
        style={{
          display: isDesktop ? "flex" : "block",
          margin: isDesktop ? "0" : "50px 0px",
          flexDirection: "row-reverse"
        }}
        imgStyle={{
          width: "100%",
          objectFit: "cover",
          paddingRight: isDesktop ? 30 : 0,
          paddingLeft: 0
        }}
      >
        <Box
          style={{
            width: isDesktop ? "50%" : "100%",
            padding: isDesktop ? 50 : 0,
            display: "flex",
            marginTop: isDesktop ? "unset" : 30
          }}
        >
          {loveSurprisesText}
        </Box>
      </HowItWorksItem>
    </Box>
  );
}
