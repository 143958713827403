import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../style";
import { SectionHeaderTitle } from "../ProfileComponents";

const componentStyles = makeStyles(() => ({
  root: {
    marginBottom: 32
  },
  header: {
    marginBottom: 12
  }
}));
interface AccountDetailsStoreCreditProps {
  storeCredit: number | string;
}

const AccountDetailsStoreCredit: React.FC<AccountDetailsStoreCreditProps> = ({
  storeCredit
}: AccountDetailsStoreCreditProps) => {
  if (!storeCredit || storeCredit <= 0) {
    return null;
  }

  const classes = componentStyles();

  return (
    <div className={classes.root}>
      <SectionHeaderTitle text="Store Credit" />
      <Typography variant="body1">
        You have <span style={{ color: COLORS.PINK }}>{"$" + storeCredit}</span> in store credit. It will apply at
        checkout.
      </Typography>
    </div>
  );
};

export default AccountDetailsStoreCredit;
