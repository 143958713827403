import React from "react";
import { Box, Typography, BoxProps } from "@material-ui/core";
import PrimaryLink from "../buttons/PrimaryLink";
import { withStyles } from "@material-ui/core/styles";
import { FONT_FAMILIES, COLORS } from "../../style";
interface TermsAndConditionsV2Props extends BoxProps {
  textAlign?: "center" | "left" | "right";
  mainFontSize?: string;
  linkFontSize?: string;
}

export default function TermsAndConditionsV2({
  mainFontSize,
  linkFontSize,
  textAlign,
  ...rest
}: TermsAndConditionsV2Props) {
  const WhiteTextTypography = withStyles({
    root: {
      color: COLORS.WHITE,
      fontFamily: FONT_FAMILIES.OPEN_SANS,
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400
    }
  })(Typography);

  return (
    <Box
      style={{
        width: "100%"
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...rest}
    >
      <WhiteTextTypography
        align={textAlign ?? "center"}
        style={{
          marginTop: "16px",
          fontSize: mainFontSize ?? "unset",
          lineHeight: "26px",
          color: rest.style.color
        }}
      >
        &copy; {new Date().getFullYear()} RealGifts, LLC. All rights reserved.&nbsp;
      </WhiteTextTypography>
      <Box display="flex" justifyContent="center">
        <PrimaryLink
          text="Terms"
          align={textAlign ?? "center"}
          marginBottom="16px"
          fontSize={linkFontSize ?? "16px"}
          textColor={rest.style.color}
          href="https://www.getrealgifts.com/home?page=terms"
        />
        <WhiteTextTypography
          style={{
            fontSize: linkFontSize ?? "16px",
            fontFamily: FONT_FAMILIES.POPPINS,
            color: rest.style.color
          }}
        >
          &nbsp;&amp;&nbsp;
        </WhiteTextTypography>
        <PrimaryLink
          text="Privacy"
          align={textAlign ?? "center"}
          marginBottom="16px"
          fontSize={linkFontSize ?? "16px"}
          textColor={rest.style.color}
          href="https://www.getrealgifts.com/home?page=privacy"
          fontFamily={FONT_FAMILIES.OPEN_SANS}
        />
      </Box>
    </Box>
  );
}
