import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ErrorIcon = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="16.188" height="16.188" viewBox="0 0 16.188 16.188"> */}
      <path
        id="error"
        d="M8.914,2.258a7.289,7.289,0,0,0,2.938-.6A7.777,7.777,0,0,0,14.273.016,7.734,7.734,0,0,0,15.914-2.4a7.3,7.3,0,0,0,.594-2.934,7.3,7.3,0,0,0-.594-2.934,7.748,7.748,0,0,0-1.645-2.422,7.736,7.736,0,0,0-2.426-1.645,7.33,7.33,0,0,0-2.937-.594,7.291,7.291,0,0,0-2.922.594,7.736,7.736,0,0,0-2.426,1.645A7.748,7.748,0,0,0,1.914-8.27,7.3,7.3,0,0,0,1.32-5.336a7.261,7.261,0,0,0,.6,2.934A7.79,7.79,0,0,0,3.563.016,7.777,7.777,0,0,0,5.984,1.66,7.269,7.269,0,0,0,8.914,2.258ZM6.359-2.117A.637.637,0,0,1,5.9-2.309a.636.636,0,0,1-.2-.473.63.63,0,0,1,.2-.461L7.984-5.328,5.9-7.406a.639.639,0,0,1-.2-.469.611.611,0,0,1,.2-.465.651.651,0,0,1,.461-.184.62.62,0,0,1,.469.188L8.922-6.25l2.094-2.094a.639.639,0,0,1,.469-.2.633.633,0,0,1,.465.191.633.633,0,0,1,.191.465.656.656,0,0,1-.2.469L9.859-5.328,11.938-3.25a.656.656,0,0,1,.2.469.649.649,0,0,1-.187.473.639.639,0,0,1-.477.191.683.683,0,0,1-.477-.2L8.922-4.391,6.844-2.312A.658.658,0,0,1,6.359-2.117Z"
        transform="translate(-0.82 13.43)"
        fill="#dd0808"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default ErrorIcon;
