import React, { useState } from "react";
import { makeStyles, Box, Theme } from "@material-ui/core";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { setUserLocalDbData } from "../../reducers/user/UserSlice";
import { updateUserAbout } from "../../actions/userAction";
import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import TextArea from "../input/TextArea";
import Toast from "light-toast";

const useStyle = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 28,
    width: 384,
    [theme.breakpoints.down("sm")]: {
      width: "inherit"
    }
  },
  continueButton: {
    marginTop: 40
  }
}));

interface ContentAboutProps {
  nextStep: () => void;
}

const ContentAbout: React.FC<ContentAboutProps> = ({ nextStep }: ContentAboutProps) => {
  const classes = useStyle();
  const { localDbData } = useAppSelector((state) => state.user.data);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [about, setAbout] = useState<string>(localDbData.about ?? "");
  const dispatch = useAppDispatch();

  const validateForm = () => {
    return true;
  };

  const handleInputChange = (e) => {
    setAbout(e.target.value);
  };
  const submitAbout = async () => {
    if (!validateForm() || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      const response = await updateUserAbout(about);
      const { localDbData } = response.data;
      dispatch(setUserLocalDbData(localDbData));
      setIsSaving(false);
      nextStep();
    } catch (error) {
      Toast.fail(error.response.data.message, 2000);
      setIsSaving(false);
    }
  };

  return (
    <Box className={classes.root}>
      <TextArea
        value={about}
        error={true}
        label="About"
        id="postSignupAbout"
        name="about"
        autoComplete="about"
        charLimit={500}
        rows={3}
        onChange={handleInputChange}
      />
      <PrimaryButtonV3 className={classes.continueButton} handleClick={submitAbout} disabled={isSaving}>
        {isSaving ? "Saving..." : "Continue"}
      </PrimaryButtonV3>
    </Box>
  );
};

export default ContentAbout;
