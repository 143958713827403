import React from "react";
import { Typography, Grid, useMediaQuery, Theme, makeStyles, Box } from "@material-ui/core";
import Section from "../common/Section";
import PrimaryButtonV2 from "../common/PrimaryButtonV2";
import { useApp } from "../../../context/app-context";
import { COLORS } from "../../../style";

const useStyles = makeStyles((theme) => ({
  section: {
    height: "100%",
    alignItems: "center",
    backgroundColor: COLORS.WHITE,
    paddingTop: 62,
    paddingBottom: 103,
    [theme.breakpoints.down("md")]: {
      height: "100%",
      paddingTop: 30,
      paddingBottom: 80
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      paddingTop: 40,
      paddingBottom: 80
    }
  },
  sectionContent: {
    alignItems: "center",
    "& > button": {
      textAlign: "center"
    }
  },
  mainTitle: {
    color: COLORS.LIGHT_BLACK,
    marginBottom: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      lineHeight: "32px",
      marginTop: 25
    }
  },
  title: {
    display: "flex",
    alignItems: "flex-start",
    fontWeight: 400,
    marginBottom: 24,
    lineHeight: "26px",
    maxWidth: 262,
    color: COLORS.LIGHT_BLACK,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      marginBottom: 20,
      maxWidth: "unset"
    },
    "& > img": {
      marginRight: 16,
      [theme.breakpoints.down("md")]: {
        maxWidth: 16
      }
    }
  },
  privacyHand: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  buttonJoin: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  }
}));

const PrivacyEverythingSection = () => {
  const { setPromptLogin } = useApp();
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  return (
    <Section className={classes.section} contentProps={{ className: classes.sectionContent }}>
      <Grid container spacing={4} style={{ marginTop: 30 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <img className={classes.privacyHand} src={"/assets/home/homeV4/privacy-hand.png"} />
        </Grid>
        <Grid item lg={5} md={6} sm={6} xs={12}>
          <Typography variant="h2" className={classes.mainTitle}>
            Privacy is everything
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: 400,
              marginBottom: 35,
              color: COLORS.LIGHT_BLACK,
              fontSize: 16,
              lineHeight: "26px"
            }}
          >
            Sending and receiving gifts with Gift.me is always safe and secure.
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <img src={"/assets/home/homeV4/privacy-everything/address.svg"} />
            Addresses stay private
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <img src={"/assets/home/homeV4/privacy-everything/gift.svg"} />
            No buyer details appear on Gift.me packaging
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <img src={"/assets/home/homeV4/privacy-everything/personal.svg"} />
            Names and personal details stay private
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <img src={"/assets/home/homeV4/privacy-everything/share.svg"} />
            Share just your handle
          </Typography>

          <Box className={classes.buttonJoin}>
            <PrimaryButtonV2
              label="Join Now"
              style={{ marginTop: 40, width: isDesktop ? 150 : 127, height: 45 }}
              onClick={() =>
                setPromptLogin({
                  promptLogin: true,
                  returnTo: undefined,
                  registration: true,
                  forceRegistration: true
                })
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default PrivacyEverythingSection;
