import React from "react";
import { Typography } from "@material-ui/core/";
import { Button } from "@material-ui/core";

const PaymentMethodsEditButton = ({ onEditPaymentMethodClick }: { onEditPaymentMethodClick: () => void }) => {
  return (
    <Typography
      variant="caption"
      color="primary"
      style={{
        position: "relative",
        top: "2px",
        textDecoration: "underline"
      }}
      onClick={onEditPaymentMethodClick}
    >
      Edit
    </Typography>
  );
};

export default PaymentMethodsEditButton;
