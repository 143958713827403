import React, { useState, useEffect } from "react";
import ProfileContentContainer from "../ProfileContentContainer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCurrentUser, getUserAccounts } from "../../../actions/userAction";
import { useHistory } from "react-router-dom";
import useNav from "../../../hooks/useNav";
import { STOREHOME_PATH } from "../../../constants/paths";
import { Box, Typography } from "@material-ui/core";
import { UserAccountSync } from "models/UserAccountSync";
import AccountSyncRow from "./AccountSyncRow";
import { setUserGiftCount } from "reducers/user/UserSlice";

const AccountSync: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { goTo } = useNav();
  const { localDbData } = useAppSelector((state) => state.user.data);
  const [state, setState] = useState({
    isSaving: false,
    currentUserLoaded: false
  });
  const [userAccounts, setUserAccounts] = useState<UserAccountSync[]>(undefined);
  const [isPerformingSync, setIsPerformingSync] = useState<boolean>(false);

  const fetchUserAccounts = async () => {
    try {
      const userAccounts = await getUserAccounts();
      setUserAccounts(userAccounts.data.userAccounts.filter((account) => account.user_id !== localDbData.userId));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401
        goTo(STOREHOME_PATH);
        return;
      } else {
        if (error.response && error.response.status === 500) {
          history.push("/error");
          return;
        }
      }
    }
  };

  const fetchUser = async () => {
    try {
      await getCurrentUser();
      setState({
        ...state,
        currentUserLoaded: true
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401
        goTo(STOREHOME_PATH);
        return;
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const onWishlistSynced = () => {
    getCurrentUser()
      .then((data) => {
        if (data) {
          dispatch(setUserGiftCount(data.data.totalCountWishlist));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (state.currentUserLoaded && localDbData && localDbData.userId) {
      fetchUserAccounts();
    }
  }, [state.currentUserLoaded, localDbData]);

  return (
    <ProfileContentContainer page="account_sync" isLoading={!state.currentUserLoaded || !localDbData || !userAccounts}>
      {userAccounts && userAccounts.length === 0 ? (
        <>
          <Typography variant="h4">No accounts found to sync</Typography>
          <Typography variant="body2" style={{ textAlign: "left" }}>
            This account must use the same email address as the other accounts that you would like to sync.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4">Accounts to Sync</Typography>
          <Box style={{ display: "flex", flexDirection: "column", marginTop: 16 }}>
            {userAccounts &&
              userAccounts.map((account, index) => {
                return (
                  <AccountSyncRow
                    key={index}
                    accountSync={account}
                    isSynced={false}
                    styles={{ marginBottom: 16 }}
                    setIsPerformingSync={setIsPerformingSync}
                    disableOverride={isPerformingSync}
                    onWishlistSynced={onWishlistSynced}
                  />
                );
              })}
          </Box>
        </>
      )}
    </ProfileContentContainer>
  );
};

export default AccountSync;
