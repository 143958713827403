import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { FormattedProductDetails } from "./FormattedProductDetails";
import { GiftAddedToWishlistCount } from "components/common/gift/GiftAddedToWishlistCount";
import { GiftPriceDesktop } from "components/common/gift/GiftPriceDesktop";
import GiftOptionsSelector from "components/gift/GiftOptionsSelector";

const ContainerStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      maxWidth: "800px",
      padding: "70px 50px"
    }
  },
  leftColumn: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    paddingTop: "40px",
    paddingBottom: "40px",
    [theme.breakpoints.up("md")]: {
      flexWrap: "wrap",
      flexBasis: "40%"
    }
  },
  rightColumn: {
    [theme.breakpoints.up("md")]: {
      "flex-wrap": "wrap",
      flexBasis: "60%"
    }
  },
  rightColumnTextHeaderContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  rightColumnTextHeaderContainerLeft: {
    flexBasis: "70%"
  },
  rightColumnTextHeaderContainerRight: {
    flexBasis: "30%",
    textAlign: "right"
  },
  rightColumnDescription: {
    paddingTop: "36px",
    paddingBottom: "36px",
    textAlign: "justify",
    minHeight: "200px"
  },
  rightColumnDetailedFeaturesTitle: {
    paddingBottom: "25px"
  },
  rightColumnButtonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "37px"
  },
  rightColumnButtonsContainerPerColumn: {
    flexBasis: "50%"
  }
}));

const StoreProductContainerDesktop = (props) => {
  const styles = ContainerStyles();
  const { gift } = props;

  const finalBrand = gift.brand || gift.name;
  const finalName = gift.brand ? gift.name : "";

  const {
    addGiftToWishlist,
    removeGiftFromWishlist,
    handleBuyGift,
    selectedGiftOptions,
    setSelectedGiftOptions,
    isMyWishlist
  } = props;

  return (
    <Box className={styles.root}>
      <Box className={styles.leftColumn}>
        <img src={gift.image_url} alt={"product image"} />
        <GiftAddedToWishlistCount gift={gift} />
      </Box>
      <Box className={styles.rightColumn}>
        <Box className={styles.rightColumnTextHeaderContainer}>
          <Box className={styles.rightColumnTextHeaderContainerLeft}>
            <Box>
              <Typography style={{ fontWeight: "bold" }}>{finalBrand}</Typography>
            </Box>
            <Box>
              <Typography>{finalName}</Typography>
            </Box>
          </Box>
          <GiftPriceDesktop gift={gift} />
        </Box>
        {isMyWishlist ? (
          <GiftOptionsSelector
            selectedGiftOptions={selectedGiftOptions}
            setSelectedGiftOptions={setSelectedGiftOptions}
          />
        ) : null}
        <Box className={styles.rightColumnDescription}>
          <FormattedProductDetails {...gift} />
        </Box>
        {gift.features && gift.features.length > 0 && (
          <Box>
            <Box className={styles.rightColumnDetailedFeaturesTitle}>
              <Typography style={{ fontWeight: "bold" }}>Detailed Features</Typography>
            </Box>
            <Box>
              <table>
                <thead>
                  <tr>
                    <th style={{ minWidth: "200px" }}>Feature</th>
                    <th style={{ minWidth: "200px" }}>Value</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {gift.features.map((feature) => (
                    <tr key={feature.feature_name + "_" + feature.feature_value}>
                      <td>{feature.feature_name}</td>
                      <td>{feature.feature_value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Box>
        )}
        <Box className={styles.rightColumnButtonsContainer}>
          <Box className={styles.rightColumnButtonsContainerPerColumn}>
            {props.isMyWishlist && (
              <SecondaryButton
                text={gift.is_in_user_wishlist ? "♡ Added to wishlist" : "♡ Add to wishlist"}
                style={{ color: "#E92983", width: "inherit" }}
                handleClick={gift.is_in_user_wishlist ? removeGiftFromWishlist : addGiftToWishlist}
              />
            )}
          </Box>
          <Box className={styles.rightColumnButtonsContainerPerColumn}>
            <PrimaryButton style={{ color: "#FFFFFF" }} handleClick={handleBuyGift}>
              Buy now
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StoreProductContainerDesktop;
