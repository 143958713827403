import CardInputData from "../input/card/CardInputData";
import { FormInput } from "../input/Form";
import { CARD_CVV_LABEL, CARD_CVV_SIZE } from "../input/card/CardUtils";

export const initialFormInputs = {
  card: CardInputData(),
  exp_month: FormInput(),
  exp_year: FormInput(),
  csv: FormInput(CARD_CVV_LABEL, CARD_CVV_SIZE),
  billing_name: FormInput(),
  billing_address: FormInput(),
  billing_city: FormInput(),
  billing_state: FormInput(),
  billing_province: FormInput(),
  billing_zip: FormInput(),
  billing_country: FormInput(),
  billing_email: FormInput(),
  sender_name: FormInput(),
  special_message: FormInput(undefined, undefined, "Enjoy your gift!")
};
