import { useMediaQuery, Theme } from "@material-ui/core";

const useMediaBreakpoints = (): {
  isWideDesktop: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isTabletMobileOnly: boolean;
  isTabletUp: boolean;
  isMobile: boolean;
  isMobileXs: boolean;
} => {
  const isWideDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const isTabletMobileOnly = useMediaQuery<Theme>((theme) => theme.breakpoints.between("sm", "lg"));
  const isTabletUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isMobileXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  return {
    isWideDesktop,
    isDesktop,
    isTabletMobileOnly,
    isTabletUp,
    isTablet,
    isMobile,
    isMobileXs
  };
};

export default useMediaBreakpoints;
