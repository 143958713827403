import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, List, ListItem, ListItemText, Radio } from "@material-ui/core";
import { Typography } from "@material-ui/core/";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import PrimaryButton from "../../buttons/PrimaryButton";
import { ChevronRight, Delete } from "@material-ui/icons";
import Caption from "../../common/caption/Caption";

const SavedPaymentMethodsContentStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  addButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  }
}));

const BILLING_ADDRESS = "billingAddress";
const ADD_NEW_BILLING_ADDRESS = "addNewBillingAddress";

// Dummy Data format
export const savedBillingAddress = [
  {
    type: BILLING_ADDRESS,
    email: "tech@catlike.io",
    address: "3250 Pepper, Las Vegas, NV 89112"
  },
  {
    type: BILLING_ADDRESS,
    email: "dax@catlike.io",
    address: "3250 Pepper, Las Vegas, NV 89112"
  },
  {
    type: BILLING_ADDRESS,
    email: "bikrant@catlike.io",
    address: "3250 Pepper, Las Vegas, NV 89112"
  },
  {
    type: ADD_NEW_BILLING_ADDRESS
  }
];

export default function SavedBillingInfoListContent(props) {
  const styles = SavedPaymentMethodsContentStyles();

  const [pageData, setState] = useState({
    selectedKey: undefined,
    selectedBillingAddress: undefined
  });

  const updateSelectedBillingAddress = (item, key) => {
    setState({ ...pageData, selectedKey: key, selectedBillingAddress: item });
    // TODO
  };

  const saveSelectedBillingAddress = () => {
    // TODO @bikrant: integrate with the API to save selected Payment method and close the dialog
  };

  console.log("dax ffs ", props);

  return (
    <Box>
      <SectionTitle>Billing info</SectionTitle>

      <Caption style={{ marginTop: "16px" }}>
        Your details are private and confidential. Purchases show as ‘RealGifts’
      </Caption>

      <Typography style={{ marginTop: "24px" }}>Saved address:</Typography>

      <List>
        {props.paymentInfos.map((item) =>
          renderRow(
            item,
            props.email,
            pageData.selectedKey,
            updateSelectedBillingAddress,
            props.handleAddNewBillingAddress
          )
        )}
      </List>

      <PrimaryButton className={styles.addButton} handleClick={() => saveSelectedBillingAddress()}>
        Save & Continue
      </PrimaryButton>
    </Box>
  );
}

function renderRow(item, email, selectedKey, updateSelectedBillingAddress, handleAddNewPayment) {
  if (item.billing_address) {
    return renderBillingAddressRow(item, email, selectedKey, updateSelectedBillingAddress);
  }

  return renderAddNewBillingAddressRow(handleAddNewPayment);
}

function renderBillingAddressRow(item, email, selectedKey, updateSelectedBillingAddress) {
  // TODO should this be id of the saved address?, update it accordingly
  const rowKey = item.email + "_" + item.billing_address;
  return (
    <ListItem
      alignItems="flex-start"
      button={true}
      disableGutters={true}
      divider={true}
      onClick={() => updateSelectedBillingAddress(item, rowKey)}
    >
      <Radio
        checked={selectedKey === rowKey}
        onChange={() => {
          updateSelectedBillingAddress(item, rowKey);
        }}
        value={rowKey}
        name="radio-button-demo"
        inputProps={{ "aria-label": "A" }}
      />
      <ListItemText
        primary={
          <React.Fragment>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}>
                <Typography style={{ fontWeight: 700 }}>{email}</Typography>
                <Typography noWrap={true}>
                  {item.billing_address}, {item.billing_city}, {item.billing_state} {item.billing_zip}
                </Typography>
              </Box>

              {/*TODO add delete billing address functionality*/}
              <Delete />
            </Box>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}

function renderAddNewBillingAddressRow(handleAddNewBillingAddress) {
  return (
    <ListItem
      alignItems="flex-start"
      button={true}
      disableGutters={true}
      divider={true}
      style={{
        paddingTop: "24px",
        paddingBottom: "24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
      onClick={() => handleAddNewBillingAddress()}
    >
      <Typography>Add a billing address</Typography>

      <ChevronRight style={{ color: "#f0017c" }} />
    </ListItem>
  );
}
