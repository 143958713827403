import React, { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "../../theme/theme-devins";
import { DemoGridItems } from "../homeV4/common/GridWrapper";
import Section from "../homeV4/common/Section";
import PrimaryButton from "../homeV4/common/PrimaryButtonV2";
import { COLORS } from "../../style";
import CardMedia from "../homeV4/common/CardMedia";
import FadeIn from "../../animations/fade-in";
import TextInput from "../input/TextInputV3";
import TextArea from "../input/TextArea";
import ProfilePicture from "../common/profile/ProfilePicture";

const Demo = () => {
  const [formData, setFormData] = useState({
    handle: "",
    about: ""
  });

  const handleFormData = ({ name, value }) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  return (
    <AppLayout theme={theme}>
      <Section bgcolor={COLORS.WHITE}>
        <DemoGridItems style={{ marginTop: 100, marginBottom: 100 }} />
      </Section>
      <Section bgcolor={COLORS.WHITE}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <FadeIn direction="top" delay={250}>
              <Typography variant="h1" color="textSecondary">
                Typography
              </Typography>
            </FadeIn>
            <FadeIn direction="left" delay={250}>
              <Typography variant="h2" color="textSecondary">
                Typography
              </Typography>
            </FadeIn>
            <FadeIn direction="right" delay={250}>
              <Typography variant="h3" color="textSecondary">
                Typography
              </Typography>
            </FadeIn>
            <FadeIn direction="bottom" delay={250}>
              <Typography variant="h4" color="textSecondary">
                Typography
              </Typography>
            </FadeIn>
            <Typography variant="h5" color="textSecondary">
              Typography
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Typography
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Subtitle1
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Subtitle2
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Body1
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Body2
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <ProfilePicture avatarHeight={164} avatarWidth={164} />
            <TextInput
              id="handle"
              name="handle"
              label="Handle"
              value={formData.handle}
              onChange={(e) => handleFormData({ name: "handle", value: e.target.value })}
              style={{ marginBottom: 10 }}
            />

            <TextArea
              id="about"
              name="about"
              label="About"
              value={formData.about}
              rows={3}
              charLimit={5}
              onChange={(e) => handleFormData({ name: "about", value: e.target.value })}
              style={{ marginBottom: 20, width: "100%" }}
            />
            <br />
            <PrimaryButton label="Sample Button" style={{ marginBottom: 10 }} />
            <br />
            <PrimaryButton disabled label="Disabled" />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid container spacing={4}>
              <Grid item lg={6} xs={12}>
                <CardMedia
                  title="Sample Image"
                  src="/assets/home/categories/most-wished-items-2x.jpg"
                  height={413}
                  color="textSecondary"
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardMedia
                  src="/assets/home/categories/featured-gifts-2x.jpg"
                  height={413}
                  color="textSecondary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </AppLayout>
  );
};

export default Demo;
