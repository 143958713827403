import { makeStyles } from "@material-ui/core/styles";
import { HEADER, textColor } from "style";

export const SyncHeaderStyles = makeStyles((theme) => ({
  syncHeaderStyle: {
    backgroundColor: HEADER.syncHeader,
    zIndex: 1298,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8
  },
  syncHeaderTextStyle: {
    ...theme.typography.body2,
    color: textColor.DARK1,
    textAlign: "center"
  },
}));
