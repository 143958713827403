import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GiftInterface } from "../../../models/gift";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";
import { CURRENCY } from "../Constants";

const ContainerStyles = makeStyles(() => ({
  TextHeaderContainerRight: {
    display: "flex",
    flexBasis: "30%",
    textAlign: "right",
    flexWrap: "wrap"
  },
  rightColumnTextHeaderContainerRight: {
    flexBasis: "30%",
    textAlign: "right"
  }
}));

interface GiftPriceDesktopProps {
  gift: GiftInterface;
}

export function GiftPriceDesktop(props: GiftPriceDesktopProps) {
  const styles = ContainerStyles();
  const gift = props.gift;

  const isFreeShipping = gift.pricing && gift.pricing.shipping_price_converted <= 0;

  return (
    <Box className={styles.rightColumnTextHeaderContainerRight}>
      <Box>
        <Typography style={{ fontWeight: "bold" }}>
          {CURRENCY}
          {roundToDecimalPlaces(gift.pricing.true_price_converted, 2)}
        </Typography>
        {gift.pricing.is_sale ? (
          <Typography style={{ textAlign: "right", color: "grey", textDecoration: "line-through" }}>
            {CURRENCY}
            {roundToDecimalPlaces(gift.pricing.price_converted.toString(), 2)}
          </Typography>
        ) : null}
      </Box>
      {isFreeShipping && (
        <Box>
          <Typography style={{ fontWeight: "bold", color: "#E92983" }}>FREE SHIPPING</Typography>
        </Box>
      )}
    </Box>
  );
}
