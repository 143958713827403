import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, withStyles } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Add";

import { GiftSearchAvailableFiltersObject } from "./giftSearchAvailableFilters";
import GiftFiltersItemOption from "./GiftFiltersItemOption";

interface GiftFiltersItemProps {
  filter: GiftSearchAvailableFiltersObject;
}

const AccordionWithStyles = withStyles({
  root: {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    borderRadius: "0 !important",
    borderBottom: "1px solid #AFB0B1",

    "& .MuiAccordionSummary-content": {
      margin: "20px 0"
    },
    "& .MuiAccordionSummary-root, & .MuiAccordionDetails-root": {
      paddingLeft: 0
    },
    "& .MuiAccordionDetails-root": {
      paddingBottom: 35,
      paddingTop: 0,
      display: "block"
    }
  }
})(Accordion);

const AccordionSummaryWithStyles = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      width: 15,
      height: 15,
      color: "black"
    }
  }
})(AccordionSummary);

const GiftFiltersItem: React.FC<GiftFiltersItemProps> = (props: any) => {
  const { filter } = props;
  return (
    <AccordionWithStyles>
      <AccordionSummaryWithStyles expandIcon={<RemoveIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="body2" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
          {filter.label}
        </Typography>
      </AccordionSummaryWithStyles>
      <AccordionDetails>
        {React.Children.toArray(
          filter.filters.map((option) => <GiftFiltersItemOption key={option.value} option={option} />)
        )}
      </AccordionDetails>
    </AccordionWithStyles>
  );
};

export default GiftFiltersItem;
