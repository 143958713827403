import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const usesStyles = makeStyles((theme) => ({
  caption: {
    ...theme.typography.body1,
    fontStyle: "italic",
    color: theme.palette.grey[500]
  }
}));

export default function Caption(props: any) {
  const classes = usesStyles();
  // Merging default style with styles provided by the parent
  const className = props.className ? `${classes.caption} ${props.className}` : classes.caption;
  return (
    <Typography className={className} style={props.style}>
      {props.children}
    </Typography>
  );
}
