import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { FONT_FAMILIES, textColor, BACKGROUND } from "../style";
const breakpoints = createBreakpoints({});

const setSpacing = (x: number) => (window.innerWidth <= breakpoints.width("sm") ? 4 * x : 8 * x);

const theme = createMuiTheme({
  spacing: setSpacing,
  palette: {
    primary: {
      main: "#f0017c",
      contrastText: "#fff"
    },
    text: {
      primary: textColor.GRAY,
      secondary: "#4B4B4B",
      hint: "#424242",
      disabled: "#A3A3A3"
    },
    background: {
      default: BACKGROUND.bgColor
    },
    grey: {
      300: "#CCCCCC",
      500: "#A3A3A3",
      600: "#7E7E7E",
      700: "#565656",
      800: "#424242",
      900: "#212121",
      A400: "#333333",
      A700: "#565656"
    }
  },
  typography: {
    h1: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 64,
      fontWeight: 800,
      letterSpacing: 0,
      lineHeight: "82px",
      [breakpoints.down("sm")]: {
        fontSize: 46,
        lineHeight: "48px"
      },
      [breakpoints.down("xs")]: {
        fontSize: 32,
        lineHeight: "38px"
      }
    },
    h2: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 48,
      fontWeight: 700,
      letterSpacing: "0",
      lineHeight: "58px",
      [breakpoints.down("sm")]: {
        fontSize: 38
      },
      [breakpoints.down("xs")]: {
        fontSize: 32,
        lineHeight: "40px"
      }
    },
    h3: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 32,
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "54px",
      [breakpoints.down("xs")]: {
        fontSize: 24,
        lineHeight: "32px"
      }
    },
    h4: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 24,
      fontWeight: 800,
      lineHeight: "32px",
      letterSpacing: "0",
      [breakpoints.down("md")]: {
        fontSize: 22
      }
    },
    h5: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 20,
      fontWeight: 800,
      letterSpacing: "0",
      lineHeight: "25px",
      [breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    h6: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "0",
      lineHeight: "24.39px",
      [breakpoints.down("sm")]: {
        lineHeight: "27px",
        fontWeight: 700
      }
    },
    subtitle1: {
      fontFamily: FONT_FAMILIES.POPPINS,
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: "0",
      [breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    subtitle2: {
      fontFamily: FONT_FAMILIES.POPPINS,
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: "0",
      [breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    body1: {
      fontFamily: FONT_FAMILIES.POPPINS,
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "26px"
    },
    body2: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "26px"
    },
    caption: {
      fontFamily: FONT_FAMILIES.POPPINS,
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "21px"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: FONT_FAMILIES.SOFIA_PRO,
        fontSize: 14,
        lineHeight: "22px",
        fontWeight: 700,
        "&.Mui-disabled": {
          border: "solid 1px rgba(0, 0, 0, 0.12)"
        }
      }
    }
  }
});

theme.props = {
  MuiButton: {
    disableElevation: true
  }
};

export default theme;
