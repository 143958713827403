import axios from "axios";

interface PaymentMethodPayload {
  csv: string,
  exp_month: string,
  exp_year: string,
  billing_address: string,
  billing_city: string,
  billing_country: string,
  billing_email: string,
  billing_name: string,
  billing_state: string,
  billing_zip: string,
}

interface CreatePaymentMethodPayload  extends PaymentMethodPayload{
  account_number: string
}

export async function updateCard(
  paymentProfileId: string,
  payload: PaymentMethodPayload
) {
  return await axios.post(
    `/api/payments/${paymentProfileId}/update`,
    payload
  );
}

export async function createPaymentMethod(payload: CreatePaymentMethodPayload) {
  return await axios.post("/api/payments/create", payload);
}
