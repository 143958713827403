import * as React from "react";

function ShopOutlinedIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12.439} height={15.479} viewBox="0 0 12.439 15.479" {...props}>
      <path d="M1.412 3.648h9.738l.633 10.761H.827z" fill="#e92983" />
      <path
        d="M12.311 13.289l-.872-9.819a.42.42 0 00-.417-.383H9.225a3.009 3.009 0 00-6.017 0H1.417A.417.417 0 001 3.47l-.875 9.819v.037a2.17 2.17 0 002.286 2.027h7.614a2.17 2.17 0 002.289-2.028.152.152 0 00-.003-.036zM6.22.966a2.17 2.17 0 012.168 2.121H4.052A2.17 2.17 0 016.22.966zm3.805 13.547H2.411a1.342 1.342 0 01-1.445-1.168l.835-9.414h1.408v1.276a.42.42 0 10.841 0V3.931h4.338v1.276a.42.42 0 10.841 0V3.931h1.408l.838 9.414a1.344 1.344 0 01-1.45 1.168z"
        fill="#c1256f"
        stroke="#c1256f"
        strokeWidth={0.25}
      />
    </svg>
  );
}

export default ShopOutlinedIcon;
