import * as React from "react";

function ShopIcon(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9204 21H6.08035C5.94202 21.0004 5.8051 20.9722 5.67825 20.917C5.5514 20.8618 5.43737 20.7809 5.34339 20.6794C5.2494 20.5779 5.1775 20.458 5.13222 20.3273C5.08695 20.1966 5.06929 20.0579 5.08035 19.92L5.93035 8.92001C5.95051 8.66889 6.0647 8.43463 6.25009 8.26407C6.43549 8.0935 6.67843 7.9992 6.93035 8.00001H17.0704C17.3223 7.9992 17.5652 8.0935 17.7506 8.26407C17.936 8.43463 18.0502 8.66889 18.0704 8.92001L18.9204 19.92C18.9314 20.0579 18.9138 20.1966 18.8685 20.3273C18.8232 20.458 18.7513 20.5779 18.6573 20.6794C18.5633 20.7809 18.4493 20.8618 18.3225 20.917C18.1956 20.9722 18.0587 21.0004 17.9204 21V21Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11V6C9 5.20435 9.31607 4.44129 9.87868 3.87868C10.4413 3.31607 11.2044 3 12 3V3C12.7956 3 13.5587 3.31607 14.1213 3.87868C14.6839 4.44129 15 5.20435 15 6V11"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShopIcon;
