import React from "react";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { getCardImage } from "../../components/input/card/CardUtils";
import { makeStyles } from "@material-ui/core/styles";

const SavedPaymentInfoStyles = makeStyles(() => ({
  root: {
    marginTop: "24px"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  changeLink: {
    color: "#f0017c"
  },
  paymentInfoDetail: {
    display: "flex",
    flexDirection: "row",
    marginTop: "18px",
    marginLeft: "16px",
    marginRight: "16px"
  },
  paymentCardImg: {
    alignSelf: "auto",
    height: "1.6em"
  },
  paymentCardText: {
    marginLeft: "8px"
  }
}));

export default function SavedCheckoutPaymentInfo(props: any) {
  const styles = SavedPaymentInfoStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.headerContainer}>
        <Typography>Payment Method:</Typography>

        <Typography>
          <Link
            component={Button}
            href="#"
            className={styles.changeLink}
            onClick={props.openChoosePaymentMethodDialog}
            underline="always"
            disabled={props.disabled}
          >
            Change
          </Link>
        </Typography>
      </Box>
      <Box className={styles.paymentInfoDetail}>
        {/*TODO Use the correct card info image & text. Refer to CardUtils.getCardImage() */}
        <img src={getCardImage({ type: props.paymentInfo.account_type })} className={styles.paymentCardImg} />
        <Typography className={styles.paymentCardText}>
          {props.paymentInfo.account_type} ending in {props.paymentInfo.last_four}
        </Typography>
      </Box>
    </Box>
  );
}
