export default class DataLayer {
  /**
   * Pushes data to dataLayer
   *
   * @param {object} data
   * @returns {void}
   */
  gtag(data: object): void {
    if (!("dataLayer" in window)) {
      return console.error("Datalayer does not exist!");
    }

    // @ts-ignore
    window.dataLayer.push(data);
  }
}
