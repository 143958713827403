import React, { ReactElement } from "react";
import { Box, BoxProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    backgroundSize: "cover",
    position: "relative"
  },
  content: {
    maxWidth: 1440,
    height: "100%",
    margin: "auto",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingLeft: 15,
      paddingRight: 15
    }
  }
}));

interface ISectionProps extends BoxProps {
  backgroundImage?: string;
  children?: ReactElement;
  contentProps?: BoxProps;
}

const Section = ({
  backgroundImage,
  children,
  style,
  contentProps,
  ...rest
}: ISectionProps): ReactElement => {
  const classes = useStyles();
  return (
    <Box className={classes.root} style={{ backgroundImage: `url(${backgroundImage})`, ...style }} {...rest}>
      <Box className={classes.content} {...contentProps}>
        {children}
      </Box>
    </Box>
  );
};

export default Section;
