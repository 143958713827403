import React from "react";
import clsx from "clsx";
import { Box, makeStyles, Theme, BoxProps, Grid, useMediaQuery } from "@material-ui/core";
import TermsAndConditions from "../../termsAndConditions/TermsAndConditionsV2";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";
import GridWrapper from "../../homeV4/common/GridWrapper";
import { COLORS } from "../../../style";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    backgroundSize: "cover",
    position: "relative",
    backgroundColor: "inherit",
    paddingTop: 56,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 45
    }
  },
  content: {
    display: "flex",
    height: "100%"
  },
  withoutContent: {
    width: "100%",
    position: "fixed",
    bottom: 0
  },
  socialLinks: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      marginBottom: 15
    }
  },
  footerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "space-between"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between"
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      marginBottom: 0
    },
    "&:last-child": {
      paddingBottom: 20,
      marginBottom: 0,
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 80
      }
    }
  }
}));
interface AppFooterVariant2Props extends BoxProps {
  linkFontSize?: string;
  footerBottomFixed?: boolean;
}

const AppFooterVariant2 = ({
  className,
  linkFontSize,
  footerBottomFixed,
  ...rest
}: AppFooterVariant2Props): ReturnType<React.FC> => {
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <GridWrapper className={footerBottomFixed ? classes.withoutContent : classes.content}>
        <Grid container spacing={4} style={{ height: "100%", marginBottom: isDesktop ? "0" : "unset" }}>
          <Grid item lg={12} className={classes.footerWrapper} style={{ alignItems: isDesktop ? "center" : "unset" }}>
            <TermsAndConditions
              textAlign="left"
              justifyContent="flex-start"
              alignItems="baseline"
              style={{
                display: "flex",
                alignItems: "baseline",
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "26px",
                color: COLORS.LIGHT_GRAY,
                flexDirection: isDesktop ? "row" : "column"
              }}
              linkFontSize={linkFontSize}
            />
            <SocialMediaLinks className={classes.socialLinks} />
          </Grid>
        </Grid>
      </GridWrapper>
    </Box>
  );
};

export default AppFooterVariant2;
