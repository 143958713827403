import React from "react";
import { Typography, FormControl } from "@material-ui/core";
import Caption from "../../common/caption/Caption";
import TextInput from "../../input/TextInput";
import { makeStyles } from "@material-ui/core/";
import { SectionHeaderTitle } from "../ProfileComponents";

interface AccountSettingsChangePasswordProps {
  currentPassword: any;
  password: any;
  confirmPassword: any;
  handleInputChange: (e: any) => void;
  handleOnFocus: (e: any) => void;
  handleOnBlur: (e: any) => void;
}

const componentStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    maxWidth: 408
  },
  caption: {
    marginTop: 8,
    marginBottom: 23,
    color: "inherit",
    fontStyle: "normal"
  }
}));
const AccountSettingsChangePassword: React.FC<AccountSettingsChangePasswordProps> = (
  props: AccountSettingsChangePasswordProps
) => {
  const { currentPassword, password, confirmPassword, handleInputChange, handleOnBlur, handleOnFocus } = props;
  const styles = componentStyles();
  return (
    <>
      <SectionHeaderTitle text="Change Password" />
      <Caption className={styles.caption}>
        It’s a good idea to use a strong password that you’re not using elsewhere
      </Caption>
      <FormControl className={styles.formControl}>
        <TextInput
          id="currentPassword"
          name="currentPassword"
          type="password"
          label={"Current Password"}
          style={{ marginTop: "16px" }}
          autoComplete="new-password"
          value={currentPassword.value}
          error={currentPassword.error}
          helperText={currentPassword.error}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
        />
        <TextInput
          id="password"
          name="password"
          type="password"
          label={"New Password"}
          autoComplete="new-password"
          style={{ marginTop: "16px" }}
          value={password.value}
          error={password.error}
          helperText={password.error}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
        />
        <TextInput
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label={"Confirm Password"}
          autoComplete="new-password"
          style={{ marginTop: "16px" }}
          value={confirmPassword.value}
          error={confirmPassword.error}
          helperText={confirmPassword.error}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
        />
      </FormControl>
    </>
  );
};

export default AccountSettingsChangePassword;
