import React, { useState, useEffect } from "react";
import { Box, Divider, Link, Typography } from "@material-ui/core";
import ReceivedGiftImage from "./ReceivedGiftImage";
import {
  RedeemGiftCaptionStyle,
  RedeemGiftDividerStyle,
  RedeemGiftHeaderStyle,
  RedeemGiftNameStyle
} from "./RedeemGiftFlowStyles";
import RedeemGiftDialogButtons from "./RedeemGiftDialogButtons";
import RedeemGiftShippingForm from "./RedeemGiftShippingForm";
import Form, { FormInput } from "../input/Form";
import RedeemGiftOptionsSelector, { parseGiftOptionsList } from "./RedeemGiftOptionsSelector";
import useShippingValidation from "./hooks/useShippingValidation";

function getInitialFormInputs(account: any) {
  const prefillFormInputValue = (formInput, value) => {
    if (value && value !== "") {
      formInput.value = value;
    }
  };

  const formInputs = {
    delivery_fullname: FormInput(),
    delivery_email: FormInput(),
    delivery_address: FormInput(),
    delivery_city: FormInput(),
    delivery_province: FormInput(),
    delivery_state: FormInput(),
    delivery_zip: FormInput(),
    delivery_country: FormInput(),
    delivery_phone: FormInput()
  };

  const firstname = account.delivery_first_name || account.firstname;
  const lastname = account.delivery_last_name || account.lastname;

  if (firstname !== "" && lastname !== "") {
    formInputs.delivery_fullname.value = firstname + " " + (lastname || "");
  }

  prefillFormInputValue(formInputs.delivery_address, account.delivery_address || account.address);
  prefillFormInputValue(formInputs.delivery_country, account.delivery_country || account.country);
  prefillFormInputValue(formInputs.delivery_zip, account.delivery_zip || account.zip);
  prefillFormInputValue(formInputs.delivery_city, account.delivery_city || account.city);
  prefillFormInputValue(formInputs.delivery_state, account.delivery_state || account.state);
  prefillFormInputValue(formInputs.delivery_province, account.delivery_province || account.state);
  prefillFormInputValue(formInputs.delivery_phone, account.delivery_phone || account.phone);
  prefillFormInputValue(formInputs.delivery_email, account.delivery_email || account.email);

  return formInputs;
}

export default function GiftAcceptedDialogContent(props: any) {
  const redeemGiftNameStyle = RedeemGiftNameStyle();
  const redeemGiftHeaderStyle = RedeemGiftHeaderStyle();
  const redeemGiftCaptionStyle = RedeemGiftCaptionStyle();
  const redeemGiftDividerStyle = RedeemGiftDividerStyle();
  const [selectedGiftOptions, setSelectedGiftOptions] = useState([]);
  const { validate, validateZip, validatePhoneNumber } = useShippingValidation();

  useEffect(() => {
    const {
      order: { gift }
    } = props;
    let giftOptions = [];

    if (gift.option_1_name && gift.option_1_values && gift.option_1_values.length > 0 && gift.option_1_values !== "[]") {
      const options = parseGiftOptionsList(gift.option_1_values);
      giftOptions.push({
        name: "option_1",
        label: gift.option_1_name,
        value: gift.option_1_values_selected === "" ? options[0]?.value : gift.option_1_values_selected,
        error: "",
        options
      });
    }

    if (gift.option_2_name && gift.option_2_values && gift.option_2_values.length > 0 && gift.option_2_values !== "[]") {
      const options = parseGiftOptionsList(gift.option_2_values);
      giftOptions.push({
        name: "option_2",
        label: gift.option_2_name,
        value: gift.option_2_values_selected === "" ? options[0]?.value : gift.option_2_values_selected,
        error: "",
        options
      });
    }

    if (gift.option_3_name && gift.option_3_values && gift.option_3_values.length > 0 && gift.option_3_values !== "[]") {
      const options = parseGiftOptionsList(gift.option_3_values);
      giftOptions.push({
        name: "option_3",
        label: gift.option_3_name,
        value: gift.option_3_values_selected === "" ? options[0]?.value : gift.option_3_values_selected,
        error: "",
        options
      });
    }
    setSelectedGiftOptions(giftOptions);
  }, [props]);

  const confirmShippingAddress = () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });
      return;
    }

    props.onEnteredGiftOptions(selectedGiftOptions);

    props.onEnteredShippingAddress({
      order_id: props.order.order_id,
      delivery_email: formInputs.delivery_email.value,
      delivery_first_name: formInputs.delivery_fullname.value.split(/ (.+)/)[0],
      delivery_last_name: formInputs.delivery_fullname.value.split(/ (.+)/)[1] || "",
      delivery_address: formInputs.delivery_address.value,
      delivery_city: formInputs.delivery_city.value,
      delivery_state: formInputs.delivery_state.value,
      delivery_province: formInputs.delivery_province.value,
      delivery_zip: formInputs.delivery_zip.value,
      delivery_country: formInputs.delivery_country.value,
      delivery_phone: formInputs.delivery_phone.value
    });
  };

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (let inputName in inputs) {
      const formInput = inputs[inputName];
      if ("delivery_fullname" === inputName) {
        formInput.error = validate("fullname", formInput.value);
      } else if ("delivery_address" === inputName) {
        formInput.error = validate("address", formInput.value);
      } else if ("delivery_country" === inputName) {
        formInput.error = formInput.value === "" ? "Country is required" : "";
      } else if ("delivery_city" === inputName) {
        formInput.error = validate("city", formInput.value);
      } else if ("delivery_state" === inputName && formInputs["delivery_country"].value === "US") {
        formInput.error = validate("state", formInput.value);
      } else if ("delivery_province" === inputName && formInputs["delivery_country"].value !== "US") {
        formInput.error = validate("province", formInput.value);
      } else if ("delivery_zip" === inputName) {
        validateZip(formInput, formInputs);
      } else if ("delivery_email" === inputName) {
        formInput.error = validate("email", formInput.value);
      } else if ("delivery_phone" === inputName) {
        validatePhoneNumber(formInput, formInputs);
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    if (selectedGiftOptions.length > 0) {
      // Add validation for empty select or ?
      selectedGiftOptions.map((item) => {
        if (["", "?"].includes(item.value)) {
          item["error"] = "Please select option";
          hasError = true;
        }
        return item;
      });
    }

    return !hasError;
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    getInitialFormInputs(props.claimAddress ? props.claimAddress : props.redeemingUser),
    validateForm
  );

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" style={{ paddingBottom: "16px" }}>
      <Typography variant="body1">Order number: {props.order.order_id}</Typography>
      <ReceivedGiftImage
        alt="Gift Image"
        src={props.order.gift.image_url}
        style={{ alignSelf: "center", marginTop: "16px" }}
      />
      <Typography variant="h6" className={redeemGiftNameStyle.textStyle} style={{ marginTop: "8px" }}>
        {props.order.gift.name}
      </Typography>
      <RedeemGiftOptionsSelector
        styles={redeemGiftCaptionStyle}
        selectedGiftOptions={selectedGiftOptions}
        setSelectedGiftOptions={setSelectedGiftOptions}
      />
      <Box display="flex" flexWrap="wrap" style={{ marginTop: "24px" }}>
        <Typography variant="body2" className={redeemGiftCaptionStyle.textStyle}>
          <i>Need something else?</i>
        </Typography>
        <Link
          color="inherit"
          component="button"
          underline="always"
          className={redeemGiftCaptionStyle.textStyle}
          onClick={props.onNegativeButtonClick}
          style={{ marginLeft: "8px" }}
        >
          <i>Contact us here</i>
        </Link>
      </Box>
      <Divider className={redeemGiftDividerStyle.style} style={{ marginTop: "32px" }} />
      <Typography variant="h6" className={redeemGiftHeaderStyle.textStyle} style={{ marginTop: "32px" }}>
        Where shall we deliver the goods?
      </Typography>
      <Typography className={redeemGiftCaptionStyle.textStyle} style={{ marginTop: "24px" }}>
        Your details are private and confidential. Your information will <b>not</b> be shared with the gift buyer.
      </Typography>
      <RedeemGiftShippingForm
        style={{ display: "flex", marginTop: "28px" }}
        delivery_fullname={formInputs.delivery_fullname ? formInputs.delivery_fullname : ""}
        delivery_address={formInputs.delivery_address ? formInputs.delivery_address : ""}
        delivery_zip={formInputs.delivery_zip ? formInputs.delivery_zip : ""}
        delivery_city={formInputs.delivery_city ? formInputs.delivery_city : ""}
        delivery_province={formInputs.delivery_province ? formInputs.delivery_province : ""}
        delivery_state={formInputs.delivery_state ? formInputs.delivery_state : ""}
        delivery_country={formInputs.delivery_country ? formInputs.delivery_country : ""}
        delivery_phone={formInputs.delivery_phone ? formInputs.delivery_phone : ""}
        delivery_email={formInputs.delivery_email ? formInputs.delivery_email : ""}
        handleInputChange={handleInputChange}
        handleOnBlur={handleOnBlur}
        handleOnFocus={handleOnFocus}
      />
      <RedeemGiftDialogButtons
        positiveButtonText="Continue"
        onPositiveButtonClick={() => confirmShippingAddress()}
        onNegativeButtonClick={props.onGoBackClicked}
        negativeButtonText="Go back"
      />
    </Box>
  );
}
