import React, { useState } from "react";
import { Typography, Box, FormControl } from "@material-ui/core";
import TextInputV3, { CustomInputLabel } from "../../input/TextInputV3";
import GenderSelector from "../../input/GenderSelector";
import BirthdaySelector from "../../input/BirthdaySelector";
import VanityUrl from "../../VanityUrl/VanityUrl";
import { makeStyles } from "@material-ui/core/styles";
import { SectionHeaderTitle } from "../ProfileComponents";
import PrimaryButton from "components/buttons/PrimaryButton";
import UpdateEmailDialog from "./UpdateEmailDialog";
import { FormInput } from "components/input/Form";

const componentStyles = makeStyles(() => ({
  root: {},
  input: {
    marginBottom: 24,
    width: "100%"
  },
  orderButton: {
    height: "50px",
    borderRadius: "25px",
    width: "200px",
    marginTop: "4px"
  }
}));

interface AccountDetailsPersonalInformationProps {
  nickname: any;
  gender: any;
  email: any;
  birthYear: any;
  birthMonth: any;
  birthDay: any;
  handleInputChange: (e: any) => void;
  handleOnFocus: (e: any) => void;
  handleOnBlur: (e: any) => void;
  vanityUrlOnChangedHandler: (vanity: string, error: string) => void;
  validateNickname: (nickname: string) => void;
}

const AccountDetailsPersonalInformation: React.FC<AccountDetailsPersonalInformationProps> = (
  props: AccountDetailsPersonalInformationProps
) => {
  const {
    nickname,
    email,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    handleInputChange,
    handleOnFocus,
    handleOnBlur,
    validateNickname,
    vanityUrlOnChangedHandler
  } = props;
  const classes = componentStyles();

  const [updateEmailIsOpen, setUpdateEmailIsOpen] = useState<boolean>(false);

  const onUpdateEmailDialogClosed = () => {
    setUpdateEmailIsOpen(false);
  };

  const onEmailUpdated = (newEmail: string) => {
    handleInputChange({ target: { name: "email", value: newEmail } });
    setUpdateEmailIsOpen(false);
  };

  const onUpdateEmailClicked = () => {
    setUpdateEmailIsOpen(true);
  };

  return (
    <Box>
      <SectionHeaderTitle text="Your Info" />
      <FormControl fullWidth style={{ marginTop: "24px" }}>
        <TextInputV3
          id="nickname"
          name="nickname"
          label="Handle"
          value={nickname.value}
          error={nickname.error}
          helperText={nickname.error}
          onBlur={(e) => {
            handleOnBlur(e);
            validateNickname(e.target.value);
          }}
          onChange={(e) => {
            handleInputChange(e);
          }}
          onFocus={handleOnFocus}
          className={classes.input}
        />
        <CustomInputLabel htmlFor={"email"}>Your Email Address</CustomInputLabel>
        <Box display={"inline-flex"}>
          <TextInputV3
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            value={email.value}
            error={email.error}
            disabled={true}
            helperText={email.error}
            className={classes.input}
          />
          <Box style={{ minWidth: "8px" }}></Box>
          <PrimaryButton className={classes.orderButton} handleClick={onUpdateEmailClicked}>
            Update Email
          </PrimaryButton>
        </Box>
        <GenderSelector
          value={gender.value}
          error={gender.error}
          helperText={gender.error}
          onChange={handleInputChange}
          onBlur={handleOnBlur}
        />

        <Box>
          <BirthdaySelector
            values={{
              birthYear: birthYear.value,
              birthMonth: birthMonth.value,
              birthDay: birthDay.value
            }}
            onChange={handleInputChange}
            onBlur={handleOnBlur}
          />
        </Box>
        <VanityUrl vanityUrlOnChangedHandler={vanityUrlOnChangedHandler} />
      </FormControl>
      <UpdateEmailDialog
        open={updateEmailIsOpen}
        onClose={onUpdateEmailDialogClosed}
        onEmailUpdated={onEmailUpdated}
        initialEmail={email.value}
        nickname={nickname.value}
      />
    </Box>
  );
};

export default AccountDetailsPersonalInformation;
