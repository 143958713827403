import React, { CSSProperties, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { replaceUnicodeChars } from "../../utils/stringUtils";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppDialog from "../common/dialog/AppDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { StoreInterface } from "../../models/store";
import useNav from "../../hooks/useNav";
import { STORE_PATH } from "../../constants/paths";
import { BACKGROUND } from "../../style";

const StoreHeaderStyles = makeStyles((theme: any) => ({
  root: {
    // display: "flex",
    // flexDirection: "column",
    paddingTop: "16px",
    marginLeft: "auto",
    marginRight: "auto",
    minWidth: "100%",
    textAlign: "center"
  },
  content: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    borderRadius: "12px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    background: BACKGROUND.WHITE,
    minWidth: "100%",
    textAlign: "center"
  },
  heading: {
    ...theme.typography.h5,
    fontWeight: 500,
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale"
  },
  subHeading: {
    ...theme.typography.subtitle1,
    fontWeight: "500",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale"
  },
  removeButtonStyle: {
    fontWeight: 500,
    fontSize: 20,
    textTransform: "none"
  },
  perItem: {
    cursor: "pointer",
    "&:hover": {
      background: "#F7F7F7"
    }
  },
  dialogContent: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
}));

interface IStoreHeaderProps {
  loadedStores: Array<StoreInterface>;
  onStoreChanged?: (id: string | number, storeName: string) => void;
  storeName?: string;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  moreStyle?: CSSProperties;
}

const StoreHeader = ({ loadedStores, onStoreChanged, storeName, style, innerStyle, buttonStyle, moreStyle }: IStoreHeaderProps) => {
  const { goTo } = useNav();
  const styles = StoreHeaderStyles();
  const [open, setOpen] = useState(false);

  const navigateToStore = (id, storeName) => {
    setOpen(false);

    if (onStoreChanged) {
      onStoreChanged(id, storeName);
    }

    goTo(`${STORE_PATH}/${id}/${replaceUnicodeChars(storeName)}`);
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.content}>
        <Button
          className={styles.removeButtonStyle}
          onClick={() => {
            setOpen(true);
          }}
          style={{ ...buttonStyle }}
        >
          {replaceUnicodeChars(storeName)} <ExpandMoreIcon style={{ ...moreStyle }}/>
        </Button>
      </Box>
      <AppDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth={false}
        title="Example"
        noHeaderLogo={true}
        isNotTransparent={true}
        dialogContentClassName={styles.dialogContent}
      >
        <List style={{ width: 300, zIndex: 1, paddingTop: 2 }}>
          {loadedStores
            .filter((store) => {
              return store.name !== storeName;
            })
            .map((store) => (
              <ListItem
                key={store.store_id}
                className={styles.perItem}
                onClick={() => navigateToStore(store.store_id, store.name)}
              >
                <ListItemText primary={replaceUnicodeChars(store.name)} />
              </ListItem>
            ))}
        </List>
      </AppDialog>
    </Box>
  );
};

export default StoreHeader;
