import React from "react";
import { FormControl, Box } from "@material-ui/core";
import TextInput from "../input/TextInput";

export default function BillingInformationEmailForm(props) {
  return (
    <Box display="flex">
      <FormControl fullWidth style={props.style}>
        <Box marginTop="24px">
          <TextInput
            id="emailAddress"
            name="billing_email"
            label="Email Address"
            type="email"
            autoComplete="email"
            width="80%"
            value={props.billing_email.value}
            error={props.billing_email.error}
            helperText={props.billing_email.error}
            onChange={props.handleInputChange}
            onFocus={props.handleOnFocus}
            onBlur={props.handleOnBlur}
          />
        </Box>
      </FormControl>
    </Box>
  );
}
