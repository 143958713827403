import React from "react";
import Caption from "../../common/caption/Caption";
import { SectionHeaderTitle } from "../ProfileComponents";

const PaymentMethodsHeader = () => {
  return (
    <>
      <SectionHeaderTitle text="Payment Methods" />
      <Caption style={{ marginTop: "16px", fontStyle: "normal", color: "inherit" }}>
        Your details are private and confidential.
        <br />
        Purchases show as ‘RealGifts’
      </Caption>
    </>
  );
};

export default PaymentMethodsHeader;
