import React, { useEffect, useState } from "react";
import AppDialog from "../common/dialog/AppDialog";
import LoginDialogContent from "./LoginDialogContent";

export default function LoginDialog(props: any) {
  const { open, onClose } = props;
  const [showRegistration, setShowRegistration] = useState(false);
  const returnTo = props.returnTo ? props.returnTo : undefined;

  useEffect(() => {
    if (props.registration) {
      setShowRegistration(props.registration);
    }

    if (typeof props.forceRegistration === "undefined") {
      if (localStorage.getItem("loggedInBefore") === "true") {
        console.log("Has logged in before, overriding registration to false");
        setShowRegistration(false);
      }
    } else {
      if (props.forceRegistration) {
        setShowRegistration(true);
      }
    }

    return () => setShowRegistration(false); // when unmount default back to sign in context
  }, [props.forceRegistration, props.registration]);

  return (
    <AppDialog open={open} onClose={onClose} fullWidth={false} title="Welcome to Gift.Me">
      <LoginDialogContent registration={showRegistration} returnTo={returnTo} />
    </AppDialog>
  );
}
