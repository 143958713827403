type ENVS = "local" | "test01" | "test02" | "test03" | "test04" | "testqa" | "staging" | "prod";
const REACT_APP_ENV = process.env.REACT_APP_ENV as ENVS;

export const isIncludeENVS = (envArray: ENVS[]) => {
  return envArray.includes(REACT_APP_ENV);
};

export const isTestENVSOnly = () => {
  return isIncludeENVS(["local", "test01", "test02", "test03", "testqa", "test04"]);
};

export const isLocalENVOnly = () => {
  return isIncludeENVS(["local"]);
};

export const isProdENVOnly = () => {
  return isIncludeENVS(["prod"]);
};

export const isTestQa = () => {
  return REACT_APP_ENV === "testqa";
};
