import React from "react";
import { Typography, Box, useMediaQuery, Theme } from "@material-ui/core";
import PrimaryButton from "../buttons/PrimaryButton";

interface CantFindProps {
  handleClick: () => void;
}

const CantFind: React.FC<CantFindProps> = ({ handleClick }: CantFindProps) => {
  const isDesktop = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.up("md"));
  const isMobile = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection="column"
      style={{
        position: "relative",
        height: "160px",
        marginTop: "90px"
      }}
    >
      <Box
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          textAlign: "center"
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "#272727",
            fontSize: isMobile ? 16 : 25,
            fontWeight: 600,
            lineHeight: "32px"
          }}
        >
          {"Can't find what you are looking for?"}
        </Typography>
        <PrimaryButton
          handleClick={handleClick}
          style={{
            width: 142,
            marginTop: isMobile ? 24 : 35
          }}
        >
          Contact Us
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default CantFind;
