import React from "react";
import { Box, FormControl, FormHelperText, Grid, Typography } from "@material-ui/core";
import TextInputV3 from "../../input/TextInputV3";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";
import USStateSelectorV2 from "../../input/USStateSelectorV2";
import { SectionHeaderTitle } from "../ProfileComponents";
import CountrySelectorV2 from "../../input/CountrySelectorV2";
import { textColor } from "../../../style";

interface AccountDetailsShippingAddressProps {
  firstname: any;
  lastname: any;
  address: any;
  country: any;
  city: any;
  state: any;
  zip: any;
  handleInputChange: (e: any) => void;
  handleOnFocus: (e: any) => void;
  handleOnBlur: (e: any) => void;
}
const AccountDetailsShippingAddress: React.FC<AccountDetailsShippingAddressProps> = (
  props: AccountDetailsShippingAddressProps
) => {
  const { firstname, lastname, address, country, city, state, zip, handleInputChange, handleOnFocus, handleOnBlur } =
    props;
  const { isMobile } = useMediaBreakpoints();

  return (
    <Box style={{ position: "relative", marginTop: isMobile ? "2rem" : "5rem" }}>
      <SectionHeaderTitle text="Shipping Address" />
      <Typography variant="caption" style={{ position: "absolute", top: 5, left: 200 }}>
        (Optional)
      </Typography>
      <Typography
        variant="caption"
        style={{
          color: textColor.LIGHT_GRAY3
        }}
      >
        This information is private and will never be shown to anyone buying you gifts.
      </Typography>
      <FormControl fullWidth>
        <Grid container spacing={4} style={{ marginTop: 16 }}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextInputV3
              id="firstname"
              name="firstname"
              autoComplete="firstname"
              label="First Name"
              style={{ width: "100%" }}
              value={firstname.value}
              error={firstname.error}
              helperText={firstname.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextInputV3
              id="lastname"
              name="lastname"
              autoComplete="lastname"
              label="Last Name"
              style={{ width: "100%" }}
              value={lastname.value}
              error={lastname.error}
              helperText={lastname.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CountrySelectorV2
              id="countrySelector"
              name="country"
              label="Country"
              autoComplete="shipping country"
              value={country.value}
              error={country.error}
              helperText={country.error}
              onChange={handleInputChange}
              onBlur={handleOnBlur}
              style={{ marginTop: 20, width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} style={{ marginTop: 0 }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextInputV3
              id="shippingaddress"
              name="address"
              label="Street Address"
              autoComplete="shipping street-address"
              style={{ width: "100%" }}
              value={address.value}
              error={address.error}
              helperText={address.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </Grid>
        </Grid>

        <Grid container spacing={isMobile ? 2 : 4} style={{ marginTop: 0 }}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            {country.value == "US" ? (
              <USStateSelectorV2
                id="stateSelector"
                name="state"
                label="State"
                autoComplete="shipping address-level1"
                value={state.value}
                error={state.error}
                helperText={state.error}
                onChange={handleInputChange}
                onBlur={handleOnBlur}
                style={{ width: "100%" }}
              />
            ) : (
              <TextInputV3
                id="state"
                name="state"
                label="State/Region"
                value={state.value}
                error={state.error}
                helperText={state.error}
                onChange={handleInputChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                style={{ width: "100%" }}
              />
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={8} xs={8}>
            <TextInputV3
              id="city"
              name="city"
              label="City"
              autoComplete="shipping address-level2"
              value={city.value}
              error={city.error}
              helperText={city.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextInputV3
              id="ziporpostalcode"
              name="zip"
              label={isMobile ? "Zip" : "Zip/Postal Code"}
              autoComplete="shipping postal-code"
              value={zip.value}
              error={zip.error}
              inputProps={{ maxLength: 10 }}
              helperText={zip.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  );
};

export default AccountDetailsShippingAddress;
