import React, { useState, useEffect } from "react";
import { withStyles, Tooltip, useMediaQuery } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { Typography, ClickAwayListener } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    display: "show",
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: 220,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const CardCVVTooltip: React.FC = () => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.up("xs"));
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const handleOnClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleOnClickAway}>
      <HtmlTooltip
        PopperProps={{
          disablePortal: true
        }}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <React.Fragment>
            <Typography color="inherit">
              The security code has 3 digits and is located at the back of your credit card. For American
              Express cards, the security code has 4 digits and is located at the front.
            </Typography>
          </React.Fragment>
        }
      >
        <InfoOutlined
          style={{
            position: "absolute",
            right: -28,
            cursor: "pointer"
          }}
          onClick={handleTooltipOpen}
        />
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

export default CardCVVTooltip;
