import React, { Fragment, useContext } from "react";
import { styled, Link } from "@material-ui/core";
import classNames from "classnames";
import { backgroundTheme, slidebarTheme, textColor } from "../../../../style";
import { getBrowserDetails } from "../../../../utils/helpers";
import { disableBodyScroll } from "body-scroll-lock";
import useNav from "../../../../hooks/useNav";
import { UserContext } from "../../../../context";
import { useAppDispatch } from "../../../../hooks";
import { logoutAction } from "../../../../actions/authAction";
import { logout as logoutUser } from "../../../../reducers/user/AuthSlice";
import { clearLocalStorageWhenLogout } from "../../../../utils/localStorage";

const SidebarFooter = styled("div")({
  padding: "40px 10px 40px 10px",
  minHeight: 40
});

const StyledSlidebar = styled("div")({
  "ul li:hover": {
    backgroundColor: slidebarTheme.listHover
  }
});

interface MultilevelSidebarProps {
  open?: boolean;
  onToggle?: () => void;
  options?: any[];
  header?: any;
  persis?: boolean;
  wrapperClassName?: string;
  headerClassName?: string;
  onClose: () => void;
  onItemClick: (item: any) => void;
  width: number;
}

const MultilevelSidebar = (props: MultilevelSidebarProps) => {
  const { header, headerClassName, open, options, width, wrapperClassName } = props;

  const initiateTabs = () => {
    disableBodyScroll(document.getElementById("sidebar-parent"));
    document.getElementById("sidebar-parent").style.overflowY = "scroll";
  };

  const handleTabClick = (tabData: any) => {
    if (tabData.disabled) {
      return;
    }

    if (tabData.link) {
      initiateTabs();
    }

    const { onItemClick } = props;
    onItemClick(tabData);
  };

  return (
    <div id="react-sidebar" className="slidebar">
      <SidebarContent
        {...props}
        handleTabClick={handleTabClick}
        headerContent={
          typeof header === "string" ? (
            <div
              className={`sidebar-header ${classNames({
                [headerClassName]: headerClassName
              })}`}
            >
              {header}
            </div>
          ) : (
            <div
              className={classNames({
                [headerClassName]: headerClassName
              })}
            >
              {header}
            </div>
          )
        }
        sidebarProps={{
          id: "sidebar-parent",
          className: classNames("sidebar-main", {
            show: open,
            [wrapperClassName]: wrapperClassName
          }),
          style: {
            marginTop: 15,
            width: width,
            height: getBrowserDetails().isMobile && getBrowserDetails().isIos ? "85vh" : "90vh",
            paddingBottom: getBrowserDetails().isMobile && getBrowserDetails().isIos ? "40px" : "initial",
            zIndex: 1297
          }
        }}
        options={options}
      />
    </div>
  );
};

export default MultilevelSidebar;

interface ISidebarContent {
  handleTabClick?: (item?: any) => void;
  headerContent?: any;
  sidebarProps?: any;
  options?: any[];
  parentMenuItems?: any[];
}

const SidebarContent = (props: ISidebarContent) => {
  const { handleTabClick, headerContent, sidebarProps, options } = props;
  const context = useContext(UserContext);
  const dispatch = useAppDispatch();
  const { goTo } = useNav();

  const parentMenu = (id) => {
    return identifyParent(id) ? { fontSize: 15 } : {};
  };

  // Creates a method to determine which menu links are parent ones
  const identifyParent = (id) => {
    const { parentMenuItems } = props;
    for (let i = 0; i < parentMenuItems.length; i++) {
      if (id === parentMenuItems[i]) {
        return true;
      }
    }

    return false;
  };

  const logout = async () => {
    try {
      const response = await logoutAction();
      if (!response.data.authenticated) {
        context.user.set({
          email: null,
          nickname: null,
          gender: null,
          credits: 0
        });
        dispatch(logoutUser());
        clearLocalStorageWhenLogout();
        document.location.href = "/";
      }
    } catch (error) {
      console.error("error: " + error);
    }
  };

  const handleRedirectTo = (link: string) => {
    // use logout method same with Desktop
    if (link === "/profile/logout") {
      logout();
    } else {
      goTo(link);
    }
  };

  const MenuLink = ({ children, list }) => {
    if (list.fullUrl) {
      return (
        <Link href={list.to} target="_blank" rel="noopener" underline="none">
          {children}
        </Link>
      );
    } else {
      return (
        <Link
          /* eslint-disable-next-line */
          onClick={() => handleRedirectTo(list.to)}
          underline="none"
        >
          {children}
        </Link>
      );
    }
  };

  return (
    <StyledSlidebar {...sidebarProps}>
      <div className="sidebar-main-content">
        {headerContent}
        <div className="sidebar-body">
          {/* eslint-disable-next-line react/prop-types */}
          {options.map((data, id) => {
            return (
              <Fragment key={id}>
                {!(!!data.hideBorder || id === 0) && <hr className="section-separator" />}
                {data.title && (
                  <div className="section-heading" style={{ backgroundColor: backgroundTheme.grayFill }}>
                    {data.titleIcon ? data.titleIcon : ""}
                    <span className={classNames({ text: data.titleIcon })} style={{ color: textColor.NORMAL }}>
                      {data.title}
                    </span>
                  </div>
                )}
                <ul>
                  {data.content.length > 0 ? (
                    data.content.map((list, index) => {
                      {
                        if (!list.hidden) {
                          return (
                            <Fragment key={index}>
                              {list.to && !list.disabled ? (
                                <MenuLink list={list}>
                                  <li
                                    id={identifyParent(list.id) ? `${list.name}-Section` : `${list.name}`}
                                    className={classNames({
                                      disabled: list.disabled
                                    })}
                                    onClick={() => handleTabClick(list)}
                                  >
                                    {list.name !== "" && (
                                      <span className="flex-align-center">
                                        <span style={parentMenu(list.id)}>{list.name}</span>
                                      </span>
                                    )}
                                    {list.icon}
                                  </li>
                                </MenuLink>
                              ) : (
                                <li
                                  id={identifyParent(list.id) ? `${list.name}-Section` : `${list.name}`}
                                  className={classNames({ disabled: list.disabled })}
                                  onClick={() => handleTabClick(list)}
                                >
                                  {list.name !== "" && (
                                    <span className="flex-align-center">
                                      <span style={parentMenu(list.id)}>{list.name}</span>
                                    </span>
                                  )}
                                  {list.icon}
                                </li>
                              )}
                            </Fragment>
                          );
                        } else {
                          return <div key={index}></div>;
                        }
                      }
                    })
                  ) : (
                    <div style={{ padding: "1.3rem 1.5rem 1.3rem 1.5rem" }} />
                  )}
                </ul>
              </Fragment>
            );
          })}
        </div>
      </div>
      <SidebarFooter />
    </StyledSlidebar>
  );
};

// MultilevelSidebar.defaultProps = {
//   persist: false
// };
