import React, { ReactElement } from "react";
import { useSpring, animated } from "react-spring";

interface FadeInProps {
  direction?: "top" | "bottom" | "left" | "right";
  children: ReactElement;
  delay?: number;
}

export default function FadeIn({ children, direction, delay = 0 }: FadeInProps): ReactElement {
  let origin = "";
  let result = "";
  switch (direction) {
    case "top":
      origin = "translateY(100px)";
      result = "translateY(0px)";
      break;
    case "bottom":
      origin = "translateY(-100px)";
      result = "translateY(0px)";
      break;
    case "right":
      origin = "translateX(100px)";
      result = "translateX(0px)";
      break;
    case "left":
      origin = "translateX(-100px)";
      result = "translateX(0px)";
      break;
  }

  const fade = useSpring({
    delay,
    from: { transform: origin, opacity: 0 },
    transform: result,
    opacity: 1,
    width: "100%"
  });

  return <animated.div style={fade}>{children}</animated.div>;
}
