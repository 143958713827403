import * as React from "react";
import { Collapse, List, ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { replaceUnicodeChars } from "../../utils/stringUtils";
import useNav from "../../hooks/useNav";

const NestedListHeader = ({ styles, title, items }) => {
  const { goTo } = useNav();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const navigateToStore = (to) => {
    setOpen(false);
    goTo(to);
  };

  return (
    <>
      <ListItem onClick={handleClick}>
        <ListItemText
          classes={{
            primary: styles
          }}
          primary={replaceUnicodeChars(title)}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {items.map((content, idx) => {
        return (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem key={idx} sx={{ pl: 4 }} onClick={() => navigateToStore(content.to)}>
                <ListItemText primary={replaceUnicodeChars(content.name)} />
              </ListItem>
            </List>
          </Collapse>
        );
      })}
    </>
  );
};

export default NestedListHeader;
