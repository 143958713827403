import React, { Fragment } from "react";
import { Typography, Divider, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const PaymentMethodsAddRow = ({ onAddPaymentMethodClick }: { onAddPaymentMethodClick: () => void }) => {
  return (
    <Box
      style={{
        marginTop: "1rem"
      }}
    >
      <Typography variant="body1" onClick={onAddPaymentMethodClick} style={{ cursor: "pointer" }}>
        <AddIcon
          style={{
            position: "relative",
            top: "6px",
            fontWeight: "bold"
          }}
        />{" "}
        <Typography component="span" style={{ fontWeight: "bold" }}>
          Add a payment method
        </Typography>
      </Typography>
      <Divider
        style={{
          marginTop: "1.8rem"
        }}
      />
    </Box>
  );
};

export default PaymentMethodsAddRow;
