import React, { CSSProperties } from "react";
import { Typography, Box, BoxProps, StyledProps } from "@material-ui/core";

interface ISectionLabelProps extends BoxProps {
  mainTitle?: string;
  title?: string;
  description?: string;
  titleStyle?: CSSProperties;
  lists?: string[];
}

const SectionLabel = ({
  mainTitle,
  title,
  description,
  lists,
  titleStyle,
  ...rest
}: ISectionLabelProps): ReturnType<React.FC> => {
  return (
    <Box {...rest}>
      {mainTitle && (
        <Typography variant="h1" style={{ marginBottom: 35, marginTop: 10 }}>
          {mainTitle}
        </Typography>
      )}
      <Typography variant="h2" style={{ fontWeight: "bold", marginBottom: 10, ...titleStyle }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
        {description}
      </Typography>
      {lists &&
        lists.map((item, i) => {
          return (
            <Typography variant="subtitle2" style={{ fontWeight: 500 }} key={i}>
              {item}
            </Typography>
          );
        })}
    </Box>
  );
};

export default SectionLabel;
