export const STOREHOME_PATH = "/store/storehome";
export const ERROR_PATH = "/error";
export const HOW_IT_WORKS_PATH = "/how-it-works";
export const MY_ACCOUNT_PATH = "/profile/account";
export const PROFILE_PERSONAL_PATH = "/profile/personal";
export const PROFILE_MY_GIFTS_PATH = "/profile/my-gifts";
export const PROFILE_ORDER_HISTORY_PATH = "/profile/order-history";
export const PROFILE_ACCOUNT_SETTINGS_PATH = "/profile/account-settings";
export const PROFILE_PAYMENT_METHODS = "/profile/payment-methods";
export const PROFILE_ACCOUNT_SYNC_PATH = "/profile/account-sync";
export const HOME_PATH = "/";
export const DEMO_PATH = "/demo";
export const STORE_PATH = "/store/store";
export const CONTACT_US_PATH = "/contact-us";
export const STORE_GIFT_PATH = "/store/gift";
export const WISHLIST_EDIT_PROFILE_PATH = "/wishlist/edit-profile";
export const WISHLIST_PATH = "/gift/wishlist";
export const FAQ_PATH = "/faq";
