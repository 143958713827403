import React from "react";
import { FormControl, Box, useMediaQuery, Theme } from "@material-ui/core";
import TextInput from "../input/TextInput";
import ZipCodeInput from "../input/ZipCodeInput";
import USStateSelector from "../input/USStateSelector";
import CountrySelector from "../input/CountrySelector";
import { makeStyles } from "@material-ui/core/";
import cx from "classnames";
import CustomSelect from "components/input/CustomSelect";

const componentStyles = makeStyles((theme) => ({
  root: {},
  formRowContainer: {
    display: "flex",
    marginTop: 24
  },
  inputField: {
    width: "100%"
  },
  inputField1stRow: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 12
    }
  },
  inputField2ndRow: {
    width: "70%"
  }
}));
export default function BillingInformationForm(props) {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const styles = componentStyles();

  const handleCityChanged = (event) => {
    props.populateCityAndState(event.target.value);
  };

  return (
    <Box display="flex">
      <FormControl fullWidth style={props.style}>
        <Box marginTop="24px">
          <TextInput
            id="billingAddress"
            name="billing_address"
            label="Billing Address"
            autoComplete="billing street-address"
            value={props.billing_address.value}
            error={props.billing_address.error}
            helperText={props.billing_address.error}
            onChange={props.handleInputChange}
            onFocus={props.handleOnFocus}
            onBlur={props.handleOnBlur}
            fullWidth={true}
            disabled={props.disabled}
          />
        </Box>
        <Box marginTop="24px" justifyContent="space-between" className={styles.formRowContainer}>
          <CountrySelector
            id="countrySelector"
            name="billing_country"
            label="Country"
            autoComplete="billing country"
            value={props.billing_country.value}
            error={props.billing_country.error}
            helperText={props.billing_country.error}
            onChange={props.handleInputChange}
            disabled={props.disabled}
            className={cx(styles.inputField1stRow, styles.inputField)}
          />
          {props.billing_country.value === "US" ? (
            <USStateSelector
              id="state"
              name="billing_state"
              label="State"
              autoComplete="billing address-level1"
              className={cx(styles.inputField, styles.inputField2ndRow)}
              value={props.billing_state.value}
              error={props.billing_state.error}
              helperText={props.billing_state.error}
              onChange={props.handleInputChange}
              disabled={props.disabled}
            />
          ) : (
            <TextInput
              id="province"
              name="billing_province"
              label="State/Province"
              autoComplete="billing address-level2"
              className={cx(styles.inputField, styles.inputField2ndRow)}
              value={props.billing_province.value}
              error={props.billing_province.error}
              helperText={props.billing_province.error}
              onChange={props.handleInputChange}
              onFocus={props.handleOnFocus}
              onBlur={props.handleOnBlur}
              disabled={props.disabled}
            />
          )}
        </Box>
        <Box display="flex" flexGrow={1} justifyContent="space-between" className={styles.formRowContainer}>
          {props.addresses.length > 0 ? (
            <CustomSelect
              options={props.addresses}
              id="city-select"
              name="billing_city"
              label="City"
              autoComplete="billing address-level1"
              value={props.billing_city.value}
              error={props.billing_city.error}
              helperText={props.billing_city.error}
              onChange={handleCityChanged}
              className={cx(styles.inputField1stRow, styles.inputField)}
            />
          ) : (
            <TextInput
              id="city"
              name="billing_city"
              label="City"
              autoComplete="billing address-level2"
              value={props.billing_city.value}
              error={props.billing_city.error}
              helperText={props.billing_city.error}
              onChange={props.handleInputChange}
              onFocus={props.handleOnFocus}
              onBlur={props.handleOnBlur}
              disabled={props.disabled}
              className={cx(styles.inputField1stRow, styles.inputField)}
            />
          )}
          {!isDesktop && (
            <ZipCodeInput
              id="zipCode"
              name="billing_zip"
              label={props.billing_country.value === "US" ? "Zip" : "Postal Code"}
              autoComplete="billing postal-code"
              className={cx(styles.inputField2ndRow, styles.inputField)}
              style={{ width: "70%" }}
              value={props.billing_zip.value}
              error={props.billing_zip.error}
              helperText={props.billing_zip.error}
              onChange={props.handleInputChange}
              onFocus={props.handleOnFocus}
              onBlur={props.handleOnBlur}
              disabled={props.disabled}
            />
          )} 
          {isDesktop && (
            <ZipCodeInput
              id="zipCode"
              name="billing_zip"
              label={props.billing_country.value === "US" ? "Zip" : "Postal Code"}
              autoComplete="billing postal-code"
              className={styles.inputField}
              style={{ width: "70%" }}
              value={props.billing_zip.value}
              error={props.billing_zip.error}
              helperText={props.billing_zip.error}
              onChange={props.handleInputChange}
              onFocus={props.handleOnFocus}
              onBlur={props.handleOnBlur}
              disabled={props.disabled}
            />
          )}
        </Box>
        <Box marginTop="24px">
          <TextInput
            id="emailAddress"
            name="billing_email"
            label="Email Address"
            type="email"
            autoComplete="email"
            width="100%"
            value={props.billing_email.value}
            error={props.billing_email.error}
            helperText={props.billing_email.error}
            onChange={props.handleInputChange}
            onFocus={props.handleOnFocus}
            onBlur={props.handleOnBlur}
            disabled={props.disabled}
          />
        </Box>
      </FormControl>
    </Box>
  );
}
