import React from "react";
import { Box } from "@material-ui/core";
import { GiftInterface } from "../../../models/gift";
import SecondaryButton from "../../buttons/SecondaryButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import { makeStyles } from "@material-ui/core/styles";

const ComponentStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 37,
    paddingBottom: 16
  },
  ButtonsContainerPerColumn: {
    display: "flex",
    flexBasis: "50%",
    alignItems: "center",
    justifyContent: "center"
  }
}));

interface GiftModalMobileActionButtonsProps {
  addGiftToWishlist: (e) => Promise<void>;
  removeGiftFromWishlist: (e) => Promise<void>;
  handleBuyGift: (e) => Promise<void>;
  gift: GiftInterface;
}

const GiftModalMobileActionButtons: React.FC<GiftModalMobileActionButtonsProps> = ({
  addGiftToWishlist,
  removeGiftFromWishlist,
  handleBuyGift,
  gift
}: GiftModalMobileActionButtonsProps) => {
  const styles = ComponentStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.ButtonsContainerPerColumn}>
        <SecondaryButton
          text={gift.is_in_wishlist ? "♡ Added to wishlist" : "♡ Add to wishlist"}
          style={{ color: "#E92983", fontSize: "12px", margin: "auto" }}
          handleClick={gift.is_in_wishlist ? removeGiftFromWishlist : addGiftToWishlist}
        />
      </Box>
      <Box className={styles.ButtonsContainerPerColumn}>
        <PrimaryButton style={{ color: "#FFFFFF", fontSize: "12px", margin: "auto" }} handleClick={handleBuyGift}>
          Buy now
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default GiftModalMobileActionButtons;
