import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableContainerProps
} from "@material-ui/core";

interface AppTableProps extends TableContainerProps {
  rows: any[];
  valueAlign?: "center" | "inherit" | "justify" | "left" | "right";
  classes?: any;
}

export const AppTable = (props: AppTableProps): ReturnType<FC> => {
  const { rows, classes, style } = props;
  const valueAlign = props.valueAlign ? props.valueAlign : "right";

  return (
    <TableContainer style={style}>
      <Table aria-label="customized table">
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell
                component="th"
                scope="row"
                className={row.descriptionStyle ? row.descriptionStyle : classes.tableCell}
              >
                <Typography component="span" variant="body1">
                  {row.description}
                </Typography>
              </TableCell>
              <TableCell align={valueAlign} className={row.valueStyle ? row.valueStyle : classes.tableCell}>
                <Typography component="span" variant="body1" className={classes.tableCellValueStyle}>
                  {row.value}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export function createRow(
  description: string,
  value: string,
  descriptionStyle: any = undefined,
  valueStyle: any = descriptionStyle
) {
  return { description, value, descriptionStyle, valueStyle };
}

export default AppTable;
