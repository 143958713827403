import { getAuthenticatedUserId, getSelectedRecipient } from "../../../utils/localStorage";

export const filterRecipientList = (recipients) => {
  if (!recipients || typeof recipients === "undefined") {
    return [];
  }

  const authenticatedUserId = getAuthenticatedUserId();
  const filteredRecipients = filterRecipientListByAuthenticatedUser(recipients, authenticatedUserId);

  return filteredRecipients.filter((recipient) => {
    return (
      (recipient.nickname !== null && recipient.giftCount > 0 && recipient.userId !== null) ||
      recipient.userId === authenticatedUserId.userId
    );
  });
};

export const filterRecipientListByAuthenticatedUser = (recipients, authenticatedUserId) => {
  if (!recipients || !recipients.length || !authenticatedUserId) {
    return [];
  }

  const selectedRecipient = getSelectedRecipient();
  const authenticatedUser = recipients.find((recipient) => recipient.userId === authenticatedUserId.userId);
  const filterByAuthenticatedUser = recipients.filter((recipient) => recipient.userId !== authenticatedUserId.userId);

  let newRecipients = [...filterByAuthenticatedUser];
  if (selectedRecipient.userId !== authenticatedUser.userId) {
    const { giftCount, userId } = authenticatedUser;
    newRecipients.unshift({
      nickname: "Me",
      userId,
      giftCount
    });
  }

  return newRecipients;
};

export default filterRecipientList;
