import React, { useState } from "react";
import { Box, Typography, InputLabel, MenuItem, useMediaQuery, Theme, Button, Menu } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

interface GiftSortSelectProps {
  sortBy: string;
  setSortBy: (sortBy: string) => void;
}

const GiftSortSelect: React.FC<GiftSortSelectProps> = (props: GiftSortSelectProps) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const { sortBy, setSortBy } = props;
  const [sortLabel, setSortLabel] = useState<string>("Popular");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (sortBy: string, sortLabel: string) => {
    setSortBy(sortBy);
    setSortLabel(sortLabel);
    handleClose();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        fontSize: 16
      }}
    >
      <InputLabel
        id="sortBySelectLabel"
        style={{
          fontSize: 16,
          whiteSpace: isDesktop ? "initial" : "nowrap",
          letterSpacing: isDesktop ? 0 : "-0.9px"
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 400, fontSize: 16 }}>
          SORT BY:
        </Typography>
      </InputLabel>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ fontSize: 16, textTransform: "uppercase" }}
      >
        <Typography variant="h6" style={{ fontWeight: 700, fontSize: 16 }}>
          {sortLabel}
        </Typography>
        {open && <ExpandLess style={{ marginTop: 2 }} />}
        {!open && <ExpandMoreIcon style={{ marginTop: -2 }} />}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          value="popular"
          onClick={() => {
            handleChange("popular", "POPULAR");
          }}
        >
          POPULAR
        </MenuItem>
        <MenuItem
          value="newest"
          onClick={() => {
            handleChange("newest", "NEWEST");
          }}
        >
          NEWEST
        </MenuItem>
        <MenuItem
          value="price_asc"
          onClick={() => {
            handleChange("price_asc", "LOW TO HIGH");
          }}
        >
          $ - LOW TO HIGH
        </MenuItem>
        <MenuItem
          value="price_desc"
          onClick={() => {
            handleChange("price_desc", "HIGH TO LOW");
          }}
        >
          $ - HIGH TO LOW
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default GiftSortSelect;
