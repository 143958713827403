import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { styled } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FONT_FAMILIES, textColor } from "../../style";
import { makeStyles } from "@material-ui/core/styles";

export const genders = [
  {
    value: "M",
    label: "Male"
  },
  {
    value: "F",
    label: "Female"
  },
  {
    value: "O",
    label: "Others"
  }
];

const componentStyles = makeStyles(() => ({
  label: {
    position: "relative",
    color: textColor.DARK1,
    fontSize: 18,
    fontWeight: 500,
    transform: "translate(0, 0) scale(1)",
    fontFamily: FONT_FAMILIES.SOFIA_PRO
  }
}));
interface GenderSelectorProps {
  id?: string;
  value: string;
  label?: string;
  name?: string;
  helperText?: string;
  autoComplete?: string;
  style?: React.CSSProperties;
  error?: boolean;
  onChange: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
}
const GenderSelector: React.FC<GenderSelectorProps> = (props: GenderSelectorProps) => {
  const { value, name, label, helperText, style, error, onChange, onFocus, onBlur } = props;
  const providedGender = value ? value.toUpperCase() : "";
  const styles = componentStyles();

  const selectedGender = providedGender
    ? genders.find((gender) => gender.value === providedGender || gender.label === providedGender)
    : undefined;

  return (
    <FormControl component="fieldset" style={style} error={error}>
      <FormLabel className={styles.label}>{label || "Gender"}</FormLabel>
      <RadioGroup
        row
        aria-label="gender"
        name={name || "gender"}
        value={selectedGender ? selectedGender.value : ""}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {genders.map((gender) => (
          <FormControlLabel key={gender.value} value={gender.value} control={<Radio />} label={gender.label} />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default GenderSelector;
