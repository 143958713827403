import React from "react";
import TextInput from "../input/TextInput";
import PrimaryButton from "../buttons/PrimaryButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 16,
    paddingBottom: "20px"
  },
  button: {
    width: "32%"
  },
  textField: {
    paddingLeft: "14px"
  }
}));

export default function ContactUsForm(props: any) {
  const classes = useStyles();

  return (
    <div>
      <form
        id="formContactUs"
        name="fa-form-1"
        onSubmit={(e) => props.handleOnSubmit(e)}
        action="https://webhook.frontapp.com/forms/7eec7b5f0698487698de/4gwBU06aIEPra3AlRNzwrBsTxyR1-c7W9QKO3esR6dpCL9PEKOjHwkWSoZatRh6BQe_lMknQYsKDzeKluaR6i4bBuwtZ0rb628zYPDrfLMoxZIo1999WfDE-0C7K-w"
        method="POST"
        encType="multipart/form-data"
        acceptCharset="utf-8"
      >
        <TextInput
          id="name"
          name="name"
          label="Your Name"
          marginTop="6px"
          width="100%"
          className={classes.input}
          value={props.name.value}
          error={props.name.error}
          helperText={props.name.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
          maxLength={80}
        />
        <TextInput
          id="email"
          name="email"
          label="Email"
          width="100%"
          className={classes.input}
          value={props.email.value}
          error={props.email.error}
          helperText={props.email.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
          maxLength={80}
        />
        <TextInput
          id="body"
          name="body"
          label="Message"
          multiline={true}
          rows={"3"}
          width="100%"
          className={classes.input}
          value={props.body.value}
          error={props.body.error}
          helperText={props.body.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
        />
        <PrimaryButton type={"submit"} className={classes.button}>
          Submit
        </PrimaryButton>
      </form>
    </div>
  );
}
