import React from "react";
import { Typography, Link, LinkProps } from "@material-ui/core";

interface PrimaryLinkProps extends LinkProps {
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  fontSize?: string;
  fontWeight?: any;
  fontFamily?: string;
  text?: any;
  className?: string;
  handleClick?: () => void;
  underline?: any;
  textColor?: string;
  href?: string;
  align?: "left" | "right" | "center";
}

const PrimaryLink = ({
  align,
  marginTop,
  marginBottom,
  marginLeft,
  fontSize,
  fontWeight,
  fontFamily,
  text,
  handleClick,
  className,
  underline,
  textColor,
  href
}: PrimaryLinkProps) => {
  return (
    <Typography
      align={align}
      style={{
        marginTop: marginTop ? marginTop : undefined,
        marginBottom: marginBottom ? marginBottom : undefined,
        marginLeft: marginLeft ? marginLeft : undefined,
        fontSize: fontSize ? fontSize : undefined,
        fontWeight: fontWeight ? fontWeight : undefined,
        fontFamily: fontFamily ? fontFamily : undefined
      }}
    >
      <Link
        className={className ? className : ""}
        onClick={handleClick}
        color="inherit"
        underline={underline ? underline : "always"}
        style={{ color: textColor ? textColor : undefined }}
        href={href ? href : undefined}
      >
        {text}
      </Link>
    </Typography>
  );
};

export default PrimaryLink;
