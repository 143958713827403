import React from "react";
import { useMediaQuery, Theme, makeStyles, ButtonProps } from "@material-ui/core";
import PrimaryButton from "../../buttons/PrimaryButton";
import { COLORS } from "../../../style";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 138,
    height: 45,
    fontFamily: "Sofia Pro",
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "22.4px",
    borderRadius: 8,
    background: COLORS.MEDIUM_PINK,
    [theme.breakpoints.down("md")]: {
      width: 150
    }
  }
}));

interface PrimaryButtonV2Props extends ButtonProps {
  label: string;
  onClick?: ButtonProps["onClick"];
}

const PrimaryButtonV2 = ({ label, onClick, ...rest }: PrimaryButtonV2Props) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <PrimaryButton handleClick={onClick} className={classes.button} {...rest}>
      {label}
    </PrimaryButton>
  );
};

export default PrimaryButtonV2;
