import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const usesStyles = makeStyles((theme) => ({
  buttonStyle: {
    width: "90%",
    height: "46px",
    borderRadius: "23px",
    color: "#000000",
    backgroundColor: "#ffffff",
    border: "#f0017c 2px solid",
    textTransform: "none"
  }
}));

export default function SecondaryButton(props: any) {
  const classes = usesStyles();

  // Merging default style with styles provided by the parent
  const className = props.className ? `${classes.buttonStyle} ${props.className}` : classes.buttonStyle;

  return (
    <Button
      variant="outlined"
      className={className}
      onClick={props.handleClick}
      style={{
        width: props.fullWidth ? "90%" : props.width ? props.width : undefined,
        marginLeft: props.fullWidth ? "5%" : undefined,
        height: props.height,
        position: props.position,
        bottom: props.bottom,
        ...props.style
      }}
    >
      {props.text}
    </Button>
  );
}
