import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { useAppSelector } from "../../hooks";
import WishlistContent from "./WishlistContent";
import { useParams } from "react-router-dom";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "../../theme/theme-devins";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";

const OVERIDE_CUSTOM_VANITY = [
  {
    vanity: "/sophiedee",
    userId: "aee5dce261d2db0dba0b35bd8e9e00fc69ab7ebc9aac7b719d5c4037e648040f66c518870171d1a845572cde1ab12104"
  },
  {
    vanity: "/mona",
    userId: "6da6378874d90629b254b12088ce33505ebd46059dbb434fbf64f78376be3889a2dfa0fc804024436e856c8dbc1e49ad"
  },
  {
    vanity: "/lupefuentes",
    userId: "bfc505860fc8a60ecf14adc50076684cf51c3a677d81b929ba3e231ba3db921d0be156cc7e78472962a2446467712d3d"
  },
  {
    vanity: "/chantal",
    userId: "ad78445de6bc483defefe9a8440007e2386661fd980b7bd4ee50426489a122ad459fd03d9cd49e6bb75a5d36863ff3af"
  },
  {
    vanity: "/milanaricci",
    userId: "26858bcaf9ce1333ea345e9ae23c66a56b2ecc36d61125c7e8e12f6ff2a278ca9c3ff673e17a9f9f21cf81d37d3767c4"
  }
];

const Wishlist = (): ReturnType<React.FC> => {
  const { vanity, userId } = useParams();
  const { isDesktop } = useMediaBreakpoints();

  const receiverUserIdParam =
    OVERIDE_CUSTOM_VANITY.find(({ vanity }) => vanity === window.location.pathname.toLowerCase())?.userId ?? userId;

  const { encryptedUserId } = useAppSelector((state) => state.user.data);

  const isCurrentLoggedIn = useMemo(
    () => (!vanity && encryptedUserId === receiverUserIdParam) ?? false,
    [encryptedUserId, receiverUserIdParam, vanity]
  );

  return (
    <AppLayout theme={theme} headerVariant="header1" style={{ marginTop: isDesktop ? "unset" : -112 }}>
      <Box style={{ marginBottom: 100, minHeight: 500, marginTop: 50 }}>
        <WishlistContent
          wishlistType={isCurrentLoggedIn ? "userId" : vanity ? "vanity" : "userId"}
          wishlistValue={isCurrentLoggedIn ? receiverUserIdParam : vanity ? vanity : receiverUserIdParam}
        />
      </Box>
    </AppLayout>
  );
};

export default Wishlist;
