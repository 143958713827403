import React, { useState } from "react";
import { Box, CircularProgress, FormControl, Link, makeStyles, Typography } from "@material-ui/core/";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import TextInput from "../../input/TextInput";
import Caption from "../../common/caption/Caption";
import Form, { FormInput } from "../../input/Form";
import PrimaryButton from "../../buttons/PrimaryButton";
import Toast from "light-toast";
import RollbarTracker from "../../../utils/RollbarTracker";
import { signInOrSignUpAction } from "../../../actions/authAction";
import { isLocalENVOnly, isTestQa } from "utils/appEnv";
import { setSignUpEvent } from "utils/localStorage";
import { updateEmailAction } from "actions/userAction";

const CreateAccountWithPasswordStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "24px"
  },
  createAccountButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  },
  skipTypography: {
    marginTop: "40px",
    textAlign: "center"
  },
  skipLink: {
    color: theme.palette.grey["A400"]
  },
  subtitle: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(8),
    fontSize: 14,
    textAlign: "center"
  },
  textField: {
    fontSize: 16,
    padding: "14px"
  },
  emailButton: {
    width: "100%",
    height: "46px",
    borderRadius: "23px",
    padding: 0,
    background: "linear-gradient(135deg, #eb3693, #f0017c)",
    marginTop: theme.typography.pxToRem(12)
  },
  orderCircularProgress: {
    color: "#fff",
    marginRight: "8px"
  }
}));

export const initialFormInputs = {
  otp: FormInput(),
  password: FormInput()
};

export function CreateAccountWithPassword(props) {
  const styles = CreateAccountWithPasswordStyles();
  const { senderUserId } = props;

  const [emailAuthData, setEmailAuthData] = useState<any>({});
  const [isVerifyingOtp, setIsVerifyingOtp] = useState<boolean>(false);
  const [showOtp, setShowOtp] = useState(false);
  const toastMessageDuration = isTestQa() ? 7000 : 5000;

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (const inputName in inputs) {
      const formInput = inputs[inputName];
      if ("otp" === inputName && showOtp) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      }

      if ("password" === inputName && !showOtp) {
        formInput.error =
          formInput.value.length !== 0 ? (formInput.value.length <= 3 ? "Password too short." : "") : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const verifyEmailSignUpOtp = async () => {
    if (!validateForm()) {
      console.log("Form is invalid");
      return;
    }

    setIsVerifyingOtp(true);

    interface UserPayloadObject {
      nickname: string;
      email: string;
      code: string;
    }

    const payload: UserPayloadObject = {
      nickname: "",
      email: props.email.toString().toLowerCase(),
      code: formInputs.otp.value
    };

    try {
      const response = await updateEmailAction(payload);
      setIsVerifyingOtp(false);
      if (!response.data.success) {
        Toast.fail(
          response.data.message ? response.data.message : "An error has occurred. Please contact support",
          3000,
          () => {
            Toast.hide();
          }
        );
        return;
      }

      if (emailAuthData.redirectTo) {
        // Sends GA join event on Signup
        setSignUpEvent("email and password");

        // Redirects when user exists
        console.log(
          "password authentication, redirecting to: " + "https://" + window.location.host + emailAuthData.redirectTo
        );

        const redirectUrl = `${isLocalENVOnly() ? "http" : "https"}://${window.location.host}${
          emailAuthData.redirectTo
        }`;
        const queryParams = emailAuthData.newUser ? "?userFirstTimeLogin=1" : "";

        window.location.href = `${redirectUrl}${queryParams}`;
        localStorage.setItem("loggedInBefore", "true");

        // Set userFirstTimeLogin to check if user need to input handle/nickname
        if (emailAuthData.newUser) {
          localStorage.setItem("userFirstTimeLogin", "1");
        }

        // dispatch(setUserLocalDbData(response.data.localDbData));
        // dispatch(setUserData(response.data.userData));

        Toast.success("Verified", 2000);
        setIsVerifyingOtp(false);
      }
    } catch (error) {
      setIsVerifyingOtp(false);
      if (error.response?.data && error.response?.data?.reset) {
        setEmailAuthData({});
        setShowOtp(false);
        formInputs.otp.value = "";
      }

      Toast.fail(
        error.response?.data?.message ? error.response.data.message : "An error has occurred. Please contact support",
        3000,
        () => {
          Toast.hide();
        }
      );
    }
  };

  const createAccount = () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });

      return;
    }

    Toast.loading("Please wait...");

    let payload: any = {
      email: props.email.toString().toLowerCase(),
      password: formInputs.password.value,
      returnTo: "/store/storehome",
      userId: senderUserId,
    };

    signInOrSignUpAction(payload)
      .then((response) => {
        console.log("Create account - response:", response);
        if (response.data.otpSent) {
          // Expects OTP
          Toast.hide();
          setEmailAuthData(response.data);
          setShowOtp(true);
        } else if (response.data.redirectTo) {
          // Sends GA join event on Signup
          setSignUpEvent("email and password");

          // Redirects when user exists
          console.log(
            "password authentication, redirecting to: " + "https://" + window.location.host + response.data.redirectTo
          );

          const redirectUrl = `${isLocalENVOnly() ? "http" : "https"}://${window.location.host}${
            response.data.redirectTo
          }`;
          const queryParams = response.data.newUser ? "?userFirstTimeLogin=1" : "";

          window.location.href = `${redirectUrl}${queryParams}`;
          localStorage.setItem("loggedInBefore", "true");

          // Set userFirstTimeLogin to check if user need to input handle/nickname
          if (response.data.newUser) {
            localStorage.setItem("userFirstTimeLogin", "1");
          }
        } else if (response.data.error) {
          Toast.info("Your password is incorrect, please try again.", toastMessageDuration);
        } else {
          Toast.info("Something went wrong, please try again later.", toastMessageDuration);
        }
      })
      .catch((error) => {
        const errorMsg = "Error on email authentication.";

        RollbarTracker.logError(errorMsg, error);
        console.log(errorMsg, error);

        if (error.response.status === 1000) {
          props.history.push("/error");
        }
      });
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const renderEnterPassword = () => {
    return (
      <div>
        <SectionTitle>Make it easier next time</SectionTitle>
        <Caption style={{ marginTop: "8px" }}>Just add a password below to create your account.</Caption>
        <FormControl fullWidth className={styles.formControl}>
          <TextInput
            id="password"
            name="password"
            label="New password"
            type="password"
            fullWidth={true}
            value={formInputs.password.value}
            error={formInputs.password.error}
            helperText={formInputs.password.error}
            onChange={handleInputChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
        </FormControl>
        <PrimaryButton className={styles.createAccountButton} handleClick={() => createAccount()}>
          Create your account
        </PrimaryButton>
        <Typography className={styles.skipTypography}>
          <Link href="/store/storehome" underline="always" className={styles.skipLink}>
            Skip and return to home
          </Link>
        </Typography>
      </div>
    );
  };

  const renderOtp = () => {
    return (
      <>
        <Typography variant="subtitle1" className={styles.subtitle}>
          We sent a code to your email!
        </Typography>
        <Typography variant="subtitle1" className={styles.subtitle}>
          Please enter it below.
        </Typography>
        <TextInput
          id="otp"
          name="otp"
          label="Enter your code here"
          marginTop="16px"
          width="100%"
          InputLabelProps={{
            style: {
              fontSize: 16,
              lineHeight: 0.5
            }
          }}
          InputProps={{
            classes: {
              input: styles.textField
            }
          }}
          onChange={handleInputChange}
          value={formInputs.otp.value}
          error={formInputs.otp.error}
          helperText={formInputs.otp.error}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />

        <PrimaryButton className={styles.emailButton} handleClick={() => verifyEmailSignUpOtp()}>
          {isVerifyingOtp ? <CircularProgress className={styles.orderCircularProgress} size={20} /> : null}
          {isVerifyingOtp ? "Verifying..." : "Verify"}
        </PrimaryButton>
      </>
    );
  };

  const renderContent = () => {
    if (showOtp) {
      return renderOtp();
    } else {
      return renderEnterPassword();
    }
  };

  return (
    <Box>
      {props.hasSession ? (
        renderContent()
      ) : (
        <Typography className={styles.skipTypography}>
          <Link href="/store/storehome" underline="always" className={styles.skipLink}>
            Return to home
          </Link>
        </Typography>
      )}
    </Box>
  );
}
