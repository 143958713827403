import { makeStyles } from "@material-ui/core/styles";
import { HEADER } from "../../../style";

const SYNC_HEADER_HEIGHT = 100;
const TOOLBAR_DEFAULT_HEIGHT = 56;
const TOOLBAR_SM_HEIGHT = 70;
const TOOLBAR_XS_HEIGHT = 70;

export const HeaderStyle = makeStyles((theme) => ({
  appBarStyle: {
    backgroundColor: HEADER.headerBg,
    zIndex: 1298
  },
  appBarSpaceStyle: {
    minHeight: TOOLBAR_DEFAULT_HEIGHT + SYNC_HEADER_HEIGHT,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      minHeight: TOOLBAR_XS_HEIGHT + SYNC_HEADER_HEIGHT
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: TOOLBAR_SM_HEIGHT + SYNC_HEADER_HEIGHT - 40
    }
  },
  toolbarStyle: {
    minHeight: 63,
    justifyContent: "space-between",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  toolbarStyle2: {
    minHeight: 74,
    justifyContent: "space-between",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  logoStyle: {
    position: "absolute",
    left: getLogoDefaultStateLeft(TOOLBAR_DEFAULT_HEIGHT),
    alignSelf: "center",
    padding: "5px",
    maxWidth: 94,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      left: getLogoDefaultStateLeft(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      left: getLogoDefaultStateLeft(TOOLBAR_SM_HEIGHT)
    },
    transition: "left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  logoStyle2: {
    width: 94,
    position: "absolute",
    left: getLogoDefaultStateLeft(TOOLBAR_DEFAULT_HEIGHT),
    alignSelf: "center",
    height: getLogoHeight(TOOLBAR_DEFAULT_HEIGHT),
    padding: "5px",
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      left: getLogoDefaultStateLeft(TOOLBAR_XS_HEIGHT),
      height: getLogoHeight(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      left: getLogoDefaultStateLeft(TOOLBAR_SM_HEIGHT),
      height: getLogoHeight(TOOLBAR_SM_HEIGHT)
    },
    transition: "left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  logoExpandedTransition: {
    left: "0%"
  },
  headerIconStyle: {
    alignSelf: "center",
    color: "#e6007e",
    width: TOOLBAR_DEFAULT_HEIGHT,
    height: TOOLBAR_DEFAULT_HEIGHT,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      width: TOOLBAR_XS_HEIGHT,
      height: TOOLBAR_XS_HEIGHT
    },
    [theme.breakpoints.up("sm")]: {
      width: TOOLBAR_SM_HEIGHT,
      height: TOOLBAR_SM_HEIGHT
    }
  },
  shareIconFadeInTransition: {
    visibility: "visible",
    opacity: "1",
    transition: getFadeInTransition()
  },
  shareIconFadeOutTransition: {
    visibility: "hidden",
    opacity: "0",
    transition: getFadeOutTransition()
  },
  searchWishlistIconStyle: {
    transform: "rotate(0deg)",
    transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  searchWishlistIconExpandedStyle: {
    transform: "rotate(45deg)",
    transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  searchBoxStyle: {
    position: "absolute",
    width: "0%",
    borderRadius: "4px",
    border: `#e6007e 1px solid`,
    visibility: "hidden",
    opacity: "0",
    right: getExpandedSearchBoxRight(TOOLBAR_DEFAULT_HEIGHT),
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      right: getExpandedSearchBoxRight(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      right: getExpandedSearchBoxRight(TOOLBAR_SM_HEIGHT)
    },
    transition: `${getWidthChangeTransition()}, ${getFadeOutTransition()}`
  },
  searchBoxExpandedTransition: {
    visibility: "visible",
    opacity: "1",
    width: getExpandedSearchBoxWidth(TOOLBAR_DEFAULT_HEIGHT),
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      width: getExpandedSearchBoxWidth(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      width: getExpandedSearchBoxWidth(TOOLBAR_SM_HEIGHT)
    },
    transition: `${getWidthChangeTransition()}, ${getFadeInTransition()}`
  },
  searchInputStyle: {
    // left: '50%',
    padding: `4px ${theme.typography.pxToRem(4)}`,
    transform: "translateX(-25%)",
    ...theme.typography.body2
  },
  searchInputIconStyle: {
    color: "#e6007e"
  },
  recipientLabelStyle: {
    backgroundColor: "white",
    position: "absolute",
    paddingLeft: "2px",
    paddingRight: "2px",
    right: "8px",
    top: "-10px",
    color: "#e6007e",
    fontWeight: "bold"
  },
  searchSelectedRecipientBoxStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: getExpandedSelectedRecipientBoxHeight(TOOLBAR_DEFAULT_HEIGHT),
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: getExpandedSelectedRecipientBoxHeight(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      height: getExpandedSelectedRecipientBoxHeight(TOOLBAR_SM_HEIGHT),
      visibility: "visible",
      opacity: "1",
      width: getExpandedSearchBoxWidth(TOOLBAR_DEFAULT_HEIGHT),
      [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        width: getExpandedSearchBoxWidth(TOOLBAR_XS_HEIGHT)
      },
      [theme.breakpoints.up("sm")]: {
        width: getExpandedSearchBoxWidth(TOOLBAR_SM_HEIGHT)
      }
    }
  },
  recipientPopUpPaperStyle: {
    width: getRecipientPopUpWidth(TOOLBAR_DEFAULT_HEIGHT),
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      width: getRecipientPopUpWidth(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      width: getRecipientPopUpWidth(TOOLBAR_SM_HEIGHT)
    },
    // height: "20vh",
    left: "15%",
    // right: "16px",
    marginTop: "6px",
    borderRadius: "2px 2px 16px 16px",
    backgroundColor: "rgba(200, 200, 220, 0.3)",
    backdropFilter: `blur(3px)`,
    WebkitBackdropFilter: `blur(3px)`
  },
  recipientPopUpItemStyle: {
    width: "88%",
    height: "36px",
    left: "15%",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "16px",
    padding: "6px",
    borderRadius: "4px 4px 4px 4px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    backdropFilter: `blur(3px)`,
    WebkitBackdropFilter: `blur(3px)`,
    fontWeight: "bold"
  }
}));

export function getLogoHeight(toolbarHeight) {
  return toolbarHeight;
}

export function getLogoDefaultStateLeft(toolbarHeight) {
  return `calc(47% - ${getLogoHeight(toolbarHeight) / 2}px)`;
}

function getExpandedSearchBoxWidth(toolbarHeight) {
  return `calc(100% - ${toolbarHeight * 2}px)`;
}

function getExpandedSelectedRecipientBoxHeight(toolbarHeight) {
  return toolbarHeight - 16 - 16;
}

function getRecipientPopUpWidth(toolbarHeight) {
  return `calc(100% - ${toolbarHeight * 2}px)`;
  // return `70%`;
}

function getExpandedSearchBoxRight(toolbarHeight) {
  return toolbarHeight;
}

function getWidthChangeTransition() {
  return `width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`;
}

function getFadeInTransition() {
  return "visibility 0ms linear 0ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
}

function getFadeOutTransition() {
  return "visibility 0ms linear 300ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
}
