import React, { useState } from "react";
import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { DESKTOP_CONTENT_WIDTH } from "../common/Constants";
import SelectRecipientPopper from "../common/selectRecipient/SelectRecipientPopper";
import { getAuthenticatedUserId } from "../../utils/localStorage";
import { COLORS } from "../../style";
import { textColor } from "../../style/index";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px",
    display: "flex",
    alignItems: "center",
    padding: "16px 0",
    [theme.breakpoints.up("lg")]: {
      margin: "0 auto",
      width: DESKTOP_CONTENT_WIDTH
    }
  },
  label: {
    ...theme.typography.h6,
    fontWeight: "normal",
    marginRight: "5px",
    color: textColor.DARK1
  },
  recipient: {
    ...theme.typography.h6,
    color: "#E92983",
    cursor: "pointer"
  }
}));

export default function SelectRecipientShoppingFor(props: any) {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRecipient] = useState(JSON.parse(localStorage.getItem("selectedRecipient")));
  const [recipients] = useState(JSON.parse(localStorage.getItem("recipients")));
  const authenticatedUserId = getAuthenticatedUserId();
  const { style, justifyContent = "space-between" } = props;

  const toggleSelectRecipientForm = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "select-recipient-popper" : undefined;

  if (
    !authenticatedUserId ||
    typeof authenticatedUserId === "undefined" ||
    !authenticatedUserId.hasOwnProperty("userId")
  ) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={justifyContent}
      aria-describedby={id}
      onClick={toggleSelectRecipientForm}
    >
      <Typography className={styles.label} style={{ ...style }}>
        Shopping For
      </Typography>
      <Box display="flex" flexDirection="row">
        <Typography
          className={styles.recipient}
          style={{
            ...style,
            textDecoration: isDesktop ? "" : "underline"
          }}
        >
          {selectedRecipient.userId === authenticatedUserId.userId ? "Myself" : selectedRecipient.nickname}
        </Typography>
        {isDesktop && (
          <ExpandMoreSharpIcon
            color="secondary"
            style={{
              cursor: "pointer",
              marginTop: 5
            }}
          />
        )}
      </Box>
      <SelectRecipientPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        recipients={recipients}
        selectedRecipient={selectedRecipient}
        toggleSelectRecipientForm={toggleSelectRecipientForm}
      ></SelectRecipientPopper>
    </Box>
  );
}
