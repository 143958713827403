import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";

interface ReadMoreReadLessProps {
  charLimit: number;
  readMoreText: string;
  readLessText: string;
  longtext: string;
  className: any;
  forceShowlongText?: boolean;
}

const ReadMoreReadLess = ({
  longtext,
  charLimit,
  readMoreText,
  readLessText,
  className,
  forceShowlongText = false
}: ReadMoreReadLessProps) => {
  const [hidden, setHidden] = useState(true);
  const { isDesktop } = useMediaBreakpoints();

  return (
    longtext && (
      <Box>
        <Typography variant="body1" className={className} style={{ wordBreak: "break-word" }}>
          {hidden && !forceShowlongText ? `${longtext.substr(0, charLimit).trim()}` : longtext}
        </Typography>
        {!forceShowlongText && longtext.length > charLimit && (
          <Typography
            variant="body1"
            onClick={() => setHidden((prev) => !prev)}
            style={{
              fontWeight: 700,
              fontStyle: "italic",
              lineHeight: "26px",
              cursor: "pointer",
              textAlign: isDesktop ? "center" : "left"
            }}
          >
            {hidden ? readMoreText : readLessText}
          </Typography>
        )}
      </Box>
    )
  );
};

export default ReadMoreReadLess;
