import React from "react";
import GetStartedItem from "./GetStartedItem";
import ShareMyWishlistItem from "./ShareMyWishlistItem";
import GetGifts from "./GetGifts";
import HowItWorks2 from "./howItWorks/HowItWorks2";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "../../theme/theme-devins";
import GridWrapper from "../common/appLayout/GridWrapper";
import { useApp } from "../../context/app-context";

const HowItWorks: React.FC = () => {
  const { setPromptLogin } = useApp();

  return (
    <AppLayout theme={theme} headerVariant="header1">
      <GridWrapper>
        <GetStartedItem
          handleClick={() => {
            setPromptLogin({
              promptLogin: true,
              returnTo: undefined,
              registration: true,
              forceRegistration: true
            });
          }}
        />
        <ShareMyWishlistItem />
        <GetGifts />
        <HowItWorks2 />
      </GridWrapper>
    </AppLayout>
  );
};

export default HowItWorks;
