import React from "react";
import { FormControl, Box } from "@material-ui/core";
import TextInput from "../input/TextInput";
import { Typography } from "@material-ui/core";
import CardCVVTooltip from "../common/tooltip/CardCVVTooltip";
interface EditCardInformationFormProps {
  exp_month: any;
  exp_year: any;
  csv: any;
  handleInputChange: (e: any) => void;
  handleOnBlur: (e: any) => void;
  handleOnFocus: (e: any) => void;
  onBindCardValidate: (onValidateHook: any) => void;
  onCardInputDataUpdated: (cardNumber: any, validation: any, error: any) => void;
  style?: React.CSSProperties;
}

const EditCardInformationForm: React.FC<EditCardInformationFormProps> = (props: EditCardInformationFormProps) => {
  return (
    <Box display="flex">
      <FormControl fullWidth style={props.style}>
        <Typography
          style={{
            marginTop: "16px"
          }}
        >
          Expiration
        </Typography>
        <Box
          width="100%"
          flexDirection="row"
          style={{
            marginTop: "16px"
          }}
        >
          <TextInput
            id="cardExpiryMonth"
            name="exp_month"
            label="Month"
            type="tel"
            maxLength="2"
            autoComplete="cc-exp-month"
            width="25%"
            value={props.exp_month.value}
            error={props.exp_month.error}
            helperText={props.exp_month.error}
            onChange={props.handleInputChange}
            onFocus={props.handleOnFocus}
            onBlur={props.handleOnBlur}
            marginRight="8px"
          />
          <TextInput
            id="cardExpiryYear"
            name="exp_year"
            label="Year"
            type="tel"
            autoComplete="cc-exp-year"
            width="25%"
            maxLength="2"
            value={props.exp_year.value}
            error={props.exp_year.error}
            helperText={props.exp_year.error}
            onChange={props.handleInputChange}
            onFocus={props.handleOnFocus}
            onBlur={props.handleOnBlur}
          />
          <Box
            style={{
              width: "25%",
              marginLeft: 8,
              position: "relative",
              display: "inline-flex"
            }}
          >
            <TextInput
              id="cardCVV"
              name="csv"
              label={props.csv.label}
              autoComplete="cc-csc"
              maxLength={props.csv.maxLength}
              value={props.csv.value}
              error={props.csv.error}
              helperText={props.csv.error}
              onChange={props.handleInputChange}
              onFocus={props.handleOnFocus}
              onBlur={props.handleOnBlur}
              alignSelf="flex-end"
            />
            <CardCVVTooltip />
          </Box>
        </Box>
      </FormControl>
    </Box>
  );
};

export default EditCardInformationForm;
