import React from "react";
import { mapOrderStatus } from "../../../actions/orderAction";
import { Box, Divider, Typography } from "@material-ui/core";
import SecondaryButton from "../../buttons/SecondaryButton";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { buttonColor } from "../../../style";
import { CURRENCY } from "../../common/Constants";
import sup from "superscript-text";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";

interface OrderHistoryProps {
  order: any;
  styles?: any;
  isDesktop: boolean;
}

export default function PerOrderHistory(props: OrderHistoryProps) {
  const { order, isDesktop } = props;
  const finalBrand = order.gift.brand && order.gift.brand.length > 0 ? order.gift.brand : order.gift.name;
  const history = useHistory();

  // convert "exchanged" status to "accepted"
  // we don't want to show "exchanged" status in buyer view
  // instead show "accepted"
  const status = order.claim_status === "exchanged" ? "claimed" : order.claim_status;
  const mappedStatus = mapOrderStatus(status, order.canceled);

  return (
    <>
      {isDesktop ? (
        <>
          <tr>
            <td className={props.styles.hover} style={{ verticalAlign: "top" }}>
              <Box display="flex" flexDirection="row" paddingTop={"23px"}>
                <Box minWidth="100px">
                  <img
                    src={order.gift.image_url}
                    alt={order.gift.name}
                    style={{
                      alignSelf: "center",
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px",
                      objectFit: "cover"
                    }}
                  />
                </Box>
                <Box minWidth="180px" paddingLeft="28px" paddingRight="28px">
                  <Typography style={{ fontSize: 14 }}>{finalBrand}</Typography>
                  {order.gift.brand && order.gift.brand.length > 0 && (
                    <Typography style={{ fontSize: "14px" }}>{order.gift.name}</Typography>
                  )}
                  <Typography style={{ fontSize: "14px" }}>
                    {moment(order.date_ordered).format("MM/DD/YYYY")}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold"
                    }}
                  >
                    {CURRENCY}
                    {roundToDecimalPlaces(order.pricing.total_price_paid_converted, 2).split(".")[0]}.
                    <span style={{ fontWeight: 500 }}>
                      {sup(roundToDecimalPlaces(order.pricing.total_price_paid_converted, 2).split(".").pop())}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </td>
            <td style={{ minWidth: "200px", verticalAlign: "top" }}>
              <Box paddingTop={"23px"}>
                <Typography style={{ fontSize: "14px" }}>{order.receiver_nickname}</Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: mappedStatus.color
                  }}
                >
                  {mappedStatus.label}
                </Typography>
                <Box display={"inline-flex"} flexDirection={"row"}>
                  <img
                    src={`https://s2.googleusercontent.com/s2/favicons?domain_url=https://${order.channel_partner_data.channel_partner_url}`}
                    alt=""
                    style={{
                      alignSelf: "center",
                      width: "16px",
                      height: "16px",
                      borderRadius: "10px"
                    }}
                  />
                  <Typography variant="body2" style={{ marginLeft: 8 }}>
                    {order.channel_partner_data.channel_partner_store_name}
                  </Typography>
                </Box>
              </Box>
            </td>
            <td style={{ verticalAlign: "top" }}>
              <Box paddingTop={"23px"}>
                <SecondaryButton
                  text="See details"
                  style={{
                    color: buttonColor.PINK,
                    fontSize: "14px",
                    margin: "auto",
                    minWidth: "150px",
                    height: "auto"
                  }}
                  handleClick={() => {
                    history.push(`/profile/gift-details/${order.order_id}?is_order=1`);
                  }}
                />
              </Box>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Divider orientation={"horizontal"} variant={"fullWidth"} style={{ marginTop: "23px" }} />
            </td>
          </tr>
        </>
      ) : (
        <>
          <Box display="flex" flexDirection="row" paddingTop={"23px"}>
            <Box minWidth="100px">
              <img
                src={order.gift.image_url}
                alt={order.gift.name}
                style={{
                  alignSelf: "center",
                  width: "100px",
                  height: "100px",
                  borderRadius: "10px",
                  objectFit: "cover"
                }}
              />
            </Box>
            <Box minWidth="180px" paddingLeft="28px" paddingRight="28px">
              {order.gift.brand && (
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px"
                  }}
                >
                  {order.gift.brand}
                </Typography>
              )}
              <Typography
                style={{
                  fontSize: "14px"
                }}
              >
                {order.gift.name}
              </Typography>
              <Typography style={{ fontSize: "14px" }}>{moment(order.date_ordered).format("MM/DD/YYYY")}</Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                {CURRENCY}
                {Math.trunc(order.pricing.total_price_paid_converted)}.
                <span style={{ fontWeight: 500 }}>
                  {sup((order.pricing.total_price_paid_converted % 1).toFixed(2).split(".")[1])}
                </span>
              </Typography>
              <Box paddingTop={"16px"}>
                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>Recipient</Typography>
                <Typography style={{ fontSize: "16px" }}>{order.receiver_nickname} </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: mappedStatus.color
                  }}
                >
                  {mappedStatus.label}
                </Typography>
              </Box>
              <Box display={"inline-flex"} flexDirection={"row"}>
                <img
                  src={`https://s2.googleusercontent.com/s2/favicons?domain_url=https://${order.channel_partner_data.channel_partner_url}`}
                  alt=""
                  style={{
                    alignSelf: "center",
                    width: "16px",
                    height: "16px",
                    borderRadius: "10px"
                  }}
                />
                <Typography variant="body2" style={{ marginLeft: 8 }}>
                  {order.channel_partner_data.channel_partner_store_name}
                </Typography>
              </Box>
              <Box paddingTop={"16px"}>
                <SecondaryButton
                  text="See details"
                  style={{
                    color: buttonColor.PINK,
                    fontSize: "14px",
                    maxWidth: "150px",
                    height: "auto"
                  }}
                  handleClick={() => {
                    history.push(`/profile/gift-details/${order.order_id}?is_order=1`);
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Divider orientation={"horizontal"} variant={"fullWidth"} style={{ marginTop: "23px" }} />
        </>
      )}
    </>
  );
}
