import React from "react";
import { Box, FormControl, useMediaQuery, Theme } from "@material-ui/core";
import TextInput from "../input/TextInput";
import USStateSelector from "../input/USStateSelector";
import CountrySelector from "../input/CountrySelector";

export default function RedeemGiftShippingForm(props: any) {
  const isTabletToDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  return (
    <FormControl fullWidth style={props.style}>
      <TextInput
        id="fullname"
        name="delivery_fullname"
        label="Full Name"
        autoComplete="name"
        value={props.delivery_fullname.value}
        error={props.delivery_fullname.error}
        helperText={props.delivery_fullname.error}
        onChange={props.handleInputChange}
        onFocus={props.handleOnFocus}
        onBlur={props.handleOnBlur}
      />
      <TextInput
        id="shippingaddress"
        name="delivery_address"
        label="Shipping Address"
        autoComplete="shipping street-address"
        style={{ marginTop: "12px" }}
        value={props.delivery_address.value}
        error={props.delivery_address.error}
        helperText={props.delivery_address.error}
        onChange={props.handleInputChange}
        onFocus={props.handleOnFocus}
        onBlur={props.handleOnBlur}
      />
      <CountrySelector
        id="countrySelector"
        name="delivery_country"
        label="Country"
        autoComplete="shipping country"
        style={{ marginTop: "12px" }}
        value={props.delivery_country.value}
        error={props.delivery_country.error}
        helperText={props.delivery_country.error}
        onChange={props.handleInputChange}
        onFocus={props.handleOnFocus}
        onBlur={props.handleOnBlur}
      />
      <TextInput
        id="ziporpostalcode"
        name="delivery_zip"
        label="Zip/Postal Code"
        autoComplete="shipping postal-code"
        style={{ marginTop: "12px" }}
        value={props.delivery_zip.value}
        maxLength={10}
        error={props.delivery_zip.error}
        helperText={props.delivery_zip.error}
        onChange={props.handleInputChange}
        onFocus={props.handleOnFocus}
        onBlur={props.handleOnBlur}
      />
      <Box style={{ display: "flex", flexWrap: "nowrap", marginTop: "12px" }}>
        <TextInput
          fullWidth={false}
          id="city"
          name="delivery_city"
          label="City"
          autoComplete="shipping address-level2"
          value={props.delivery_city.value}
          error={props.delivery_city.error}
          helperText={props.delivery_city.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
          style={{
            marginRight: "16px"
          }}
        />
        {props.delivery_country.value === "US" ? (
          <USStateSelector
            id="state"
            name="delivery_state"
            label="State"
            autoComplete="delivery address-level1"
            value={props.delivery_state.value}
            error={props.delivery_state.error}
            helperText={props.delivery_state.error}
            onChange={props.handleInputChange}
            onFocus={props.handleOnFocus}
            onBlur={props.handleOnBlur}
            disabled={props.disabled}
            style={{
              width: "120px",
              marginRight: 0
            }}
          />
        ) : (
          <TextInput
            id="province"
            name="delivery_province"
            label={isTabletToDesktop ? "State/Province" : "State/Prov"}
            autoComplete="delivery address-level2"
            value={props.delivery_province.value}
            error={props.delivery_province.error}
            helperText={props.delivery_province.error}
            onChange={props.handleInputChange}
            onFocus={props.handleOnFocus}
            onBlur={props.handleOnBlur}
            disabled={props.disabled}
          />
        )}
      </Box>
      <TextInput
        id="phonenumber"
        name="delivery_phone"
        label="Phone Number"
        type="tel"
        autoComplete="tel"
        style={{ marginTop: "12px" }}
        value={props.delivery_phone.value}
        error={props.delivery_phone.error}
        helperText={props.delivery_phone.error}
        onChange={props.handleInputChange}
        handleOnFocus={props.handleOnFocus}
        handleOnBlur={props.handleOnBlur}
      />
      <TextInput
        id="emailaddress"
        name="delivery_email"
        label="Email Address"
        type="email"
        autoComplete="email"
        style={{ marginTop: "12px" }}
        value={props.delivery_email.value}
        error={props.delivery_email.error}
        helperText={props.delivery_email.error}
        onChange={props.handleInputChange}
        handleOnFocus={props.handleOnFocus}
        handleOnBlur={props.handleOnBlur}
      />
    </FormControl>
  );
}
