import React from "react";
import { Typography } from "@material-ui/core/";
import { GiftInterface } from "models/gift";

interface GiftAddedToWishlistCountProps {
  gift: GiftInterface;
}

export function GiftAddedToWishlistCount(props: GiftAddedToWishlistCountProps) {
  const gift = props.gift;

  const shouldShow = gift.wishlist_count !== null && gift.wishlist_count !== undefined && gift.wishlist_count >= 100;

  function formatCount(count: number) {
    function formatAndTrim(number: number, divisor: number, suffix: string) {
      // Format the number with one decimal place
      var formattedNumber = (number / divisor).toFixed(1);
      // If the decimal part is .0, remove the decimal part, otherwise keep as is
      if (formattedNumber.endsWith(".0")) {
        formattedNumber = formattedNumber.substring(0, formattedNumber.length - 2);
      }
      return formattedNumber + suffix;
    }

    if (count >= 1e9) {
      return formatAndTrim(count, 1e9, "B");
    } else if (count >= 1e6) {
      return formatAndTrim(count, 1e6, "M");
    } else if (count >= 1e3) {
      return formatAndTrim(count, 1e3, "K");
    } else {
      return count.toString();
    }
  }

  if (shouldShow) {
    return (
      <Typography style={{ fontSize: 14 }}>
        &hearts; {formatCount(gift.wishlist_count)} recently added this to their wishlist
      </Typography>
    );
  }

  return null;
}
