import React, { useState, useRef } from "react";
import { makeStyles, Box, Theme, Typography, Button } from "@material-ui/core";
import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import AppDialog from "../common/dialog/AppDialog";
import PhotoCropper from "../common/modules/photo/PhotoCropper";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";
import { useAppDispatch } from "../../hooks";
import { uploadProfilePicture } from "../../actions/userAction";
import { setUserLocalDbData } from "../../reducers/user/UserSlice";
import { FONT_FAMILIES } from "../../style/index";

const useStyle = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  heading: {
    marginTop: "10px",
    marginBottom: "25px",
    textAlign: "center",
    maxWidth: "220px",
    fontSize: 14,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 18
    }
  },
  profilePic: {
    maxWidth: 170,
    maxHeight: 170
  },
  actionButtons: {
    marginTop: 40
  },
  doItLaterButton: {
    marginTop: 25,
    textDecoration: "underline",
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 14,
    fontWeight: 700,
    textTransform: "none"
  }
}));

interface ContentProfilePictureProps {
  nextStep: () => void;
}

const ContentProfilePicture: React.FC<ContentProfilePictureProps> = ({
  nextStep
}: ContentProfilePictureProps) => {
  const classes = useStyle();

  const DefaultProfilePic = "/assets/profile/profile-empty-other.jpg";
  const { isMobile } = useMediaBreakpoints();
  const inputRef = useRef<HTMLInputElement>(null);
  const [openImageCropper, setOpenImageCropper] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [imageSrc, setImageSrc] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const onClose = () => {
    setOpenImageCropper(false);
    setImageSrc(null);
  };

  const onSaveChanges = async (croppedBlob: any, croppedUri: string) => {
    setIsSaving(true);
    try {
      const response = await uploadProfilePicture(croppedUri);
      const { localDbData } = response.data;

      // TODO: remove setimeout and validate if image has already been done in photoprocessor
      setTimeout(() => {
        dispatch(setUserLocalDbData(localDbData));
        setIsSaving(false);
        nextStep();
      }, 2000);
    } catch (error) {
      setIsSaving(false);
    }
  };

  const onFileChange = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    setOpenImageCropper(true);
  };

  const onClickChangeProfilePictureHandler = (e) => {
    inputRef.current.click();
  };

  return (
    <Box className={classes.root}>
      <Typography variant="caption" className={classes.heading}>
        Profile Picture
      </Typography>
      <img src={DefaultProfilePic} alt="Gift.me default profile picture" className={classes.profilePic} />
      <Box className={classes.actionButtons}>
        <input
          accept="image/*"
          id="contained-button-file"
          type="file"
          onChange={onFileChange}
          style={{ display: "none" }}
          ref={inputRef}
        />
        <PrimaryButtonV3 handleClick={onClickChangeProfilePictureHandler}>Upload a Photo</PrimaryButtonV3>
        <Button variant="text" className={classes.doItLaterButton} onClick={nextStep}>
          {"I'll do it later"}
        </Button>
      </Box>
      <AppDialog open={openImageCropper} onClose={onClose} fullWidth={isMobile ? true : false}>
        {openImageCropper && (
          <PhotoCropper src={imageSrc} onClose={onClose} isSaving={isSaving} onSaveChanges={onSaveChanges} />
        )}
      </AppDialog>
    </Box>
  );
};

export default ContentProfilePicture;
