import React from "react";
import { TextField, TextFieldProps, InputLabel, styled } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "../customIcons/ErrorIcon";
import CheckIcon from "../customIcons/CheckIcon";
import { FONT_FAMILIES, textColor } from "../../style/index";

const StylesTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: 30
  }
}));

export const CustomInputLabel = withStyles({
  root: {
    position: "relative",
    color: textColor.DARK1,
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 18,
    marginBottom: 9,
    fontFamily: FONT_FAMILIES.SOFIA_PRO,
    transform: "translate(0, 0) scale(1)",
    lineHeight: "24.39px"
  }
})(InputLabel);

interface TextInputV3Props {
  showErrorIcon?: boolean;
  showSuccessIcon?: boolean;
  style?: React.CSSProperties;
}

type TextInputV3ComponentProps = TextFieldProps & TextInputV3Props;

const TextInputV3: React.FC<TextInputV3ComponentProps> = ({
  showSuccessIcon,
  showErrorIcon,
  variant = "outlined",
  error,
  style,
  label,
  ...rest
}: TextInputV3ComponentProps) => {
  return (
    <>
      <CustomInputLabel htmlFor={rest.name}>{label}</CustomInputLabel>
      <StylesTextField
        {...rest}
        variant={variant}
        error={error}
        InputProps={{
          ...rest.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {showSuccessIcon && <CheckIcon style={{ marginTop: "8px", marginRight: 5 }} />}
              {error && showErrorIcon && <ErrorIcon style={{ marginTop: "8px", marginRight: 5 }} />}
            </InputAdornment>
          )
        }}
        style={style}
      />
    </>
  );
};

export default TextInputV3;
