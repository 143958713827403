import React, { FC } from "react";
import { useAppSelector } from "../../../hooks";
import AppDialog from "../../common/dialog/AppDialog";
import ShareDialogContent from "./ShareDialogContent";
import ShareDialogSocialFooter from "./ShareDialogSocialFooter";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "../../../theme";

interface ShareUrlDialogProps {
  open: boolean;
  onClose: () => void;
  shareTitle?: string;
  shareBody?: string;
  shareContentDescription: string;
  shareUrl?: string;
}

export default function ShareUrlDialog({
  open,
  onClose,
  shareTitle,
  shareBody,
  shareContentDescription
}: ShareUrlDialogProps): ReturnType<FC> {
  const { vanity, encryptedUserId } = useAppSelector((state) => state.user.data);

  let shareUrl = `${window.location.origin}/gift/wishlist/${encryptedUserId}`;
  if (vanity && vanity.length) {
    shareUrl = `${window.location.origin}/${vanity}`;
  }

  const setShareTitle = shareTitle ? shareTitle : "Check out my wishlist on Gift.Me!";
  const setShareBody = shareBody ? shareBody : "My wishlist on Gift.me";
  const setShareContentDescription = shareContentDescription
    ? shareContentDescription
    : "Current page URL address";

  return (
    <MuiThemeProvider theme={theme}>
      <AppDialog
        open={open}
        onClose={onClose}
        fullWidth={false}
        title="Share..."
        footer={
          <ShareDialogSocialFooter
            shareTitle={setShareTitle}
            shareBody={setShareBody}
            shareUrl={shareUrl}
            encryptedUserId={encryptedUserId}
          />
        }
      >
        <ShareDialogContent
          shareUrl={shareUrl}
          shareContentDescription={setShareContentDescription}
          encryptedUserId={encryptedUserId}
          closeDialog={onClose}
        />
      </AppDialog>
    </MuiThemeProvider>
  );
}
