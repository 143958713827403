import React from "react";
import { Box, Theme } from "@material-ui/core";
import HowItWorksItemHeaderImage from "./HowItWorksItemHeaderImage";
import HowItWorksNumber from "./HowItWorksNumber";
import HowItWorksDescription from "./HowItWorksDescription";
import HowItWorksNextIndicator from "./HowItWorksNextIndicator";
import { makeStyles } from "@material-ui/core/styles";

const usesStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "8px 0px 0px 0px",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex"
    }
  }
}));

export default function HowItWorksItem(props: any) {
  const classes = usesStyles();
  return (
    <Box
      className={classes.root}
      style={{
        backgroundColor: props.bgColor,
        ...props.style
      }}
    >
      <HowItWorksItemHeaderImage alt={props.alt} text={props.text} style={props.imgStyle} src={props.src} />
      {props.number && <HowItWorksNumber number={props.number} />}
      {props.description && <HowItWorksDescription description={props.description} />}
      {props.nextIndicator && <HowItWorksNextIndicator />}
      {props.children}
    </Box>
  );
}
