import React, { Component } from "react";
import { SvgIcon, styled } from "@material-ui/core";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { ChevronRight } from "@material-ui/icons";
import "react-multilevel-sidebar/src/Sidebar.css";
import "./sidebar.css";
import MultilevelSidebar from "./MultilevelSidebar";
import { getBrowserDetails } from "../../../../utils/helpers";
import { textColor } from "../../../../style";
import {
  HOW_IT_WORKS_PATH,
  FAQ_PATH,
  CONTACT_US_PATH,
  MY_ACCOUNT_PATH,
  PROFILE_ACCOUNT_SETTINGS_PATH,
  PROFILE_PAYMENT_METHODS,
  PROFILE_MY_GIFTS_PATH,
  PROFILE_ORDER_HISTORY_PATH,
  PROFILE_ACCOUNT_SYNC_PATH
} from "../../../../constants/paths";

const myWidth = () => {
  return getBrowserDetails().isMobile && !getBrowserDetails().isTablet ? window.screen.width : 428;
};

const iconStyles = {
  fontSize: 26,
  color: textColor.LIGHT
};

const UserNavItemContainerStyled = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  flex: 1
});

const parentMenuItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const options = (credits) => {
  return [
    {
      title: "MY ACCOUNT",
      hideBorder: true,
      content: [
        {
          id: 1,
          name: "Account Details",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: MY_ACCOUNT_PATH,
          link: true
        },
        {
          id: 2,
          name: "Settings",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: PROFILE_ACCOUNT_SETTINGS_PATH,
          link: true
        },
        {
          id: 3,
          name: "Account Sync",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: PROFILE_ACCOUNT_SYNC_PATH,
          link: true
        },
        {
          id: 4,
          name: "Credit",
          icon: <div>${credits}</div>,
          disabled: true,
          hidden: !(credits > 0)
        }
      ]
    },
    {
      title: "PAYMENTS",
      hideBorder: true,
      content: [
        {
          id: 3,
          name: "Payment Methods",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: PROFILE_PAYMENT_METHODS,
          link: true
        }
      ]
    },
    {
      title: "PURCHASES",
      hideBorder: true,
      content: [
        {
          id: 4,
          name: "My Gifts",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: PROFILE_MY_GIFTS_PATH,
          link: true
        },
        {
          id: 5,
          name: "Order History",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: PROFILE_ORDER_HISTORY_PATH,
          link: true
        }
      ]
    },
    {
      title: "SUPPORT",
      hideBorder: true,
      content: [
        {
          id: 6,
          name: "Terms and Conditions",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: "https://www.getrealgifts.com/home?page=terms",
          link: true,
          fullUrl: true,
          hidden: true
        },
        {
          id: 7,
          name: "Privacy Policy",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: "https://www.getrealgifts.com/home?page=privacy",
          link: true,
          fullUrl: true,
          hidden: true
        },
        {
          id: 8,
          name: "FAQ & Help Center",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: FAQ_PATH,
          link: true
        },
        {
          id: 9,
          name: "Contact Us",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: CONTACT_US_PATH,
          link: true
        },
        {
          id: 10,
          name: "How To",
          icon: <SvgIcon component={ChevronRight} style={iconStyles} />,
          to: HOW_IT_WORKS_PATH,
          link: true
        }
      ]
    },
    {
      hideBorder: true,
      content: []
    },
    {
      hideBorder: true,
      content: [
        {
          id: 10,
          name: "Sign Out",
          to: "/profile/logout",
          link: true
        }
      ]
    }
  ];
};

interface SliderSidebarProps {
  credits: any;
  openMenu?: boolean;
  options?: any[];
  parentMenuItems?: any[];
  handleSidebarToggle?: (val: boolean) => void;
}

class SliderSidebar extends Component<SliderSidebarProps> {
  handleClick = (items) => {
    if (items.link) {
      this.props.handleSidebarToggle(false);

      clearAllBodyScrollLocks();
    }
  };

  unlockPage = () => {
    clearAllBodyScrollLocks();
  };

  render() {
    const { credits, openMenu } = this.props;

    return (
      <UserNavItemContainerStyled>
        <MultilevelSidebar
          open={openMenu}
          options={options(credits)}
          onItemClick={this.handleClick}
          onClose={this.unlockPage}
          parentMenuItems={parentMenuItems}
          width={myWidth()}
          {...this.props}
        />
      </UserNavItemContainerStyled>
    );
  }
}

export default SliderSidebar;
