import { useHistory } from "react-router-dom";
import H from "history";

interface UseNavReturnType extends H.History {
  goTo: (path: string, state?: H.state) => void;
  goBack: () => void;
}

const useNav = (): UseNavReturnType => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const goTo = (path: string, state: H.state) => {
    history.push(path, state);
  };

  return {
    ...history,
    goTo,
    goBack
  };
};

export default useNav;

export const clearHistoryState = (): void => window.history.replaceState({}, document.title);
