import React from "react";
import { styled, TextField, TextFieldProps, InputLabel, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FONT_FAMILIES, textColor } from "../../style/index";

export const TextAreaStyles = styled(TextField)(({ theme }) => ({
  ...theme.typography.body1,
  "& textarea": {
    fontSize: 16,
    color: textColor.DARK1
  },
  "& .Mui-disabled": {
    color: textColor.DARK1
  }
}));

const TextAreaLabelStyle = withStyles({
  root: {
    position: "relative",
    marginBottom: 9,
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 18,
    fontFamily: FONT_FAMILIES.SOFIA_PRO,
    transform: "translate(0, 0) scale(1)",
    lineHeight: "24.39px"
  }
})(InputLabel);

interface TextAreaProps {
  charLimit: number;
  rows: number;
}

type TextAreaComponentProps = TextFieldProps & TextAreaProps;

const TextArea: React.FC<TextAreaComponentProps> = ({
  variant = "outlined",
  error,
  label,
  rows,
  charLimit,
  value,
  ...rest
}: TextAreaComponentProps) => {
  return (
    <>
      <TextAreaLabelStyle shrink htmlFor={rest.name}>
        {label}
      </TextAreaLabelStyle>
      <TextAreaStyles
        {...rest}
        multiline
        variant={variant}
        value={value}
        error={error}
        InputProps={{
          rows,
          ...rest.InputProps,
          inputProps: {
            maxLength: charLimit
          },
          endAdornment: (
            <Typography
              variant="body1"
              style={{
                color: textColor.LIGHT_GRAY3,
                position: "absolute",
                bottom: 1,
                right: 12,
                fontSize: 12
              }}
            >
              {charLimit > 0 && (
                <span>
                  {value.toString().length ?? 0}/{charLimit}
                </span>
              )}
            </Typography>
          )
        }}
      />
    </>
  );
};

export default TextArea;
