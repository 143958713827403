import axios from "axios";

const MAILBOXLAYER_ACCESS_KEY = `${process.env.REACT_APP_MAILBOXLAYER_ACCESS_KEY}`;

export function getEncryptedUserId(userId) {
  return axios.get("/api/getEncryptedUserId?user_id=" + userId);
}

export function updateProfile(payload) {
  return axios.post("/api/user", payload);
}

export function updateEmail(payload) {
  return axios.patch("/api/user", payload);
}

export function updateEmailStart(payload) {
  return axios.post("/api/user/update-email-start", payload);
}

export function checkEmail(email) {
  return axios.get(`https://apilayer.net/api/check?access_key=${MAILBOXLAYER_ACCESS_KEY}&email=${email}`);
}

export function signInOrSignUp(payload) {
  return axios.post("/api/auth/email", payload);
}

export function vysionShare(eventName, wishlistUrl, wishlistOwnerId) {
  return axios.post("/api/vysion/share/", {
    eventName,
    wishlistUrl,
    wishlistOwnerId
  });
}

export function getGifts(params) {
  return axios.get("/api/gifts/getgifts", { params });
}

export function getOrders(page: number, numOfItems: number, orderParty?: string) {
  let url = `/api/getOrders?page=${page}&page_size=${numOfItems}&sort=desc`;

  if (orderParty) {
    url += `&order_party=${orderParty}`;
  }

  return axios.get(url);
}

export function getOrderById(orderId) {
  return axios.get(`/api/getOrders?order_id=${orderId}`);
}

export function logout() {
  return axios.get("/logout");
}
