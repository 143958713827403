import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, List, ListItem, ListItemText, Radio } from "@material-ui/core";
import { Typography } from "@material-ui/core/";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import PrimaryButton from "../../buttons/PrimaryButton";
import { ChevronRight, Edit } from "@material-ui/icons";
import Caption from "../../common/caption/Caption";

const SavedPaymentMethodsContentStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  supportedCardsImg: {
    width: "193px",
    height: "34px"
  },
  addButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  }
}));

const PAYMENT_METHOD = "paymentMethod";
const ADD_NEW_PAYMENT_METHOD = "addNewPaymentMethod";

// Dummy Data format
export const savedPaymentMethods = [
  {
    type: PAYMENT_METHOD,
    card: "Visa ****9999",
    fullName: "Dax",
    expiryDate: "Expires 02/2022"
  },
  {
    type: PAYMENT_METHOD,
    card: "Visa ****4242",
    fullName: "Dave",
    expiryDate: "Expires 01/2023"
  },
  {
    type: PAYMENT_METHOD,
    card: "Visa ****4241",
    fullName: "Tommy",
    expiryDate: "Expires 04/2022"
  },
  {
    type: ADD_NEW_PAYMENT_METHOD
  }
];

export default function SavedPaymentMethodsContent(props) {
  const styles = SavedPaymentMethodsContentStyles();
  const { handleOnPaymentMethodSelected, handleAddNewPayment, handleEditPayment, paymentInfos, paymentInfo } = props;

  const [pageData, setState] = useState({
    selectedKey: undefined,
    selectedPaymentMethod: paymentInfo
  });

  const updateSelectedPaymentMethod = (item, key) => {
    setState({ ...pageData, selectedKey: key, selectedPaymentMethod: item });
  };

  const saveSelectedPaymentMethod = () => {
    handleOnPaymentMethodSelected(pageData.selectedPaymentMethod);
  };

  return (
    <Box>
      <SectionTitle>Payment method</SectionTitle>

      <Caption style={{ marginTop: "16px" }}>
        Your details are private and confidential. Purchases show as ‘RealGifts’
      </Caption>

      <Typography style={{ marginTop: "24px" }}>Saved card:</Typography>

      <List>
        {paymentInfos.map((item) =>
          renderRow(
            item,
            pageData.selectedPaymentMethod,
            updateSelectedPaymentMethod,
            handleAddNewPayment,
            handleEditPayment
          )
        )}
        {renderAddNewPaymentMethodRow(handleAddNewPayment)}
      </List>

      <PrimaryButton className={styles.addButton} handleClick={() => saveSelectedPaymentMethod()}>
        Save & Continue
      </PrimaryButton>
    </Box>
  );
}

function renderRow(item, selectedPaymentMethod, updateSelectedPaymentMethod, handleAddNewPayment, handleEditPayment) {
  if (item.billing_address) {
    return renderPaymentMethodRow(item, selectedPaymentMethod, updateSelectedPaymentMethod, handleEditPayment);
  }

  return renderAddNewPaymentMethodRow(handleAddNewPayment);
}

function renderPaymentMethodRow(item, selectedPaymentMethod, updateSelectedPaymentMethod, handleEditPayment) {
  return (
    <ListItem
      alignItems="flex-start"
      button={true}
      disableGutters={true}
      divider={true}
      onClick={() => {
        updateSelectedPaymentMethod(item, item.payment_profile_id);
      }}
    >
      <Radio
        checked={item.payment_profile_id === selectedPaymentMethod.payment_profile_id}
        value={item.payment_profile_id}
        name="radio-button-demo"
        inputProps={{ "aria-label": "A" }}
      />
      <ListItemText
        primary={
          <React.Fragment>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}>
                <Typography style={{ fontWeight: 700 }}>
                  {item.account_type} {item.account_number}
                </Typography>
                <Typography>{item.billing_name}</Typography>
                <Typography>
                  Expires {item.exp_month}/{item.exp_year}
                </Typography>
              </Box>

              {/*TODO add delete card functionality*/}
              <Edit
                style={{
                  position: "absolute",
                  right: 0,
                  top: "30px"
                }}
                onClick={() => {
                  handleEditPayment(item);
                }}
              />
            </Box>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}

function renderAddNewPaymentMethodRow(handleAddNewPayment) {
  return (
    <ListItem
      alignItems="flex-start"
      button={true}
      disableGutters={true}
      divider={true}
      style={{
        paddingTop: "24px",
        paddingBottom: "24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
      onClick={() => handleAddNewPayment()}
    >
      <Typography>Add a payment method</Typography>

      <ChevronRight style={{ color: "#f0017c" }} />
    </ListItem>
  );
}
