import React from "react";
import { Typography, FormControl, makeStyles, Box } from "@material-ui/core";
import Caption from "../../common/caption/Caption";
import TextInput from "../../input/TextInput";
import { SectionHeaderTitle } from "../ProfileComponents";

interface AccountSettingsSetPasswordProps {
  password: any;
  confirmPassword: any;
  handleInputChange: (e: any) => void;
  handleOnFocus: (e: any) => void;
  handleOnBlur: (e: any) => void;
}

const componentStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    maxWidth: 408
  },
  caption: {
    marginTop: 8,
    marginBottom: 23,
    color: "inherit",
    fontStyle: "normal"
  }
}));
const AccountSettingsSetPassword: React.FC<AccountSettingsSetPasswordProps> = (
  props: AccountSettingsSetPasswordProps
) => {
  const { password, confirmPassword, handleInputChange, handleOnBlur, handleOnFocus } = props;
  const styles = componentStyles();
  return (
    <>
      <SectionHeaderTitle text="Set your password" />
      <Caption className={styles.caption}>Use your Facebook, Twitter or Google account to log in to Gift.me</Caption>
      <FormControl className={styles.formControl}>
        <TextInput
          id="password"
          name="password"
          type="password"
          label={"New Password"}
          autoComplete="new-password"
          style={{ marginTop: "16px" }}
          value={password.value}
          error={password.error}
          helperText={password.error}
          onChange={handleInputChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
        />
        <TextInput
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label={"Confirm Password"}
          autoComplete="new-password"
          style={{ marginTop: "16px" }}
          value={confirmPassword.value}
          error={confirmPassword.error}
          helperText={confirmPassword.error}
          onChange={handleInputChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
        />
      </FormControl>
    </>
  );
};

export default AccountSettingsSetPassword;
