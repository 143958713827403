import React from "react";
import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";

const usesStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      "flex-wrap": "wrap",
      "flex-direction": "column",
      "justify-content": "flex-end"
    }
  }
}));

export default function ShareMyWishlistItem() {
  const { isDesktop } = useMediaBreakpoints();
  const classes = usesStyles();

  return (
    <Box className={classes.root}>
      <Box
        style={{
          margin: isDesktop ? "30px 0 0 120px" : "30px 0"
        }}
        m="auto"
      >
        <Box
          style={{
            position: isDesktop ? "absolute" : "relative",
            top: isDesktop ? 80 : 0,
            left: isDesktop ? "55%" : 0,
            textAlign: "center",
            height: isDesktop ? "600px" : "150px",
            marginTop: 30
          }}
        >
          <img
            style={{
              width: "auto",
              height: isDesktop ? "450px" : "260px",
              padding: "10px 0",
              position: isDesktop ? "relative" : "absolute",
              right: 0,
              top: 0
            }}
            alt="share_overlay"
            src="/assets/howItWorks/shopping_for.png"
          />
          <img
            style={{
              width: "auto",
              height: isDesktop ? 230 : 135,
              position: "absolute",
              left: isDesktop ? -160 : 0,
              bottom: isDesktop ? 0 : -180
            }}
            alt="iphone_shadow"
            src="/assets/howItWorks/shopping_for_bottom.png"
          />
        </Box>
        <div
          style={{
            background: "url(/assets/homepage_path-bottom.svg) no-repeat bottom right",
            backgroundSize: isDesktop ? "60% 260px" : "100% 200px",
            height: isDesktop ? 260 : 200
          }}
        />
      </Box>
    </Box>
  );
}
