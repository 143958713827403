import React from "react";
import { Typography, Box } from "@material-ui/core/";
import GiftDetailsMessage from "./GiftDetailsMessage";
import { GiftDetailsStyles } from "./GiftDetails";
import { GiftDetailsType } from "./GiftDetailsTypes";
import { mapOrderStatus, ORDER_STATUSES } from "../../../actions/orderAction";
import { SectionHeaderTitle } from "../ProfileComponents";

const GiftDetailsFromDetails = ({ orderData, isOrder }: { orderData: GiftDetailsType; isOrder: boolean }) => {
  const giftDetailsStyles = GiftDetailsStyles();
  const { buyer_nickname, receiver_nickname, claim_status, message } = orderData;
  const isGuestCheckout = orderData.buyer_id === process.env.REACT_APP_GUEST_CHECKOUT_USER_ID;

  // convert "exchanged" status to "accepted"
  // we don't want to show "exchanged" status in buyer view
  // instead show "accepted"
  let status = claim_status;
  if (isOrder && claim_status === "exchanged") {
    status = "claimed";
  }

  const mappedStatus = mapOrderStatus(status, orderData.canceled);

  return (
    <Box className={giftDetailsStyles.column}>
      <SectionHeaderTitle text={isOrder ? "Recipient" : !isGuestCheckout && "From"} />
      <Box className={giftDetailsStyles.nickname}>
        <Typography variant="body1">
          {isOrder ? receiver_nickname : !isGuestCheckout && buyer_nickname}
        </Typography>
        {mappedStatus && (
          <Typography
            variant="body1"
            className={giftDetailsStyles.label}
            style={{ color: mappedStatus.color }}
          >
            {mappedStatus.label}
          </Typography>
        )}
      </Box>
      {message && message.length && <GiftDetailsMessage message={message} />}
    </Box>
  );
};

export default GiftDetailsFromDetails;
