import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const GifterSpecialNoteStyle = makeStyles((theme: Theme) => ({
  noteBoxStyle: {
    width: "100%",
    borderRadius: "6px",
    paddingLeft: "12px",
    paddingTop: "16px",
    paddingRight: "12px",
    paddingBottom: "16px",
    backgroundColor: "rgba(85,193,231,0.1)",
    fontWeight: "normal",
    color: "#3A3A3A"
  },
  fromBoxStyle: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "16px"
  },
  fromLabelStyle: {
    color: theme.palette.grey[700]
  },
  fromNameStyle: {
    fontWeight: "bold",
    marginLeft: "16px",
    color: "#4C4C4C"
  }
}));

export default function GifterSpecialNote(props: any) {
  const classes = GifterSpecialNoteStyle();

  return props.gifterName !== undefined && props.gifterName.length > 0 ? (
    <Box style={props.style}>
      <Typography variant="body1" className={classes.noteBoxStyle}>
        {props.specialNote && props.specialNote.length > 0 ? props.specialNote : "I thought you'd like this gift!"}
      </Typography>

      <Box className={classes.fromBoxStyle}>
        <Typography variant="body1" className={classes.fromLabelStyle}>
          From:
        </Typography>
        <Typography variant="body1" className={classes.fromNameStyle}>
          {props.gifterName}
        </Typography>
      </Box>
    </Box>
  ) : (
    <div />
  );
}
