import React from "react";
import AppContentContainer from "../common/AppContentContainer";
import GiftResults from "../common/gift/GiftResults";
import GiftModal from "../common/gift/GiftModal";
import { GiftInterface } from "../../models/gift";
import { getGift } from "../../actions/giftAction";
import { StoreInterface } from "../../models/store";
import { SetPromptLoginInterface } from "../../interface/AppInterface";
import InfiniteScroll from "../common/InfiniteScroll";
import StoreHeader from "../store/StoreHeader";
import GiftShareModal from "../common/gift/GiftShareModal";
import Store from "../store/Store";
import AppLayout from "../common/appLayout/AppLayout";
import GridWrapper from "../common/appLayout/Section";
import theme from "../../theme/theme-devins";

interface GiftV2Props {
  match: any;
  history: any;
  loadedStores: Array<StoreInterface>;
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
}

class GiftV2 extends Store {
  constructor(props: GiftV2Props) {
    super(props);
    this.loadGift = this.loadGift.bind(this);
  }

  onModalClose(): void {
    this.setState(() => {
      return {
        productModalIsOpen: false
      };
    });

    // redirect to original store url
    this.props.history.push(`/store/store/${this.state.storeId}/${this.state.storeName}`);
  }

  async loadGift(giftId: string): Promise<void> {
    const response = await getGift(giftId);
    const giftArray: Array<GiftInterface> = Object.values(response.data.gifts);

    this.setState((prev) => {
      return {
        ...prev,
        productModalIsOpen: true,
        currentGift: giftArray[0]
      };
    });
  }

  componentDidMount(): void {
    this.updateState({
      storeId: this.props.match.params.storeId,
      storeName: this.props.match.params.storeName,
      giftId: this.props.match.params.giftId
    });

    if (this.props.match.params.giftId && this.props.match.params.giftId.length) {
      this.loadGift(this.props.match.params.giftId);
    }
  }

  render(): JSX.Element {
    const {
      onBottomHit,
      onPageUpdated,
      onSortUpdated,
      onCurrentGift,
      onProductModalIsOpen,
      onModalClose,
      onStoreChanged,
      state,
      props
    } = this;

    const { isFetching, hasMoreData, page, gifts, sort, productModalIsOpen, currentGift } = state;
    const { loadedStores, setPromptLogin } = props;
    return (
      <AppLayout theme={theme} headerVariant="header1">
        <GridWrapper style={{ minHeight: 550 }}>
          <InfiniteScroll
            onBottomHit={onBottomHit}
            isLoading={isFetching}
            hasMoreData={hasMoreData}
            loadOnMount={false}
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem"
            }}
            additionalInnerHeight={1200}
          >
            <StoreHeader
              storeName={props.match.params.storeName}
              loadedStores={loadedStores}
              onStoreChanged={onStoreChanged}
            />
            <AppContentContainer
              style={{
                marginTop: 35
              }}
            >
              <GiftResults
                isLoading={isFetching}
                isPaginated={false}
                hasMoreData={hasMoreData}
                page={page}
                totalPages={5}
                setPage={onPageUpdated}
                gifts={gifts}
                sortBy={sort}
                setSortBy={onSortUpdated}
                setCurrentGift={onCurrentGift}
                setProductModalIsOpen={onProductModalIsOpen}
                storeId={props.match.params.storeId}
                storeName={props.match.params.storeName}
                setPromptLogin={setPromptLogin}
                style={{
                  width: "73%"
                }}
              />
            </AppContentContainer>
            <GiftModal
              open={productModalIsOpen}
              onClose={onModalClose}
              gift={currentGift}
              setPromptLogin={setPromptLogin}
              onShareProductClicked={this.handleOnProductShareClicked}
            />

            <GiftShareModal
              open={this.state.openProductShareModal}
              onClose={this.onGiftShareModalClose}
              gift={currentGift}
            />
          </InfiniteScroll>
        </GridWrapper>
      </AppLayout>
    );
  }
}

export default GiftV2;
