import React, { cloneElement } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Button, useMediaQuery } from "@material-ui/core";
import { buttonColor } from "../../../style";
import SecondaryButtonV2 from "../../buttons/SecondaryButtonV2";
interface AccountSettingsSocialLoginOptionProps {
  isLinked: boolean;
  social: string;
  label: string;
  socialColor: string;
  iconComponent: React.ReactElement<any>;
  onChangeHandler: (social: string, checked: boolean) => void;
}

const componentStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center"
  },

  socialLabel: {
    color: buttonColor.PINK1
  },

  iconElement: {
    transform: "scale(2.66666667)",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      transform: "scale(1.66666667)"
    }
  }
}));

interface LinkUnlinkButtonProps {
  isLinked: boolean;
  onClickHandler: () => void;
}

const LinkUnlinkButton = ({ isLinked, onClickHandler }: LinkUnlinkButtonProps): ReturnType<React.FC> => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  if (isLinked) {
    return (
      <Button
        variant="text"
        onClick={onClickHandler}
        style={{
          color: buttonColor.PINK1,
          fontSize: isMobile ? 12 : 16,
          textTransform: "capitalize"
        }}
      >
        Unlink Account
      </Button>
    );
  }

  return (
    <SecondaryButtonV2
      text="Link Account"
      handleClick={onClickHandler}
      style={{
        fontSize: isMobile ? 12 : 16,
        textTransform: "capitalize"
      }}
    />
  );
};

const AccountSettingsSocialLoginOption: React.FC<AccountSettingsSocialLoginOptionProps> = (
  props: AccountSettingsSocialLoginOptionProps
) => {
  const styles = componentStyles();
  const { isLinked, social, label, iconComponent, socialColor, onChangeHandler } = props;

  const onClickHandler = () => {
    onChangeHandler(social, !isLinked);
  };

  return (
    <Box className={styles.root}>
      {cloneElement(iconComponent, { name: social, className: styles.iconElement })}
      <Typography
        variant="button"
        paragraph={true}
        style={{
          color: socialColor
        }}
      >
        {label}
      </Typography>
      <LinkUnlinkButton isLinked={isLinked} onClickHandler={onClickHandler} />
    </Box>
  );
};

export default AccountSettingsSocialLoginOption;
