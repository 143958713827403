import React from "react";
import clsx from "clsx";
import { Button, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AuthButtonsProps } from "../types";
import IconFacebookRounded from "../../customIcons/socialMedia/IconFacebookRounded";
import { socialColors } from "../../../style";

const useButtonStyle = makeStyles(() => ({
  button: {
    backgroundColor: socialColors.FACEBOOK,
    height: "46px",
    borderRadius: "23px",
    width: "100%",
    "&:hover": {
      backgroundColor: socialColors.FACEBOOK
    }
  }
}));

export default function FacebookLoginButton({ className, label, ...rest }: AuthButtonsProps): ReturnType<React.FC> {
  const classes = useButtonStyle();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <Button className={clsx(classes.button, className)} {...rest}>
      <IconFacebookRounded
        style={{
          marginRight: isDesktop ? 6 : 2,
          marginLeft: isDesktop ? 10 : "unset",
          transform: "scale(0.8)"
        }}
      />
      <div style={{ color: "#FFFFFF" }}>{`${label} with Facebook`}</div>
    </Button>
  );
}
