import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/";
import { GiftDetailsType } from "./GiftDetailsTypes";
import { CURRENCY } from "../../common/Constants";
import sup from "superscript-text";

const ProductInformationStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  img: {
    width: "100%",
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      marginBottom: "30px",
      marginRight: 0
    }
  }
}));

const GiftDetailsProductInformation = ({
  orderData,
  totalPrice
}: {
  orderData: GiftDetailsType;
  totalPrice: number;
}) => {
  const styles = ProductInformationStyles();
  const { image_url_high_res, name, brand, options } = orderData.gift;
  return (
    <Box display="flex" className={styles.root}>
      <Box className={styles.img}>
        <img
          src={image_url_high_res}
          alt={name}
          style={{
            width: "100%",
            borderRadius: "10px"
          }}
        />
      </Box>
      <Box>
        {brand && (
          <Typography variant="h6" style={{ textTransform: "uppercase", fontSize: 16 }}>
            {brand}
          </Typography>
        )}
        <Typography variant="body1">{name}</Typography>
        {options.map(({ name, values_selected }) => {
          return (
            name &&
            values_selected && (
              <Typography variant="body1">
                {name}: {values_selected}
              </Typography>
            )
          );
        })}
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {CURRENCY}
          {Math.trunc(totalPrice)}.
          <span style={{ fontWeight: 500 }}>{sup((totalPrice % 1).toFixed(2).split(".")[1])}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default GiftDetailsProductInformation;
