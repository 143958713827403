import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { UserContext } from "../../context";
import RollbarTracker from "../../utils/RollbarTracker";
import { logoutAction } from "../../actions/authAction";
import { useAppDispatch } from "../../hooks";
import { logout as logoutUser } from "../../reducers/user/AuthSlice";
import { clearLocalStorageWhenLogout } from "../../utils/localStorage";
import useNav from "../../hooks/useNav";
import SecondaryButtonV3 from "../buttons/SecondaryButtonV3";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";
import { SectionHeaderTitle } from "./ProfileComponents";
import { COLORS } from "../../style";
import { FONT_FAMILIES } from "../../style/index";

const MyAccountMenuStyles = makeStyles((theme) => ({
  menuBoxStyleItemTitle: {
    marginTop: "25px",
    marginBottom: "60px"
  },
  menuBoxStyleItems: {
    marginBottom: 16,
    fontWeight: 500,
    cursor: "pointer",
    display: "block",
    lineHeight: "22px",
    background: "none",
    "&:hover": {
      textDecoration: "none",
      color: COLORS.PINK
    }
  }
}));

interface ComponentInterface {
  isSelected: string;
}

const MyAccountMenu: React.FC<ComponentInterface> = (props: ComponentInterface) => {
  const myAccountMenuStyles = MyAccountMenuStyles();
  const context = useContext(UserContext);
  const dispatch = useAppDispatch();
  const { goTo } = useNav();

  const { isDesktop } = useMediaBreakpoints();
  const logout = async () => {
    try {
      const response = await logoutAction();
      if (!response.data.authenticated) {
        context.user.set({
          email: null,
          nickname: null,
          gender: null,
          credits: 0
        });
        dispatch(logoutUser());
        clearLocalStorageWhenLogout();
        document.location.href = "/";
      }
    } catch (error) {
      RollbarTracker.logError("Error calling logout", error);
      console.error("error: " + error);
    }
  };

  return (
    <Box
      style={{
        display: isDesktop ? "block" : "none"
      }}
    >
      <SectionHeaderTitle text="My account" className={myAccountMenuStyles.menuBoxStyleItemTitle} />
      <Link
        variant="body1"
        color={props.isSelected === "account_details" ? undefined : "inherit"}
        onClick={() => {
          goTo("/profile/account");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        Account Details
      </Link>
      <Link
        variant="body1"
        color={props.isSelected === "account_settings" ? undefined : "inherit"}
        onClick={() => {
          goTo("/profile/account-settings/");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        Settings
      </Link>
      <Link
        variant="body1"
        color={props.isSelected === "account_sync" ? undefined : "inherit"}
        onClick={() => {
          goTo("/profile/account-sync/");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        Account Sync
      </Link>
      <Link
        variant="body1"
        color={props.isSelected === "payment_methods" ? undefined : "inherit"}
        onClick={() => {
          goTo("/profile/payment-methods");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        Payment Methods
      </Link>
      <Link
        variant="body1"
        color={props.isSelected === "gifts_received" ? undefined : "inherit"}
        onClick={() => {
          goTo("/profile/my-gifts");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        My Gifts
      </Link>
      <Link
        variant="body1"
        color={props.isSelected === "order_history" ? undefined : "inherit"}
        onClick={() => {
          goTo("/profile/order-history");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        Order History
      </Link>
      <Link
        variant="body1"
        color="inherit"
        onClick={() => {
          goTo("/faq");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        FAQ & Help Center
      </Link>
      <Link
        variant="body1"
        color="inherit"
        onClick={() => {
          goTo("/contact-us");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        Contact Us
      </Link>
      <Link
        variant="body1"
        color="inherit"
        onClick={() => {
          goTo("/how-it-works");
        }}
        className={myAccountMenuStyles.menuBoxStyleItems}
      >
        How To
      </Link>
      <SecondaryButtonV3
        style={{
          marginTop: 15,
          maxWidth: 128,
          fontWeight: 400,
          borderRadius: 30,
          fontSize: 16,
          fontFamily: FONT_FAMILIES.POPPINS
        }}
        text="Sign Out"
        handleClick={logout}
      />
    </Box>
  );
};

export default MyAccountMenu;
