import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const usesStyles = makeStyles((theme) => ({
  sectionStyle: {
    ...theme.typography.h4,
    color: theme.palette.grey["A400"]
  }
}));

export default function SectionTitle({ className, children, ...rest }: TypographyProps) {
  const classes = usesStyles();

  return (
    <Typography className={clsx(classes.sectionStyle, className)} {...rest}>
      {children}
    </Typography>
  );
}
