import React from "react";
import { TextField, TextFieldProps, styled } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorIcon from "../customIcons/ErrorIcon";
import CheckIcon from "../customIcons/CheckIcon";

const StyledTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: 30
  },
  "& legend": {
    display: "none"
  },
  "& label": {
    background: "white",
    height: "10px",
    padding: "0 5px"
  }
}));

interface TextInputV4Props {
  marginTop?: string;
  width?: string;
  showErrorIcon?: boolean;
  showSuccessIcon?: boolean;
}

type TextInputComponentV4Props = TextFieldProps & TextInputV4Props;

export default function TextInputV4({
  marginTop,
  width,
  showSuccessIcon,
  showErrorIcon,
  variant = "outlined",
  error,
  ...rest
}: TextInputComponentV4Props) {
  return (
    <StyledTextField
      {...rest}
      variant={variant}
      error={error}
      InputProps={{
        ...rest.InputProps,
        endAdornment: (
          <InputAdornment position="start">
            {showSuccessIcon && <CheckIcon style={{ marginTop: "8px", marginRight: 5 }} />}
            {error && showErrorIcon && <ErrorIcon style={{ marginTop: "8px", marginRight: 5 }} />}
          </InputAdornment>
        )
      }}
      style={{
        ...rest.style,
        marginTop,
        width
      }}
    />
  );
}
