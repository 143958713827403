import React from "react";
import AppDialog from "../dialog/AppDialog";
import AppDialogMobile from "../dialog/AppDialogMobile";

interface ProfilePictureDialogProps {
  isMobile: boolean;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ProfilePictureDialog: React.FC<ProfilePictureDialogProps> = (props: ProfilePictureDialogProps) => {
  const { isMobile, open, children } = props;
  if (isMobile) {
    return (
      <AppDialogMobile
        open={open}
        onClose={undefined}
        noHeaderLogo={true}
        isNotTransparent={true}
        fullWidth={false}
        paperClassName={{
          borderRadius: 0
        }}
      >
        {children}
      </AppDialogMobile>
    );
  }

  return (
    <AppDialog open={open} onClose={undefined} fullWidth={false}>
      {children}
    </AppDialog>
  );
};

export default ProfilePictureDialog;
