import * as sdk from "../sdk";
import { AxiosResponse } from "axios";

interface SignInOrSignUpInterface {
  email: string;
  password: string;
  returnTo: string;
}

export const logoutAction = (): Promise<AxiosResponse<any>> => {
  return sdk.logout();
};

export const checkEmailAction = (email: string): Promise<AxiosResponse<any>> => {
  return sdk.checkEmail(email);
};

export const signInOrSignUpAction = (payload: SignInOrSignUpInterface): Promise<AxiosResponse<any>> => {
  return sdk.signInOrSignUp(payload);
};
