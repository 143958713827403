import { FormInput } from "../../input/Form";

export const initialFormInputs = {
  billing_address: FormInput(),
  billing_city: FormInput(),
  billing_state: FormInput(),
  billing_province: FormInput(),
  billing_zip: FormInput(),
  billing_country: FormInput(),
  billing_email: FormInput()
};
