import React from "react";
import { Typography, Box } from "@material-ui/core";
import PrimaryButton from "../buttons/PrimaryButton";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";

export default function GetStartedItem(props: any) {
  const { isDesktop } = useMediaBreakpoints();
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection="column"
      style={{
        position: "relative",
        height: isDesktop ? 420 : 380
      }}
    >
      <Box
        style={{
          height: "100%",
          width: "100%",
          position: "absolute"
        }}
      >
        <div
          style={{
            height: isDesktop ? 180 : 106
          }}
        />
        <Typography
          variant="h3"
          style={{
            color: "#272727",
            position: "absolute",
            textAlign: "left",
            top: isDesktop ? "inherit" : 75,
            left: isDesktop ? 120 : 0,
            fontSize: 36,
            lineHeight: "50px"
          }}
        >
          Gifting has never
          <br />
          been easier!
        </Typography>
        <Box
          textAlign="left"
          style={{
            margin: isDesktop ? "110px 450px auto 120px" : "125px 16px 0 16px"
          }}
          m="auto"
        >
          <Typography variant="body1">
            Gift.me makes your wishlist come true.
            <br />
            The Gifts You Love, for the Lifestyle You Want
          </Typography>
        </Box>
      </Box>
      <PrimaryButton
        handleClick={props.handleClick}
        style={{
          width: isDesktop ? "25%" : "50%",
          position: "absolute",
          left: isDesktop ? "120px" : 0
        }}
      >
        Get Started
      </PrimaryButton>
    </Box>
  );
}
