import React, { FC } from "react";
import Masonry from "react-masonry-css";
import GiftItem from "./GiftItem";
import { GiftInterface } from "../../../models/gift";
import { SetPromptLoginInterface } from "../../../interface/AppInterface";
import "./Gift.css";
import { trackWindowScroll } from "react-lazy-load-image-component";

const itemsBreakpointColumns = {
  default: 4,
  500: 2
};

interface GiftListsProps {
  gifts: Array<GiftInterface>;
  setCurrentGift: React.Dispatch<React.SetStateAction<GiftInterface | null>>;
  setProductModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  columnCount: number;
  storeId: string;
  storeName: string;
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
  scrollPosition: object;
}

const GiftLists = (props: GiftListsProps): ReturnType<FC> => {
  const {
    gifts,
    setCurrentGift,
    setProductModalIsOpen,
    columnCount,
    storeId,
    storeName,
    setPromptLogin,
    scrollPosition
  } = props;

  return (
    <Masonry
      breakpointCols={itemsBreakpointColumns}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
      // style={{
      //   WebkitOverflowScrolling: "unset",
      //   marginBottom: "48px",
      //   columnCount: isTablet ? columnCount : 2,
      //   display: "block"
      // }}
    >
      {gifts.map((gift, idx) => (
        <GiftItem
          key={idx}
          gift={gift}
          setCurrentGift={setCurrentGift}
          setProductModalIsOpen={setProductModalIsOpen}
          storeId={storeId}
          storeName={storeName}
          setPromptLogin={setPromptLogin}
          scrollPosition={scrollPosition}
        />
      ))}
    </Masonry>
  );
};

export default trackWindowScroll(GiftLists);
