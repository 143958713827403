import React, { useState, useEffect, useContext, createContext, ReactElement, Dispatch, SetStateAction } from "react";
import axios from "axios";
import { useAppDispatch } from "../hooks";
import { setUserEncryptedId } from "../reducers/user/UserSlice";

interface IUseApp {
  promptLogin: {
    promptLogin: boolean;
    returnTo: boolean | undefined;
    registration: boolean;
    forceRegistration: boolean;
  };
  encryptedUserId: string | undefined;
  loggedInUserFullname: string;
  sidebarToggle: boolean;
  isFetching: boolean;
  openShareDialog: boolean;
  toastNotification: any;
  setToastNotification: Dispatch<SetStateAction<any>>;
  setSidebarToggle: Dispatch<SetStateAction<boolean>>;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  setEncryptedUserId: Dispatch<SetStateAction<string | undefined>>;
  setLoggedInUserFullname: Dispatch<SetStateAction<string>>;
  setPromptLogin: Dispatch<SetStateAction<any>>;
  setOpenShareDialog: Dispatch<SetStateAction<any>>;
  setToastMessage: (state: any) => void;
}

const AppContext = createContext({});

const AppProvider = (props: any): ReactElement => {
  const dispatch = useAppDispatch();
  const [encryptedUserId, setEncryptedUserId] = useState(undefined);
  const [loggedInUserFullname, setLoggedInUserFullname] = useState<string>(undefined);
  const [promptLogin, setPromptLogin] = useState({
    promptLogin: false,
    returnTo: undefined,
    registration: false,
    forceRegistration: false
  });
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [openShareDialog, setOpenShareDialog] = useState<boolean>(false);
  const [toastNotification, setToastNotification] = useState<any>({
    position: "bottom-center",
    delay: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: false,
    width: 257,
    message1: "",
    message2: ""
  });

  const setToastMessage = (state) => {
    setToastNotification((prev) => {
      return {
        ...prev,
        ...state
      };
    });
  };

  useEffect(() => {
    const getEncryptedUserId = async () => {
      try {
        const response = await axios.get("/api/user/id");
        setEncryptedUserId(response.data.encryptedUserId);
        setLoggedInUserFullname(response.data.nickname);
        dispatch(setUserEncryptedId(response.data.encryptedUserId));
      } catch (error) {
        setEncryptedUserId(undefined);
        setLoggedInUserFullname(undefined);
        dispatch(setUserEncryptedId(null));
      }
    };
    getEncryptedUserId();
  }, []);

  const values: IUseApp = {
    promptLogin,
    setPromptLogin,
    encryptedUserId,
    setEncryptedUserId,
    loggedInUserFullname,
    setLoggedInUserFullname,
    sidebarToggle,
    setSidebarToggle,
    isFetching,
    setIsFetching,
    openShareDialog,
    setOpenShareDialog,
    toastNotification,
    setToastNotification,
    setToastMessage
  };

  return <AppContext.Provider value={values} {...props} />;
};

const useApp = (): IUseApp => useContext(AppContext) as IUseApp;

export { AppProvider, useApp };
