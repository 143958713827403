import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CopyIcon = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="16.266" height="19.719" viewBox="0 0 16.266 19.719"> */}
      <path
        id="Path_744"
        data-name="Path 744"
        d="M5.516-10.523H6.852V-12.25a1.121,1.121,0,0,1,.277-.82,1.12,1.12,0,0,1,.832-.281h3.164v3.633a1.345,1.345,0,0,0,.34,1,1.369,1.369,0,0,0,1,.332H15.8v6.2a1.123,1.123,0,0,1-.277.816,1.111,1.111,0,0,1-.832.285H13.273V.266H14.8a2.363,2.363,0,0,0,1.762-.6,2.446,2.446,0,0,0,.59-1.781V-8.031a3.808,3.808,0,0,0-.164-1.2,2.455,2.455,0,0,0-.6-.937l-3.7-3.773a2.373,2.373,0,0,0-.9-.59,3.284,3.284,0,0,0-1.109-.176h-2.8a2.366,2.366,0,0,0-1.758.6,2.436,2.436,0,0,0-.594,1.781Zm6.766.625v-2.859l3.18,3.227h-2.82A.321.321,0,0,1,12.281-9.9ZM1.883,1.633a2.436,2.436,0,0,0,.594,1.781,2.366,2.366,0,0,0,1.758.6h6.938a2.366,2.366,0,0,0,1.758-.6,2.436,2.436,0,0,0,.594-1.781V-4.086a3.956,3.956,0,0,0-.133-1.152,2.335,2.335,0,0,0-.594-.887l-4.031-4.1a2.231,2.231,0,0,0-.863-.586,3.477,3.477,0,0,0-1.074-.141H4.234a2.366,2.366,0,0,0-1.758.6A2.436,2.436,0,0,0,1.883-8.57ZM3.227,1.57V-8.516a1.089,1.089,0,0,1,.281-.8A1.123,1.123,0,0,1,4.336-9.6H6.672v4.133a1.523,1.523,0,0,0,.375,1.133,1.536,1.536,0,0,0,1.117.367h4.023V1.57a1.114,1.114,0,0,1-.281.816,1.125,1.125,0,0,1-.836.285H4.328A1.1,1.1,0,0,1,3.5,2.387,1.123,1.123,0,0,1,3.227,1.57ZM8.3-5.172a.438.438,0,0,1-.32-.1.45.45,0,0,1-.1-.328V-9.328l4.086,4.156Z"
        transform="translate(-1.383 15.203)"
        fill="#484848"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default CopyIcon;
