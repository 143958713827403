import React, { ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { MuiThemeProvider, Theme, BoxProps } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AppHeaderV2 from "../appHeader/AppHeaderV2";
import AppFooterV2 from "../appFooter/AppFooterV2";
import { useApp } from "../../../context/app-context";
import { BACKGROUND } from "../../../style";
import ShareUrlDialog from "../shareDialog/ShareUrlDialog";
import ToastContainer from "../toast/ToastContainer";
import { FOOTER_VARIANT1, FOOTER_VARIANT2 } from "../appFooter/FooterVariants";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";
import { getBrowserDetails } from "../../../utils/helpers";

interface AppLayoutProps extends BoxProps {
  theme: Theme;
  darkMode?: boolean;
  hasFooter?: boolean;
  children: ReactNode;
  headerVariant?: "header1" | "header2";
  footerVariant?: typeof FOOTER_VARIANT1 | typeof FOOTER_VARIANT2;
  backgroundColor?: string;
  footerBottomFixed?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  theme,
  children,
  headerVariant = "header1",
  footerVariant = "footer1",
  backgroundColor = BACKGROUND.bgColor,
  style,
  footerBottomFixed,
  ...rest
}: AppLayoutProps) => {
  const { isMobile } = useMediaBreakpoints();
  const { encryptedUserId, openShareDialog, setOpenShareDialog } = useApp();
  const location = useLocation();
  const hideFooterOnMobileStoreCategoryPage = useMemo(
    () => location.pathname.startsWith("/store/store/") && isMobile,
    [location, isMobile]
  );

  // force scroll to top when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        width={{ lg: "100%" }}
        marginX={{ lg: "auto" }}
        style={{
          background: backgroundColor,
          marginBottom: -68,
          marginTop: 0,
          marginRight: getBrowserDetails().isIpad ? "25px" : "inherit",
          ...style
        }}
        {...rest}
      >
        <AppHeaderV2 variant={headerVariant} />
        {children}
        {!hideFooterOnMobileStoreCategoryPage && (
          <AppFooterV2 variant={footerVariant} footerBottomFixed={footerBottomFixed} />
        )}
        <ShareUrlDialog
          open={openShareDialog}
          onClose={() => setOpenShareDialog(false)}
          shareContentDescription="Wishlist URL address"
          shareUrl={"https://" + window.location.host + "/gift/wishlist/" + encryptedUserId}
        />
        <ToastContainer />
      </Box>
    </MuiThemeProvider>
  );
};

export default AppLayout;
