import React, { Fragment } from "react";
import { Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { setSelectedRecipient } from "../../../utils/localStorage";

const useStyles = makeStyles((theme: Theme) => ({
  numberOfCredit: {
    ...theme.typography.subtitle1
  }
}));

export default function RecipientItem(props: any) {
  const { recipient, index, count } = props;
  const styles = useStyles();
  const history = useHistory();

  return (
    <Fragment>
      <ListItem
        style={{
          paddingLeft: "16px"
        }}
        divider={index < count - 1}
        disableGutters
        button
        onClick={() => {
          setSelectedRecipient(recipient);
          history.push(`/gift/wishlist/${recipient.userId}`);
        }}
      >
        <ListItemText
          primary={recipient.nickname}
          primaryTypographyProps={{
            style: { wordBreak: "break-word" }
          }}
          secondary={
            <Fragment>
              <Typography component="h6" variant="body2" className={styles.numberOfCredit}>
                {recipient.giftCount} Items
              </Typography>
            </Fragment>
          }
        />
      </ListItem>
    </Fragment>
  );
}
