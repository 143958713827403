import React, { useState, memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppDialog from "../common/dialog/AppDialog";
import PostSignupDialogContent from "./PostSignupDialogContent";
import useNav from "../../hooks/useNav";
import { STOREHOME_PATH } from "../../constants/paths";
import queryString from "query-string";
import theme from "../../theme/theme-devins";
import { MuiThemeProvider } from "@material-ui/core";

export const PostSignupDialog = () => {
  const { goTo } = useNav();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [title, setTitle] = useState("Welcome to Gift.me");
  const [hasOnClose, setHasOnClose] = useState<boolean>(false);

  const handleCloseDialog = () => {
    goTo(STOREHOME_PATH);
    setOpenDialog(false);
  };

  useEffect(() => {
    const urlParams = queryString.parse(location.search);
    if (localStorage.getItem("userFirstTimeLogin") === "1" || urlParams?.userFirstTimeLogin === "1") {
      setOpenDialog(true);
    }
  }, [location.search]);

  useEffect(() => {
    setHasOnClose(step !== 1);

    if (step === 2) {
      setTitle("Add Photo (optional)");
      return;
    }

    if (step === 3) {
      setTitle("Tell us about yourself");
      return;
    }

    setTitle("Welcome to Gift.me");
  }, [step]);

  return (
    <AppDialog open={openDialog} fullWidth={false} onClose={hasOnClose ? handleCloseDialog : undefined}>
      <MuiThemeProvider theme={theme}>
        <PostSignupDialogContent closeDialog={handleCloseDialog} title={title} step={step} setStep={setStep} />
      </MuiThemeProvider>
    </AppDialog>
  );
};

const MemoizedPostSignupDialog = memo(PostSignupDialog);

export default MemoizedPostSignupDialog;
