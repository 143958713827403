import React, { FC } from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import AddedToWishlistIcon from "@material-ui/icons/Favorite";
import AddToWishlistIcon from "@material-ui/icons/FavoriteBorder";
import cx from "classnames";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { backgroundTheme } from "../../style";
import { buttonColor } from "../../style/index";

const useWishListButtonStyles = makeStyles<Theme>(() => ({
  button: {
    backgroundColor: backgroundTheme.wishlistButtonFill,
    borderRadius: "25px",
    position: "absolute",
    color: buttonColor.PINK,
    "&:hover": {
      backgroundColor: backgroundTheme.wishlistButtonFill,
      "& $fakeIcon": {
        display: "block"
      },
      "& $icon": {
        display: "none"
      },
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: backgroundTheme.wishlistButtonFill,
        "& $fakeIcon": {
          display: "none"
        },
        "& $icon": {
          display: "block"
        }
      }
    }
  },
  fakeIcon: {
    display: "none",
    alignSelf: "center",
    width: 25,
    height: 25,
    transition: "display 0s ease-out 0.3s"
  },
  selectedButton: {
    backgroundColor: backgroundTheme.wishlistButtonFill,
    borderRadius: "25px",
    position: "absolute",
    "&:hover": {
      backgroundColor: backgroundTheme.wishlistButtonFill,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: backgroundTheme.wishlistButtonFill
      }
    }
  },
  icon: {
    alignSelf: "center",
    width: 25,
    height: 25
  },
  selectedIcon: {
    color: buttonColor.PINK
  }
}));

interface WishlistButtonProps extends IconButtonProps {
  addedToWishlist: boolean;
  handleClick: NonNullable<IconButtonProps["onClick"]>;
}

const WishlistButton = ({ addedToWishlist, handleClick, ...rest }: WishlistButtonProps): ReturnType<FC> => {
  const classes = useWishListButtonStyles();
  return (
    <IconButton onClick={handleClick} className={addedToWishlist ? classes.selectedButton : classes.button} {...rest}>
      {addedToWishlist ? (
        <AddedToWishlistIcon className={cx(classes.icon, classes.selectedIcon)} />
      ) : (
        <>
          <AddToWishlistIcon className={classes.icon} />
          <AddedToWishlistIcon className={classes.fakeIcon} />
        </>
      )}
    </IconButton>
  );
};

export default WishlistButton;
