import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core/";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import SupportedCards from "../supported_cards.png";
import PaymentInformationForm from "../PaymentInformationForm";
import { CARD_CVV_LABEL, CARD_CVV_SIZE } from "../../input/card/CardUtils";
import Form from "../../input/Form";
import { initialFormInputs } from "./PaymentMethodFormInputs";
import PrimaryButton from "../../buttons/PrimaryButton";
import axios from "axios";
import Toast from "light-toast";
import EditCardInformationForm from "../EditCardInformationForm";
import { updateCard } from "../../../actions/paymentAction";

const EditAPaymentMethodContent = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  supportedCardsImg: {
    width: "193px",
    height: "34px"
  },
  paymentInfoCaption: {
    ...theme.typography.body2,
    fontStyle: "italic",
    color: theme.palette.grey[500]
  },
  addButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  }
}));

export default function EnterAPaymentMethodContent(props) {
  const styles = EditAPaymentMethodContent();
  const { handleOnPaymentMethodEdited, selectedPaymentInfoToEdit } = props;
  const [isSaving, seIsSaving] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (let inputName in inputs) {
      const formInput = inputs[inputName];

      if ("exp_month" === inputName) {
        if (formInput.value === "") {
          formInput.error = "Expiration Month is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter a 2 digit expiry month";
        } else {
          formInput.error = "";
        }
      } else if ("exp_year" === inputName) {
        if (formInput.value === "") {
          formInput.error = "Expiration Year is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter the last 2 digits of expiry year!";
        } else {
          formInput.error = "";
        }
      } else if ("csv" === inputName) {
        validateCSV(formInput);
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const validateCSV = (csvInput) => {
    if (csvInput.value === "") {
      csvInput.error = "Card " + csvInput.label + " is required";
    } else if (csvInput.value.length !== csvInput.maxLength) {
      csvInput.error = csvInput.maxLength + " digit " + csvInput.label + " required";
    } else {
      csvInput.error = "";
    }
  };

  const onBindCardValidate = (onValidateHook) => {
    formInputs.card.onValidate = onValidateHook;
  };

  const onCardInputDataUpdated = (cardNumber, validation, error) => {
    formInputs.card.cardNumber = cardNumber;
    formInputs.card.validation = validation;
    formInputs.card.error = error;

    let csvLabel;
    let csvMaxLength;
    let csvInput = formInputs.csv;
    let card = validation && validation.card ? validation.card : undefined;

    if (card) {
      csvLabel = card.code.name;
      csvMaxLength = card.code.size;
    } else {
      csvLabel = CARD_CVV_LABEL;
      csvMaxLength = CARD_CVV_SIZE;
    }

    if (csvLabel !== csvInput.label || csvMaxLength !== csvInput.maxLength) {
      csvInput.label = csvLabel;
      csvInput.maxLength = csvMaxLength;

      if (csvInput.value !== "" || csvInput.error !== "") {
        validateCSV(csvInput);
      }

      setFormInputs({
        ...formInputs
      });
    }
  };

  const editPaymentMethod = async () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });
      return;
    }

    seIsSaving(true);
    try {
      const payload = {
        csv: formInputs.csv.value,
        exp_month: formInputs.exp_month.value,
        exp_year: `20${formInputs.exp_year.value}`,
        billing_address: selectedPaymentInfoToEdit.billing_address,
        billing_city: selectedPaymentInfoToEdit.billing_city,
        billing_country: selectedPaymentInfoToEdit.billing_country,
        billing_email: selectedPaymentInfoToEdit.billing_email,
        billing_name: selectedPaymentInfoToEdit.billing_name,
        billing_state: selectedPaymentInfoToEdit.billing_state,
        billing_zip: selectedPaymentInfoToEdit.billing_zip
      };
      const response = await updateCard(selectedPaymentInfoToEdit.payment_profile_id, payload);

      handleOnPaymentMethodEdited(response.data.payment_profile_id, payload);
      seIsSaving(false);
    } catch (error) {
      console.log(error);
      Toast.fail("Error adding new card. Please try again.");
      seIsSaving(false);
    }
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  if (isFirstLoad) {
    formInputs.exp_month.value =
      selectedPaymentInfoToEdit.exp_month.length === 1
        ? `0${selectedPaymentInfoToEdit.exp_month}`
        : selectedPaymentInfoToEdit.exp_month;

    // get only last 2 digits
    formInputs.exp_year.value = selectedPaymentInfoToEdit.exp_year.toString().substr(-2);
  }

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  return (
    <Box>
      <SectionTitle>Edit a payment method</SectionTitle>

      <EditCardInformationForm
        exp_month={formInputs.exp_month ? formInputs.exp_month : ""}
        exp_year={formInputs.exp_year ? formInputs.exp_year : ""}
        csv={formInputs.csv ? formInputs.csv : ""}
        onBindCardValidate={onBindCardValidate}
        onCardInputDataUpdated={onCardInputDataUpdated}
        handleInputChange={handleInputChange}
        handleOnBlur={handleOnBlur}
        handleOnFocus={handleOnFocus}
        style={{ marginTop: "16px" }}
      />

      <PrimaryButton className={styles.addButton} handleClick={() => editPaymentMethod()} disabled={isSaving}>
        {isSaving ? "Editing card..." : "Edit card"}
      </PrimaryButton>
    </Box>
  );
}
