import React, { useEffect, useState } from "react";
import { Typography, Box, useMediaQuery, Theme, makeStyles, Link } from "@material-ui/core";
import Section from "./common/Section";
import PrimaryLink from "../buttons/PrimaryLink";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./css/HowItWorksMenuV2.css";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { getGiftsAction } from "../../actions/giftAction";
import { CURRENCY } from "../common/Constants";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 555,
    margin: "auto",
    textAlign: "left",
    marginRight: 0,
    marginTop: 15
  },
  listItem: {
    width: 260,
    display: "flex",
    marginBottom: 20,
    marginTop: 15
  },
  root: {
    width: "162px",
    whiteSpace: "pre-wrap",
    fontWeight: 400,
    fontSize: "14px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      width: "290px",
      display: "block",
      marginBottom: "30px"
    }
  },
  price: {
    fontSize: "16px",
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      display: "block",
      marginBottom: "30px"
    }
  },
  text: {
    fontSize: "24px",
    lineHeight: "31px",
    color: "#2D2D2D",
    paddingBottom: "12px"
  },
  subText: {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#2D2D2D",
    paddingBottom: "12px"
  },
  howItWorksSection: {
    textAlign: "left",
    marginTop: "25px",
    padding: "0 12px",
    margin: "auto"
  },
  arrowLeft: {
    position: "absolute",
    left: "25px",
    [theme.breakpoints.up("md")]: {
      left: "25px"
    }
  },
  arrowRight: {
    position: "absolute",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      right: "25px"
    }
  },
  sliderWrapper: {
    [theme.breakpoints.up("md")]: {
      margin: "0px 70px"
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "8px"
    }
  },
  title: {
    display: "inline-block",
    paddingTop: "8px",
    fontWeight: 400,
    color: "#2d2d2d",
    [theme.breakpoints.down("md")]: {
      fontWeight: 700,
      fontSize: "20px"
    }
  },
  viewAll: {
    color: "#2c2c2c",
    fontSize: "16px",
    "&:hover, &:focus": {
      textDecoration: "none"
    }
  },
  viewAllArrowLeft: {
    fontSize: "16px",
    left: "56px",
    top: "10px",
    position: "absolute"
  }
}));

// One item component selected prop will be passed
const MenuItem = ({ title, src, toUrl, selected, price }) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const classes = useStyles();
  const content = (
    <div>
      <img
        src={src}
        alt={title}
        style={{
          width: isDesktop ? "180px" : "160px",
          height: isDesktop ? "258px" : "200px"
        }}
      />
      <div className={`menu-item ${selected ? "active" : ""}`}>
        <Typography className={classes.root}>{title}</Typography>
        <Typography className={`${classes.root} ${classes.price}`}>{`${CURRENCY}${price.toFixed(2)}`}</Typography>
      </div>
    </div>
  );

  return src ? (
    <PrimaryLink text={content} href={toUrl} underline="none" />
  ) : (
    <Typography className={classes.root}>{title}</Typography>
  );
};

const Menu = (list, selected) =>
  list.map((el, i: number) => {
    const { name, title, src, toUrl, price } = el;
    return <MenuItem key={name} title={title} src={src} toUrl={toUrl} selected={selected} price={price} />;
  });

const Arrow = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

const StoreHomeMenuSlider = () => {
  const classes = useStyles();
  const [storeList, setStoreList] = useState([]);

  const ArrowLeft = Arrow({ children: <ArrowBack />, className: classes.arrowLeft });
  const ArrowRight = Arrow({ children: <ArrowForward />, className: classes.arrowRight });
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const fetchStorePopular = async () => {
    const payload = {
      storeId: 407569,
      page: 1,
      pageSize: 9,
      product_filters: { sort: "popular" }
    };

    return await getGiftsAction(payload);
  };

  useEffect(() => {
    fetchStorePopular().then((response) => {
      const gifts = Object.values(response.data.gifts);
      gifts.slice(0, 10).map(({ gift_id, image_url_high_res, name, pricing }) =>
        setStoreList((storeList) => [
          ...storeList,
          {
            name: gift_id,
            title: name,
            src: image_url_high_res,
            toUrl: `/store/407569/☆%20Popular%20☆/gift/${gift_id}`,
            price: pricing.price_converted
          }
        ])
      );
    });
  }, []);

  if (!storeList) {
    return null;
  }

  const menuItems = Menu(storeList, { key: 0 });

  return (
    <Section
      height={isDesktop ? "470px" : "400px"}
      style={{
        display: "block"
      }}
      contentProps={{
        maxWidth: "1920px !important"
      }}
    >
      <Box style={{ maxWidth: 1920 }}>
        <Typography variant={"h3"} style={{ margin: isDesktop ? "0px 0 24px" : "24px 0 24px" }}>
          {"Most-Wished Items"}
        </Typography>
        <Typography
          variant={"h6"}
          style={{
            position: "absolute",
            right: isDesktop ? "90px" : "30px",
            top: isDesktop ? 0 : "20px"
          }}
        >
          <Link href={"/store/store/407569/☆%20Popular%20☆"} className={`${classes.title} ${classes.viewAll}`}>
            View All
            <ArrowForwardIosIcon className={classes.viewAllArrowLeft} />
          </Link>
        </Typography>
        <ScrollMenu
          wrapperClass={classes.sliderWrapper}
          arrowLeft={isDesktop ? ArrowLeft : null}
          arrowRight={isDesktop ? ArrowRight : null}
          data={menuItems}
          hideSingleArrow={true}
          alignCenter={false}
          itemClass={isDesktop ? "menu-item-wrapper-desktop" : "menu-item-wrapper"}
          wheel={false}
        />
      </Box>
    </Section>
  );
};

export default StoreHomeMenuSlider;
