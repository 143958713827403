import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

interface IUserIconProps extends SvgIconProps {
  stroke?: string;
}

const UserIcon = ({ stroke = "white", ...rest }: IUserIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...rest}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 7C16 7.79113 15.7654 8.56448 15.3259 9.22228C14.8864 9.88008 14.2616 10.3928 13.5307 10.6955C12.7998 10.9983 11.9956 11.0775 11.2196 10.9231C10.4437 10.7688 9.73098 10.3878 9.17157 9.82843C8.61216 9.26902 8.2312 8.55629 8.07686 7.78036C7.92252 7.00444 8.00173 6.20017 8.30448 5.46927C8.60723 4.73836 9.11992 4.11365 9.77772 3.67412C10.4355 3.2346 11.2089 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7ZM15 15H9C7.67392 15 6.40215 15.5268 5.46447 16.4645C4.52678 17.4021 4 18.6739 4 20V20C4 20.2652 4.10536 20.5196 4.29289 20.7071C4.48043 20.8946 4.73478 21 5 21H19C19.2652 21 19.5196 20.8946 19.7071 20.7071C19.8946 20.5196 20 20.2652 20 20C20 18.6739 19.4732 17.4021 18.5355 16.4645C17.5979 15.5268 16.3261 15 15 15Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default UserIcon;
