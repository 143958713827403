import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import Form from "../../input/Form";
import PrimaryButton from "../../buttons/PrimaryButton";
import BillingInformationForm from "../BillingInformationForm";
import { initialFormInputs } from "./BillingAddressFormInputs";

const AddNewBillingAddressContentStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  addButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  }
}));

export default function AddNewBillingContent(props) {
  const styles = AddNewBillingAddressContentStyles();

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (let inputName in inputs) {
      const formInput = inputs[inputName];

      if ("billing_address" === inputName) {
        formInput.error = formInput.value === "" ? "Billing Address is required" : "";
      } else if ("billing_country" === inputName) {
        formInput.error = formInput.value === "" ? "Country is required" : "";
      } else if ("billing_city" === inputName) {
        formInput.error = formInput.value === "" ? "City is required" : "";
      } else if ("billing_state" === inputName && formInputs["billing_country"].name === "US") {
        formInput.error = formInput.value === "" ? "State is required" : "";
      } else if ("billing_province" === inputName && formInputs["billing_country"].name !== "US") {
        formInput.error = formInput.value === "" ? "State/Province is required" : "";
      } else if ("billing_zip" === inputName) {
        formInput.error = formInput.value === "" ? "Zip Code is required" : "";
      } else if ("billing_email" === inputName) {
        formInput.error = formInput.value === "" ? "Email is required" : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const addBillingAddress = () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });
      return;
    }

    // TODO @bikrant: integrate with the API
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  return (
    <Box>
      <SectionTitle>Add a new billing address</SectionTitle>

      <BillingInformationForm
        billing_address={formInputs.billing_address ? formInputs.billing_address : ""}
        billing_zip={formInputs.billing_zip ? formInputs.billing_zip : ""}
        billing_city={formInputs.billing_city ? formInputs.billing_city : ""}
        billing_state={formInputs.billing_state ? formInputs.billing_state : ""}
        billing_province={formInputs.billing_province ? formInputs.billing_province : ""}
        billing_country={formInputs.billing_country ? formInputs.billing_country : ""}
        billing_email={formInputs.billing_email ? formInputs.billing_email : ""}
        handleInputChange={handleInputChange}
        handleOnBlur={handleOnBlur}
        handleOnFocus={handleOnFocus}
        style={{ marginTop: "18px" }}
      />

      <PrimaryButton className={styles.addButton} handleClick={() => addBillingAddress()}>
        Add new billing address
      </PrimaryButton>
    </Box>
  );
}
