import React from "react";
import { Typography, Box, useMediaQuery, Theme, BoxProps, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../style";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    alignItems: "start"
  },
  infoWrapper: {
    marginLeft: 30,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20
    }
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    marginBottom: 10,
    fontSize: 24,
    color: COLORS.LIGHT_BLACK,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    }
  },
  description: {
    marginBottom: 16,
    fontWeight: 400,
    fontSize: 16,
    color: COLORS.LIGHT_GRAY,
    lineHeight: "26px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      lineHeight: "21px",
      fontSize: 14
    }
  },
  lists: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "26px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      lineHeight: "21px",
      fontSize: 14
    }
  }
}));

interface SectionLabelProps extends BoxProps {
  iconSrc: string;
  title: string;
  description: string;
  lists: string[];
}

const SectionLabel = ({ iconSrc, title, description, lists, ...rest }: SectionLabelProps): ReturnType<React.FC> => {
  const classes = useStyles();
  return (
    <Box className={classes.section} {...rest}>
      <img src={`/assets/home/homeV4/how-it-works/${iconSrc}`} />
      <div className={classes.infoWrapper}>
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        <Typography variant="subtitle2" className={classes.description}>
          {description}
        </Typography>
        {lists.map((item, i) => {
          return (
            <Box style={{ display: "flex" }} key={i}>
              <Typography variant="subtitle2" className={classes.lists}>
                ■
              </Typography>
              <Typography variant="subtitle2" className={classes.lists} style={{ marginLeft: 3 }}>
                {item}
              </Typography>
            </Box>
          );
        })}
      </div>
    </Box>
  );
};

export default SectionLabel;
