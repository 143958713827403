import React from "react";
import { Typography, Box } from "@material-ui/core";
import { GiftDetailsStyles } from "./GiftDetails";

const GiftDetailsMessage = ({ message }: { message: string }) => {
  const giftDetailsStyles = GiftDetailsStyles();
  return (
    <Box className={giftDetailsStyles.subColumn}>
      <Typography variant="body1" className={giftDetailsStyles.subTitle}>
        Gift Message
      </Typography>
      <Typography variant="body1">“{message}”</Typography>
    </Box>
  );
};

export default GiftDetailsMessage;
