import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const usesStyles = makeStyles((theme) => ({
  sectionStyle: {
    color: theme.palette.text.secondary
  }
}));

const SectionSubtitle = ({ className, children, ...rest }: TypographyProps) => {
  const classes = usesStyles();

  return (
    <Typography variant="subtitle1" className={clsx(classes.sectionStyle, className)} {...rest}>
      {children}
    </Typography>
  );
};

export default SectionSubtitle;
