import React from "react";
import { Typography, Box, makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import PrimaryButton from "../../buttons/PrimaryButton";
import Section from "../common/Section";
import { useApp } from "../../../context/app-context";
import FadeIn from "../../../animations/fade-in";
import { COLORS } from "../../../style";

const useStyles = makeStyles((theme) => ({
  section: {
    height: 785,
    alignItems: "center",
    backgroundColor: "#090909",
    backgroundImage: `url(${"/assets/home/homeV4/heroimage-2x.jpg"})`,
    backgroundRepeat: "no-repeat",
    marginTop: 68,
    [theme.breakpoints.down("md")]: {
      backgroundPositionX: "center",
      height: 714,
      overflow: "hidden"
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      paddingTop: 73,
      overflow: "hidden"
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${"/assets/home/homeV4/mobilebg.png"})`,
      paddingTop: 73,
      overflow: "hidden"
    }
  },
  heroWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  boxHeadlineContainer: {
    width: 416,
    background: "none",
    marginBottom: 150,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 0,
      textAlign: "left"
    }
  },
  boxCircleSunContainer: {
    width: 899,
    height: 854,
    position: "absolute",
    left: 300,
    transform: "translate(0, -39px)",
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    " & > img": {
      position: "absolute"
    },
    [theme.breakpoints.down("md")]: {
      transform: "translate(-100px, -39px)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      left: "50%",
      transform: "translate(-50%, 1px)",
      " & > img": {
        position: "absolute",
        transform: "scale(0.82)"
      }
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      left: "50%",
      transform: "translate(-50%, -27px)",
      " & > img": {
        position: "absolute",
        transform: "scale(0.82)"
      }
    }
  },
  subtitle: {
    marginTop: 20,
    marginBottom: 24,
    lineHeight: "28px",
    [theme.breakpoints.down("md")]: {
      marginBottom: 17,
      fontSize: 16,
      lineHeight: "26px"
    }
  },
  button: {
    width: 148,
    height: 40,
    fontFamily: "Sofia Pro",
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "22.4px",
    borderRadius: 8,
    background: COLORS.MEDIUM_PINK,
    marginBottom: 33
  }
}));

const Hero = () => {
  const { setPromptLogin } = useApp();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const classes = useStyles();

  return (
    <Section className={classes.section}>
      <FadeIn delay={200} direction="right">
        <>
          <Box className={classes.heroWrapper}>
            <Box className={classes.boxHeadlineContainer}>
              <Typography variant="h2">Gifts Manifested.</Typography>
              <Typography variant="h2">Create Your</Typography>
              <Typography variant="h2">Wishlist Now.</Typography>
              <Typography variant="h6" className={classes.subtitle}>
                Gift.me makes your wishlist come true.
              </Typography>
            </Box>
            <Box className={classes.boxCircleSunContainer}>
              <img
                src={
                  isDesktop ? "/assets/home/homeV4/hero_sun.png" : "/assets/home/homeV4/hero_sun_mobile.png"
                }
              />
              <Typography
                variant="h4"
                style={{ zIndex: 1, marginBottom: 24, fontSize: 24, marginTop: isDesktop ? "unset" : 40 }}
              >
                Create Your Wishlist
              </Typography>
              <PrimaryButton
                handleClick={() => {
                  setPromptLogin({
                    promptLogin: true,
                    returnTo: undefined,
                    registration: true,
                    forceRegistration: true
                  });
                }}
                className={classes.button}
              >
                Get Started
              </PrimaryButton>
            </Box>
          </Box>
        </>
      </FadeIn>
    </Section>
  );
};

export default Hero;
