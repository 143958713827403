import React, { useState } from "react";
import { Box, CircularProgress, Link, Theme, Typography, useMediaQuery, Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { backgroundTheme, textColor } from "../../style";
import Form, { FormInput } from "../input/Form";
import ContactUsForm from "./ContactUsForm";
import Toast from "light-toast";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "../../theme/theme-devins";
import Section from "../common/appLayout/Section";
import { FOOTER_VARIANT2 } from "../common/appFooter/FooterVariants";

const useStyles = makeStyles(() => ({
  title: {
    padding: "40px 16px 30px 0px",
    marginRight: "10px",
    color: textColor.GRAY
  },
  text: {
    fontSize: "12px",
    paddingBottom: "20px"
  },
  email: {
    fontWeight: 600,
    textDecoration: "underline",
    color: textColor.NORMAL,
    paddingBottom: "20px"
  },
  image: {
    width: "100%",
    padding: "40px 0"
  },
  input: {
    fontSize: 16,
    paddingBottom: "20px"
  },
  button: {
    width: "32%"
  }
}));

const initialFormInputs = {
  name: FormInput(),
  email: FormInput(),
  body: FormInput()
};

export default function ContactUs() {
  const classes = useStyles();

  const [isFetching, setIsFetching] = useState(false);

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (const inputName in inputs) {
      const formInput = inputs[inputName];

      if ("name" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("email" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("body" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setFormInputs({ ...formInputs });

      return;
    }

    // @ts-ignore
    document.getElementById("body").value = "Gift.Me " + document.getElementById("body").value;
    // @ts-ignore
    document.getElementById("formContactUs").submit();

    Toast.info("Successfully submitted your inquiry...", 1000);
  };

  return (
    <AppLayout
      theme={theme}
      headerVariant="header1"
      backgroundColor={backgroundTheme.grayFill}
      footerVariant={FOOTER_VARIANT2}
    >
      <Section backgroundImage="url(/assets/secondary_bg.svg)">
        <Box
          style={{
            background: "url(/assets/secondary_bg.svg) center top / 100% no-repeat"
          }}
        >
          {isFetching ? (
            <Box
              style={{
                height: "150px",
                justifyContent: "center",
                position: "fixed",
                left: "49%",
                top: "50%",
                zIndex: 9999
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
          <Grid container spacing={1}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Box
                style={
                  isDesktop
                    ? {
                        margin: "40px 90px 0 0"
                      }
                    : {
                        margin: "25px 15px 0 15px"
                      }
                }
              >
                <div>
                  <Typography variant="h4" className={classes.title}>
                    Contact Us
                  </Typography>
                  <Typography variant="body2" className={classes.text}>
                    Questions on how to send or receive the perfect gifts? We’re here to help!
                    <br />
                    You can also email us directly at:
                  </Typography>
                  <Typography variant="body1">
                    <a href="mailto:hello@gift.me" className={classes.email}>
                      hello@gift.me
                    </a>
                  </Typography>
                  {isDesktop && <img src="/assets/contact.png" alt="contact-us" className={classes.image} />}
                </div>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box
                style={
                  isDesktop
                    ? {
                        margin: "40px 15px 0 0"
                      }
                    : {
                        margin: "25px 15px 0 15px"
                      }
                }
              >
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={
                    isDesktop
                      ? {
                          paddingTop: "40px"
                        }
                      : {
                          paddingTop: "0px"
                        }
                  }
                >
                  If you're looking for an immediate answer, we've covered the most common questions we get in our
                  &nbsp;
                  <Link href={"/faq"} target={"_blank"} rel="noopener" underline="none">
                    FAQs
                  </Link>
                  .
                </Typography>
                <ContactUsForm
                  name={formInputs.name}
                  email={formInputs.email}
                  body={formInputs.body}
                  handleInputChange={handleInputChange}
                  handleOnFocus={handleOnFocus}
                  handleOnBlur={handleOnBlur}
                  handleOnSubmit={handleOnSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Section>
    </AppLayout>
  );
}
