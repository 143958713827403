import React, { useState } from "react";
import { Link } from "@material-ui/core";
import Confirmation from "../common/Confirmation";
import Toast from "light-toast";
import { OrderInterface } from "../../models/order";
import { declineGift } from "../../actions/giftAction";

interface DeclineAGiftButtonProps {
  order: OrderInterface;
  onGiftDeclined: () => void;
}

const DeclineAGiftButton: React.FC<DeclineAGiftButtonProps> = ({ order, onGiftDeclined }: DeclineAGiftButtonProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const declineGiftHandler = () => {
    setOpen(true);
  };

  const handleCancelHandler = () => {
    setOpen(false);
  };

  const handleOkHandler = async () => {
    setIsProcessing(true);
    try {
      await declineGift(order.order_id);
      setIsProcessing(false);
      onGiftDeclined();
    } catch (error) {
      Toast.fail(error.response.data.message, 3000);
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Link
        onClick={declineGiftHandler}
        color="inherit"
        variant="body2"
        component="button"
        underline="always"
        style={{ alignSelf: "center" }}
      >
        Decline Gift
      </Link>
      <Confirmation
        processing={isProcessing}
        open={open}
        handleCancel={handleCancelHandler}
        handleOk={handleOkHandler}
        content={"Are you sure you want to Decline this Gift?"}
      />
    </>
  );
};

export default DeclineAGiftButton;
