import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppBar, Box, Toolbar, makeStyles, Theme } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HeaderLogo from "../appHeader/header_logo_v3.png";
import {
  SYNC_HEADER_HEIGHT,
  TOOLBAR_DEFAULT_HEIGHT,
  TOOLBAR_SM_HEIGHT,
  TOOLBAR_XS_HEIGHT
} from "../DesktopAppHeader/DesktopAppHeaderStyles";
import { getLogoDefaultStateLeft } from "../appHeader/AppHeaderStyles";
import { DESKTOP_CONTENT_WIDTH } from "../Constants";
import { HEADER } from "../../../style/index";

const useStyles = makeStyles((theme: Theme) => ({
  appBarStyle: {
    backgroundColor: HEADER.headerBg,
    zIndex: 1298,
    padding: "0 16px"
  },
  toolbarStyle: {
    minHeight: 63,
    justifyContent: "space-between",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_WIDTH,
      margin: "0 auto"
    }
  },
  logoStyle: {
    position: "absolute",
    left: getLogoDefaultStateLeft(TOOLBAR_DEFAULT_HEIGHT),
    alignSelf: "center",
    padding: "5px",
    maxWidth: 94,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      left: getLogoDefaultStateLeft(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      left: getLogoDefaultStateLeft(TOOLBAR_SM_HEIGHT)
    },
    transition: "left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  headerIconStyle: {
    alignSelf: "center",
    color: "#333333",
    width: TOOLBAR_DEFAULT_HEIGHT,
    height: TOOLBAR_DEFAULT_HEIGHT,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      width: TOOLBAR_XS_HEIGHT,
      height: TOOLBAR_XS_HEIGHT
    },
    [theme.breakpoints.up("sm")]: {
      width: TOOLBAR_SM_HEIGHT,
      height: TOOLBAR_SM_HEIGHT
    }
  },
  appBarSpaceStyle: {
    minHeight: TOOLBAR_DEFAULT_HEIGHT + SYNC_HEADER_HEIGHT,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      minHeight: TOOLBAR_XS_HEIGHT + SYNC_HEADER_HEIGHT
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: TOOLBAR_SM_HEIGHT + SYNC_HEADER_HEIGHT
    }
  }
}));

export function CheckoutPageHeader(props: any) {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();

  const navigateToStore = () => {
    if (location.pathname.startsWith("/checkoutresult/")) {
      history.push("/store/storehome");
    }

    return;
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={styles.appBarStyle}
        style={{
          boxShadow: "0px 8px 8px -8px rgba(0,0,0,0.1)"
        }}
      >
        <Toolbar className={styles.toolbarStyle}>
          {!props.hasConfirmedPayment && (
            <ArrowBackIosIcon
              onClick={() => {
                history.goBack();
              }}
              style={{
                cursor: "pointer",
                color: "#ffffff"
              }}
            />
          )}
          <img src={HeaderLogo} alt="GiftMe Logo" className={styles.logoStyle} onClick={navigateToStore} />
        </Toolbar>
      </AppBar>
      {/*
        Below is for handling the appbar being fixed so content
        doesn't go underneath as the height of the AppBar is dynamic,
        it changes with the screen width.
      */}
      <Box className={styles.appBarSpaceStyle} />
    </>
  );
}
