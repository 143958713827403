import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { FONT_FAMILIES, COLORS } from "../../style";
const breakpoints = createBreakpoints({});

const setSpacing = (x: number) => (window.innerWidth <= breakpoints.width("sm") ? 4 * x : 8 * x);

const theme = createMuiTheme({
  spacing: setSpacing,
  palette: {
    primary: {
      main: "#f0017c"
    },
    text: {
      primary: COLORS.WHITE,
      secondary: COLORS.LIGHT_BLACK
    }
  },
  typography: {
    h1: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 64,
      fontWeight: 800,
      letterSpacing: 0,
      color: COLORS.WHITE,
      lineHeight: "82px",
      [breakpoints.down("sm")]: {
        fontSize: 46,
        lineHeight: "48px"
      },
      [breakpoints.down("xs")]: {
        fontSize: 32,
        lineHeight: "38px"
      }
    },
    h2: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 48,
      fontWeight: 700,
      letterSpacing: "0",
      lineHeight: "58px",
      color: COLORS.WHITE,
      [breakpoints.down("sm")]: {
        fontSize: 38
      },
      [breakpoints.down("xs")]: {
        fontSize: 32,
        lineHeight: "40px"
      }
    },
    h3: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 32,
      fontWeight: "bold",
      letterSpacing: "0",
      color: COLORS.WHITE,
      lineHeight: "54px",
      [breakpoints.down("xs")]: {
        fontSize: 24,
        lineHeight: "32px"
      }
    },
    h4: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "0",
      color: COLORS.WHITE,
      [breakpoints.down("md")]: {
        fontSize: 22
      }
    },
    h5: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 20,
      fontWeight: 800,
      letterSpacing: "0",
      color: COLORS.WHITE,
      lineHeight: "25px",
      [breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    h6: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: "0",
      color: COLORS.WHITE,
      lineHeight: "24.16px"
    },
    subtitle1: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: "0",
      color: COLORS.WHITE,
      [breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    subtitle2: {
      fontFamily: FONT_FAMILIES.POPPINS,
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: "0",
      color: COLORS.LIGHT_GRAY,
      [breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    body1: {
      fontFamily: FONT_FAMILIES.SOFIA_PRO,
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      color: COLORS.WHITE,
      lineHeight: "20px"
    },
    body2: {
      fontFamily: FONT_FAMILIES.POPPINS,
      fontSize: "12px",
      fontWeight: "normal",
      color: COLORS.WHITE,
      letterSpacing: "0"
    }
  }
});

export default theme;
