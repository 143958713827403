import React, { useEffect, useState } from "react";
import { Typography, Grid, useMediaQuery, Theme, makeStyles, Box } from "@material-ui/core";
import Section from "../common/Section";
import PrimaryButtonV2 from "../common/PrimaryButtonV2";
import { COLORS, FONT_FAMILIES } from "../../../style";
import CardMedia from "../common/CardMedia";
import useNav from "../../../hooks/useNav";
import { STOREHOME_PATH, STORE_GIFT_PATH } from "../../../constants/paths";
import { getGiftsAction } from "../../../actions/giftAction";
import { mapGiftsByMasterGiftId, getRandomItems } from "../../gifter/utils/gift-utils";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";
import { CURRENCY } from "../../common/Constants";

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    flexDirection: "row",
    paddingTop: 104,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20
    }
  },
  wrapperMostWIshedItems: {
    marginTop: 48,
    marginBottom: 75,
    [theme.breakpoints.down("md")]: {
      marginTop: 18,
      marginBottom: 30
    }
  }
}));

const MostWishedSection = () => {
  const { goTo } = useNav();
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const [featuredWishlists, setFeaturedWishlists] = useState([]);
  const popularUrl = "store/407569/☆%20Popular%20☆/gift";

  useEffect(() => {
    const fetchGifts = async () => {
      const payload = {
        storeId: 407569,
        page: 1,
        pageSize: 12
      };
      const { data } = await getGiftsAction(payload);
      const gifts = mapGiftsByMasterGiftId(data.gifts);
      setFeaturedWishlists(gifts);
    };

    fetchGifts();
  }, []);

  return (
    <Section bgcolor="#FFFFFF" contentProps={{ className: classes.sectionContent }}>
      <>
        <Typography
          variant="h2"
          style={{
            textAlign: "center",
            color: COLORS.LIGHT_BLACK,
            marginTop: isDesktop ? "unset" : 20,
            paddingTop: isDesktop ? 0 : 30,
            fontWeight: isDesktop ? 700 : 800,
            lineHeight: isDesktop ? "58px" : "32px",
            fontSize: isDesktop ? 48 : 24
          }}
        >
          Most Wished Items
        </Typography>

        <Grid container spacing={4} className={classes.wrapperMostWIshedItems}>
          {getRandomItems(featuredWishlists, isDesktop ? 4 : 2).map((item, idx) => (
            <Grid item lg={3} xs={6} key={idx}>
              <CardMedia
                title={item.name}
                titleStyle={{
                  fontFamily: FONT_FAMILIES.POPPINS,
                  fontSize: isDesktop ? 20 : "14px !important",
                  lineHeight: isDesktop ? "28px" : "21px",
                  fontWeight: "400 !important",
                  marginTop: "2px"
                }}
                subTitle={`${CURRENCY}${roundToDecimalPlaces(item.pricing.true_price_converted, 2)}`}
                src={item.image_url_high_res}
                bgSize="contain"
                height={isDesktop ? 335 : 224}
                color="textSecondary"
                redirectTo={`${popularUrl}/${item.gift_id}`}
              />
            </Grid>
          ))}
        </Grid>
        <Box style={{ textAlign: "center" }}>
          <PrimaryButtonV2
            label="See More"
            onClick={() => goTo(STOREHOME_PATH)}
            style={{ width: 134, height: isDesktop ? 48 : 40, marginBottom: isDesktop ? 102 : 90 }}
          />
        </Box>
      </>
    </Section>
  );
};

export default MostWishedSection;
