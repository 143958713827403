import React from "react";
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

interface ConfirmationProps {
  open: boolean;
  handleCancel: () => void;
  handleOk: () => void;
  title?: string;
  content?: string;
  buttonText?: string;
  processing: boolean;
}
const Confirmation: React.FC<ConfirmationProps> = (props: ConfirmationProps) => {
  const { open, handleCancel, handleOk, title, content, buttonText, processing } = props;

  const handleOnClose = () => {
    if (!processing) {
      handleCancel();
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleOnClose} aria-labelledby="dialog-title" disableEscapeKeyDown={processing}>
        <DialogTitle id="dialog-title">{title || "Confirmation"}</DialogTitle>
        <DialogContent
          style={{
            minWidth: 300
          }}
        >
          <DialogContentText
            dangerouslySetInnerHTML={{
              __html: content || "Are you sure?"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" disabled={processing}>
            {buttonText || "Yes"}
            {processing ? "..." : ""}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Confirmation;
