import React from "react";
import { Box, Typography, useMediaQuery, Theme } from "@material-ui/core";

export default function AllRightReservedComponent() {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return (
    <Box
      style={{
        margin: "0 auto",
        textAlign: "center",
        color: "#777777",
        marginTop: isDesktop ? "75px" : "30px"
      }}
    >
      <Typography
        style={{
          fontSize: "14px"
        }}
      >
        &copy; {new Date().getFullYear()} RealGifts, LLC. All rights reserved.&nbsp; <br />
        <a href="https://www.getrealgifts.com/home?page=terms" target="_blank" style={{ color: "#777777" }}>
          {" "}
          Terms{" "}
        </a>
        &{" "}
        <a href="https://www.getrealgifts.com/home?page=privacy" target="blank" style={{ color: "#777777" }}>
          Policy
        </a>
      </Typography>
    </Box>
  );
}
