import { isEmpty } from "../../../utils/helpers";
import { US_STATES } from "../../input/USStateSelector";

export const useShippingValidation = () => {
  // Utils
  const validateEmail = (email: string) => {
    const rules = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return rules.test(email);
  };

  const validateAddress = (str: string) => {
    const rules = /^[a-zA-Z0-9-,..#'" /]{1,75}$/;
    const specialCharsOnly = /^[^\w\s]+$/;
    if (specialCharsOnly.test(str)) {
      return false;
    }
    return rules.test(str);
  };

  const validateFullname = (str: string) => {
    const rules = /^[a-zA-Z ]{1,50}$/;
    return rules.test(str);
  };

  const alphaNumericOnly = (str: string) => {
    const rules = /^[a-zA-Z ]{1,30}$/;
    return rules.test(str);
  };

  const validatePhoneNumber = (formInput: any, formInputs: any) => {
    const value = formInput.value;
    if (value === "") {
      formInput.error = "Phone Number is required";
    } else if (value.length < 10 && formInputs["delivery_country"].value === "US") {
      formInput.error = "Phone Number should be 10 digits or greater";
    } else if (value.length < 5 && formInputs["delivery_country"].value !== "US") {
      formInput.error = "Phone Number should be 5 digits or greater";
    } else {
      formInput.error = "";
    }
    return formInput;
  };

  const validateZip = (formInput: any, formInputs: any) => {
    const value = formInput.value;
    const USZipRules = /^[0-9]{5,9}$/;
    const nonUSZipRules = /^[a-zA-Z0-9 ]{1,12}$/;

    if (value === "") {
      formInput.error = "Zip Code is required";
    } else if (!USZipRules.test(value) && formInputs["delivery_country"].value === "US") {
      formInput.error = "US Zip Code must be 5 or 9 digits";
    } else if (!nonUSZipRules.test(value) && formInputs["delivery_country"].value !== "US") {
      formInput.error = "Invalid International Zip";
    } else {
      formInput.error = "";
    }
    return formInput;
  };

  const validate = (type: string, value: string) => {
    switch (type) {
      case "fullname":
        if (isEmpty(value)) {
          return "Full Name is required";
        }
        if (!validateFullname(value)) {
          return "Full Name is invalid";
        }
        break;
      case "address":
        if (isEmpty(value)) {
          return "Shipping Address is required";
        }
        if (!validateAddress(value)) {
          return "Shipping Address is invalid";
        }
        break;
      case "email":
        if (isEmpty(value)) {
          return "Email is required";
        }
        if (!validateEmail(value)) {
          return "Email is invalid";
        }
        break;
      case "city":
        if (isEmpty(value)) {
          return "City is required";
        }
        if (!alphaNumericOnly(value)) {
          return "City is invalid";
        }
        break;
      case "state":
        const findStateByValue = US_STATES.find((item) => item.value === value);
        if (isEmpty(value) || isEmpty(findStateByValue)) {
          return "State is required";
        }
        if (!alphaNumericOnly(value)) {
          return "State is invalid";
        }
        break;
      case "province":
        if (isEmpty(value)) {
          return "State/Province is required";
        }
        if (!alphaNumericOnly(value)) {
          return "State/Province is invalid";
        }
        break;
    }
    return "";
  };

  return {
    validate,
    validateZip,
    validatePhoneNumber
  };
};

export default useShippingValidation;
