import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const UploadPinkIcon = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path
        d="M8.66732 13.1665V10.4998H10.6673L8.00065 7.1665L5.33398 10.4998H7.33398V13.1665H8.66732Z"
        fill="#E6007E"
      />
      <path
        d="M4.66732 13.1668H6.00065V11.8335H4.66732C3.56465 11.8335 2.66732 10.9362 2.66732 9.8335C2.66732 8.8975 3.46665 7.99616 4.44932 7.8235L4.83665 7.7555L4.96465 7.3835C5.43332 6.01616 6.59732 5.16683 8.00065 5.16683C9.83865 5.16683 11.334 6.66216 11.334 8.50016V9.16683H12.0007C12.736 9.16683 13.334 9.76483 13.334 10.5002C13.334 11.2355 12.736 11.8335 12.0007 11.8335H10.0007V13.1668H12.0007C13.4713 13.1668 14.6673 11.9708 14.6673 10.5002C14.6663 9.90257 14.4651 9.32255 14.0958 8.85272C13.7265 8.38288 13.2104 8.05033 12.63 7.90816C12.3387 5.6135 10.374 3.8335 8.00065 3.8335C6.16332 3.8335 4.56732 4.9075 3.83865 6.60016C2.40665 7.02816 1.33398 8.38016 1.33398 9.8335C1.33398 11.6715 2.82932 13.1668 4.66732 13.1668Z"
        fill="#E6007E"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default UploadPinkIcon;
