import axios from "axios";

export interface GetAddressByZipCodeParamsObject {
  zipcode: string;
  country: string;
}

export async function getAddressByZipCode(params: GetAddressByZipCodeParamsObject) {
  try {
    const response = await axios.get("/api/zipCode2Address", {
      params,
      withCredentials: true
    });

    const { address } = response.data;

    return address;
  } catch (error) {
    return [];
  }
}

export function separateCityAndStateFromAddress(address) {
  const [city, state] = address.split(",");

  return {
    city,
    state: state.trim()
  };
}
