import React, { useState } from "react";
import { Box, Divider, CircularProgress, Grid } from "@material-ui/core";
import { GiftInterface } from "../../../models/gift";
import GiftSearchActions from "./GiftSearchActions";
import GiftFilters from "./GifterFilters";
import GiftLists from "./GiftLists";
import GiftPagination from "./GiftPagination";
import { SetPromptLoginInterface } from "../../../interface/AppInterface";
import AppDialog from "../dialog/AppDialog";
import SelectRecipientShoppingFor from "../../store/SelectRecipientShoppingFor";
import Credits from "../credits/Credits";
import GiftSortSelect from "./GiftSortSelect";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";

interface GiftResultsProps {
  page: number;
  totalPages: number;
  setPage?: (page: number) => void;
  gifts: Array<GiftInterface>;
  sortBy: string;
  setSortBy?: (sortBy: string) => void;
  setCurrentGift?: (gift) => void;
  setProductModalIsOpen?: (show: boolean) => void;
  storeId?: string;
  storeName?: string;
  style?: React.CSSProperties;
  isLoading: boolean;
  isPaginated: boolean;
  hasMoreData?: boolean;
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
}

const GiftResults: React.FC<GiftResultsProps> = (props: GiftResultsProps) => {
  const { isDesktop, isMobileXs, isMobile, isTablet, isTabletMobileOnly, isTabletUp } = useMediaBreakpoints();

  const {
    page,
    totalPages,
    setPage,
    gifts,
    sortBy,
    setSortBy,
    setCurrentGift,
    setProductModalIsOpen,
    storeId,
    storeName,
    style,
    isLoading,
    isPaginated,
    hasMoreData,
    setPromptLogin
  } = props;
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const filterHeader = (isFilterModal = false) => {
    return (
      <GiftSearchActions
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        isFilterModal={isFilterModal}
        sortBy={sortBy}
        setSortBy={setSortBy}
        style={{ marginBottom: 24 }}
      />
    );
  };

  const filterOptions = () => {
    return (
      <GiftFilters
        style={{
          ...style,
          maxWidth: 285,
          marginRight: 15,
          background: "transparent",
          borderTop: "1px solid #AFB0B1",
          width: isDesktop ? "inherit" : "100%"
        }}
      />
    );
  };

  return (
    <Grid container>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="center"
          justifyContent={isMobile ? "space-between" : "unset"}
        >
          <SelectRecipientShoppingFor justifyContent="unset" style={{ fontSize: "16px", marginTop: 5 }} />
          {isMobileXs && (
            <Credits
              style={{
                fontSize: 16,
                marginTop: 2
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Box display="flex" alignItems="center" flexDirection={isMobileXs ? "inherit" : "row-reverse"}>
          <GiftSortSelect sortBy={sortBy} setSortBy={setSortBy} />
          {isTabletUp && (
            <Credits
              style={{
                marginRight: 25,
                fontSize: 16,
                marginTop: 2
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        style={{
          marginTop: 16
        }}
      >
        <Box display="flex">
          {isDesktop && isFilterOpen && filterOptions()}
          <GiftLists
            columnCount={isDesktop && isFilterOpen ? 2 : 3}
            gifts={gifts}
            setCurrentGift={setCurrentGift}
            setProductModalIsOpen={setProductModalIsOpen}
            storeId={storeId}
            storeName={storeName}
            setPromptLogin={setPromptLogin}
          />
        </Box>
      </Grid>
      <Grid
        item
        style={{
          width: "100%"
        }}
      >
        {hasMoreData ? (
          isLoading && (
            <Box display="flex" alignItems="center">
              <CircularProgress style={{ margin: "25px auto" }} />
            </Box>
          )
        ) : (
          <Box
            style={{
              textAlign: "center",
              fontSize: "10px",
              fontWeight: "bold",
              color: "rgba(0,0,0,0.4)"
            }}
          >
            <hr style={{ borderTop: "1px solid rgba(0,0,0,.05)" }} />
            {"End of Results"}
          </Box>
        )}
      </Grid>
      {!isLoading && isPaginated && (
        <>
          <Divider />
          <GiftPagination page={page} setPage={setPage} totalPages={totalPages} />
        </>
      )}
      <AppDialog
        open={!isDesktop && isFilterOpen}
        fullWidth={true}
        fullScreen={true}
        noHeaderLogo={true}
        isNotTransparent={true}
      >
        {filterHeader(true)}
        {filterOptions()}
      </AppDialog>
    </Grid>
  );
};

export default GiftResults;
