import React from "react";
import { Typography, Theme } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import { textColor } from "../../style";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: "40px 16px 10px 0px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    marginRight: "10px",
    color: "#2D2D2D"
  },
  root: {
    "&:before": {
      backgroundColor: "initial"
    },
    "&:last-child": {
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important"
    }
  },
  summary: {
    padding: "0px 16px 0 0"
  },
  expandedAccordion: {
    margin: "0px 10px 0 0"
  },
  expandedSummary: {
    color: textColor.NORMAL,
    alignItems: "center"
  },
  expandedDetails: {
    padding: "8px 16px 16px 0"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular
  },
  body: {
    fontSize: "12px"
  }
}));

export default function FaqContent(props: any) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        {props.title}
      </Typography>
      {props.items.map((item) => (
        <Accordion
          className={classes.root}
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            margin: "0 10px 0 0"
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`${item.id}`}
            className={classes.summary}
            classes={{
              expanded: classes.expandedSummary
            }}
          >
            <Typography className={classes.heading}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.question
                }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.expandedDetails}>
            <Typography className={classes.body}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.answer
                }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
