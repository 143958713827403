import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import SecondaryButton from "../../buttons/SecondaryButton";
import useNav from "../../../hooks/useNav";
import { emptyViewData } from "./constant";
import { EmptyViewProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      boxShadow: "none",
      background: "transparent",
      textAlign: "center"
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    title: {
      marginTop: 10,
      marginBottom: 20
    },
    description: {
      marginBottom: 10
    },
    cardActions: {
      justifyContent: "center"
    },
    button: {
      width: 200,
      color: "#E92983"
    }
  })
);

const EmptyView = ({ type }: EmptyViewProps): ReturnType<React.FC> => {
  const { title, description, image, buttonText } = emptyViewData[type];
  const classes = useStyles();
  const { goTo } = useNav();
  return (
    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Card className={classes.root}>
        <CardMedia className={classes.media} image={image} title="Order History" />
        <CardContent>
          <Typography variant="h6" color="textSecondary" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
            {description}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <SecondaryButton text={buttonText} className={classes.button} handleClick={() => goTo("/store/storehome")} />
        </CardActions>
      </Card>
    </Box>
  );
};

export default EmptyView;
