import { useState, useEffect } from "react";

/**
 *
 * @param label label of the form input
 * @param maxLength max character length of the form input
 * @param value current value of the form input field
 * @param error error text to show
 * @param hadErrorOnFocus true if there was at least one error within this input field otherwise, false
 * @param edited if the input was at least edited one time otherwise, false
 * @returns {{edited: boolean, hadErrorOnFocus: boolean, label: undefined, error: string, value: string, maxLength: undefined}}
 * @constructor
 */
export function FormInput(
  label = undefined,
  maxLength = undefined,
  value = "",
  error = "",
  hadErrorOnFocus = false,
  edited = false
) {
  return { label, maxLength, value, error, hadErrorOnFocus, edited };
}

/**
 *
 * @param initialFormInputs form input fields that this form will keep the state for
 * @param validate callback function that is triggered to check if form input/s is valid or not
 * @returns {{handleOnBlur: handleOnBlur, handleOnFocus: handleOnFocus, setFormInputs: React.Dispatch<React.SetStateAction<unknown>>, formInputs: unknown, handleInputChange: handleInputChange}}
 * @constructor
 */
export default function Form(initialFormInputs: any, validate: any) {
  const [formInputs, setFormInputs] = useState(initialFormInputs);
  const [isFormInputsValueChanged, setIsFormInputsValueChanged] = useState(false);

  useEffect(() => {
    const keys = Object.keys(formInputs);
    const filtered = keys.filter((key) => {
      const value = formInputs[key].value;
      const original = formInputs[key].original ?? "";
      return value !== original;
    });

    setIsFormInputsValueChanged(filtered.length > 0);
  }, [formInputs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formInput = formInputs[name];
    if (!formInput) return;

    formInput.value = value;
    formInput.edited = true;

    // If formInput had an error on focus, perform input validation on every edit
    if (formInput.hadErrorOnFocus && validate) {
      validate({ [name]: formInput });
    }

    setFormInputs({
      ...formInputs,
      [name]: formInput
    });
  };

  const handleOnFocus = (e) => {
    const name = e.target.name;
    const formInput = formInputs[name];
    if (!formInput) return;

    formInput.hadErrorOnFocus = formInput.hadErrorOnFocus ? formInput.hadErrorOnFocus : formInput.error.length > 0;
  };

  const handleOnBlur = (e) => {
    const name = e.target.name;
    const formInput = formInputs[name];
    if (!formInput) return;

    // If formInput was edited, perform input validation
    if (formInput.edited && validate) {
      validate({ [name]: formInput });

      setFormInputs({
        ...formInputs,
        [name]: formInput
      });
    }
  };

  return {
    formInputs,
    setFormInputs,
    handleInputChange,
    handleOnFocus,
    handleOnBlur,
    isFormInputsValueChanged
  };
}
