import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { FONT_FAMILIES } from "../../style";
import useNav from "../../hooks/useNav";

const useStyles = makeStyles(() => ({
  gridTabItem: {
    textTransform: "none",
    fontFamily: FONT_FAMILIES.POPPINS,
    fontWeight: 400,
    fontSize: "16px",
    whiteSpace: "nowrap",
    color: "#2C2C2C",
    padding: "0 36px",
    "&:hover, &:focus": {
      fontWeight: 600,
      textDecoration: "none",
      backgroundColor: "transparent"
    }
  }
}));

const MenuHeader = ({ title, items }) => {
  const styles = useStyles();
  const { goTo } = useNav();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (to, link) => {
    setAnchorEl(null);

    if (link) {
      goTo(to);
    }
  };

  const listItems = items.map((item) => (
    <MenuItem
      onClick={() => handleClose(item.to, item.link)}
      style={{
        justifyContent: "right"
      }}
    >
      {item.name}
    </MenuItem>
  ));

  return (
    <>
      <Button
        id={"basic-button"}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.gridTabItem}
        endIcon={<KeyboardArrowDown />}
      >
        {title}
      </Button>
      <Menu
        id={"basic-menu"}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {listItems}
      </Menu>
    </>
  );
};

export default MenuHeader;
