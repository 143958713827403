import React, { memo } from "react";
import { makeStyles, Box, Theme, Typography, Grid } from "@material-ui/core";
import { useAppSelector } from "../../hooks";
import { textColor } from "../../style";
import TextArea from "../input/TextArea";
import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import SecondaryButtonV3 from "../buttons/SecondaryButtonV3";
import ProfilePicture from "../common/profile/ProfilePicture";
import PersonalizeVanityUrlView from "./PersonalizeVanityUrlView";

const useStyle = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300
    }
  },
  contentContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300
    }
  },
  heading: {
    marginTop: 9,
    marginBottom: 25,
    textAlign: "center",
    maxWidth: 278,
    fontWeight: 700,
    lineHeight: "40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 18
    }
  },
  subtitle: {
    ...theme.typography.subtitle1,
    marginBottom: "15px",
    fontSize: 14,
    color: textColor.LIGHT_GRAY,
    fontWeight: "normal"
  },
  avatar: {
    textAlign: "center",
    margin: "0 auto !important"
  }
}));

interface ContentReviewProps {
  nextStep: () => void;
  prevStep: () => void;
}

const ContentReview: React.FC<ContentReviewProps> = memo(({ nextStep, prevStep }: ContentReviewProps) => {
  const classes = useStyle();
  const { localDbData } = useAppSelector((state) => state.user.data);
  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.heading}>
        {"You're all set!"}
      </Typography>
      <Box className={classes.contentContainer}>
        <ProfilePicture
          style={{
            alignItems: "center",
            textAlign: "center"
          }}
          avatarClassName={classes.avatar}
        />
        <PersonalizeVanityUrlView />
        <TextArea
          value={localDbData.about}
          error={true}
          label="About"
          id="postSignupAbout"
          name="about"
          autoComplete="about"
          charLimit={0}
          rows={3}
          disabled={true}
          style={{ width: "100%", marginBottom: 40 }}
        />
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <SecondaryButtonV3 handleClick={prevStep} text="Back" />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <PrimaryButtonV3 className={classes.continueButton} handleClick={nextStep}>
              Done
            </PrimaryButtonV3>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

ContentReview.displayName = "ContentReview";
export default ContentReview;
