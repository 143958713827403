import React from "react";

import { ArrowBack } from "@material-ui/icons";
import { SvgIcon } from "@material-ui/core";
import { iconTheme } from "../../style";

const ArrowLeft = ({ color = iconTheme.DARK, cssStyle = {} }) => {
  return <SvgIcon component={ArrowBack} viewBox={"4 3 18 18"} style={{ fontSize: 26, color: color, ...cssStyle }} />;
};

export default ArrowLeft;
