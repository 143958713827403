export const replaceUnicodeChars = (string: string) => {
  return string.replace(/&#(?:x([\da-f]+)|(\d+));/gi, function (_, hex, dec): string {
    return String.fromCharCode(dec || +("0x" + hex));
  });
};

export const roundToDecimalPlaces = (string: string, decimalPlaces: number, separator: string = ","): string => {
  const rounded = Number(Math.round(parseFloat(string + "e" + decimalPlaces)) + "e-" + decimalPlaces).toFixed(
    decimalPlaces
  );
  return numberWithCommas(rounded, separator);
};

export const numberWithCommas = (string: string, separator: string): string => {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};
