import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Theme } from "@material-ui/core";
import { useAppSelector } from "../../../hooks";
import AppDialog from "../../common/dialog/AppDialog";
import ShareDialogSocialFooter from "../shareDialog/ShareDialogSocialFooter";
import ShareDialogContent from "../shareDialog/ShareDialogContent";
import { GiftInterface } from "../../../models/gift";
import { getImageUrl } from "../../../actions/giftAction";

const giftShareModalStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "15px auto",
    flexDirection: "inherit",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  section: {
    padding: "0 25px",
    maxWidth: 300,
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  productBrand: {
    fontSize: 16,
    textTransform: "uppercase"
  },
  productName: {
    fontWeight: "normal",
    marginBottom: 20
  },
  divider: {
    border: "1px solid #70707040",
    height: "inherit",
    [theme.breakpoints.down("sm")]: {
      margin: "30px 0"
    }
  },
  productDetailsSection: {
    paddingTop: 10
  },
  shareSection: {
    padding: "1px 34px",
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  }
}));

interface GiftShareModalProps {
  open: boolean;
  onClose: () => void;
  shareTitle?: string;
  shareBody?: string;
  shareUrl?: string;
  gift: GiftInterface;
}

export default function GiftShareModal({
  open,
  onClose,
  shareTitle,
  shareBody,
  gift
}: GiftShareModalProps): ReturnType<FC> {
  const { vanity, encryptedUserId } = useAppSelector((state) => state.user.data);
  const styles = giftShareModalStyles();

  if (!gift) {
    return null;
  }

  const shareUrl = `${window.location.origin}/shop/${vanity}/gift/${gift.gift_id}`;
  const setShareTitle = shareTitle ? shareTitle : "Check out my wishlist on Gift.Me!";
  const setShareBody = shareBody ? shareBody : "My wishlist on Gift.me";

  const ProductDetailsSection = () => {
    return (
      <Box className={clsx([styles.section, styles.productDetailsSection])}>
        <Typography variant="h6" className={styles.productBrand}>
          {gift.brand}
        </Typography>
        <Typography variant="h6" className={styles.productName}>
          {gift.name}
        </Typography>
        <img
          src={getImageUrl(gift)}
          alt="Name"
          style={{
            maxWidth: "65%",
            borderRadius: 5
          }}
        />
      </Box>
    );
  };

  const ShareSection = () => {
    return (
      <Box className={clsx([styles.section, styles.shareSection])}>
        <ShareDialogContent
          shareUrl={shareUrl}
          shareContentDescription="Tell people you want this product!"
          encryptedUserId={encryptedUserId}
          closeDialog={onClose}
          hidePersonalizedLink={true}
        />
        <ShareDialogSocialFooter
          shareTitle={setShareTitle}
          shareBody={setShareBody}
          shareUrl={shareUrl}
          encryptedUserId={encryptedUserId}
        />
      </Box>
    );
  };

  return (
    <AppDialog open={open} onClose={onClose} fullWidth={false} title="Share...">
      <Box display="flex" className={styles.root}>
        <ProductDetailsSection />
        <Divider className={styles.divider} />
        <ShareSection />
      </Box>
    </AppDialog>
  );
}
