import React from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface AccountSettingsSaveChangesButtonProps {
  submit: () => Promise<void>;
  isSaving: boolean;
  isDisabled: boolean;
}

const componentStyles = makeStyles(() => ({
  root: {
    marginTop: 60,
    maxWidth: 168
  },
  buttonDisabled: {
    background: "#868686",
    backgroundColor: "#868686 !important",
    color: "#fff !important"
  }
}));
const AccountSettingsSaveChangesButton: React.FC<AccountSettingsSaveChangesButtonProps> = (
  props: AccountSettingsSaveChangesButtonProps
) => {
  const { submit, isSaving, isDisabled } = props;
  const styles = componentStyles();
  return (
    <PrimaryButton
      disabled={isSaving || isDisabled}
      handleClick={submit}
      className={clsx(styles.root, isDisabled && styles.buttonDisabled)}
      style={{
        textTransform: "capitalize"
      }}
    >
      {isSaving ? "Save Changes..." : "Save Changes"}
    </PrimaryButton>
  );
};

export default AccountSettingsSaveChangesButton;
