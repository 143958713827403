import React, { useState, useEffect } from "react";
import Toast from "light-toast";
import AccountSettingsChangePassword from "./AccountSettingsChangePassword";
import AccountSettingsLoginOptions from "./AccountSettingsLoginOptions";
import AccountSettingsSaveChangesButton from "./AccountSettingsSaveChangesButton";
import ProfileContentContainer from "../ProfileContentContainer";
import AccountSettingsSetPassword from "./AccountSettingsSetPassword";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Form, { FormInput } from "../../input/Form";
import { updatePassword, setPassword, getCurrentUser } from "../../../actions/userAction";
import { useHistory } from "react-router-dom";
import { setUserLocalDbData } from "../../../reducers/user/UserSlice";
import useNav from "../../../hooks/useNav";
import { STOREHOME_PATH } from "../../../constants/paths";
import { Box } from "@material-ui/core";

const initialFormInputs = {
  currentPassword: FormInput(),
  password: FormInput(),
  confirmPassword: FormInput()
};

const AccountSettings: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { goTo } = useNav();
  const { localDbData } = useAppSelector((state) => state.user.data);
  const [state, setState] = useState({
    isSaving: false,
    currentUserLoaded: false
  });

  const validatePassword = (password) => {
    if (password.length === 0) {
      return "Password is required";
    }
    if (password.length !== 0 && password.length <= 3) {
      return "Password too short";
    }

    return "";
  };

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (const inputName in inputs) {
      const formInput = inputs[inputName];

      if ("currentPassword" === inputName && localDbData.hasPassword) {
        formInput.error = formInput.value.length === 0 ? "Current password is required" : "";
      } else if ("password" === inputName) {
        formInputs.password.error = validatePassword(formInput.value);
        formInputs.confirmPassword.error =
          (formInputs.confirmPassword.value.length > 0 &&  formInput.value !== formInputs.confirmPassword.value) ? "Confirm password should match password" : "";
      } else if ("confirmPassword" === inputName) {
        formInput.error = (formInput.value.length > 0 && formInputs.password.value !== formInput.value) ? "Confirm password should match password" : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const submit = async () => {
    if (!validateForm() || state.isSaving) {
      setFormInputs({ ...formInputs });
      return;
    }
    resetFormError();
    setState({
      ...state,
      isSaving: true
    });

    Toast.info("Updating Your Password...", 500);
    try {
      if (localDbData.hasPassword) {
        await updatePassword({
          password: formInputs.password.value,
          confirmPassword: formInputs.confirmPassword.value,
          currentPassword: formInputs.currentPassword.value
        });
      } else {
        await setPassword({
          password: formInputs.password.value,
          confirmPassword: formInputs.confirmPassword.value
        });
      }
      Toast.success("Your Password has be Updated.", 2000);
      refreshUserLocalDbData();
      resetFormValue();
      setState({
        ...state,
        isSaving: false
      });
    } catch (error) {
      setState({
        ...state,
        isSaving: false
      });
      if (error.response.status === 500) {
        history.push("/error");
        return;
      }

      Toast.fail(error.response.data, 3000, () => {
        Toast.hide();
      });
    }
  };

  const resetFormValue = () => {
    formInputs.password.value = "";
    formInputs.currentPassword.value = "";
    formInputs.confirmPassword.value = "";
    setFormInputs({ ...formInputs });
  };

  const resetFormError = () => {
    formInputs.password.error = "";
    formInputs.currentPassword.error = "";
    formInputs.confirmPassword.error = "";
    setFormInputs({ ...formInputs });
  };

  const refreshUserLocalDbData = async () => {
    const response = await getCurrentUser();
    dispatch(setUserLocalDbData(response.data.localDbData));
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const fetchUser = async () => {
    try {
      await getCurrentUser();
      setState({
        ...state,
        currentUserLoaded: true
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401
        goTo(STOREHOME_PATH);
        return;
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <ProfileContentContainer page="account_settings" isLoading={!state.currentUserLoaded || !localDbData}>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        {localDbData && localDbData.hasPassword ? (
          <AccountSettingsChangePassword
            currentPassword={formInputs.currentPassword}
            password={formInputs.password}
            confirmPassword={formInputs.confirmPassword}
            handleOnBlur={handleOnBlur}
            handleOnFocus={handleOnFocus}
            handleInputChange={handleInputChange}
          />
        ) : (
          <AccountSettingsSetPassword
            password={formInputs.password}
            confirmPassword={formInputs.confirmPassword}
            handleOnBlur={handleOnBlur}
            handleOnFocus={handleOnFocus}
            handleInputChange={handleInputChange}
          />
        )}
        <AccountSettingsSaveChangesButton
          submit={submit}
          isSaving={state.isSaving}
          isDisabled={formInputs.password.value === "" || formInputs.confirmPassword.value === ""}
        />
      </Box>
      <AccountSettingsLoginOptions user={localDbData} />
    </ProfileContentContainer>
  );
};

export default AccountSettings;
