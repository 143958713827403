import CardValidator from "card-validator";
import AMEX from "./images/ic_amex.png";
import DINERS_CLUB from "./images/ic_diners_club.png";
import DISCOVER from "./images/ic_discover.png";
import HIPER from "./images/ic_hiper.png";
import HIPERCARD from "./images/ic_hipercard.png";
import JCB from "./images/ic_jcb.png";
import MAESTRO from "./images/ic_maestro.png";
import MASTER_CARD from "./images/ic_mastercard.png";
import UNIONPAY from "./images/ic_unionpay.png";
import VISA from "./images/ic_visa.png";
import UNKNOWN_CARD from "./images/ic_unknown.png";

export const CARD_CVV_LABEL = "CVV";
export const CARD_CVV_SIZE = 3;

export const CARD_NUMBER_SEPARATOR = " ";

/**
 * Fetches card detail from the card number
 * @param cardNumber
 * @returns {{unFormattedNumber: string, formattedCardNumber: string, validation: CardNumberVerification}}
 */
export function resolveCardDetail(cardNumber: any) {
  const unFormattedNumber = cardNumber.replaceAll(CARD_NUMBER_SEPARATOR, "");
  const validation = CardValidator.number(unFormattedNumber);
  const formattedCardNumber = formatCardNumber(unFormattedNumber, validation.card);

  return {
    unFormattedNumber,
    formattedCardNumber,
    validation
  };
}

export function formatCardNumber(cardNumber: any, card: any) {
  if (card) {
    const offsets = [].concat(0, card.gaps, cardNumber.length);
    const components = [];

    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      const start = offsets[i];
      const end = Math.min(offsets[i + 1], cardNumber.length);
      components.push(cardNumber.substring(start, end));
    }

    return components.join(CARD_NUMBER_SEPARATOR);
  }

  return cardNumber;
}

export function getCardImage(card?: any) {
  let cardImage;
  if (card) {
    if (card.type === "american-express") {
      cardImage = AMEX;
    } else if (card.type === "diners-club") {
      cardImage = DINERS_CLUB;
    } else if (card.type === "discover") {
      cardImage = DISCOVER;
    } else if (card.type === "hiper") {
      cardImage = HIPER;
    } else if (card.type === "hipercard") {
      cardImage = HIPERCARD;
    } else if (card.type === "jcb") {
      cardImage = JCB;
    } else if (card.type === "maestro") {
      cardImage = MAESTRO;
    } else if (card.type === "mastercard" || card.type === "MasterCard") {
      cardImage = MASTER_CARD;
    } else if (card.type === "unionpay") {
      cardImage = UNIONPAY;
    } else if (card.type === "visa" || card.type === "Visa") {
      cardImage = VISA;
    } else {
      cardImage = UNKNOWN_CARD;
    }
  } else {
    cardImage = UNKNOWN_CARD;
  }

  return cardImage;
}

export function getValidationError(cardNumber: string, validation: any, strict?: any) {
  const card = validation.card;
  const inputCardLength = cardNumber.length;
  let error = "";

  if (!validation.isPotentiallyValid && !strict) {
    if (card) {
      const maxSupportedCardLength = card.lengths[card.lengths.length - 1];
      if (inputCardLength > maxSupportedCardLength) {
        error = "Card number too long";
      }
    } else {
      error = "Please enter a valid card number";
    }
  } else if (strict) {
    // Card number is potentially valid but it's not valid for submission
    if (!validation.isValid) {
      if (cardNumber === "") {
        error = "Card Number is required";
      } else if (card) {
        for (let i = 0; i < card.lengths.length; i++) {
          const supportedCardLength = card.lengths[i];
          if (inputCardLength === supportedCardLength) {
            error = "Please enter a valid card number";
            break;
          }
          if (inputCardLength < supportedCardLength) {
            error = "Card number too short";
            break;
          } else {
            error = "Card number too long";
          }
        }
      } else {
        error = "Please enter a valid card number";
      }
    }
  }

  return error;
}
