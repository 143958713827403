import React from "react";
import { Box } from "@material-ui/core";
import { giftSearchAvailableFilters } from "./giftSearchAvailableFilters";
import GiftFiltersItem from "./GiftFiltersItem";

interface GiftFiltersProps {
  style?: React.CSSProperties;
}

const GiftFilters: React.FC<GiftFiltersProps> = (props: GiftFiltersProps) => {
  const { style } = props;
  return (
    <Box style={{ ...style }}>
      {React.Children.toArray(
        giftSearchAvailableFilters.map((filter) => <GiftFiltersItem key={filter.label} filter={filter} />)
      )}
    </Box>
  );
};
export default GiftFilters;
