import React from "react";
import TuneIcon from "@material-ui/icons/Tune";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";

interface GiftFiltersButtonProps {
  isFilterOpen: boolean;
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
}

const componentStyles = makeStyles((theme) => ({
  common: {
    fontSize: 16,
    fontWeight: "bold"
  }
}));

const GiftFiltersButton: React.FC<GiftFiltersButtonProps> = (props) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const styles = componentStyles();
  const { isFilterOpen, setIsFilterOpen, style } = props;

  const onClickHandler = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <Box onClick={onClickHandler} display="flex" style={{ ...style, cursor: "pointer" }}>
      <TuneIcon
        className={styles.common}
        style={{
          marginRight: 5
        }}
      />
      <Typography
        variant="body2"
        className={styles.common}
        style={{
          marginRight: 5
        }}
      >
        FILTERS
      </Typography>
      {isFilterOpen && (
        <Box onClick={onClickHandler}>
          <img
            src={"/assets/icons/CloseButton.svg"}
            alt="Close Button"
            style={
              isDesktop
                ? {}
                : {
                    right: "30px",
                    position: "absolute"
                  }
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default GiftFiltersButton;
