import React, { Fragment } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@material-ui/icons/Close";
import RecipientItem from "./RecipientItem";
import { filterRecipientList } from "./utils";

const useStyles = makeStyles((theme) => ({
  numberOfCredit: {
    ...theme.typography.subtitle1
  },
  cardRoot: {
    borderRadius: 10,
    maxHeight: 300,
    overflow: "auto"
  },
  cardContent: {
    padding: "16px 0 0 0 !important;"
  }
}));

export default function RecipientList(props: any) {
  const { recipients, selectedRecipient, toggleSelectRecipientForm } = props;
  const styles = useStyles();
  const filteredRecipients = filterRecipientList(recipients);

  const recipientItems = filteredRecipients.map((recipient, index) => (
    <Fragment>
      <RecipientItem
        key={recipient.id}
        selectedRecipient={selectedRecipient}
        recipient={recipient}
        index={index}
        count={filteredRecipients.length}
      />
    </Fragment>
  ));
  return (
    <Card classes={{ root: styles.cardRoot }} variant="outlined">
      <CardContent classes={{ root: styles.cardContent }}>
        <Box display="flex" flexDirection="row" marginX="auto" justifyContent="space-between">
          <Typography
            variant="body1"
            color="textSecondary"
            gutterBottom
            style={{
              paddingLeft: "16px"
            }}
          >
            Select a recipient
          </Typography>
          <Typography
            onClick={toggleSelectRecipientForm}
            style={{
              cursor: "pointer",
              paddingRight: "16px"
            }}
          >
            <CloseIcon
              fontSize="small"
              style={{
                cursor: "pointer"
              }}
            />
          </Typography>
        </Box>
        <List>{recipientItems}</List>
      </CardContent>
    </Card>
  );
}
