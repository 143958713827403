import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "../customIcons/ErrorIcon";
import CheckIcon from "../customIcons/CheckIcon";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        backgroundColor: "rgba(255, 255, 255, 0.3)"
      }
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0
    }
  }
})(TextField);

interface TextInputProps {
  marginTop?: string;
  width?: string;
  showErrorIcon?: boolean;
  showSuccessIcon?: boolean;
}

type TextInputComponentProps = TextFieldProps & TextInputProps;

export default function TextInput({
  marginTop,
  width,
  showSuccessIcon,
  showErrorIcon,
  variant = "outlined",
  error,
  ...rest
}: TextInputComponentProps) {
  return (
    <CustomTextField
      {...rest}
      variant={variant}
      error={error}
      InputProps={{
        ...rest.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            {showSuccessIcon && <CheckIcon style={{ marginTop: "8px", marginRight: 5 }} />}
            {error && showErrorIcon && <ErrorIcon style={{ marginTop: "8px", marginRight: 5 }} />}
          </InputAdornment>
        )
      }}
      style={{
        ...rest.style,
        marginTop,
        width
      }}
    />
  );
}
