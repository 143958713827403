import React from "react";
import { makeStyles, Box, Theme, Typography } from "@material-ui/core";
import ContentNickname from "./ContentNickname";
import ContentProfilePicture from "./ContentProfilePicture";
import ContentReview from "./ContentReview";
import ContentAbout from "./ContentAbout";

const useStyle = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    maxWidth: "496px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  title: {
    ...theme.typography.h6,
    fontWeight: 400,
    marginTop: "10px"
  }
}));

export interface PostSignupDialogContentProps {
  closeDialog: () => void;
  title: string;
  step: number;
  setStep: (step) => void;
}

const PostSignupDialogContent: React.FC<PostSignupDialogContentProps> = ({
  closeDialog,
  title,
  step,
  setStep
}: PostSignupDialogContentProps) => {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      {step === 1 && <ContentNickname nextStep={() => setStep(2)} />}
      {step === 2 && <ContentProfilePicture nextStep={() => setStep(3)} />}
      {step === 3 && <ContentAbout nextStep={() => setStep(4)} />}
      {step === 4 && <ContentReview prevStep={() => setStep(3)} nextStep={closeDialog} />}
    </Box>
  );
};

export default PostSignupDialogContent;
