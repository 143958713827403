import React from "react";
import { Box } from "@material-ui/core";
import PageNotFoundContent from "./PageNotFoundContent";
import useNav from "../../hooks/useNav";
import { STOREHOME_PATH } from "../../constants/paths";

export default function PageNotFound() {
  const { goTo } = useNav();
  const browseStore = () => {
    goTo(STOREHOME_PATH);
  };
  return (
    <Box>
      <PageNotFoundContent handleClick={browseStore} />
    </Box>
  );
}
