import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import SectionSubtitle from "../common/sectionTitle/SectionSubtitle";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";

const usesStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      margin: "0 auto"
    }
  }
}));

interface WishListEmptyContentProps {
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
}
const WishListEmptyContent: React.FC<WishListEmptyContentProps> = ({
  handleClick,
  label
}: WishListEmptyContentProps) => {
  const styles = usesStyles();
  const { isDesktop } = useMediaBreakpoints();

  return (
    <Box style={{ paddingTop: isDesktop ? 32 : 50, paddingBottom: "56px", width: "100%" }} className={styles.root}>
      <Box display="flex" justifyContent="center" style={{ width: isDesktop ? "unset" : "100%" }}>
        <img
          src="/assets/wishlist/empty-gift-box.png"
          alt="Wishlist is Empty"
          style={{ width: isDesktop ? "unset" : "100%" }}
        />
      </Box>
      <Box style={{ marginTop: 30 }}>
        <SectionSubtitle style={{ textAlign: "center", color: "#484848", fontSize: 24 }}>
          No items on wishlist
        </SectionSubtitle>
        <Typography variant="body1" style={{ textAlign: "center", color: "#484848", fontSize: 16 }}>
          {label}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" style={{ marginTop: 20 }}>
        <PrimaryButtonV3 onClick={handleClick} style={{ width: isDesktop ? 370 : "100%" }}>
          Browse Products
        </PrimaryButtonV3>
      </Box>
    </Box>
  );
};

export default WishListEmptyContent;
