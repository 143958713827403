import React from "react";
import { Box } from "@material-ui/core";
import ErrorContent from "./ErrorContent";

export default function Error(): ReturnType<React.FC> {
  return (
    <Box>
      <ErrorContent />
    </Box>
  );
}
