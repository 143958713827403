import React from "react";
import clsx from "clsx";
import { Button, makeStyles, Theme } from "@material-ui/core";
import GoogleLogo from "./google_logo_color.png";
import { AuthButtonsProps } from "../types";

const useButtonStyle = makeStyles<Theme>((theme) => ({
  button: {
    backgroundColor: theme.palette.common.white,
    height: "46px",
    borderRadius: "23px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.common.white
    },
    paddingLeft: "8px"
  }
}));

export default function GoogleLoginButton({ className, label, ...rest }: AuthButtonsProps): ReturnType<React.FC> {
  const classes = useButtonStyle();

  return (
    <Button variant="outlined" className={clsx(classes.button, className)} {...rest}>
      <img src={GoogleLogo} alt={`${label} with Google`} height="15px" width="15px" style={{ marginRight: "10px" }} />
      <div style={{ color: "#222222" }}>{`${label} with Google`}</div>
    </Button>
  );
}
