import React from "react";
import { Box, Typography } from "@material-ui/core";

interface SearchResultCountProps {
  count?: number;
  style: React.CSSProperties;
  searchString: string;
}

const SearchResultCount: React.FC<SearchResultCountProps> = (props: SearchResultCountProps) => {
  const { style, searchString } = props;

  return (
    <Box style={{ ...style }}>
      <Typography
        variant="body1"
        style={{
          marginBottom: 10,
          fontWeight: "normal"
        }}
      >
        Search results for
      </Typography>
      <Typography variant="h6">{searchString}</Typography>
    </Box>
  );
};

export default SearchResultCount;
