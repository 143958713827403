/**
 *
 * @param cardNumber card number entered in the CardInput
 * @param validation card validation result
 * @param error
 * @param onValidate callback to manually trigger card validation
 * @returns {{onValidate: undefined, error: string, cardNumber: undefined, validation: undefined}}
 * @constructor
 */
export default function CardInputData(
  cardNumber = undefined,
  validation = undefined,
  error = "",
  onValidate = undefined
) {
  return { cardNumber, validation, error, onValidate };
}
