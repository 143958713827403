import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import { CheckoutResultStyles } from "./CheckoutResultStyles";
import RollbarTracker from "../../../utils/RollbarTracker";
import AppTable, { createRow } from "../../common/table/AppTable";
import CheckoutResultHeader from "./CheckoutResultHeader";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import CheckoutGiftDetail from "../CheckoutGiftDetail";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";
import TermsAndConditions from "../../termsAndConditions/TermsAndConditions";
import { CreateAccountWithPassword } from "./CreateAccountWithPassword";
import Caption from "../../common/caption/Caption";
import { CURRENCY } from "../../common/Constants";
import DataLayer from "../../../utils/dataLayer";

export default function CheckoutResultV2(props) {
  const styles = CheckoutResultStyles();

  const [purchaseTracked, setPurchaseTracked] = useState(false);

  const [pageData, setState] = useState({
    gift: undefined,
    receiver_nickname: props.location.state.receiver_nickname,
    sender_nickname: props.location.state.sender_nickname,
    order: props.location.state.order.order_data,
    error: null,
    email: props.location.state.email,
    paymentType: props.match.params.paymentType,
    sender_user_id: props.location.state.sender_user_id
  });

  const trackPurchaseEvent = () => {
    if (!purchaseTracked && pageData.order) {
      console.log("Purchase event: ", pageData.order);

      new DataLayer().gtag({
        key: "event",
        trackingId: "purchase",
        config: {
          transaction_id: pageData.order.order_id,
          affiliation: "Gift.Me",
          value: pageData.order.total_price_paid_converted,
          currency: pageData.order.currency_code,
          tax: pageData.order.tax_price_paid_converted,
          shipping: pageData.order.shipping_price_paid_converted,
          items: [
            {
              id: pageData.order.gift_id,
              name: pageData.order.gift_name,
              quantity: 1,
              price: pageData.order.total_price_paid_converted,
            },
          ],
        },
      });

      setPurchaseTracked(true);
    }
  };

  const fetchGift = () => {
    axios
      .get("/api/gifts/getgift?giftId=" + pageData.order.gift_id)
      .then((response) => {
        console.log("Get gift data:", response);

        const gift = Object.values(response.data)[0];
        setState({
          ...pageData,
          gift: gift
        });
      })
      .catch((error) => {
        const errorMsg = "Error on getting gift";
        RollbarTracker.logError(errorMsg, error);
        console.log(errorMsg, error);

        setState((prev) => ({
          ...prev,
          error
        }));

        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  useEffect(() => {
    fetchGift();
    trackPurchaseEvent();
  }, []);

  if (pageData.gift === undefined) {
    return (
      <Box>
        <Box display="flex" justifyContent="center">
          <Typography
            style={{
              marginTop: "50px",
              textAlign: "center",
              color: "#aaaaaa"
            }}
          >
            Processing payment...
          </Typography>
          <CircularProgress
            style={{
              marginTop: "100px",
              position: "absolute"
            }}
          />
        </Box>
      </Box>
    );
  } else {
    const itemCost = pageData.order.sale_price_paid_converted;

    const orderDetailRows = [
      createRow("Item:", CURRENCY + itemCost),
      createRow(
        "Delivery:",
        CURRENCY + roundToDecimalPlaces(pageData.order.shipping_price_paid_converted, 2)
      ),
      createRow("Tax/Fees:", CURRENCY + pageData.order.tax_price_paid_converted, styles.beforeLastTableCell),
      createRow(
        "Order total:",
        CURRENCY + pageData.order.total_price_paid_converted,
        styles.highlightedTableCell
      )
    ];

    // 2 = credit and card, 1 = just card, 0 = credit
    const getPaymentType = () => {
      if (pageData.paymentType == 0) {
        return "Credits";
      } else if (pageData.paymentType == 1) {
        return "Credit Card";
      } else if (pageData.paymentType == 2) {
        return "Credits/Credit Card";
      }
    };

    return (
      <Box>
        <Box className={styles.contentStyle}>
          <CheckoutResultHeader email={pageData.email} />
          <Box className={styles.cardsContainer}>
            <Card className={styles.leftCard}>
              <CardContent className={styles.cardContent}>
                <SectionTitle>Order Confirmation</SectionTitle>
                <Caption style={{ marginTop: "8px" }}>
                  Thank you. We’ve received order #{pageData.order.order_id} and are working on it now.
                </Caption>
                <Box className={styles.orderConfirmationContainer}>
                  <Typography>
                    Transaction number #:{" "}
                    <Typography component={"span"} style={{ fontWeight: 600 }}>
                      {pageData.order.order_id}
                    </Typography>
                  </Typography>
                  <Typography style={{ marginTop: "8px" }}>
                    Payment method:{" "}
                    <Typography component={"span"} style={{ fontWeight: 600 }}>
                      {getPaymentType()}
                    </Typography>
                  </Typography>
                  <CheckoutGiftDetail
                    imageUrl={pageData.order.image_url_xlarge_ssl}
                    giftName={pageData.order.gift_name}
                    receiverNickname={pageData.receiver_nickname}
                    itemCost={itemCost}
                    style={{
                      marginTop: "8px"
                    }}
                  />
                  <Typography component="span">
                    From: <b>{pageData.sender_nickname || "Myself"}</b>
                  </Typography>
                  <Typography style={{ marginTop: "8px" }}>{pageData.order.message || ""}</Typography>
                </Box>
                <AppTable rows={orderDetailRows} classes={styles} style={{ marginTop: "16px" }} />
              </CardContent>
            </Card>
            <Card className={styles.rightCard}>
              <CardContent className={styles.cardContent}>
                <CreateAccountWithPassword
                  email={pageData.email}
                  senderUserId={pageData.sender_user_id}
                  hasSession={props.match.params.hasSession}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
        <TermsAndConditions />
      </Box>
    );
  }
}
