import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Box, makeStyles, Typography, styled } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { useApp } from "../../../context/app-context";

const useStyles = ({ position, width }) => {
  return makeStyles((theme) => ({
    toastMessage: {
      ...theme.typography.body1,
      "& > .Toastify__toast": {
        borderRadius: 30
      },
      [theme.breakpoints.down("xs")]: {
        left: position === "bottom-center" ? "50%" : "unset",
        transform: position === "bottom-center" ? "translate(-50%, -92px)" : "unset",
        width: width ?? 257
      }
    }
  }));
};

const Toast = () => {
  const {
    toastNotification: { position, width, message1, message2, delay },
    setToastMessage
  } = useApp();

  const classes = useStyles({ position, width })();

  useEffect(() => {
    if (message1 && message2) {
      toast(() => (
        <Box style={{ textAlign: "center" }}>
          <Typography variant="body1" style={{ fontSize: 12, fontWeight: 500 }}>
            {message1}
          </Typography>
          <Typography variant="body1" style={{ fontSize: 12, fontWeight: 300 }}>
            {message2}
          </Typography>
        </Box>
      ));
    }

    return () => {
      setToastMessage({ message1: "", message2: "" });
    };
  }, [message1, message2]);

  return (
    <ToastContainer
      className={classes.toastMessage}
      position={position}
      autoClose={delay}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
    />
  );
};

export default Toast;
