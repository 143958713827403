import React, { useEffect, useState } from "react";
import { Box, makeStyles, Theme, FormControl, CircularProgress } from "@material-ui/core";
import { useAppSelector, useAppDispatch } from "../../hooks";
import Section from "../common/appLayout/Section";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "../../theme/theme-devins";
import TextInput from "../input/TextInputV3";
import TextArea from "../input/TextArea";
import Form, { FormInput } from "../input/Form";
import { updateProfileAction, validateUserNickname } from "../../actions/userAction";
import { setUserData, setUserLocalDbData } from "../../reducers/user/UserSlice";
import PrimaryButtonV3 from "../buttons/PrimaryButtonV3";
import SecondaryButtonV3 from "../buttons/SecondaryButtonV3";
import { COLORS } from "../../style";
import useNav from "../../hooks/useNav";
import { WISHLIST_PATH } from "../../constants/paths";
import ProfilePicture from "../common/profile/ProfilePicture";
import Toast from "light-toast";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";
import { useApp } from "../../context/app-context";

const useStyle = makeStyles<Theme>((theme) => ({
  section: {
    marginTop: 50,
    marginBottom: 88,
    [theme.breakpoints.down("md")]: {
      backgroundColor: COLORS.WHITE,
      marginTop: 15,
      marginBottom: 0,
      paddingTop: 30,
      paddingBottom: 88
    }
  },
  validateSuccess: {
    fontSize: "14px",
    fontStyle: "italic",
    color: "#B5B5B5",
    marginTop: 5,
    marginBottom: 5
  },
  profileAction: {
    marginTop: 30,
    marginBottom: 50,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "flex"
    },
    "& > button": {
      marginLeft: 24,
      fontSize: 14,
      width: 175,
      height: 48,
      lineHeight: 0,
      border: "solid 1px #E6007E",
      boxShadow: "none",
      "&:last-child": {
        marginLeft: 24
      },
      [theme.breakpoints.down("xs")]: {
        width: 130,
        marginLeft: 0,
        "&:last-child": {
          marginLeft: 10
        }
      }
    }
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: COLORS.WHITE,
    minHeight: 519,
    width: "100%",
    paddingTop: 80,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: 20
    }
  },
  formContainer: {
    width: 700,
    marginLeft: 100,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0
    }
  },
  labelClassName: {
    marginLeft: 0,
    marginTop: 10
  },
  avatarClassName: {
    marginLeft: "0 !important;"
  }
}));

const Wishlist = (): ReturnType<React.FC> => {
  const { firstname, lastname, gender, email, encryptedUserId, localDbData } = useAppSelector(
    (state) => state.user.data
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyle();
  const { goTo } = useNav();
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaBreakpoints();
  const { setLoggedInUserFullname } = useApp();

  const initialFormInputs = {
    nickname: FormInput(),
    about: FormInput()
  };

  const prefillFormInputValue = (formInput, value) => {
    if (value && value !== "") {
      formInput.value = value;
      formInput.original = value;
    }
  };
  const validateForm = (inputs = formInputs) => {
    let hasError: boolean;
    for (const inputName in inputs) {
      const formInput = inputs[inputName];
      if ("nickname" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      }
      hasError = hasError ? hasError : formInput.error !== "";
    }
    return !hasError;
  };

  const checkAvailability = async () => {
    const nickname = formInputs.nickname.value;
    if (nickname === "") {
      formInputs.nickname.error = "This is required";
    } else {
      try {
        const validateUserNicknameResponse = await validateUserNickname(nickname);
        if (!validateUserNicknameResponse.isAvailable) {
          formInputs.nickname.error = validateUserNicknameResponse.message;
          setIsValid(false);
        } else {
          formInputs.nickname.error = "";
          setIsValid(true);
        }
        setFormInputs({ ...formInputs });
      } catch (error) {
        formInputs.nickname.error = error.response.data;
        setIsValid(false);
      }
    }
  };

  const updateProfile = async () => {
    setIsLoading(true);
    try {
      const payload = {
        nickname: formInputs.nickname.value,
        about: formInputs.about.value,
        email,
        firstname,
        lastname,
        gender
      };
      const response = await updateProfileAction(payload);
      const { localDbData, ...userData } = response.data;

      dispatch(setUserLocalDbData(localDbData));
      dispatch(setUserData(userData));
      setLoggedInUserFullname(localDbData.nickname);
      Toast.success("Your Profile Has Been Updated", 2000);
      setTimeout(() => {
        goTo(`${WISHLIST_PATH}/${encryptedUserId}`);
      }, 2200);
    } catch (error) {
      console.log(error);
    }
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur, isFormInputsValueChanged } = Form(
    initialFormInputs,
    validateForm
  );

  useEffect(() => {
    if (localDbData?.nickname) {
      prefillFormInputValue(formInputs.nickname, localDbData?.nickname ?? "");
    }
    if (localDbData?.about) {
      prefillFormInputValue(formInputs.about, localDbData?.about ?? "");
    }
  }, [localDbData?.about]);
  return (
    <AppLayout theme={theme} headerVariant="header1">
      {!localDbData?.nickname ? (
        <Box style={{ minHeight: 500, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Section className={classes.section}>
          <Box className={classes.wrapper}>
            <ProfilePicture
              avatarWidth={164}
              avatarHeight={164}
              avatarClassName={classes.avatarClassName}
              labelClassName={classes.labelClassName}
              style={{ marginTop: 0, alignSelf: isDesktop ? "unset" : "center", textAlign: "center" }}
            />

            <Box className={classes.formContainer}>
              <FormControl fullWidth style={{ marginTop: "24px" }}>
                <TextInput
                  id="nickname"
                  name="nickname"
                  label=" Handle"
                  placeholder="Handle"
                  InputProps={{
                    disabled: isLoading,
                    style: { borderRadius: 50 }
                  }}
                  onBlur={(e) => {
                    handleOnBlur(e);
                    checkAvailability();
                  }}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={formInputs.nickname.value}
                  error={formInputs.nickname.error}
                  helperText={formInputs.nickname.error}
                  onFocus={handleOnFocus}
                  showSuccessIcon={isValid}
                  showErrorIcon={formInputs.nickname.error}
                  style={{ marginBottom: 30, width: isDesktop ? 340 : "100%" }}
                />

                <TextArea
                  id="about"
                  name="about"
                  label="About"
                  disabled={isLoading}
                  value={formInputs.about.value}
                  rows={3}
                  charLimit={500}
                  onChange={handleInputChange}
                  style={{ marginBottom: 20, width: "100%" }}
                />
              </FormControl>

              <Box className={classes.profileAction}>
                <SecondaryButtonV3
                  disabled={isLoading}
                  text="Cancel"
                  style={{
                    background: "#fff",
                    color: "#E6007E",
                    height: isDesktop ? 48 : 40
                  }}
                  onClick={() => goTo(`${WISHLIST_PATH}/${encryptedUserId}`)}
                />
                <PrimaryButtonV3
                  onClick={() => updateProfile()}
                  style={{ height: isDesktop ? 48 : 40 }}
                  disabled={isLoading || !isFormInputsValueChanged || !isValid}
                >
                  {isLoading ? "Saving Changes..." : "Save Changes"}
                </PrimaryButtonV3>
              </Box>
            </Box>
          </Box>
        </Section>
      )}
    </AppLayout>
  );
};

export default Wishlist;
