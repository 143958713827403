import React from "react";
import { Typography, Box } from "@material-ui/core";

interface HowItWorksDescriptionProps {
  description: string;
}

export default function HowItWorksDescription({ description }: HowItWorksDescriptionProps) {
  return (
    <Box width="79%" textAlign="center" marginTop="10px" m="auto">
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
}
