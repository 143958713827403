import { GiftInterface } from "../models/gift";
import * as sdk from "../sdk";
import { AxiosResponse } from "axios";
import axios from "axios";

export const getImageUrl = (gift: GiftInterface, default_field?: string): string => {
  if (default_field && default_field.length) {
    return gift[default_field];
  }

  return gift.image_url_high_res || gift.image_url;
};

export const getGiftsAction = (params): Promise<AxiosResponse<any>> => {
  return sdk.getGifts(params);
};

export const getGift = (giftId): Promise<AxiosResponse<any>> => {
  return axios.get(`/api/gifts/getgift?giftId=${giftId}`);
};

export function declineGift(orderId: string): Promise<AxiosResponse<any>> {
  return axios.post("/api/gifts/decline", { orderId });
}

export function exchangeGiftForCredit(orderId: string): Promise<AxiosResponse<any>> {
  return axios.post("/api/gifts/exchange-for-credit", { orderId });
}
