import React, { FC, useState, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StyledBadge from "../badge/StyledBadge";
import { Link, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { useAppSelector } from "../../../hooks";
import { TAB_MY_ACCOUNT } from "../../../constants/bottom-tabs";
import { FONT_FAMILIES } from "../../../style";

export const NAV_BAR_HEIGHT = 68;

const BottomNavBarStyles = makeStyles((theme) => ({
  navBar: {
    height: NAV_BAR_HEIGHT,
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 1298
  },
  root: {
    color: theme.palette.grey[700],
    "&$selected": {
      color: "#e6007e"
    },
    "&:hover": {
      color: "e6007e"
    }
  },
  label: {
    textAlign: "center",
    fontFamily: FONT_FAMILIES.SOFIA_PRO,
    fontSize: theme.typography.pxToRem(12),
    "&$selected": {
      fontSize: theme.typography.pxToRem(13)
    }
  },
  badge: {
    backgroundColor: theme.palette.grey[700]
  },
  // this is required for `&$selected` selector to work and
  // is referenced by local style rules for selected state
  selected: {}
}));

interface BottomNavBarProps {
  tabs: any;
  mapCurrentPathToTab: (tabs: [], currentPath: string, encryptedUserId?: string) => any;
  setPromptLogin({ promptLogin, returnTo, registration, forceRegistration }): any;
  handleSidebarToggle: (show?: boolean) => void;
  sidebarToggle: boolean;
}

export const BottomNavBar: FC<BottomNavBarProps> = ({
  tabs,
  mapCurrentPathToTab,
  setPromptLogin,
  handleSidebarToggle,
  sidebarToggle
}: BottomNavBarProps) => {
  const navStyles = BottomNavBarStyles();
  const [selectedTabId, setSelectedTabId] = useState<number>();
  const { encryptedUserId } = useAppSelector((state) => state.user.data);

  const location = useLocation();

  const handleBottomNavClick = (tab, e) => {
    if (tab.label === "My Account") {
      if (encryptedUserId) {
        // Shows main menu for mobile
        handleSidebarToggle();
        e.preventDefault();
      } else {
        // Prompts to login when user is not logged in on clicking My Account tab
        if (setPromptLogin) {
          // Preventing BottomNavigationAction from navigating to the clicked tab's destination URL
          setPromptLogin({
            promptLogin: true,
            returnTo: tab.navigateTo,
            registration: false,
            forceRegistration: false
          });
          e.preventDefault();
        }
      }
    } else {
      handleSidebarToggle(false);
      clearAllBodyScrollLocks();
    }
  };

  useEffect(() => {
    if (sidebarToggle) {
      setSelectedTabId(TAB_MY_ACCOUNT);
    } else {
      if (mapCurrentPathToTab) {
        const selectedTab = mapCurrentPathToTab(tabs, location.pathname, encryptedUserId);
        setSelectedTabId(selectedTab ? selectedTab.id : undefined);
      }
    }
  }, [location, sidebarToggle]);

  return (
    <BottomNavigation
      component={Paper}
      elevation={3}
      square={true}
      showLabels={true}
      className={navStyles.navBar}
      value={selectedTabId}
    >
      {tabs.map((tab) => {
        if (tab.label === "My Wishlist" && !encryptedUserId) {
          return;
        }

        if (tab.label === "How To" && encryptedUserId) {
          return;
        }

        let label;
        if (tab.label === "My Account" && !encryptedUserId) {
          label = "Log in";
        } else {
          label = tab.label;
        }

        return (
          <BottomNavigationAction
            key={tab.id}
            component={Link}
            label={label}
            value={tab.id}
            to={tab.navigateTo}
            onClick={(e) => {
              handleBottomNavClick(tab, e);
            }}
            selected={selectedTabId === tab.id}
            icon={getTabIcon(tab, selectedTabId, navStyles.badge)}
            classes={{
              root: navStyles.root,
              label: navStyles.label,
              selected: navStyles.selected
            }}
          />
        );
      })}
    </BottomNavigation>
  );
};

function getTabIcon(tab, selectedTabId, badgeStyle) {
  let iconLayout;
  let icon;

  const isTabSelected = tab.id === selectedTabId;
  if (isTabSelected) {
    icon = tab.selectedIcon;
  } else {
    icon = tab.icon;
  }

  if (tab.badgeCount > 0) {
    iconLayout = (
      <StyledBadge badgeContent={tab.badgeCount} className={!isTabSelected ? badgeStyle : undefined}>
        {icon}
      </StyledBadge>
    );
  } else {
    iconLayout = icon;
  }

  return iconLayout;
}

interface BottomNavTabProps {
  id: number;
  label: string;
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  badgeCount: number;
  navigateTo: string;
}

export const BottomNavTab = ({
  id,
  label,
  icon,
  selectedIcon = icon,
  badgeCount = 0,
  navigateTo
}: BottomNavTabProps) => ({
  id,
  label,
  icon,
  selectedIcon,
  badgeCount,
  navigateTo
});
