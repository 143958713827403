import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Theme, useMediaQuery } from "@material-ui/core";
import { CURRENCY } from "../common/Constants";

const CheckoutGiftDetailStyles = makeStyles((theme) => ({
  checkoutGiftContent: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    paddingBottom: "16px"
  },
  checkoutGiftImage: {
    maxHeight: "200px",
    maxWidth: "100px",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "300px",
      maxWidth: "150px"
    }
  },
  checkoutGiftNameContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "13px"
  }
}));

function ItemDetailsDesktop(props) {
  const { styles, giftName, imageUrl } = props;
  return (
    <Box className={styles.checkoutGiftContent}>
      <Box className={styles.checkoutGiftImage} style={{ margin: "0 auto" }}>
        <img src={imageUrl} alt={giftName} className={styles.checkoutGiftImage} />
      </Box>
    </Box>
  );
}

function ItemDetailsMobile(props) {
  const { styles, giftName, imageUrl, itemCost } = props;
  return (
    <Box className={styles.checkoutGiftContent} style={{ marginBottom: 25 }}>
      <Box className={styles.checkoutGiftImage}>
        <img src={imageUrl} alt={giftName} className={styles.checkoutGiftImage} />
      </Box>

      <Box className={styles.checkoutGiftNameContainer}>
        <Typography component="span">{giftName}</Typography>
        <Typography component="span" style={{ marginTop: "8px" }}>
          {`${itemCost}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function CheckoutGiftDetail(props: any) {
  const styles = CheckoutGiftDetailStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const { giftName, itemCost, imageUrl } = props;
  return (
    <Box style={props.style}>
      <Typography component="span">
        Ship gift to: <b>{props.receiverNickname}</b>
      </Typography>
      {isDesktop ? (
        <ItemDetailsDesktop styles={styles} giftName={giftName} imageUrl={imageUrl} />
      ) : (
        <ItemDetailsMobile styles={styles} giftName={giftName} itemCost={itemCost} imageUrl={imageUrl} />
      )}
    </Box>
  );
}
