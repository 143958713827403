import React, { useState, useEffect, useRef } from "react";
import { FormHelperText, TextField, Box, Typography, Theme, useMediaQuery, styled, FormLabel } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import _debounce from "lodash.debounce";
import { UserValidateVanity } from "../../reducers/user/hooks/UserValidateVanity";
import Toast from "light-toast";
import { makeStyles } from "@material-ui/core/";
import ErrorIcon from "../customIcons/ErrorIcon";
import CheckIcon from "../customIcons/CheckIcon";
import CopyIcon from "../customIcons/CopyIcon";
import { FONT_FAMILIES, textColor } from "../../style";

const ComponentStyling = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 24,
    position: "relative"
  },
  inputContainer: {
    position: "relative",
    borderRadius: 30,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    width: "100%",
    padding: "18.5px 14px"
  },
  url: {
    color: "#888888",
    fontSize: 18
  },
  icon: {
    position: "absolute",
    top: 20,
    right: 10,
    cursor: "pointer"
  },
  helperText: {
    marginBottom: 16,
    marginTop: 6
  },
  cancelButton: {
    color: "#E92983",
    cursor: "pointer",
    fontWeight: "bold",
    position: "absolute",
    top: 60,
    right: 0,
    [theme.breakpoints.up("sm")]: {
      top: 20,
      right: -65
    }
  },
  fakeField: {
    cursor: "pointer",
    position: "relative",
    top: -1,
    width: "78%"
  }
}));

const StyledFieldText = styled(TextField)(() => ({
  "& div": {
    "&:before": {
      borderBottom: "none !important"
    },
    "&:after": {
      borderBottom: "none !important"
    },
    "&:hover": {
      borderBottom: "none !important"
    }
  },
  "& input": {
    maxWidth: "90%",
    padding: 0,
    position: "relative",
    top: -4,
    height: 30
  }
}));

interface VanityUrlProps {
  vanityUrlOnChangedHandler: (vanity: string, error: string) => void;
}

const VanityUrl: React.FC<VanityUrlProps> = ({ vanityUrlOnChangedHandler }: VanityUrlProps) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const styles = ComponentStyling();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const _url = `${window.location.origin}/`;
  const { vanity: defaultVanity } = useAppSelector((state) => state.user.data);

  const [error, setError] = useState<string | null>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [updatedVanity, setUpdatedVanity] = useState(defaultVanity);

  const validateVanity = _debounce(
    async (value) => {
      try {
        setValidated(true);
        setUpdatedVanity(value);

        const regexp = /[ `!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/g;
        // reset error
        setError(null);

        if (regexp.test(value)) {
          setError('Vanity url should only contain letters, numbers and "_".');
          return;
        }

        if (!value.length) {
          setError("Vanity url should not be empty.");
          return;
        }

        const isAvailable = await UserValidateVanity(value);
        if (!isAvailable) {
          setError("Url already taken.");
        } else {
          setError(null);
        }
      } catch (error) {
        setError("Error validating wishlist url. Please try again.");
      }
    },
    250,
    { maxWait: 1000 }
  );

  const validateVanityLocal = (value) => {
    validateVanity(value);
  };

  const cancel = () => {
    setEditing(false);
    setValidated(false);
    setError(null);
    setUpdatedVanity(defaultVanity);
  };

  const copyToClipboard = (e) => {
    e.preventDefault();
    const el = document.createElement("textarea");
    el.value = `${_url}${defaultVanity}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast.info("Copied to clipboard.", 750);
  };

  useEffect(() => {
    vanityUrlOnChangedHandler(updatedVanity, error);
  }, [error, updatedVanity]);

  useEffect(() => {
    if (textFieldRef.current && editing) {
      textFieldRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    // Will trigger when you click the personalize url link
    if (location.state?.focusVanityUrlInput) {
      setEditing(true);
    }
    return () => window.history.replaceState({}, document.title); // clear history state
  }, [location.state?.focusVanityUrlInput]);

  useEffect(() => {
    setUpdatedVanity(defaultVanity);
    setEditing(false);
  }, [defaultVanity]);

  return (
    <Box className={styles.root}>
      <FormLabel
        component="h6"
        style={{
          color: textColor.DARK1,
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: 18,
          marginBottom: 6,
          fontFamily: FONT_FAMILIES.SOFIA_PRO,
          lineHeight: "24.39px",
          marginTop: 0
        }}
      >
        Custom Wishlist URL
      </FormLabel>
      <Typography id="my-helper-text" variant="body1">
        A custom URL is a short, easy-to-remember web address that makes it easier to direct people to your wishlist
        page.
      </Typography>
      <Box className={styles.inputContainer} display="flex" alignContent="center">
        {isDesktop && <Typography className={styles.url}>{_url}</Typography>}
        {editing ? (
          <StyledFieldText
            id="user-vanity"
            fullWidth={true}
            inputRef={textFieldRef}
            onChange={(e) => {
              const { value } = e.target;
              validateVanityLocal(value);
            }}
          />
        ) : (
          <Typography
            className={styles.fakeField}
            onClick={() => {
              setEditing(true);
            }}
          >
            {defaultVanity}
          </Typography>
        )}
        {!editing && <CopyIcon className={styles.icon} onClick={copyToClipboard} />}
        {validated && editing && !error && <CheckIcon className={styles.icon} />}
        {validated && editing && error && <ErrorIcon className={styles.icon} />}
        {editing && (
          <Typography variant="caption" display="block" gutterBottom className={styles.cancelButton} onClick={cancel}>
            Cancel
          </Typography>
        )}
      </Box>
      {!isDesktop && (
        <Typography className={styles.url} style={{ fontSize: 12 }}>
          {_url}
          <span style={{ fontWeight: "bold" }}>{updatedVanity}</span>
        </Typography>
      )}
      {error && (
        <FormHelperText error id="vanity-error-helper">
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default VanityUrl;
