import React from "react";
import { Box, useMediaQuery, Theme } from "@material-ui/core";
import { DESKTOP_CONTENT_WIDTH } from "./Constants";
import { makeStyles } from "@material-ui/core";

interface AppContentContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const AppContentContainerStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      padding: 12
    }
  }
}));
const AppContentContainer: React.FC<AppContentContainerProps> = (props) => {
  const styles = AppContentContainerStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  const { style, children } = props;
  return (
    <Box width={isDesktop ? DESKTOP_CONTENT_WIDTH : "100%"} style={{ ...style }} className={styles.root}>
      {children}
    </Box>
  );
};

export default AppContentContainer;
