import React, { useMemo } from "react";
import { Typography, Grid, useMediaQuery, Theme, makeStyles } from "@material-ui/core";
import Section from "../common/Section";
import { COLORS, FONT_FAMILIES } from "../../../style";
import CardMedia from "../common/CardMedia";

const useStyles = makeStyles((theme) => ({
  section: {
    height: 684,
    alignItems: "center",
    backgroundColor: "#1D1D1D",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      paddingTop: 80,
      paddingBottom: 80
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      paddingTop: 40,
      paddingBottom: 40
    }
  },
  sectionContent: {
    alignItems: "center"
  },
  mainTitle: {
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      lineHeight: "32px",
      marginBottom: 15
    }
  },
  title: {
    display: "flex",
    alignItems: "flex-start",
    fontWeight: 500,
    marginBottom: 24,
    lineHeight: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      marginBottom: 20
    },
    "& > img": {
      marginRight: 16
    }
  }
}));

const ManifestGiftsSection = () => {
  const classes = useStyles();
  const dir = "/assets/home/homeV4/vanities/";
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const vanityLists = useMemo(
    () => [
      {
        title: "noeliaramirez",
        url: "noelia",
        storeId: 0
      },
      {
        title: "arikasato",
        url: "arika",
        storeId: 0
      },
      {
        title: "victoriamyers",
        url: "victorialynnmyers",
        storeId: 0
      },
      {
        title: "jessicabarbie",
        url: "jessicabarbiee",
        storeId: 0
      }
    ],
    []
  );

  return (
    <Section className={classes.section} contentProps={{ className: classes.sectionContent }}>
      <>
        <Grid container spacing={4}>
          <Grid item lg={12} xs={12} style={{ margin: "auto", textAlign: "center", maxWidth: 788 }}>
            <Typography variant="h2" className={classes.mainTitle}>
              Manifest Gifts
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 400, lineHeight: "26px", color: COLORS.WHITE, fontSize: 16 }}
            >
              Influencers are being sent gifts from fans every day because of Gift.me. Check out their (real!)
              posts below to see what they received, and then create and share your own wishlist today!
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: 30 }}>
          {vanityLists.map((item, idx) => {
            return (
              <Grid item lg={3} xs={6} key={idx}>
                <CardMedia
                  title={`${window.location.hostname}/${item.url}`}
                  borderRadius={0}
                  titleStyle={{
                    fontFamily: FONT_FAMILIES.POPPINS,
                    fontSize: isDesktop ? 16 : "14px !important",
                    lineHeight: isDesktop ? "26px" : "21px",
                    fontWeight: "400 !important",
                    color: COLORS.WHITE,
                    marginTop: "-8px"
                  }}
                  src={`${dir}${item.title}.jpg`}
                  height={isDesktop ? 280 : 224}
                  color="textSecondary"
                  redirectTo={item.url}
                />
              </Grid>
            );
          })}
        </Grid>
      </>
    </Section>
  );
};

export default ManifestGiftsSection;
