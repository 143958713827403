interface Recipient {
  nickname: string;
  giftCount: number;
  userId: string;
}

export function getRecipients() {
  return JSON.parse(localStorage.getItem("recipients"));
}

export const updateRecipientsToLocalStorage = (recipient: Recipient) => {
  if (recipient.nickname && recipient.userId) {
    const currentRecipients = JSON.parse(localStorage.getItem("recipients")) ?? [];
    const cleanRecipients = currentRecipients.filter((item: Recipient) => item.userId !== recipient.userId);
    const finalRecipients = [...cleanRecipients, { ...recipient }];
    localStorage.setItem("recipients", JSON.stringify(finalRecipients));
  }
};

export function setAuthenticatedUserId(user) {
  localStorage.setItem("authenticatedUserId", JSON.stringify(user));
}

export function getAuthenticatedUserId() {
  return JSON.parse(localStorage.getItem("authenticatedUserId"));
}

export function getSelectedRecipient() {
  return JSON.parse(localStorage.getItem("selectedRecipient"));
}

export function setSelectedRecipient(recipient) {
  localStorage.setItem("selectedRecipient", JSON.stringify(recipient));
}

export function addItemToRecipients(newRecipient) {
  if (!newRecipient || !newRecipient.userId || !newRecipient.nickname) {
    return;
  }

  const recipients = JSON.parse(localStorage.getItem("recipients"));
  if (!recipients || !recipients.length) {
    localStorage.setItem("recipients", JSON.stringify([newRecipient]));
    return;
  }

  const filteredRecipients = recipients.filter((recipient) => recipient.userId !== newRecipient.userId);
  filteredRecipients.push(newRecipient);

  localStorage.setItem("recipients", JSON.stringify(filteredRecipients));
}

export function setSignUpEvent(social) {
  localStorage.setItem("signUpEvent", social);
}

export function getSignUpEvent() {
  return localStorage.getItem("signUpEvent");
}

export function deleteSignUpEvent() {
  localStorage.removeItem("signUpEvent");
}

export function getGiftSearches(): Array<string> {
  const giftSearches = JSON.parse(localStorage.getItem("giftSearches"));
  return giftSearches ?? [];
}

export function addItemToGiftSearches(searchString: string): void {
  if (!searchString || !searchString.length) {
    return;
  }

  const giftSearches = JSON.parse(localStorage.getItem("giftSearches"));
  if (!giftSearches || !giftSearches.length) {
    localStorage.setItem("giftSearches", JSON.stringify([searchString]));
    return;
  }

  giftSearches.push(searchString);
  localStorage.setItem("giftSearches", JSON.stringify(giftSearches));
}

export function clearLocalStorageWhenLogout() {
  localStorage.removeItem("authenticatedUserId");
  localStorage.removeItem("selectedRecipient");
  localStorage.removeItem("userFirstTimeLogin");
}

export function removeUserFirstTimeLogin() {
  localStorage.removeItem("userFirstTimeLogin");
}
