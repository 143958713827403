import CardInputData from "../../input/card/CardInputData";
import { FormInput } from "../../input/Form";
import { CARD_CVV_LABEL, CARD_CVV_SIZE } from "../../input/card/CardUtils";

export const initialFormInputs = {
  card: CardInputData(),
  exp_month: FormInput(),
  exp_year: FormInput(),
  csv: FormInput(CARD_CVV_LABEL, CARD_CVV_SIZE),
  billing_name: FormInput()
};
