import React, { FC } from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
import { vysionShare } from "../../../actions/vysionAction";
import EmailShareImage from "../../share/icon_em.png";
import FacebookShareImage from "../../share/icon_fb.png";
import TwitterShareImage from "../../share/icon_tw.png";
import DataLayer from "../../../utils/dataLayer";

const ShareFooterStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(16)}`,
  },
  socialButtonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  socialButton: {
    width: "75px",
    height: "75px",
    margin: theme.typography.pxToRem(5),
  },
  closeButton: {
    width: "70%",
    marginLeft: `0px ${theme.typography.pxToRem(5)}`,
  },
}));

interface ShareDialogSocialFooterProps {
  shareTitle: string;
  shareBody: string;
  shareUrl: string;
  encryptedUserId: string;
}

export default function ShareDialogSocialFooter({
  shareTitle,
  shareBody,
  shareUrl,
  encryptedUserId,
}: ShareDialogSocialFooterProps): ReturnType<FC> {
  const footerStyles = ShareFooterStyles();

  return (
    <Box className={footerStyles.root}>
      <Typography gutterBottom style={{ marginBottom: "16px" }}>
        or share it on...
      </Typography>

      <Box className={footerStyles.socialButtonBox}>
        {/* NOTE: Facebook sharing won't work on localhost - https://github.com/nygardk/react-share/issues/233 */}
        <FacebookShareButton
          quote={shareTitle}
          url={shareUrl}
          beforeOnClick={() => {
            new DataLayer().gtag({
              key: "event",
              trackingId: "my_wishlist_share",
              config: {
                method: "Facebook"
              },
            });

            vysionShare(
              "wishlist_share_facebook",
              shareUrl,
              encryptedUserId
            );
          }}
        >
          <img className={footerStyles.socialButton} src={FacebookShareImage} alt="Share with Facebook" />
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          title={`${shareTitle} via @giftmewishlist`}
          hashtags={["#realgifts"]}
          beforeOnClick={() => {
            new DataLayer().gtag({
              key: "event",
              trackingId: "my_wishlist_share",
              config: {
                method: "Twitter"
              },
            });

            vysionShare(
              "wishlist_share_twitter",
              shareUrl,
              encryptedUserId
            );
          }}
        >
          <img className={footerStyles.socialButton} src={TwitterShareImage} alt="Share with Twitter" />
        </TwitterShareButton>

        {/* NOTE: Cannot share to Instagram via web (only native apps) - https://stackoverflow.com/a/17682403 */}
        {/* <img src={InstagramShareImage} alt="Share with Instagram" height="75px" width="75px" style={{ margin:"5px" }} /> */}
        <EmailShareButton
          subject={shareTitle}
          body={`${shareBody}:`}
          url={shareUrl}
          beforeOnClick={() => {
            new DataLayer().gtag({
              key: "event",
              trackingId: "my_wishlist_share",
              config: {
                method: "Email"
              },
            });

            vysionShare(
              "wishlist_share_email",
              shareUrl,
              encryptedUserId
            );
          }}
        >
          <img className={footerStyles.socialButton} src={EmailShareImage} alt="Share with Email" />
        </EmailShareButton>
      </Box>
    </Box>
  );
}
