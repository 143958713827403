import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { MY_ACCOUNT_PATH } from "../../constants/paths";
import { UserContext } from "../../context";

export const ValidationRoute = ({ component: Component, encryptedUserId, ...rest }) => {
  const context = useContext(UserContext);
  const { email, nickname, gender } = context.user.get;

  // UseEffect is not yet finished setting user
  if (encryptedUserId && !email) {
    return null;
  }

  if (email && nickname && gender) {
    console.log(
      " email = " +
        email +
        " email.length = " +
        email.length +
        " nickname = " +
        nickname +
        " nickname.length = " +
        nickname.length +
        " gender = " +
        gender +
        " gender.length = " +
        gender.length
    );
  } else {
    console.log(" email = " + email + " nickname = " + nickname + " gender = " + gender);
  }

  let isProfileComplete = encryptedUserId
    ? email && email.length !== 0 && nickname && nickname.length !== 0 && gender && gender.length !== 0
    : rest.computedMatch.params.receiverId || rest.computedMatch.params.receiverId === "myself" || false;

  return (
    <Route
      {...rest}
      render={(props) =>
        isProfileComplete ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: MY_ACCOUNT_PATH,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
