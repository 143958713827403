import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncData } from "../../redux/AsyncData";
import UserDataInterface from "./UserDataInterface";
import UserSetDataPayloadInterface from "./UserSetDataPayloadInterface";
import { logout } from "./AuthSlice";
import { UserInterface } from "../../models/user";

type UserState = AsyncData<UserDataInterface>;

const initialState: UserState = {
  loading: false,
  error: null,
  data: {
    userId: null,
    encryptedUserId: null,
    active: null,
    email: null,
    gender: null,
    nickname: null,
    firstname: null,
    lastname: null,
    credits: 0,
    vanity: null,
    phone: null,
    wishlistCount: 0,
    localDbData: null
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserSetDataPayloadInterface>) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
    },

    setUserCredits: (state, action: PayloadAction<number>) => {
      state.data.credits = action.payload;
    },

    setUserGiftCount: (state, action: PayloadAction<number>) => {
      state.data.wishlistCount = action.payload;
    },

    incrementUserWishlistCount: (state) => {
      state.data.wishlistCount = state.data.wishlistCount + 1;
    },

    decrementUserWishlistCount: (state) => {
      state.data.wishlistCount = state.data.wishlistCount - 1;
    },

    setUserEncryptedId: (state, action: PayloadAction<string>) => {
      state.data.encryptedUserId = action.payload;
    },

    setUserVanity: (state, action: PayloadAction<string>) => {
      state.data.vanity = action.payload;
    },

    setUserLocalDbData: (state, action: PayloadAction<UserInterface>) => {
      state.data.localDbData = action.payload;
    },

    setLoader(state, action: PayloadAction<boolean | undefined>) {
      state.loading = action.payload === undefined ? true : action.payload;
    },

    setError: (state, action: PayloadAction<string | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.data = initialState.data;
      state.loading = initialState.loading;
      state.error = initialState.error;
    });
  }
});

export const {
  setUserData,
  setUserCredits,
  setUserGiftCount,
  incrementUserWishlistCount,
  decrementUserWishlistCount,
  setUserEncryptedId,
  setUserVanity,
  setUserLocalDbData,
  setLoader,
  setError
} = userSlice.actions;

export default userSlice.reducer;
