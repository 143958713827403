import React from "react";
import { BoxProps } from "@material-ui/core";
import AppFooterVariant1 from "./AppFooterVariant1";
import AppFooterVariant2 from "./AppFooterVariant2";
import { FOOTER_VARIANT1, FOOTER_VARIANT2 } from "./FooterVariants";

interface AppFooterV2Props extends BoxProps {
  linkFontSize?: string;
  variant?: typeof FOOTER_VARIANT1 | typeof FOOTER_VARIANT2;
  footerBottomFixed?: boolean;
}

const AppFooterV2 = ({
  className,
  linkFontSize,
  variant,
  footerBottomFixed,
  ...rest
}: AppFooterV2Props): ReturnType<React.FC> => {
  return (
    <>
      {variant === FOOTER_VARIANT1 && <AppFooterVariant1 linkFontSize={linkFontSize} className={className} {...rest} />}

      {variant === FOOTER_VARIANT2 && (
        <AppFooterVariant2
          linkFontSize={linkFontSize}
          className={className}
          footerBottomFixed={footerBottomFixed}
          {...rest}
        />
      )}
    </>
  );
};

export default AppFooterV2;
