import { makeStyles } from "@material-ui/core/styles";
import { COLORS, HEADER } from "../../../style";
import { DESKTOP_CONTENT_WIDTH } from "../Constants";
import { textColor } from "../../../style/index";


export const SYNC_HEADER_HEIGHT = 40;
export const SYNC_HEADER_HEIGHT_LOGGED_OUT = 16;
export const TOOLBAR_DEFAULT_HEIGHT = 56;
export const TOOLBAR_SM_HEIGHT = 70;
export const TOOLBAR_SM_HEIGHT_V4 = 70;
export const TOOLBAR_XS_HEIGHT = 70;

export const HeaderStyle = makeStyles((theme) => ({
  appBarStyle: {
    backgroundColor: HEADER.headerBg,
    zIndex: 1298
    // width: "1200px",
    // right: 'auto',
    // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  appBarStyle2: {
    backgroundColor: "#02060B",
    zIndex: 1298
    // width: "1200px",
    // right: 'auto',
    // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  appBarStyle3: {
    backgroundColor: theme.palette.background.default,
    zIndex: 1298
  },
  appBarSpaceStyle: {
    minHeight: TOOLBAR_SM_HEIGHT + SYNC_HEADER_HEIGHT
  },
  appBarSpaceStyleLoggedOut: {
    minHeight: TOOLBAR_SM_HEIGHT + SYNC_HEADER_HEIGHT_LOGGED_OUT
  },
  buttonContainer: {
    display: "inline-flex",
    height: "64px",
    "vertical-align": "middle",
    padding: "0 12px"
  },
  buttonContainerV3: {
    display: "inline-flex",
    height: "64px",
    verticalAlign: "middle",
    margin: "0 16px"
  },
  selectedButtonContainer: {
    // borderBottom: "2px solid pink"
  },
  selectedButtonContainerV3: {
    borderBottom: "3px solid #EF1581",
    position: "absolute",
    width: "75%",
    bottom: "-5px"
  },
  toolbarStyle: {
    justifyContent: "space-between",
    display: "flex",
    paddingLeft: "0px",
    paddingRight: "0px",
    margin: "0 auto",
    width: DESKTOP_CONTENT_WIDTH
  },
  toolbarStyle2: {
    justifyContent: "space-between",
    display: "flex",
    paddingLeft: "0px",
    paddingRight: "0px",
    margin: "0 auto",
    width: 1200,
    minHeight: 74
  },
  logoButton: {
    padding: "0px"
  },
  logoButtonV3: {
    padding: "0 20px 0 0"
  },
  logoButtonLabel: {
    width: 94,
    height: 24
  },
  logoStyle: {
    maxWidth: 94,
    margin: "2px",
    padding: "4px"
  },
  headerIconStyle: {
    width: TOOLBAR_SM_HEIGHT,
    height: TOOLBAR_SM_HEIGHT
  },
  searchWishlistIconStyle: {
    transform: "rotate(0deg)",
    transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  searchWishlistIconExpandedStyle: {
    transform: "rotate(45deg)",
    transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  searchBoxStyle: {
    position: "absolute",
    width: "0%",
    borderRadius: "4px",
    border: "#e6007e 1px solid",
    visibility: "hidden",
    opacity: "0",
    right: getExpandedSearchBoxRight(TOOLBAR_DEFAULT_HEIGHT),
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      right: getExpandedSearchBoxRight(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      right: getExpandedSearchBoxRight(TOOLBAR_SM_HEIGHT)
    },
    transition: `${getWidthChangeTransition()}, ${getFadeOutTransition()}`
  },
  searchBoxExpandedTransition: {
    visibility: "visible",
    opacity: "1",
    width: "30%",
    transition: `${getWidthChangeTransition()}, ${getFadeInTransition()}`
  },
  searchSelectedRecipientBoxStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: getExpandedSelectedRecipientBoxHeight(TOOLBAR_DEFAULT_HEIGHT),
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: getExpandedSelectedRecipientBoxHeight(TOOLBAR_XS_HEIGHT)
    },
    [theme.breakpoints.up("sm")]: {
      height: getExpandedSelectedRecipientBoxHeight(TOOLBAR_SM_HEIGHT),
      visibility: "visible",
      opacity: "1",
      width: getExpandedSearchBoxWidth(TOOLBAR_DEFAULT_HEIGHT),
      [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        width: getExpandedSearchBoxWidth(TOOLBAR_XS_HEIGHT)
      },
      [theme.breakpoints.up("sm")]: {
        width: getExpandedSearchBoxWidth(TOOLBAR_SM_HEIGHT)
      }
    }
  },
  searchInputStyle: {
    color: "#e6007e",
    padding: `4px ${theme.typography.pxToRem(4)}`,
    ...theme.typography.body1,
    fontWeight: "bold",
    left: "50%",
    transform: "translateX(-50%)",
    ...theme.typography.body2
  },
  searchInputIconStyle: {
    color: "#e6007e"
  },
  recipientLabelStyle: {
    backgroundColor: "white",
    position: "absolute",
    paddingLeft: "2px",
    paddingRight: "2px",
    right: "8px",
    top: "-10px",
    color: "#e6007e",
    fontWeight: "bold"
  },
  selectedIcon: {
    color: "#EF1581",
    width: 110,
    height: 64
  },
  selectedIconV3: {
    color: COLORS.WHITE,
    width: 110,
    height: 64,
    padding: "0"
  },
  selectedIconAccountBtn: {
    color: COLORS.WHITE,
    width: 200,
    height: 64
  },
  wishlistIcon: {
    color: "white",
    top: "-1px",
    right: "-10px",
    "background-color": textColor.DARK1,
    border: "1px solid #FFFFFF"
  },
  selectedWishlistIcon: {
    color: "white",
    top: "-1px",
    right: "-10px",
    backgroundColor: COLORS.PINK,
    border: "1px solid #FFFFFF"
  },
  recipientPopUpPaperStyle: {
    width: getRecipientPopUpWidth(),
    height: "20vh",
    right: "16px",
    marginTop: "6px",
    borderRadius: "2px 2px 16px 16px",
    backgroundColor: "rgba(200, 200, 220, 0.3)",
    backdropFilter: "blur(3px)",
    WebkitBackdropFilter: "blur(3px)"
  },
  recipientPopUpItemStyle: {
    width: "88%",
    height: "36px",
    left: "15%",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "16px",
    padding: "6px",
    borderRadius: "4px 4px 4px 4px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(3px)",
    WebkitBackdropFilter: "blur(3px)",
    fontWeight: "bold"
  },
  iconButton: {
    width: 110,
    height: 64
  },
  iconButtonV3: {
    width: 110,
    height: 64,
    padding: "0",
    color: textColor.WHITE
  },
  iconButtonAccountBtn: {
    width: 200,
    height: 64,
    color: textColor.WHITE
  },
  iconButtonText: {
    fontSize: 14
  }
}));

function getExpandedSearchBoxWidth(toolbarHeight) {
  return `calc(100% - ${toolbarHeight * 2}px)`;
}

function getExpandedSelectedRecipientBoxHeight(toolbarHeight) {
  return toolbarHeight - 16 - 16;
}

function getExpandedSearchBoxRight(toolbarHeight) {
  return toolbarHeight * 3.7;
}

function getRecipientPopUpWidth() {
  // 30% is the width of selected recipient box
  return DESKTOP_CONTENT_WIDTH * 0.3;
}

function getWidthChangeTransition() {
  return "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
}

function getFadeInTransition() {
  return "visibility 0ms linear 0ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
}

function getFadeOutTransition() {
  return "visibility 0ms linear 300ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
}
