import React, { useState, useEffect } from "react";
import { InputLabel, Select, FormControl, FormHelperText } from "@material-ui/core";

export interface CustomSelectPropsInterface {
  value: string | number | null;
  helperText?: string;
  id: string;
  name: string;
  label: string;
  error: string | null;
  autoComplete: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  onChange: (params) => void;
  onBlur?: (params) => void;
  onFocus?: (params) => void;
}

interface CustomSelectProps extends CustomSelectPropsInterface {
  options: Array<{ value: string; label: string }>;
}

const CustomSelect: React.FC<CustomSelectProps> = (props: CustomSelectProps) => {
  const [value, setValue] = useState<string | number | null>(null);
  const {
    value: propValue,
    helperText,
    name,
    id,
    label,
    error,
    autoComplete,
    disabled,
    style,
    className,
    options,
    onChange,
    onBlur = () => {},
    onFocus = () => {}
  } = props;

  const handleChange = (event) => {
    event.preventDefault();
    setValue(event.target.value);
    onChange({
      ...event,
      target: {
        value: event.target.value,
        name: name
      }
    });
  };

  const handleOnBlur = (event) => {
    onBlur({
      ...event,
      target: {
        ...event.target,
        name
      }
    });
  };

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return (
    <FormControl style={style} className={className}>
      <InputLabel variant="outlined" htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        native
        disabled={disabled}
        value={value || ""}
        id={id}
        name={name}
        autoComplete={autoComplete}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onFocus={onFocus}
        variant="outlined"
        label={label}
        inputProps={{
          name: `${name}-input`,
          id: `${id}-input`
        }}
        error={error ? true : false}
      >
        <option aria-label="None" value="" />
        {React.Children.toArray(
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        )}
      </Select>
      <FormHelperText
        style={{
          color: error ? "#f44336" : "inherit"
        }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
