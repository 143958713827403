import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Box, CircularProgress } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
  FavoriteBorderOutlined,
  FavoriteOutlined,
  PersonOutlined,
  Person,
  HelpOutlineOutlined,
  HelpOutlined
} from "@material-ui/icons";
import { ValidationRoute } from "./components/ValidationRoute";
import Home from "./components/homeV4/Home";
import Demo from "./components/demo/Demo";
import StoreHomeV2 from "./components/storeV2/StoreHome";
import Store from "./components/store/Store";
import PageNotFound from "./components/pagenotfound/PageNotFound";
import Error from "./components/error/Error";
import { BottomNavBar, BottomNavTab, NAV_BAR_HEIGHT } from "./components/common/bottomnavigation/BottomNavBar";
import { UserContext } from "./context";
import GifterRedirect from "./components/gifter/GifterRedirect";
import DataLayer from "./utils/dataLayer";
import LoginDialog from "./components/login/LoginDialog";
import CheckoutV2 from "./components/checkout/CheckoutV2";
import CheckoutResultV2 from "./components/checkout/checkoutResult/CheckoutResultV2";
import SliderSidebarContainer from "./components/common/bottomnavigation/Sidebar/SliderSidebarContainer";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { CheckoutPageHeader } from "./components/common/checkoutPageHeader/CheckoutPageHeader";
import ShopIcon from "./components/buttons/ShopIcon";
import ShopOutlinedIcon from "./components/buttons/ShopOutlinedIcon";
import {
  setAuthenticatedUserId,
  setSelectedRecipient,
  addItemToRecipients,
  getSignUpEvent,
  deleteSignUpEvent,
  clearLocalStorageWhenLogout,
  getSelectedRecipient
} from "./utils/localStorage";
import PaymentMethods from "./components/profile/paymentMethods/PaymentMethods";
import { setUserData, setUserGiftCount, setUserVanity, setLoader, setError } from "./reducers/user/UserSlice";
import { UserGetVanity } from "./reducers/user/hooks/UserGetVanity";
import { useAppDispatch, useAppSelector } from "./hooks";
import OrderHistory from "./components/profile/orderHistory/OrderHistory";
import GiftDetails from "./components/profile/giftDetails/GiftDetails";
import MyGifts from "./components/profile/myGifts/MyGifts";
import Faq from "./components/faq/Faq";
import ContactUs from "./components/contactUs/ContactUs";
import HowItWorks from "./components/howItWorks/HowItWorks";
import Search from "./components/search/Search";
import AccountSettings from "./components/profile/accountSettings/AccountSettings";
import useNav from "./hooks/useNav";
import { logout } from "./reducers/user/AuthSlice";
import {
  ERROR_PATH,
  HOME_PATH,
  DEMO_PATH,
  STOREHOME_PATH,
  PROFILE_ACCOUNT_SETTINGS_PATH,
  WISHLIST_EDIT_PROFILE_PATH,
  HOW_IT_WORKS_PATH,
  CONTACT_US_PATH,
  FAQ_PATH,
  PROFILE_ACCOUNT_SYNC_PATH
} from "./constants/paths";
import ShopForSomeone from "./components/shop/ShopForSomeone";
import { TAB_STORE_HOME, TAB_WISHLIST, TAB_HOW_TO, TAB_MY_ACCOUNT } from "./constants/bottom-tabs";
import { useApp } from "./context/app-context";
import GiftV2 from "./components/gift/GiftV2";
import AccountDetails from "./components/profile/accountDetails/AccountDetails";
import Wishlist from "./components/wishlistV2/Wishlist";
import WishlistEditProfile from "./components/wishlistV2/WishlistEditProfile";
import useMediaBreakpoints from "./hooks/useMediaBreakpoints";
import usePageTitle from "./hooks/usePageTitle";
import { getBrowserDetails } from "./utils/helpers";
import Personal from "components/profile/personal/Personal";
import AccountSync from "components/profile/accountSync/AccountSync";

function App(props: any) {
  const dispatch = useAppDispatch();
  const {
    promptLogin,
    setPromptLogin,
    sidebarToggle,
    setSidebarToggle,
    encryptedUserId,
    setEncryptedUserId,
    setLoggedInUserFullname,
    isFetching,
    setIsFetching
  } = useApp();
  const { nickname, vanity, wishlistCount: giftCount } = useAppSelector((state) => state.user.data);
  const [isCheckoutPage, setIsCheckoutPage] = useState(false);
  const [isCheckoutConfirmationPage, setIsCheckoutConfirmationPage] = useState(false);
  const { goTo } = useNav();
  const selectedRecipient = getSelectedRecipient();

  const [user, setUser] = useState({
    email: null,
    gender: null,
    nickname: null,
    credits: 0
  });
  const store = {
    user: { get: user, set: setUser }
  };
  const { isDesktop } = useMediaBreakpoints();
  const history = useHistory();
  const [wishlistCount, setWishlistCount] = useState<number>(0);

  const location = useLocation();
  const [loadedStores, setLoadedStores] = useState([]);
  const { pageTitle } = usePageTitle();
  const { isSafari } = getBrowserDetails();

  // Removes usage of gtag https://jira.reflexmedia.com/browse/RGS-665
  //useTracking("G-0SZ7ZP8PGF");

  useEffect(() => {
    setIsCheckoutPage(false);
    setIsCheckoutConfirmationPage(false);
    if (location.pathname.startsWith("/checkout/")) {
      setIsCheckoutPage(true);
    } else if (location.pathname.startsWith("/checkoutV1/")) {
      setIsCheckoutPage(true);
    } else if (location.pathname.startsWith("/checkoutresult/")) {
      setIsCheckoutPage(true);
      setIsCheckoutConfirmationPage(true);
    } else if (location.pathname.startsWith("/checkoutresultV1/")) {
      setIsCheckoutPage(true);
      setIsCheckoutConfirmationPage(true);
    }
  }, [location]);
  const fetchUserData = async () => {
    try {
      const { data } = await axios.get("/api/user");
      if (data) {
        const {
          user_id,
          active,
          email,
          gender,
          nickname,
          payment_profile_data,
          firstname,
          lastname,
          phone,
          localDbData,
          totalCountWishlist,
          encryptedUserId
        } = data;

        setUser({
          email,
          gender,
          nickname,
          credits: getUserStoreCredit(payment_profile_data)
        });
        setWishlistCount(totalCountWishlist);

        dispatch(setUserGiftCount(totalCountWishlist));
        dispatch(setLoader(false));
        dispatch(
          setUserData({
            active,
            email,
            gender,
            nickname,
            firstname,
            lastname,
            phone,
            userId: user_id,
            credits: getUserStoreCredit(payment_profile_data),
            localDbData
          })
        );

        setIsFetching(true);
        setLoggedInUserFullname(nickname);

        sendGASignUpEvent(encryptedUserId);
      }
    } catch (error) {
      console.log(error);
      setUser({
        email: null,
        gender: null,
        nickname: null,
        credits: 0
      });
      dispatch(setLoader(false));
      dispatch(setError(error));
      setIsFetching(true);
      if (error.response.status === 401) {
        setUser({
          email: null,
          gender: null,
          nickname: null,
          credits: 0
        });
        dispatch(setLoader(false));
        dispatch(setError(error));
        setEncryptedUserId(undefined);
        dispatch(logout());
        setUser({
          email: null,
          gender: null,
          nickname: null,
          credits: 0
        });
        clearLocalStorageWhenLogout();
        goTo("/");
      } else {
        goTo(ERROR_PATH, {
          errorMessage: "Error on fetching user. Please try to logout."
        });
      }
    }
  };

  useEffect(() => {
    if (encryptedUserId) {
      dispatch(setLoader(true));
      fetchUserData();
    } else {
      setIsFetching(true);
    }
  }, [encryptedUserId]);

  useEffect(() => {
    if (nickname && encryptedUserId) {
      const user = {
        nickname: nickname,
        userId: encryptedUserId,
        giftCount: wishlistCount
      };
      addItemToRecipients(user);
      setAuthenticatedUserId({
        nickname: user.nickname,
        userId: user.userId
      });

      if (!getSelectedRecipient()) {
        setSelectedRecipient(user);
      }
    }
  }, [wishlistCount, encryptedUserId, nickname, selectedRecipient]);

  useEffect(() => {
    if (loadedStores.length <= 0) {
      axios
        .get("/api/gifts/getstores?page=1&is_desktop=" + isDesktop, {
          withCredentials: true
        })
        .then((response) => {
          const responseStoresArray = Object.values(response.data.stores);

          const sortedByRank = responseStoresArray.sort((a: any, b: any) => {
            return b.rank - a.rank;
          });

          const newStores = sortedByRank.filter((store: any) => {
            return (
              store.name !== "All Gifts" && store.name !== "♡ Wish List ♡" && store.name !== "&#9825; Wish List &#9825;"
            );
          });

          setLoadedStores(newStores);
        });
    }
  }, []);

  useEffect(() => {
    async function getUseVanity() {
      const userVanity = await UserGetVanity();
      if (userVanity && typeof userVanity !== "undefined") {
        dispatch(setUserVanity(userVanity.value));
      }
    }
    if (!vanity && user.email) {
      getUseVanity();
    }
  }, [vanity, user.email]);

  const items = [
    BottomNavTab({
      id: TAB_STORE_HOME,
      label: "Shop",
      icon: <ShopIcon width={16.659} height={22.25} />,
      selectedIcon: <ShopOutlinedIcon width={16.659} height={22.25} />,
      badgeCount: 0,
      navigateTo: "/store/storehome"
    }),
    BottomNavTab({
      id: TAB_WISHLIST,
      label: "My Wishlist",
      icon: <FavoriteBorderOutlined />,
      selectedIcon: <FavoriteOutlined />,
      badgeCount: giftCount,
      navigateTo: `/gift/wishlist/${encryptedUserId}`
    }),
    BottomNavTab({
      id: TAB_HOW_TO,
      label: "How To",
      icon: <HelpOutlineOutlined />,
      selectedIcon: <HelpOutlined />,
      badgeCount: 0,
      navigateTo: "/how-it-works"
    }),
    BottomNavTab({
      id: TAB_MY_ACCOUNT,
      label: "My Account",
      icon: <PersonOutlined />,
      selectedIcon: <Person />,
      badgeCount: 0,
      navigateTo: encryptedUserId ? "/profile/account" : STOREHOME_PATH
    })
  ];

  const handleSidebarToggle = (show = true) => {
    setSidebarToggle(show && !sidebarToggle);
  };

  useEffect(() => {
    if (sidebarToggle) {
      disableBodyScroll(document.getElementById("sidebar-parent"));
    } else {
      clearAllBodyScrollLocks();
    }
  }, [sidebarToggle]);

  useEffect(() => {
    if (typeof window != "undefined") {
      window.onpageshow = (event) => {
        if (event.persisted && isSafari) {
          // only for safari
          // force reload when back button is initiated, this to prevent cache ,
          window.location.reload();
        }
      };
    }
  }, []);

  return (
    <>
      {isFetching ? (
        <UserContext.Provider value={store}>
          <Helmet>
            {pageTitle && <title>{pageTitle}</title>}
            <meta property="og:title" content="Gift.me" />
            <meta property="og:type" content="article" />
            <meta
              property="og:description"
              content="Where your wishlist gets love™! Gift.me is the best way for your fans and friends to show your wishlist the love it deserves."
            />
            <meta property="og:url" content="%PUBLIC_URL%" />
            <meta property="og:image" content="https://gift.me/giftme-sq-2.jpg" />
            <meta property="og:image:width" content="554" />
            <meta property="og:image:height" content="554" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          </Helmet>
          <Box style={{ paddingBottom: NAV_BAR_HEIGHT }}>
            {isCheckoutPage && (
              <CheckoutPageHeader encryptedUserId={encryptedUserId} hasConfirmedPayment={isCheckoutConfirmationPage} />
            )}
            <Switch>
              <Route
                exact
                path={HOME_PATH}
                render={() => (encryptedUserId ? <Redirect to={STOREHOME_PATH} /> : <Home />)}
              />
              <Route path={DEMO_PATH} component={Demo} />
              <Route exact path={"/m/:userId"} component={GifterRedirect} />
              <Route path={"/profile/payment-methods"} component={PaymentMethods} />
              <Route path={"/profile/order-history"} component={OrderHistory} />
              <Route path={"/profile/gift-details/:orderId"} component={GiftDetails} />
              <Route path={"/profile/my-gifts"} component={MyGifts} />
              <Route path={"/profile/personal"} component={Personal} />
              <Route
                path={"/profile/account"}
                render={(props) => (
                  <AccountDetails {...props} setEncryptedUserId={setEncryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route path={PROFILE_ACCOUNT_SETTINGS_PATH} component={AccountSettings} />
              <Route path={PROFILE_ACCOUNT_SYNC_PATH} component={AccountSync} />
              <Route
                path={"/store/storehome/:orderId"}
                encryptedUserId={encryptedUserId}
                setPromptLogin={setPromptLogin}
                render={(props) => (
                  <StoreHomeV2
                    {...props}
                    history={history}
                    encryptedUserId={encryptedUserId}
                    setPromptLogin={setPromptLogin}
                    loadedStores={loadedStores}
                  />
                )}
              />
              <Route
                path={STOREHOME_PATH}
                render={(props) => (
                  <StoreHomeV2
                    {...props}
                    history={history}
                    encryptedUserId={encryptedUserId}
                    setPromptLogin={setPromptLogin}
                    loadedStores={loadedStores}
                  />
                )}
              />
              <Route
                path={"/store/store/:id/:storename"}
                render={(props) => (
                  <Store
                    {...props}
                    history={history}
                    encryptedUserId={encryptedUserId}
                    setPromptLogin={setPromptLogin}
                    loadedStores={loadedStores}
                  />
                )}
              />
              <Route
                path={"/store/:storeId/:storeName/gift/:giftId"}
                render={(props) => (
                  <GiftV2
                    {...props}
                    history={history}
                    encryptedUserId={encryptedUserId}
                    setPromptLogin={setPromptLogin}
                    loadedStores={loadedStores}
                  />
                )}
              />
              <Route
                path={"/shop/:vanity/gift/:giftId"}
                render={(props) => (
                  <ShopForSomeone {...props} loadedStores={loadedStores} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route
                path={["/sophiedee", "/Sophiedee", "/SophieDee"]}
                render={(props) => (
                  <Wishlist {...props} encryptedUserId={encryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route
                path={["/mona", "/Mona"]}
                render={(props) => (
                  <Wishlist {...props} encryptedUserId={encryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route
                path={["/lupefuentes", "/Lupefuentes", "/LupeFuentes"]}
                render={(props) => (
                  <Wishlist {...props} encryptedUserId={encryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route
                path={["/chantal", "/Chantal"]}
                render={(props) => (
                  <Wishlist {...props} encryptedUserId={encryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route
                path={["/milanaricci", "/Milanaricci", "/MilanaRicci"]}
                render={(props) => (
                  <Wishlist {...props} encryptedUserId={encryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route
                path={"/gift/wishlist/:userId"}
                render={(props) => (
                  <Wishlist {...props} encryptedUserId={encryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route path={WISHLIST_EDIT_PROFILE_PATH} render={(props) => <WishlistEditProfile {...props} />} />
              <ValidationRoute
                path={"/checkout/:receiverId/:giftId/:hasSession?"}
                encryptedUserId={encryptedUserId}
                component={CheckoutV2}
              />
              <ValidationRoute
                path={"/checkoutresult/:receiverId/:orderId/:paymentType/:hasSession?"}
                encryptedUserId={encryptedUserId}
                component={CheckoutResultV2}
              />
              <Route exact path={FAQ_PATH} component={Faq} />
              <Route exact path={CONTACT_US_PATH} component={ContactUs} />
              <Route exact path={HOW_IT_WORKS_PATH} render={() => <HowItWorks />} />
              <Route
                path={"/search"}
                render={(props) => (
                  <Search
                    {...props}
                    history={history}
                    encryptedUserId={encryptedUserId}
                    setPromptLogin={setPromptLogin}
                  />
                )}
              />
              {/* Need a error component in the future */}
              <Route path={"/error"} component={Error} />
              <Route
                path={"/:vanity"}
                render={(props) => (
                  <Wishlist {...props} encryptedUserId={encryptedUserId} setPromptLogin={setPromptLogin} />
                )}
              />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </Box>

          {!isDesktop && !isCheckoutPage && (
            <BottomNavBar
              tabs={items}
              mapCurrentPathToTab={mapCurrentPathToTab}
              setPromptLogin={setPromptLogin}
              handleSidebarToggle={handleSidebarToggle}
              sidebarToggle={sidebarToggle}
            />
          )}
          <LoginDialog
            returnTo={promptLogin.returnTo || "/store/storehome"}
            open={promptLogin.promptLogin}
            registration={promptLogin.registration}
            forceRegistration={promptLogin.forceRegistration}
            onClose={() =>
              setPromptLogin({
                promptLogin: false,
                returnTo: undefined,
                registration: false,
                forceRegistration: false
              })
            }
          />
          <SliderSidebarContainer
            handleSidebarToggle={handleSidebarToggle}
            openMenu={sidebarToggle}
            credits={user.credits}
            history={history}
            {...props}
          />
        </UserContext.Provider>
      ) : (
        <Box
          style={{
            display: "flex",
            height: "100vh",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

function mapCurrentPathToTab(tabs, currentPath, encryptedUserId) {
  if (currentPath) {
    for (const item of tabs) {
      if (item.id === TAB_STORE_HOME && currentPath.startsWith("/store")) {
        return item;
      } else if (item.id === TAB_WISHLIST && currentPath === `/gift/wishlist/${encryptedUserId}`) {
        return item;
      } else if (item.id === TAB_HOW_TO && currentPath === "/how-it-works") {
        return item;
      } else if (item.id === TAB_MY_ACCOUNT && currentPath.startsWith("/profile")) {
        return item;
      }
    }
  }
  return undefined;
}

function getUserStoreCredit(paymentProfileData): number {
  if (Array.isArray(paymentProfileData)) {
    const storeCredit = paymentProfileData.find((item) => item.account_type.toLowerCase() === "store credit");

    if (storeCredit) {
      return parseFloat(storeCredit.balance);
    }
  }

  const key = Object.keys(paymentProfileData)[0];
  if (typeof key === "undefined") {
    return 0;
  }

  return paymentProfileData[key].account_type.toLowerCase() === "store credit"
    ? parseFloat(paymentProfileData[key].balance)
    : 0;
}

function sendGASignUpEvent(encryptedUserId) {
  const signUpEvent = getSignUpEvent();

  if (signUpEvent) {
    new DataLayer().gtag({
      key: "event",
      trackingId: "join",
      config: {
        method: signUpEvent,
        items: [
          {
            user_id: encryptedUserId
          }
        ]
      }
    });

    deleteSignUpEvent();
    console.log("Sends GA signup event");
  }
}

export default App;
