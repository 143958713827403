export interface GiftSearchAvailableFiltersOptionObject {
  label: string;
  value: string;
}

export interface GiftSearchAvailableFiltersObject {
  label: string;
  filters: Array<GiftSearchAvailableFiltersOptionObject>;
}

export const giftSearchAvailableFilters: Array<GiftSearchAvailableFiltersObject> = [
  {
    label: "brand",
    filters: [
      { label: "Channel", value: "channel" },
      { label: "Fendi", value: "fendi" }
    ]
  },
  {
    label: "color",
    filters: [
      { label: "Black", value: "black" },
      { label: "Grey", value: "grey" }
    ]
  }
];
