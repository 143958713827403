import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, Avatar, BoxProps } from "@material-ui/core";
import UploadPinkIcon from "../../customIcons/UploadPinkIcon";
import { makeStyles } from "@material-ui/core/";
import { COLORS } from "../../../style";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";
import PhotoCropper from "../../common/modules/photo/PhotoCropper";
import { uploadProfilePicture } from "../../../actions/userAction";
import ProfilePictureDialog from "./ProfilePictureDialog";
import { setUserLocalDbData } from "../../../reducers/user/UserSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import clsx from "clsx";

const componentStyles = ({ avatarWidth, avatarHeight }) => {
  return makeStyles(() => ({
    root: {
      color: COLORS.PINK,
      marginTop: 32
    },
    label: {
      display: "inline",
      position: "relative",
      top: -11,
      left: -5,
      cursor: "pointer",
      color: COLORS.PINK
    },
    avatarSize: {
      width: avatarWidth ?? 104,
      height: avatarHeight ?? 104
    }
  }));
};

interface ProfilePictureProps extends BoxProps {
  avatarWidth?: number;
  avatarHeight?: number;
  avatarOnly?: boolean;
  avatarClassName?: string;
  labelClassName?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  avatarWidth,
  avatarHeight,
  avatarOnly = false,
  avatarClassName,
  labelClassName,
  ...rest
}: ProfilePictureProps) => {
  const { isMobile } = useMediaBreakpoints();
  const classes = componentStyles({ avatarHeight, avatarWidth })();
  const inputRef = useRef<HTMLInputElement>(null);
  const { localDbData } = useAppSelector((state) => state.user.data);
  const dispatch = useAppDispatch();
  const defaultAvatarMale = "/assets/profile/profile-empty-male.jpg";
  const defaultAvatarFemale = "/assets/profile/profile-empty-female.jpg";
  const defaultAvatarOther = "/assets/profile/profile-empty-other.jpg";

  const [avatar, setAvatar] = useState("");
  const [openImageCropper, setOpenImageCropper] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [hasNewUpload, setHasNewUpload] = useState<boolean>(false);
  const [label, setLabel] = useState<string>("Add Profile Picture");
  const [isSaving, setIsSaving] = useState(false);

  const onClose = () => {
    setOpenImageCropper(false);
    setImageSrc(null);
    inputRef.current.value = "";
  };

  const onSaveChanges = async (croppedBlob: any, croppedUri: string) => {
    setIsSaving(true);
    try {
      const response = await uploadProfilePicture(croppedUri);
      const { localDbData } = response.data;

      dispatch(setUserLocalDbData(localDbData));
      setHasNewUpload(true);
      setIsSaving(false);
      setOpenImageCropper(false);
      inputRef.current.value = "";
      setAvatar(croppedUri);
    } catch (error) {
      setIsSaving(false);
    }
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    setOpenImageCropper(true);
  };

  const onClickChangeProfilePictureHandler = (e) => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (!localDbData || hasNewUpload) {
      return;
    }

    if (localDbData.avatar) {
      setAvatar(localDbData.avatar);
      setLabel("Change Profile Picture");
      return;
    }

    if (localDbData.gender.toLocaleLowerCase() === "m") {
      setAvatar(defaultAvatarMale);
    } else if (localDbData.gender.toLocaleLowerCase() === "f") {
      setAvatar(defaultAvatarFemale);
    } else {
      setAvatar(defaultAvatarOther);
    }
  }, [localDbData]);

  return (
    <>
      <Box
        className={classes.root}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "inherit",
          ...rest.style
        }}
        {...rest}
      >
        <Avatar
          alt="Avatar"
          src={avatar}
          className={clsx(classes.avatarSize, avatarClassName)}
          style={{
            marginLeft: isMobile ? 0 : 30
          }}
        />
        {!avatarOnly && (
          <Box style={{ marginTop: 10 }} className={labelClassName}>
            <UploadPinkIcon />
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={onFileChange}
              style={{ display: "none" }}
              ref={inputRef}
            />
            <Typography variant="caption" className={classes.label} onClick={onClickChangeProfilePictureHandler}>
              {label}
            </Typography>
          </Box>
        )}
      </Box>
      <ProfilePictureDialog open={openImageCropper} onClose={onClose} isMobile={isMobile}>
        {openImageCropper && (
          <PhotoCropper src={imageSrc} onClose={onClose} onSaveChanges={onSaveChanges} isSaving={isSaving} />
        )}
      </ProfilePictureDialog>
    </>
  );
};

export default ProfilePicture;
