import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core/";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import SupportedCards from "../supported_cards.png";
import PaymentInformationForm from "../PaymentInformationForm";
import { CARD_CVV_LABEL, CARD_CVV_SIZE } from "../../input/card/CardUtils";
import Form from "../../input/Form";
import { initialFormInputs } from "./PaymentMethodFormInputs";
import PrimaryButton from "../../buttons/PrimaryButton";
import Toast from "light-toast";
import { useState } from "react";
import { createPaymentMethod } from "../../../actions/paymentAction";

const EnterAPaymentMethodContentStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  supportedCardsImg: {
    width: "193px",
    height: "34px"
  },
  paymentInfoCaption: {
    ...theme.typography.body2,
    fontStyle: "italic",
    color: theme.palette.grey[500]
  },
  addButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  }
}));

export default function EnterAPaymentMethodContent(props) {
  const styles = EnterAPaymentMethodContentStyles();
  const { billingName, paymentInfo, handleOnPaymentMethodAdded } = props;
  const [isSavingNewCard, setIsSavingNewCard] = useState<boolean>(false);

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (let inputName in inputs) {
      const formInput = inputs[inputName];

      if ("billing_name" === inputName) {
        formInput.error = formInput.value === "" ? "Full Name is required" : "";
      } else if ("card" === inputName) {
        const validatedCardInput = formInputs.card.onValidate();
        formInputs.card.cardNumber = validatedCardInput.cardNumber;
        formInputs.card.validation = validatedCardInput.validation;
        formInputs.card.error = validatedCardInput.error;
      } else if ("exp_month" === inputName) {
        if (formInput.value === "") {
          formInput.error = "Expiration Month is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter a 2 digit expiry month";
        } else {
          formInput.error = "";
        }
      } else if ("exp_year" === inputName) {
        if (formInput.value === "") {
          formInput.error = "Expiration Year is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter the last 2 digits of expiry year!";
        } else {
          formInput.error = "";
        }
      } else if ("csv" === inputName) {
        validateCSV(formInput);
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const validateCSV = (csvInput) => {
    if (csvInput.value === "") {
      csvInput.error = "Card " + csvInput.label + " is required";
    } else if (csvInput.value.length !== csvInput.maxLength) {
      csvInput.error = csvInput.maxLength + " digit " + csvInput.label + " required";
    } else {
      csvInput.error = "";
    }
  };

  const onBindCardValidate = (onValidateHook) => {
    formInputs.card.onValidate = onValidateHook;
  };

  const onCardInputDataUpdated = (cardNumber, validation, error) => {
    formInputs.card.cardNumber = cardNumber;
    formInputs.card.validation = validation;
    formInputs.card.error = error;

    let csvLabel;
    let csvMaxLength;
    let csvInput = formInputs.csv;
    let card = validation && validation.card ? validation.card : undefined;

    if (card) {
      csvLabel = card.code.name;
      csvMaxLength = card.code.size;
    } else {
      csvLabel = CARD_CVV_LABEL;
      csvMaxLength = CARD_CVV_SIZE;
    }

    if (csvLabel !== csvInput.label || csvMaxLength !== csvInput.maxLength) {
      csvInput.label = csvLabel;
      csvInput.maxLength = csvMaxLength;

      if (csvInput.value !== "" || csvInput.error !== "") {
        validateCSV(csvInput);
      }

      setFormInputs({
        ...formInputs
      });
    }
  };

  const addPaymentMethod = async () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });
      return;
    }

    setIsSavingNewCard(true);
    try {
      const response = await createPaymentMethod({
        account_number: formInputs.card.cardNumber,
        csv: formInputs.csv.value,
        exp_month: formInputs.exp_month.value,
        exp_year: `20${formInputs.exp_year.value}`,
        billing_address: paymentInfo.billing_address,
        billing_city: paymentInfo.billing_city,
        billing_country: paymentInfo.billing_country,
        billing_email: paymentInfo.billing_email,
        billing_name: paymentInfo.billing_name,
        billing_state: paymentInfo.billing_state,
        billing_zip: paymentInfo.billing_zip
      });

      handleOnPaymentMethodAdded(response.data.payment_profile_id);
      setIsSavingNewCard(false);
    } catch (error) {
      console.log(error);
      Toast.fail("Error adding new card. Please try again.");
      setIsSavingNewCard(false);
    }
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  if (formInputs.billing_name.value === "" || typeof formInputs.billing_name.value === "undefined") {
    formInputs.billing_name.value = billingName;
  }

  return (
    <Box>
      <SectionTitle>Add a new payment method</SectionTitle>

      <img
        className={styles.supportedCardsImg}
        style={{ marginTop: "8px" }}
        src={SupportedCards}
        alt="supported cards"
      />

      <Typography className={styles.paymentInfoCaption} style={{ marginTop: "16px" }}>
        We accept all major credit cards and pre-paid debit cards.
      </Typography>

      <PaymentInformationForm
        billing_name={formInputs.billing_name ? formInputs.billing_name : ""}
        exp_month={formInputs.exp_month ? formInputs.exp_month : ""}
        exp_year={formInputs.exp_year ? formInputs.exp_year : ""}
        csv={formInputs.csv ? formInputs.csv : ""}
        onBindCardValidate={onBindCardValidate}
        onCardInputDataUpdated={onCardInputDataUpdated}
        handleInputChange={handleInputChange}
        handleOnBlur={handleOnBlur}
        handleOnFocus={handleOnFocus}
        style={{ marginTop: "16px" }}
      />
      <PrimaryButton className={styles.addButton} handleClick={() => addPaymentMethod()} disabled={isSavingNewCard}>
        {isSavingNewCard ? "Adding new card..." : "Add new card"}
      </PrimaryButton>
    </Box>
  );
}
