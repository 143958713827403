import React, { useCallback, useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Box, Grid, Typography, styled } from "@material-ui/core";
import cropImage from "./cropImage";
import PrimaryButtonV3 from "../../../buttons/PrimaryButtonV3";
import SecondaryButtonV3 from "../../../buttons/SecondaryButtonV3";
import { BACKGROUND } from "../../../../style";
import useMediaBreakpoints from "../../../../hooks/useMediaBreakpoints";

const StyledReactCrop = styled(ReactCrop)(({ theme }) => ({
  "& .ReactCrop__crop-selection": {
    border: "none"
  },
  "& .ReactCrop__drag-handle": {
    borderRadius: "50%",
    backgroundColor: "#fff",
    height: "22px",
    width: "22px",
    "&:after": {
      display: "none"
    }
  },
  "& .ReactCrop__image": {
    maxHeight: 520,
    maxWidth: 370,
    [theme.breakpoints.down("xs")]: {
      maxHeight: 368,
      maxWidth: 300
    }
  }
}));

interface PhotoCropperProps {
  helperText?: string;
  isSaving: boolean;
  src: string;
  onClose: () => void;
  onSaveChanges: (cropBlob: any, croppedUri: string) => void;
}

const PhotoCropper: React.FC<PhotoCropperProps> = (props: PhotoCropperProps) => {
  const { src, isSaving, onClose, onSaveChanges, helperText, ...rest } = props;
  const cropRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const cropObj: Partial<Crop> = {
    unit: "%",
    width: 100,
    x: 25,
    y: 25,
    aspect: 1
  };
  const [crop, setCrop] = useState<Partial<Crop>>(cropObj);

  const onLoad = useCallback((img) => {
    cropRef.current = img;
    setCrop(cropObj);
  }, []);

  const onChange = (c: any) => {
    setCrop(c);
  };

  const { isMobileXs } = useMediaBreakpoints();

  const onSaveChangesHandler = useCallback(async () => {
    if (crop?.height || crop?.width) {
      const [croppedBlob, croppedUri] = await cropImage(cropRef.current, src, crop);
      onSaveChanges(croppedBlob, croppedUri);
    }
  }, [crop]);

  return (
    <>
      <Box
        style={{
          height: isMobileXs ? 368 : 520,
          width: isMobileXs ? 300 : 370,
          background: BACKGROUND.LIGHT_GRAY,
          position: "relative",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          transform: "scale(1)",
          margin: "25 auto",
          outline: "none",
          top: 0,
          bottom: "initial",
          padding: 0
        }}
      >
        <Box
          style={{
            margin: "0 auto"
          }}
        >
          <StyledReactCrop
            src={src}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => onChange(c)}
            onComplete={(c) => setCompletedCrop(c)}
            keepSelection={true}
            minWidth={100}
            minHeight={100}
            style={{
              overflow: "visible",
              width: "auto"
            }}
            {...rest}
          />
        </Box>
      </Box>
      {helperText && <Typography variant="body2">{helperText}</Typography>}
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 14
        }}
      >
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <SecondaryButtonV3 text="Cancel" disabled={isSaving} handleClick={onClose} />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <PrimaryButtonV3 handleClick={onSaveChangesHandler} disabled={isSaving}>
            {isSaving ? "Save Changes..." : "Save Changes"}
          </PrimaryButtonV3>
        </Grid>
      </Grid>
    </>
  );
};

export default PhotoCropper;
