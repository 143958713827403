import React from "react";
import { Box, Typography } from "@material-ui/core";

interface HowItWorksNumberProps {
  number: number;
}

export default function HowItWorksNumber({ number }: HowItWorksNumberProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      m="auto"
      style={{
        marginTop: "8px",
        backgroundColor: "#000",
        borderRadius: "50%",
        height: "46px",
        width: "46px"
      }}
    >
      <Typography
        variant="h5"
        style={{
          color: "#fff"
        }}
      >
        {number}
      </Typography>
    </Box>
  );
}
