import React from "react";
import { styled, Box, Paper, Grid } from "@material-ui/core";

export const DemoItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 300,
  backgroundColor: "#ddd"
}));

export const DemoGridItems = ({ ...rest }) => (
  <Grid container spacing={4} {...rest}>
    <Grid item lg={1} md={6} sm={6} xs={12}>
      <DemoItem>1</DemoItem>
    </Grid>
    <Grid item lg={1} md={6} sm={6} xs={12}>
      <DemoItem>2</DemoItem>
    </Grid>
    <Grid item lg={1} md={6} sm={4} xs={12}>
      <DemoItem>3</DemoItem>
    </Grid>
    <Grid item lg={1} md={6} sm={4} xs={12}>
      <DemoItem>4</DemoItem>
    </Grid>
    <Grid item lg={1} md={6} sm={4} xs={6}>
      <DemoItem>5</DemoItem>
    </Grid>
    <Grid item lg={1} md={6} sm={1} xs={6}>
      <DemoItem>6</DemoItem>
    </Grid>
    <Grid item lg={1} md={3} sm={2} xs={4}>
      <DemoItem>7</DemoItem>
    </Grid>
    <Grid item lg={1} sm={3} xs={4}>
      <DemoItem>8</DemoItem>
    </Grid>
    <Grid item lg={1} sm={3} xs={4}>
      <DemoItem>9</DemoItem>
    </Grid>
    <Grid item lg={1} sm={3} xs={4}>
      <DemoItem>10</DemoItem>
    </Grid>
    <Grid item lg={1} sm={6} xs={12}>
      <DemoItem>11</DemoItem>
    </Grid>
    <Grid item lg={1} sm={6} xs={12}>
      <DemoItem>12</DemoItem>
    </Grid>
  </Grid>
);

const GridWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: 1304,
  paddingLeft: 70,
  paddingRight: 70,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 16,
    paddingRight: 16
  }
}));

export default GridWrapper;
