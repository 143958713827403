import React, { useState, useEffect } from "react";
import { Box, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Toast from "light-toast";
import Form, { FormInput } from "../../input/Form";
import RollbarTracker from "../../../utils/RollbarTracker";
import { DESKTOP_CONTENT_WIDTH } from "../../common/Constants";
import { setUserData, setUserLocalDbData, setUserVanity } from "../../../reducers/user/UserSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import _debounce from "lodash.debounce";
import useNav from "../../../hooks/useNav";
import { updateProfileAction, validateUserNickname } from "../../../actions/userAction";
import { ERROR_PATH, STOREHOME_PATH } from "../../../constants/paths";
import { capitalize, roundToDecimalPlaces } from "../../../utils/stringUtils";
import { US_STATES } from "../../input/USStateSelector";
import AccountDetailsStoreCredit from "./AccountDetailsStoreCredit";
import AccountDetailsPersonalInformation from "./AccountDetailsPersonalInformation";
import AccountDetailsShippingAddress from "./AccountDetailsShippingAddress";
import PrimaryButtonV3 from "../../buttons/PrimaryButtonV3";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";
import ProfilePicture from "../../common/profile/ProfilePicture";
import ProfileContentContainer from "../ProfileContentContainer";

const styles = makeStyles((theme) => ({
  contentStyle: {
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(24),
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_WIDTH
    }
  },
  flexContainer: {
    display: "flex",
    "flex-wrap": "wrap"
  },
  formBoxStyle: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      width: "45%"
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "60%"
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "90%"
    },
    [theme.breakpoints.up("md")]: {
      "flex-basis": "70%"
    }
  },
  dividerStyle: {
    marginTop: theme.typography.pxToRem(24)
  },
  formStyle: {
    marginTop: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.background.default
  },
  button: {
    marginTop: theme.typography.pxToRem(24),
    width: 154
  }
}));

const initialFormInputs = {
  email: FormInput(),
  firstname: FormInput(),
  lastname: FormInput(),
  birthYear: FormInput(),
  birthMonth: FormInput(),
  birthDay: FormInput(),
  address: FormInput(),
  country: FormInput(),
  city: FormInput(),
  state: FormInput(),
  zip: FormInput(),
  nickname: FormInput(),
  gender: FormInput()
};

const AccountDetails: React.FC = () => {
  const { goTo } = useNav();
  const classes = styles();
  const { isMobile, isTablet } = useMediaBreakpoints();
  const [storeCredit, setStoreCredit] = useState<number | string>("");
  const [isLoading, setIsLoading] = useState<string>("loading");
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { vanity } = useAppSelector((state) => state.user.data);
  const [updatedVanity, setUpdatedVanity] = useState(vanity);

  const dispatch = useAppDispatch();

  const vanityUrlOnChangedHandler = (updatedVanity, error) => {
    if (error) {
      setHasError(true);
    } else {
      setUpdatedVanity(updatedVanity);
      setHasError(false);
    }
  };

  const hasShippingDetails = (inputs) => {
    let hasShippingDetails = false;
    for (const inputName in inputs) {
      if (["address", "city", "zip", "state"].includes(inputName)) {
        const formInput = inputs[inputName];
        if (formInput.value !== "") {
          hasShippingDetails = true;
        }
      }
    }
    return hasShippingDetails;
  };

  const validateForm = (inputs = formInputs) => {
    let hasError;

    for (const inputName in inputs) {
      const formInput = inputs[inputName];

      if ("email" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("firstname" === inputName) {
        formInput.error = formInput.value === "" && hasShippingDetails(inputs) ? "This is required" : "";
      } else if ("lastname" === inputName) {
        formInput.error = formInput.value === "" && hasShippingDetails(inputs) ? "This is required" : "";
      } else if ("nickname" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("gender" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const prefillFormInputValue = (formInput, value) => {
    if (value && value !== "") {
      formInput.value = value;
    }
  };

  const validateNickname = _debounce(
    async (nickname: string) => {
      if (nickname) {
        const validateNicknameResponse = await validateUserNickname(nickname);
        if (!validateNicknameResponse.isAvailable) {
          formInputs.nickname.error = validateNicknameResponse.message;
          setHasError(true);
        } else {
          setHasError(false);
          formInputs.nickname.error = "";
        }
        setFormInputs({ ...formInputs });
      }
    },
    250,
    { maxWait: 1000 }
  );

  const fetchUser = () => {
    axios
      .get("/api/user")
      .then((response) => {
        for (const paymentProfileId in response.data.payment_profile_data) {
          const paymentProfileData = response.data.payment_profile_data[paymentProfileId];

          if (paymentProfileData.account_type === "Store Credit" && paymentProfileData.balance) {
            const creditBalance = parseFloat(paymentProfileData.balance);
            if (creditBalance > 0) {
              setStoreCredit(roundToDecimalPlaces(paymentProfileData.balance, 2));
            }
          }
        }

        prefillFormInputValue(formInputs.firstname, response.data.firstname);
        prefillFormInputValue(formInputs.lastname, response.data.lastname);
        prefillFormInputValue(formInputs.email, response.data.email);
        prefillFormInputValue(formInputs.address, response.data.address);
        prefillFormInputValue(formInputs.country, response.data.country);
        prefillFormInputValue(formInputs.city, response.data.city);
        prefillFormInputValue(formInputs.state, response.data.state);
        prefillFormInputValue(formInputs.zip, response.data.zip);
        prefillFormInputValue(formInputs.nickname, response.data.nickname);
        prefillFormInputValue(formInputs.gender, response.data.gender);
        prefillFormInputValue(formInputs.birthYear, response.data.localDbData.birthYear);
        prefillFormInputValue(formInputs.birthMonth, response.data.localDbData.birthMonth);
        prefillFormInputValue(formInputs.birthDay, response.data.localDbData.birthDay);

        setFormInputs({ ...formInputs });
        setIsLoading("complete");
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401
          console.log("no user, pushing to stores");
          goTo(STOREHOME_PATH);
          return;
        }

        RollbarTracker.logError("Error getting user", error);
        console.log("error: " + error);

        if (error.response && error.response.status === 500) {
          goTo(ERROR_PATH);
        }

        setIsLoading("error");
      });
  };

  const setUser = async () => {
    if (!validateForm() || hasError || isSaving) {
      setFormInputs({ ...formInputs });
      return;
    }
    setIsSaving(true);

    Toast.info("Updating Your Account...", 500);

    interface UserPayloadObject {
      nickname: string;
      email: string;
      birthYear: string;
      birthMonth: string;
      birthDay: string;
      firstname: string;
      lastname: string;
      address: string;
      country: string;
      city: string;
      state: string;
      zip: string;
      gender: string;
      vanityUrl?: string;
    }

    let state = formInputs.state.value;
    if (formInputs.country.value === "US") {
      // Verify if state value is exists for US country
      const findStateByValue = US_STATES.find((item) => item.value === state);
      if (!findStateByValue) {
        // Reset state to empty
        state = "";
      }
    }
    const payload: UserPayloadObject = {
      nickname: formInputs.nickname.value,
      email: formInputs.email.value,
      birthYear: formInputs.birthYear.value,
      birthMonth: formInputs.birthMonth.value,
      birthDay: formInputs.birthDay.value,
      firstname: formInputs.firstname.value,
      lastname: formInputs.lastname.value,
      address: formInputs.address.value,
      country: formInputs.country.value,
      city: formInputs.city.value,
      state,
      zip: formInputs.zip.value,
      gender: formInputs.gender.value
    };

    if (vanity !== updatedVanity) {
      payload.vanityUrl = updatedVanity;
    }

    try {
      const response = await updateProfileAction(payload);

      const { vanity, localDbData, ...userData } = response.data;
      if (typeof vanity !== "undefined" && vanity.length) {
        dispatch(setUserVanity(response.data.vanity));
      }

      dispatch(setUserLocalDbData(localDbData));
      dispatch(setUserData(userData));
      Toast.success("Your Account Has Been Updated", 2000);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      if (error.response.status === 500) {
        goTo(ERROR_PATH);
        return;
      }

      Toast.fail(formatErrorMessage(error.response.data), 3000, () => {
        Toast.hide();
      });
    }
  };

  const formatErrorMessage = (message: string) => {
    const formattedMessages = ["Email alias is not accepted.", "Nickname already taken.", "Email already taken."];
    if (formattedMessages.includes(message)) {
      return message;
    }

    const field = message
      .replace("user_data[0]", "")
      .replace("firstname", "name")
      .replace(/(^.*\[|\].*$)/g, "");

    // commented for now , will use this when we map specific error message
    // let details = message;
    // const originalField = field === "name" ? "firstname" : field;
    // ["Missing field", "Invalid value"].map((label) => {
    //   details = details.replace(`Error: ${label}: user_data[0][${originalField}] `, "");
    // });
    return `Missing or Invalid ${capitalize(field)}`;
  };

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <ProfileContentContainer page="account_details" isLoading={isLoading === "loading"}>
      <AccountDetailsStoreCredit storeCredit={storeCredit} />
      {isMobile && storeCredit && <Divider className={classes.dividerStyle} />}
      <Grid container spacing={1}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <ProfilePicture />
        </Grid>
        <Grid
          item
          md={8}
          lg={8}
          sm={8}
          style={{
            marginTop: isTablet && storeCredit ? 32 : "inherit"
          }}
        >
          <AccountDetailsPersonalInformation
            vanityUrlOnChangedHandler={vanityUrlOnChangedHandler}
            nickname={formInputs.nickname}
            gender={formInputs.gender}
            email={formInputs.email}
            birthYear={formInputs.birthYear}
            birthMonth={formInputs.birthMonth}
            birthDay={formInputs.birthDay}
            handleInputChange={handleInputChange}
            handleOnFocus={handleOnFocus}
            handleOnBlur={handleOnBlur}
            validateNickname={validateNickname}
          />
        </Grid>
      </Grid>
      {isMobile && <Divider className={classes.dividerStyle} />}
      <AccountDetailsShippingAddress
        firstname={formInputs.firstname}
        lastname={formInputs.lastname}
        address={formInputs.address}
        country={formInputs.country}
        city={formInputs.city}
        state={formInputs.state}
        zip={formInputs.zip}
        handleInputChange={handleInputChange}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
      />
      <Divider className={classes.dividerStyle} />
      <Box className={classes.button}>
        <PrimaryButtonV3 disabled={hasError} handleClick={setUser}>
          {isSaving ? "Save Changes..." : "Save Changes"}
        </PrimaryButtonV3>
      </Box>
    </ProfileContentContainer>
  );
};

export default AccountDetails;
