import React from "react";
import { Box, Theme, Typography, useMediaQuery, Grid } from "@material-ui/core/";
import FaqContent from "./FaqContent";
import CantFind from "./CantFind";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "../../theme/theme-devins";
import Section from "../homeV4/common/Section";
import { backgroundTheme } from "../../style";
import { FOOTER_VARIANT2 } from "../common/appFooter/FooterVariants";

export default function Faq(props: any) {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const contactUs = () => {
    props.history.push("/contact-us");
    return;
  };

  const buyingGiftItems = [
    {
      id: 1,
      question: "What happens when I buy a gift?",
      answer:
        "The recipient gets an email with a link to redeem the gift online. We also keep you updated on the progress of your gift. You'll get an email when the recipient accepts your gift and when they receive it (if tracking is provided)."
    },
    {
      id: 2,
      question: "Do I need the recipient's delivery address?",
      answer: "No. The recipient enters their own delivery address when they accept the gift."
    },
    {
      id: 3,
      question: "Do I need the recipient's size or other details?",
      answer: "No. The recipient selects their size or other preferences once they've accepted the gift."
    },
    {
      id: 4,
      question: "Will the recipient accept my gift?",
      answer:
        "The recipient must accept your gift within 14 days or less. Otherwise, you receive 100% store credit toward another gift. You can also contact us to request store credit sooner!"
    },
    {
      id: 5,
      question: "Will my name and payment details stay private?",
      answer:
        "Yes. Your name and payment details stay private and are not shared with the recipient — not on the packaging or anywhere else. When someone receives a gift from you, we only share your profile username and any message you write."
    },
    {
      id: 6,
      question: "Can I send a note with my gift?",
      answer:
        "Yes, you can add a note to your gift at the last step of checkout. Keep an eye out for the message field. Your message will be included via email and delivered on a note with your gift, when possible."
    },
    {
      id: 7,
      question: "Can I send someone recurring gifts?",
      answer: "At this time we do not have subscription services but will be adding it in the future."
    },
    {
      id: 8,
      question: "How can I use my store credit?",
      answer:
        "We automatically apply your store credit at checkout. You can use it on thousands of items available in our store. Store credit cannot be used to buy any items outside of our store."
    },
    {
      id: 9,
      question: "Why is a payment method needed when I pay with store credit?",
      answer: "If the total exceeds your store credit, you'll need to enter a payment method to cover the balance."
    },
    {
      id: 10,
      question: "Are these gifts really real? Like, can I hold them in my hands?",
      answer: "Yes, these are real, physical gifts that your recipient will get to wear, eat, or experience."
    },
    {
      id: 11,
      question: "Do you price-match other retailers?",
      answer:
        "Our goods are generally listed at or below MSRP and prices found at major department stores. While we don't price match, we do guarantee that our products are brand new and authentic."
    },
    {
      id: 12,
      question:
        'If you find an item sold by us and available for less at a major brick-and-mortar retailer, please <a href="mailto:support@gift.me" style="color: #E92983; font-weight: bold;">send us a message</a>.',
      answer:
        "Unlike other online retailers, we guarantee that our goods are new and authentic. By purchasing from us, you never have to worry about counterfeit products. Our authentic designer goods feature real precious metals, premium materials, and superior craftsmanship."
    },
    {
      id: 13,
      question: "Can I send gifts internationally?",
      answer:
        "Yes, we have products available for both US and international delivery. International users may send gifts to recipients based in the US and vice versa."
    },
    {
      id: 14,
      question: "When will my gift be delivered?",
      answer:
        'Gifts are scheduled for the next available delivery date. Some gifts like flowers are often delivered the same day, or within 1-2 business days. Other items may take 2-5 days to arrive via post, or longer if your gift is crossing international borders. Delivery times vary by item and depend on when the recipient redeems their gift. If you have a special request, we may be able to accommodate you. Please <a href="mailto:support@gift.me" style="color: #E92983; font-weight: bold;">contact us here</a>.'
    }
  ];

  const businessInquiriesItems = [
    {
      id: 21,
      question: "I am a product vendor. How can I add my products into this store?",
      answer:
        'Please <a href="https://www.getrealgifts.com/home?page=signup&account_type=vendor" target="_blank" style="color: #E92983; font-weight: bold;">sign up here</a> if you would like to add your items into this store. We\'ll review your request and get back to you shortly.'
    },
    {
      id: 22,
      question: "I have a website/app. How can I add this store?",
      answer:
        'Please <a href="https://www.getrealgifts.com/home?page=signup&account_type=developer" target="_blank" style="color: #E92983; font-weight: bold;">sign up here</a> if you would like to add this store to your site or app. We\'ll review your request and get back to you shortly.'
    }
  ];

  const receivingGiftItems = [
    {
      id: 31,
      question: "Can I buy items for myself?",
      answer: "Yes. You can add items to your Wishlist and continue through checkout to purchase items immediately."
    },
    {
      id: 32,
      question: "How will the buyer know where to send my gifts?",
      answer:
        "Buyers can buy gifts for you without needing to enter your delivery address. When someone gets you a gift, we'll send you a link where you can enter the delivery address yourself."
    },
    {
      id: 33,
      question: "How will the buyer know my size or other preferences for my gifts?",
      answer:
        "The buyer can purchase gifts for you without choosing your size or other preferences. We'll send you a link where you can select your size or other options yourself."
    },
    {
      id: 34,
      question: "Will my delivery address stay private?",
      answer: "Yes, your delivery address is kept private and will <b>not</b> be shared with the buyer."
    },
    {
      id: 35,
      question: "Can I share my Wishlist?",
      answer:
        "Yes. You can drop a link to your Gift.me Wishlist on your social and personal pages, share it by email, or send it via text."
    },
    {
      id: 36,
      question: "How do I redeem my gift?",
      answer:
        "You\'ll get an email with a link to redeem your gift and provide the necessary delivery details. Please check your email spam/junk folder. If you need help, please <a href=\"mailto:support@gift.me\" style=\"color: #E92983; font-weight: bold;\">contact us here</a>."
    },
    {
      id: 37,
      question: "How do I update my delivery address?",
      answer:
        "You can update your delivery address when you click on the link to redeem your gift. During the redemption process, you'll be prompted to either use your previous delivery address or enter a new delivery address."
    },
    {
      id: 38,
      question: "What business name will be on the gift packaging?",
      answer:
        "The gift packaging will display RealGifts or the name of our vendor partners (such as a cookie company). We do not show the gift buyer's name or details on the gift packaging, except for notes included with gifts like floral arrangements."
    },
    {
      id: 39,
      question: "Where is my tracking number?",
      answer:
        "Your order will process and ship after you accept your gift and provide any required delivery details. Once your gift ships, we'll send an email with any tracking info or delivery estimate. "
    },
    {
      id: 40,
      question: "Why is a payment method needed when I pay with store credit?",
      answer: "If the total exceeds your store credit, you'll need to enter a payment method to cover the balance."
    },
    {
      id: 41,
      question: "Can I return my gift?",
      answer:
        "Some gifts may be returnable, but most are generally not. Items are considered used once a recipient accepts or receives the gift. Examples of gifts that are not returnable include, but are not limited to items redeemable online, perishable, special orders, or of a personal nature. Please see our terms or <a href=\"mailto:support@gift.me\" style=\"color: #E92983; font-weight: bold;\">contact us here</a>."
    }
  ];

  const otherItems = [
    {
      id: 51,
      question: "My question was not answered! What now?",
      answer:
        "Thanks for taking the time to read all the way through our FAQ! If you have a more detailed question or a general inquiry that we didn\'t address here, don\'t hesitate to reach out by <a href=\"mailto:support@gift.me\" style=\"color: #E92983; font-weight: bold;\">sending a message here</a>. We\'ll be happy to answer your question directly."
    }
  ];

  return (
    <AppLayout
      theme={theme}
      headerVariant="header1"
      backgroundColor={backgroundTheme.grayFill}
      footerVariant={FOOTER_VARIANT2}
    >
      <Section>
        <Box
          style={{
            background: "url(/assets/secondary_bg.svg) center top / 100% no-repeat"
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "#2D2D2D",
              fontSize: "25px",
              fontWeight: 600,
              lineHeight: "32px",
              textAlign: "center",
              padding: "30px"
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FaqContent items={buyingGiftItems} title="Buying Gifts" />
              <FaqContent items={businessInquiriesItems} title="Business Inquiries" />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FaqContent items={receivingGiftItems} title="Receiving Gifts" />
              <FaqContent items={otherItems} title="Other" />
            </Grid>
          </Grid>
          <CantFind handleClick={contactUs} />
        </Box>
      </Section>
    </AppLayout>
  );
}
