import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GiftInterface } from "../../../models/gift";

const ContainerStyles = makeStyles(() => ({
  TextHeaderContainerRight: {
    display: "flex",
    flexBasis: "30%",
    textAlign: "right",
    flexWrap: "wrap"
  }
}));

interface GiftPriceMobileProps {
  gift: GiftInterface;
}

export function GiftPriceMobile(props: GiftPriceMobileProps) {
  const styles = ContainerStyles();
  const gift = props.gift;

  const isFreeShipping = gift.pricing && gift.pricing.shipping_price_converted <= 0;

  return (
    <Box className={styles.TextHeaderContainerRight}>
      <Box style={{ width: "100%" }}>
        <Typography
          style={{ fontWeight: "bold", textAlign: "right" }}
          dangerouslySetInnerHTML={{
            __html: gift.pricing.sale_price_display_short
          }}
        />
        {gift.pricing.is_sale ? (
          <Typography
            style={{ textAlign: "right", color: "grey", textDecoration: "line-through" }}
            dangerouslySetInnerHTML={{
              __html: gift.pricing.price_display_short
            }}
          />
        ) : null}
      </Box>
      {isFreeShipping && (
        <Box style={{ width: "100%" }}>
          <Typography style={{ fontWeight: "bold", color: "#E92983", textAlign: "right", fontSize: "13px" }}>
            FREE SHIPPING
          </Typography>
        </Box>
      )}
    </Box>
  );
}
