import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const IconFacebook = (props: SvgIconProps): ReturnType<React.FC> => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20"> */}
      <path
        id="Shape"
        d="M26.454,16.536a6.456,6.456,0,0,0-1.749-.266c-.71,0-2.241.452-2.241,1.33v2.1H26.1v3.537H22.464V33H18.8V23.237H17V19.7h1.8V17.918c0-2.686,1.256-4.92,4.289-4.92A14.875,14.875,0,0,1,27,13.4l-.546,3.138Z"
        transform="translate(-17 -12.998)"
        fill="#ef1581"
        fillRule="evenodd"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default IconFacebook;
