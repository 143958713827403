import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const ReceivedGiftImageStyle = makeStyles(() => ({
  rootStyle: {
    display: "flex",
    flexDirection: "row",
    width: "150px",
    padding: "8px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px"
  },
  imageStyle: {
    width: "100%",
    alignSelf: "center",
    borderRadius: "8px"
  }
}));

export default function ReceivedGiftImage(props: any) {
  const classes = ReceivedGiftImageStyle();
  return (
    <Box className={classes.rootStyle} style={props.style}>
      <img className={classes.imageStyle} alt={props.alt} src={props.src} />
    </Box>
  );
}
