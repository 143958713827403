import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import ReceivedGiftImage from "./ReceivedGiftImage";
import RedeemGiftDialogButtons from "./RedeemGiftDialogButtons";
import { RedeemGiftDividerStyle, RedeemGiftHeaderStyle, RedeemGiftNameStyle } from "./RedeemGiftFlowStyles";
import { roundToDecimalPlaces } from "../../utils/stringUtils";

export default function AcceptStoreCreditDialogContent(props: any) {
  const redeemGiftNameStyle = RedeemGiftNameStyle();
  const redeemGiftHeaderStyle = RedeemGiftHeaderStyle();
  const redeemGiftDividerStyle = RedeemGiftDividerStyle();

  let order_offer_total =
    props.order && props.order.offer_data.exchange.credits
      ? props.order.offer_data.exchange.credits.offer_total
      : undefined;

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" style={{ paddingBottom: "16px" }}>
      <Typography variant="body1">Order number: {props.order.order_id}</Typography>

      <ReceivedGiftImage
        alt="Gift Image"
        src={props.order.gift.image_url}
        style={{ alignSelf: "center", marginTop: "16px" }}
      />

      <Typography variant="h6" className={redeemGiftNameStyle.textStyle} style={{ marginTop: "8px" }}>
        {props.order.gift.name}
      </Typography>

      <Divider className={redeemGiftDividerStyle.style} style={{ marginTop: "16px" }} />

      <Typography variant="h6" className={redeemGiftHeaderStyle.textStyle} style={{ marginTop: "16px" }}>
        Instead of <b>declining</b> this gift, would you like to exchange for{" "}
        <b>
          {"$" +
            roundToDecimalPlaces(
              order_offer_total && order_offer_total > 0
                ? order_offer_total
                : props.order.pricing.total_price_paid_converted,
              2
            )}{" "}
          Store Credit
        </b>{" "}
        in our store?
      </Typography>

      <RedeemGiftDialogButtons
        positiveButtonText="Yes, exchange for credit"
        onPositiveButtonClick={props.onExchangeForCreditClicked}
        onNegativeButtonClick={props.onDeclineGiftAndCreditClicked}
        negativeButtonText="No thanks. Decline gift & store credit"
      />
    </Box>
  );
}
