import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const usesStyles = makeStyles(() => ({
  buttonStyle: {
    width: "90%",
    height: "46px",
    borderRadius: "23px",
    padding: 0,
    background: "linear-gradient(135deg, #eb3693, #f0017c)",
    textTransform: "none"
  }
}));

interface PrimaryButtonProps extends ButtonProps {
  className?: any;
  type?: "button" | "reset" | "submit";
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  id?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = (props: PrimaryButtonProps) => {
  const classes = usesStyles();
  const { className, color, startIcon, type, handleClick, style, disabled, children, id, ...rest } = props;
  // Merging default style with styles provided by the parent
  const classNameLocal = className ? `${classes.buttonStyle} ${className}` : classes.buttonStyle;

  return (
    <Button
      id={id}
      color={color || "primary"}
      variant="contained"
      startIcon={startIcon}
      onClick={handleClick}
      className={classNameLocal}
      style={style}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
