import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

interface UsePageTitleReturnType {
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
}

const usePageTitle = (): UsePageTitleReturnType => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState<string>();

  useEffect(() => {
    if (location.pathname.startsWith("/store/store/")) {
      const storeNameSliced = location.pathname.split("/");
      const storeName = storeNameSliced[storeNameSliced.length - 1];
      setPageTitle(`Gift.Me - ${storeName}`);
    } else if (location.pathname.startsWith("store/storehome")) {
      setPageTitle("Gift.Me - Shops");
    } else if (location.pathname.startsWith("/login")) {
      setPageTitle("Gift.Me - Login");
    } else if (location.pathname === "/") {
      setPageTitle("Gift.me - Create Your Free Gift Wishlist for Fans Today!");
    } else if (location.pathname.startsWith("/wishlist")) {
      setPageTitle("Gift.Me - My Wishlist");
    } else if (location.pathname.startsWith("/store/gift/")) {
      setPageTitle("Gift.Me - Product Detail");
    } else if (location.pathname.startsWith("/checkout/")) {
      setPageTitle("Gift.Me - Checkout");
    } else if (location.pathname.startsWith("/checkoutV1/")) {
      setPageTitle("Gift.Me - Checkout V1");
    } else if (location.pathname.startsWith("/checkoutresult/")) {
      setPageTitle("Gift.Me - Thank You!");
    } else if (location.pathname.startsWith("/checkoutresultV1/")) {
      setPageTitle("Gift.Me - Thank You!");
    } else if (location.pathname.startsWith("/gift/wishlist/")) {
      setPageTitle("Gift.Me - Wishlist");
    } else if (location.pathname.startsWith("/profile/account")) {
      setPageTitle("Gift.Me - My Account");
    } else if (location.pathname.startsWith("/profile/payment-methods")) {
      setPageTitle("Gift.Me - Payment Methods");
    } else if (location.pathname.startsWith("/profile/account-settings")) {
      setPageTitle("Gift.Me - Account Settings");
    } else if (location.pathname.startsWith("/profile/account-sync")) {
      setPageTitle("Gift.Me - Account Sync");
    } else {
      setPageTitle("Gift.Me");
    }
  }, [location]);

  return {
    ...location,
    pageTitle,
    setPageTitle
  };
};

export default usePageTitle;
