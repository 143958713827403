import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
// import { Link } from '@material-ui/core/';
import AppDialog from "../../common/dialog/AppDialog";
import TextInput from "../../input/TextInput";
import PrimaryButton from "../../buttons/PrimaryButton";

const HeaderStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "40px"
  },
  content: {
    width: "95%",
    marginBottom: theme.typography.pxToRem(16)
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "inset 0 -1px 0 0 #E3E3E3, 0 1px 7px 0 rgba(0,0,0,0.15)"
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  email: {
    ...theme.typography.h5,
    fontWeight: 600,
    marginTop: "12px"
  },
  changeEmail: {
    ...theme.typography.body1,
    marginTop: "22px"
  }
}));

export default function CheckoutResultHeader(props) {
  const styles = HeaderStyles();

  const [openChangeEmail, setOpenChangeEmail] = useState(false);

  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography>Order confirmation has been sent to:</Typography>
        <Typography className={styles.email}>{props.email}</Typography>
        {/*<Typography className={styles.changeEmail}>
          <Link
            href="#"
            underline="always"
            onClick={(e) => {
              e.preventDefault();
              setOpenChangeEmail(true);
            }}
          >
            Click here
          </Link>
          {' '}
          if you need to change that email address
        </Typography>*/}
      </CardContent>
      <AppDialog
        open={openChangeEmail}
        onClose={() => {
          setOpenChangeEmail(false);
        }}
        fullWidth={false}
        title={"Change Email Address"}
        noHeaderLogo={true}
      >
        <Box className={styles.root}>
          <Typography variant="subtitle1" style={{ marginBottom: "16px" }}>
            Re-send order confirmation to this email
          </Typography>
          <TextInput defaultText={props.email} className={styles.content} />
          <PrimaryButton
            className={styles.content}
            //handleClick={}
          >
            Update email
          </PrimaryButton>
        </Box>
      </AppDialog>
    </Card>
  );
}
