import axios from "axios";

export const UserValidateVanity = async (vanity): Promise<boolean> => {
  try {
    const response = await axios.post("/api/user/vanity/validate", { vanity });
    return response.data.isAvailable;
  } catch (error) {
    console.log("Error validating user vanity", error);
  }
};
