import { makeStyles } from "@material-ui/core/styles";
import { DESKTOP_DIALOG_MAX_WIDTH } from "../common/Constants";

export const RedeemDialogStyle = makeStyles((theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_DIALOG_MAX_WIDTH
    }
  }
}));
export const RedeemDialogContentStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.typography.pxToRem(8)
  }
}));

export const RedeemGiftNameStyle = makeStyles((theme) => ({
  textStyle: {
    fontWeight: "normal",
    color: theme.palette.text.secondary
  }
}));

export const RedeemGiftHeaderStyle = makeStyles((theme) => ({
  textStyle: {
    fontWeight: "normal",
    color: theme.palette.text.primary
  }
}));

export const RedeemGiftCaptionStyle = makeStyles((theme) => ({
  textStyle: {
    color: theme.palette.grey[600]
  }
}));

export const RedeemGiftDividerStyle = makeStyles((theme) => ({
  style: {
    backgroundColor: theme.palette.grey[300]
  }
}));

export const RedeemDialogButtonStyle = makeStyles((theme) => ({
  button: {
    ...theme.typography.body2,
    fontWeight: "bold",
    height: "fit-content",
    padding: "10px 0px",
    borderRadius: "60px",
    whiteSpace: "pre-line"
  },
  negativeButtonStyle: {
    marginTop: "24px",
    alignSelf: "center"
  }
}));
