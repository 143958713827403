import React from "react";
import { Box, Grid, Theme, useMediaQuery } from "@material-ui/core";
import GiftFiltersButton from "./GiftFiltersButton";
import SelectRecipientShoppingFor from "../../store/SelectRecipientShoppingFor";
import Credits from "../credits/Credits";
import GiftSortSelect from "./GiftSortSelect";
import { SHOW_GIFT_FILTER } from "../Constants";

interface GiftSearchActionsProps {
  isFilterOpen: boolean;
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterModal: boolean;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  style?: React.CSSProperties;
}

const GiftSearchActions: React.FC<GiftSearchActionsProps> = (props: GiftSearchActionsProps) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const { isFilterOpen, setIsFilterOpen, isFilterModal, sortBy, setSortBy, style } = props;

  return (
    <>
      <Grid container>
        <Grid item>
          {SHOW_GIFT_FILTER && (
            <GiftFiltersButton
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              style={{
                marginRight: isFilterOpen && isDesktop ? 217 : 25,
                marginTop: isFilterOpen && isFilterModal && !isDesktop ? 20 : "inherit"
              }}
            />
          )}
          {isDesktop && <SelectRecipientShoppingFor style={{ fontSize: "16px" }} />}
        </Grid>
        <Grid item>
          {isDesktop && (
            <Box>
              <Credits
                style={{
                  marginRight: 35,
                  fontSize: 16
                }}
              />
            </Box>
          )}
          {!isFilterModal && (
            <Box>
              <GiftSortSelect sortBy={sortBy} setSortBy={setSortBy} />
            </Box>
          )}
        </Grid>
      </Grid>
      {!isDesktop && !isFilterModal && (
        <Box display="flex" justifyContent="space-between" style={{ ...style }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <SelectRecipientShoppingFor style={{ fontSize: "16px" }} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Credits
              style={{
                fontSize: 16
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default GiftSearchActions;
