import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Masonry from "react-masonry-css";

const useStyles = makeStyles((theme) => ({
  masonryGrid: {
    display: "flex",
    marginLeft: theme.spacing(-4),
    width: "inherit"
  },
  masonryColumn: {
    paddingLeft: theme.spacing(4),
    backgroundClip: "padding-box"
  }
}));

const BreakpointMasonry = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  const breakpointCols = {
    default: 4,
    [theme.breakpoints.values.xl]: 4,
    [theme.breakpoints.values.lg]: 3,
    [theme.breakpoints.values.md]: 3,
    [theme.breakpoints.values.sm]: 2,
    [theme.breakpoints.values.xs]: 2
  };

  return (
    <Masonry breakpointCols={breakpointCols} className={classes.masonryGrid} columnClassName={classes.masonryColumn}>
      {children}
    </Masonry>
  );
};

export default BreakpointMasonry;
