import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { Box, CircularProgress } from "@material-ui/core";
import { updateRecipientsToLocalStorage, setSelectedRecipient } from "../../utils/localStorage";
import { useAppSelector } from "../../hooks";
import PageNotFound from "../pagenotfound/PageNotFound";
import StoreModal from "../store/StoreModal";
import useNav from "../../hooks/useNav";
import InfiniteScroll from "../common/InfiniteScroll";
import { useApp } from "../../context/app-context";
import { mapGiftsByMasterGiftId } from "../gifter/utils/gift-utils";
import { ERROR_PATH, STOREHOME_PATH } from "../../constants/paths";
import WishlistEmpty from "./WishlistEmpty";
import Section from "../common/appLayout/Section";
import BreakPointMansory from "./common/BreakPointMansory";
import GiftItem from "./common/GiftItem";
import WishlistProfileDesktop, { ReceiverDetailsObject } from "./WishlistProfileDesktop";
import WishlistProfileMobile from "./WishlistProfileMobile";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";

interface WishlistContentProps {
  wishlistType: "vanity" | "userId";
  wishlistValue: string;
}

const WishlistContent = ({ wishlistType, wishlistValue }: WishlistContentProps) => {
  const { setPromptLogin } = useApp();
  const { goTo } = useNav();
  const { encryptedUserId } = useAppSelector((state) => state.user.data);

  const { isDesktop } = useMediaBreakpoints();

  const [vanityOrUserIdValue, setVanityOrUserIdValue] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [endOfPages, setEndOfPages] = useState(false);
  const [page, setPage] = useState(1);
  const [gifts, setGifts] = useState([]);
  const [giftCount, setGiftCount] = useState(0);

  const [receiverDetails, setReceiverDetails] = useState<ReceiverDetailsObject>({
    nickname: "",
    encryptedUserId: "",
    vanity: "",
    about: ""
  });
  const [is404, setIs404] = useState(false);

  const [productModalIsOpen, setProductModalIsOpen] = useState(false);
  const [currentGift, setCurrentGift] = useState({
    image_url: "",
    brand: "",
    name: "",
    description: "",
    description_plus: "",
    pricing: {
      sale_price_display_short: ""
    },
    is_in_wishlist: false
  });

  const wishlistAdditionalQuery = useMemo(
    () =>
      wishlistType === "vanity"
        ? `/${vanityOrUserIdValue}?page=${page}`
        : `?page=${page}&userId=${vanityOrUserIdValue}`,
    [wishlistType, page, vanityOrUserIdValue]
  );

  const isViewingOtherWishlist = useMemo(
    () => receiverDetails.encryptedUserId !== encryptedUserId ?? true,
    [receiverDetails.encryptedUserId, encryptedUserId]
  );

  const prepareWishlist = (data: any) => {
    const { username, userId, gifts, vanity, about, avatar, totalGiftCount } = data;

    setReceiverDetails({
      nickname: username,
      encryptedUserId: userId,
      vanity,
      about,
      profileUrl: avatar ?? null
    });

    if (totalGiftCount > 0) {
      setGiftCount(totalGiftCount);
    }
    setGifts((prevGifts) => {
      const groupGifts = [...prevGifts, ...mapGiftsByMasterGiftId(gifts)];

      updateRecipientsToLocalStorage({ nickname: username, userId, giftCount: totalGiftCount });
      setSelectedRecipient({
        nickname: username,
        userId,
        giftCount: totalGiftCount
      });

      return groupGifts;
    });
  };

  const loadWishlist = async () => {
    setIsFetching(true);
    try {
      const response = await axios.get(`/api/wishlist${wishlistAdditionalQuery}`);
      if (response) {
        const { data } = response;
        if (data.totalGiftCount === 0) {
          setEndOfPages(true);
          setGiftCount(0);
        }
        prepareWishlist(data);
        setIsFetching(false);
        setLoaded(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setIs404(true);
        } else {
          goTo(ERROR_PATH);
        }
      }
    } finally {
      setIsFetching(false);
      setLoaded(true);
    }
  };

  const onBottomHit = useCallback(() => {
    if (loaded && !isFetching && !endOfPages && giftCount !== gifts.length) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loaded, isFetching, endOfPages, giftCount]);

  const onItemRemoved = (giftId: number) => {
    setGifts((prevGifts) => prevGifts.filter((gift) => gift.gift_id !== giftId));
    setGiftCount((count) => count - 1);
  };

  useEffect(() => {
    if (vanityOrUserIdValue) {
      loadWishlist();
    }
  }, [page, vanityOrUserIdValue]);

  useEffect(() => {
    setVanityOrUserIdValue(wishlistValue);
    setPage(1);
    setLoaded(false);
    setGifts([]);
    setEndOfPages(false);
  }, [wishlistValue]);

  return loaded && is404 ? (
    <PageNotFound />
  ) : (
    <>
      {isDesktop ? (
        <WishlistProfileDesktop
          receiverDetails={receiverDetails}
          isLoading={page > 1 ? false : isFetching}
          giftCount={giftCount ?? 0}
        />
      ) : (
        <WishlistProfileMobile
          receiverDetails={receiverDetails}
          isLoading={isFetching}
          giftCount={giftCount ?? 0}
          pageCount={page}
        />
      )}

      <Section style={{ marginTop: isDesktop ? 40 : 10 }} className="wishlist-items">
        <Box style={{ width: "100%" }}>
          {loaded &&
            !is404 &&
            (gifts.length ? (
              <InfiniteScroll
                onBottomHit={onBottomHit}
                isLoading={isFetching}
                hasMoreData={!endOfPages}
                loadOnMount={false}
                additionalInnerHeight={1200}
              >
                <BreakPointMansory>
                  {gifts.map((item, idx) => (
                    <GiftItem
                      gift={item}
                      key={idx}
                      wishlistButtonCallback={() => onItemRemoved(item.gift_id)}
                      receiverDetails={receiverDetails}
                      isWishlistPage={true}
                      onGiftClicked={() => {
                        setCurrentGift(item);
                        setProductModalIsOpen(true);
                      }}
                      showWishlistButton={receiverDetails.encryptedUserId === encryptedUserId}
                    />
                  ))}
                </BreakPointMansory>
              </InfiniteScroll>
            ) : (
              <WishlistEmpty
                label={
                  isViewingOtherWishlist
                    ? "Tap \"Browse Products\" button below to find a gift for them!"
                    : "Tap \"Browse Products\" button below to start creating a wishlist!"
                }
                handleClick={() => goTo(STOREHOME_PATH)}
              />
            ))}

          {isFetching && page > 1 && (
            <Box height={150} display="flex" justifyContent="center">
              <CircularProgress style={{ marginTop: 50 }} />
            </Box>
          )}
          <StoreModal
            open={productModalIsOpen}
            onClose={() => {
              setProductModalIsOpen(false);
            }}
            gift={currentGift}
            setPromptLogin={setPromptLogin}
            encryptedUserId={encryptedUserId}
            isMyWishlist={!isViewingOtherWishlist}
          />
        </Box>
      </Section>
    </>
  );
};

export default WishlistContent;
