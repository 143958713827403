import React, { useState } from "react";
import { Box, Button, Link, List, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FONT_FAMILIES } from "../../style";
import { replaceUnicodeChars } from "../../utils/stringUtils";
import MenuButton from "./MenuHeader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NestedListHeader from "./NestedListHeader";
import MenuHeader from "./MenuHeader";
import { DESKTOP_CONTENT_WIDTH } from "../common/Constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppDialog from "../common/dialog/AppDialog";
import useNav from "../../hooks/useNav";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "16px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.background.default
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  removeButtonStyle: {
    ...theme.typography.body2,
    ...theme.typography.h5,
    fontWeight: 500,
    fontFamily:
      "-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",\"Oxygen\",\"Ubuntu\",\"Cantarell\",\"Fira Sans\",\"Droid Sans\",\"Helvetica Neue\",sans-serif",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontSize: 20
  },
  dialogContent: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  gridTabItem: {
    textTransform: "none",
    fontFamily: FONT_FAMILIES.POPPINS,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "nowrap",
    color: "#2C2C2C",
    "&:hover, &:focus": {
      fontWeight: 600,
      textDecoration: "none",
      backgroundColor: "transparent"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 36px",
      fontWeight: 400
    }
  }
}));

const firstHeader = () => {
  return [
    {
      title: "Popular",
      to: "/store/store/407569/☆%20Popular%20☆",
      link: true
    },
    {
      title: "Bags & Wallets",
      to: "/store/store/5376240/Bags%20&%20Wallets",
      link: true
    },
    {
      title: "Clothing",
      content: [
        {
          id: 1,
          name: "Lingerie",
          to: "/store/store/501039/Lingerie",
          link: true
        },
        {
          id: 2,
          name: "Sleepwear",
          to: "/store/store/1756528/Sleepwear",
          link: true
        },
        {
          id: 3,
          name: "Partywear",
          to: "/store/store/5524061/Partywear",
          link: true
        },
        {
          id: 4,
          name: "Swimwear",
          to: "/store/store/1756525/Swimwear",
          link: true
        }
      ]
    },
    {
      title: "Shoes",
      content: [
        {
          id: 5,
          name: "Sneakers",
          to: "/store/store/5376246/Sneakers",
          link: true
        },
        {
          id: 6,
          name: "Boots",
          to: "/store/store/5376250/Boots",
          link: true
        },
        {
          id: 7,
          name: "Heels & Sandals",
          to: "/store/store/5376249/Heels%20&%20Sandals",
          link: true
        }
      ]
    },
    {
      title: "Accessories",
      content: [
        {
          id: 8,
          name: "Sunglasses",
          to: "/store/store/5376248/Sunglasses",
          link: true
        },
        {
          id: 9,
          name: "Timepieces",
          to: "/store/store/5376251/Timepieces",
          link: true
        }
      ]
    },
    {
      title: "Fragrances",
      to: "/store/store/129730/Fragrances",
      link: true
    },
    {
      title: "Floral",
      to: "/store/store/1742068/Floral",
      link: true
    }
  ];
};

const secondHeader = () => {
  return [
    {
      title: "Home Decor",
      to: "",
      link: false,
      content: [
        {
          id: 1,
          name: "Home Furniture",
          to: "/store/store/3900907/Home%20Furniture",
          link: true
        },
        {
          id: 2,
          name: "Decor & Lighting",
          to: "/store/store/3900918/Decor%20&%20Lighting",
          link: true
        },
        {
          id: 3,
          name: "Bed & Bath",
          to: "/store/store/3900932/Bed%20&%20Bath",
          link: true
        },
        {
          id: 4,
          name: "Kitchen & Dining",
          to: "/store/store/3900934/Kitchen%20&%20Dining",
          link: true
        },
        {
          id: 5,
          name: "Outdoor Living",
          to: "/store/store/3900938/Outdoor%20Living",
          link: true
        },
        {
          id: 6,
          name: "Home Office",
          to: "/store/store/3900939/Home%20Office",
          link: true
        }
      ]
    },
    {
      title: "Jewelry",
      content: [
        {
          id: 7,
          name: "Diamonds",
          to: "/store/store/114293/Diamonds",
          link: true
        },
        {
          id: 8,
          name: "Anklets",
          to: "/store/store/121795/Anklets",
          link: true
        },
        {
          id: 9,
          name: "Necklaces",
          to: "/store/store/252022/Necklaces",
          link: true
        },
        {
          id: 10,
          name: "Earrings",
          to: "/store/store/114672/Earrings",
          link: true
        },
        {
          id: 11,
          name: "Bracelets",
          to: "/store/store/121406/Bracelets",
          link: true
        },
        {
          id: 12,
          name: "Toe Rings",
          to: "/store/store/121805/Toe%20Rings",
          link: true
        }
      ]
    },
    {
      title: "Foods",
      content: [
        {
          id: 13,
          name: "Cookies",
          to: "/store/store/1823646/Cookies",
          link: true
        },
        {
          id: 14,
          name: "Chocolates",
          to: "/store/store/5427903/Chocolates",
          link: true
        }
      ]
    }
  ];
};

const StoreHomeTabHeader = () => {
  const styles = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const [openDialog, setOpenDialog] = useState(false);
  const { goTo } = useNav();

  const navigateToStore = (to: any) => {
    goTo(to);
  };

  return isDesktop ? (
    <>
      <div
        style={{
          textAlign: "center",
          padding: "15px 0"
        }}
      >
        {firstHeader().map((data, id) => {
          if (data.link) {
            return (
              <Link key={id} href={data.to} className={styles.gridTabItem}>
                {replaceUnicodeChars(data.title)}
              </Link>
            );
          } else {
            return (
              <>
                <MenuButton title={data.title} items={data.content} />
              </>
            );
          }
        })}
      </div>
      <div
        style={{
          textAlign: "center",
          padding: "15px 0"
        }}
      >
        {secondHeader().map((nextData, id) => {
          if (nextData.link) {
            return (
              <Link
                key={id}
                href={nextData.to}
                className={styles.gridTabItem}
                style={{
                  padding: "0 36px"
                }}
              >
                {replaceUnicodeChars(nextData.title)}
              </Link>
            );
          } else {
            return <MenuHeader title={nextData.title} items={nextData.content} />;
          }
        })}
      </div>
    </>
  ) : (
    <>
      <Box width={{ lg: DESKTOP_CONTENT_WIDTH }} className={styles.root} style={{ paddingTop: "unset" }}>
        <Box
          className={styles.content}
          style={{
            backgroundColor: "unset",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 0
          }}
        >
          <Button
            className={styles.removeButtonStyle}
            onClick={() => {
              setOpenDialog(true);
            }}
            style={{
              width: "100%",
              justifyContent: "left",
              fontSize: "16px",
              fontWeight: 700
            }}
          >
            {replaceUnicodeChars("Popular")}{" "}
            <ExpandMoreIcon
              style={{
                position: "absolute",
                right: 0
              }}
            />
          </Button>
        </Box>
        <AppDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          fullWidth={false}
          title="Example"
          noHeaderLogo={true}
          isNotTransparent={true}
          dialogContentClassName={styles.dialogContent}
        >
          <List style={{ width: 300, zIndex: 1, paddingTop: 2 }}>
            {firstHeader().map((data, id) => {
              if (data.link) {
                return (
                  <ListItem key={id} onClick={() => navigateToStore(data.to)}>
                    <ListItemText
                      classes={{
                        primary: styles.gridTabItem
                      }}
                      primary={replaceUnicodeChars(data.title)}
                    />
                  </ListItem>
                );
              } else {
                return <NestedListHeader styles={styles.gridTabItem} title={data.title} items={data.content} />;
              }
            })}
            {secondHeader().map((nextData, id) => {
              if (nextData.link) {
                return (
                  <ListItem key={id} onClick={() => navigateToStore(nextData.to)}>
                    <ListItemText
                      classes={{
                        primary: styles.gridTabItem
                      }}
                      primary={replaceUnicodeChars(nextData.title)}
                    />
                  </ListItem>
                );
              } else {
                return <NestedListHeader styles={styles.gridTabItem} title={nextData.title} items={nextData.content} />;
              }
            })}
          </List>
        </AppDialog>
      </Box>
    </>
  );
};

export default StoreHomeTabHeader;
