import React from "react";
import GiftDetailsOrderInformation from "./GiftDetailsOrderInformation";
import GiftDetailsShippingInformation from "./GiftDetailsShippingInformation";
import { Box, Typography } from "@material-ui/core";
import { GiftDetailsStyles } from "./GiftDetails";
import { GiftDetailsType } from "./GiftDetailsTypes";
import { SectionHeaderTitle } from "../ProfileComponents";

const GiftDetailsOrderDetails = ({
  orderData,
  isOrder
}: {
  orderData: GiftDetailsType;
  isOrder: boolean;
}) => {
  const giftDetailsStyles = GiftDetailsStyles();
  return (
    <Box className={giftDetailsStyles.column}>
      <SectionHeaderTitle text="Gift Details" />
      <GiftDetailsOrderInformation orderData={orderData} />
      <GiftDetailsShippingInformation orderData={orderData} isOrder={isOrder} />
    </Box>
  );
};

export default GiftDetailsOrderDetails;
