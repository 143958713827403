import { createContext } from "react";

interface User {
  user?: {
    get: any;
    set: any;
  };
}

export const UserContext = createContext<User>({});
