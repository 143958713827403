import React, { MouseEvent, useState, useCallback, useEffect } from "react";
import { Box, styled, BoxProps } from "@material-ui/core";
import CardMedia from "./CardMedia";
import { GiftInterface } from "../../../models/gift";
import WishlistButton from "../../buttons/WishlistButton";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { addWishlist, removeWishlist } from "../../../actions/wishlistAction";
import { decrementUserWishlistCount, incrementUserWishlistCount } from "../../../reducers/user/UserSlice";
import useNav from "../../../hooks/useNav";
import { ERROR_PATH } from "../../../constants/paths";
import { useApp } from "../../../context/app-context";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";
import { CURRENCY } from "../../common/Constants";
import PrimaryButtonV3 from "../../buttons/PrimaryButtonV3";
import { useHistory } from "react-router-dom";
import { updateRecipientsToLocalStorage } from "../../../utils/localStorage";

interface GiftItemProps extends BoxProps {
  gift: GiftInterface;
  wishlistButtonCallback?: () => void;
  showWishlistButton?: boolean;
  showOverlay?: boolean;
  isWishlistPage?: boolean;
  receiverDetails?: any;
  onGiftClicked?: () => void;
}

const StyledGiftItem = styled(Box)(() => ({
  marginBottom: 30,
  position: "relative",
  borderRadius: 8
}));

const Overlay = styled(Box)(() => ({
  backgroundColor: "rgb(255 255 255 / 38%)",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  borderRadius: 8
}));

const GiftItem = ({
  gift,
  wishlistButtonCallback,
  showOverlay = true,
  showWishlistButton = true,
  receiverDetails,
  isWishlistPage = false,
  onGiftClicked
}: GiftItemProps) => {
  const { goTo } = useNav();
  const { setPromptLogin, setToastMessage } = useApp();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { encryptedUserId, wishlistCount, nickname } = useAppSelector((state) => state.user.data);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addedToWishList, setAddedToWishlist] = useState<boolean>(gift.is_in_user_wishlist);

  useEffect(() => {
    setAddedToWishlist(gift.is_in_user_wishlist);
  }, [gift]);

  useEffect(() => {
    // update to recipients to local storage
    updateRecipientsToLocalStorage({
      nickname,
      giftCount: wishlistCount,
      userId: encryptedUserId
    });
  }, [wishlistCount]);

  const addGiftToWishlist = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsLoading(true);
      if (isLoading) return false;

      if (!encryptedUserId) {
        setPromptLogin({
          promptLogin: true,
          returnTo: undefined,
          registration: false
        });
        return;
      }

      dispatch(incrementUserWishlistCount());
      setAddedToWishlist(true);

      try {
        await addWishlist(gift);
        dispatch(incrementUserWishlistCount());
      } catch (error) {
        dispatch(decrementUserWishlistCount());
        setAddedToWishlist(false);
        if (error.response.status === 500) goTo(ERROR_PATH);
      } finally {
        setIsLoading(false);
      }
    },
    [gift, isLoading]
  );

  const removeGiftFromWishlist = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsLoading(true);
      if (isLoading) return false;

      dispatch(decrementUserWishlistCount());
      setAddedToWishlist(false);

      try {
        await removeWishlist(gift);
        setToastMessage({ message1: gift.name, message2: "Removed from wishlist" });
        wishlistButtonCallback();
      } catch (error) {
        dispatch(incrementUserWishlistCount());
        setAddedToWishlist(false);
        if (error.response.status === 500) goTo(ERROR_PATH);
      } finally {
        setIsLoading(false);
      }
    },
    [gift, isLoading]
  );

  return (
    <StyledGiftItem>
      <CardMedia
        title={gift.name}
        titleStyle={{
          fontSize: 16,
          lineHeight: "22px",
          fontWeight: "400 !important",
          marginTop: "2px"
        }}
        subTitle={`${CURRENCY}${roundToDecimalPlaces(gift.pricing.true_price_converted, 2)}`}
        src={gift.image_url_high_res}
        bgSize="contain"
        height={"auto"}
        color="textSecondary"
        redirectTo={onGiftClicked}
      >
        {isWishlistPage && (
          <PrimaryButtonV3 onClick={onGiftClicked} style={{ width: "100%", height: 40, fontSize: 14, marginTop: 12 }}>
            Buy
          </PrimaryButtonV3>
        )}
      </CardMedia>
      {showWishlistButton && (
        <WishlistButton
          addedToWishlist={addedToWishList}
          handleClick={(e) => {
            addedToWishList ? removeGiftFromWishlist(e) : addGiftToWishlist(e);
          }}
          style={{
            top: 10,
            right: 10,
            height: "48px",
            width: "48px"
          }}
        />
      )}
      {isLoading && showOverlay && <Overlay />}
    </StyledGiftItem>
  );
};

export default GiftItem;
