import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { FormattedProductDetails } from "./FormattedProductDetails";
import { GiftAddedToWishlistCount } from "components/common/gift/GiftAddedToWishlistCount";
import { GiftPriceMobile } from "components/common/gift/GiftPriceMobile";
import GiftOptionsSelector from "components/gift/GiftOptionsSelector";

const ContainerStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  imgColumn: {
    display: "flex",
    flexDirection: "row",
    flexBasis: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "40px",
    paddingBottom: "40px",
    maxWidth: "150px",
    margin: "auto"
  },
  wishlistCount: {
    display: "flex",
    flexDirection: "row",
    flexBasis: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    margin: "auto"
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    flexBasis: "100%"
  },
  TextHeaderContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  TextHeaderContainerLeft: {
    display: "flex",
    flexBasis: "70%",
    flexWrap: "wrap"
  },
  TextHeaderContainerRight: {
    display: "flex",
    flexBasis: "30%",
    textAlign: "right",
    flexWrap: "wrap"
  },
  descriptionColumn: {
    paddingTop: "10px",
    paddingBottom: "30px"
  },
  DetailedFeaturesTitle: {
    paddingBottom: "10px"
  },
  ButtonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "37px"
  },
  ButtonsContainerPerColumn: {
    display: "flex",
    flexBasis: "50%",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const StoreProductContainerMobile = (props) => {
  const styles = ContainerStyles();
  const {
    gift,
    selectedGiftOptions,
    setSelectedGiftOptions,
    isMyWishlist,
    addGiftToWishlist,
    removeGiftFromWishlist,
    handleBuyGift
  } = props;

  const finalBrand = gift.brand || gift.name;
  const finalName = gift.brand ? gift.name : "";

  const [containerWidth, setContainerWidth] = useState(300);

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth - 33);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
  });

  return (
    <Box width={containerWidth}>
      <Box className={styles.imgColumn} width={containerWidth}>
        <img src={gift.image_url} alt={"product image"} />
      </Box>
      <Box className={styles.wishlistCount}>
        <GiftAddedToWishlistCount gift={gift} />
      </Box>
      <Box className={styles.headerColumn}>
        <Box className={styles.TextHeaderContainerLeft}>
          <Box>
            <Typography style={{ fontWeight: "bold" }}>{finalBrand}</Typography>
          </Box>
          <Box>
            <Typography>{finalName}</Typography>
          </Box>
        </Box>
        <GiftPriceMobile gift={gift} />
      </Box>
      {isMyWishlist ? (
        <GiftOptionsSelector
          selectedGiftOptions={selectedGiftOptions}
          setSelectedGiftOptions={setSelectedGiftOptions}
        />
      ) : null}
      <Box className={styles.descriptionColumn}>
        <FormattedProductDetails {...gift} />
      </Box>
      {gift.features && gift.features.length > 0 && (
        <Box>
          <Box className={styles.DetailedFeaturesTitle}>
            <Typography style={{ fontWeight: "bold" }}>Detailed Features</Typography>
          </Box>
          <Box>
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: "150px" }}>Feature</th>
                  <th style={{ minWidth: "150px" }}>Value</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {gift.features.map((feature) => (
                  <tr key={feature.feature_name + "_" + feature.feature_value}>
                    <td>{feature.feature_name}</td>
                    <td>{feature.feature_value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>
      )}
      <Box className={styles.ButtonsContainer}>
        <Box className={styles.ButtonsContainerPerColumn}>
          {props.isMyWishlist && (
            <SecondaryButton
              text={gift.is_in_user_wishlist ? "♡ Added to wishlist" : "♡ Add to wishlist"}
              style={{ color: "#E92983", fontSize: "12px", margin: "auto" }}
              handleClick={gift.is_in_user_wishlist ? removeGiftFromWishlist : addGiftToWishlist}
            />
          )}
        </Box>
        <Box className={styles.ButtonsContainerPerColumn}>
          <PrimaryButton style={{ color: "#FFFFFF", fontSize: "12px", margin: "auto" }} handleClick={handleBuyGift}>
            Buy now
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default StoreProductContainerMobile;
